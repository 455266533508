// @flow

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Button } from "../../ButtonDefault";
import InputStyledCheckbox from "../../InputStyledCheckbox";
import * as Style from "../styledComponents";
import ArrowGrey from "../../../../root/img/svg/arrow-grey.svg";

import { __ } from "../../../lib/translate";
import apiMethod from "../../../api/apiMethod";
import { loadUsers } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

const InviteUsersToTeamsModal = ({
  user,
  resetModalAction = () => {},
  isOpen = false,
  handleClose = () => { },
}: { user: any, resetModalAction: any }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [list, setList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const userTeams = user.teams;
  const teams = useSelector(({ business }) => business.teams);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const checkboxes = document.getElementById("checkboxes");
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        checkboxes.style.display = "none";
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const teamsList = teams.filter((team) => team.active).map(team => {
      const { name, id } = team;
      const selectedTeam = userTeams.find(({ team: { id: teamId } }) => id === teamId);
      return {
        title: name,
        href: false,
        extra: { id: id, isChecked: selectedTeam !== undefined }
      };
    });

    teamsList.sort(
      ({ extra: { isChecked: isAChecked } }, { extra: { isChecked: isBChecked } }) =>
        isBChecked - isAChecked
    );
    setList(teamsList);
  }, [teams, userTeams]);

  const onCheckboxChange = (e, index) => {
    const target = e.target;
    const updatedList = [...list];

    updatedList[index].extra.isChecked = target.checked;
    setList(updatedList);
  };

  const renderTeamOption = ({ title, extra: { id, isChecked } }, index) => {
    if (id === 0) {
      return null;
    }

    return (
      <CheckboxWrapper key={id}>
        <InputStyledCheckbox
          name={id}
          size="small"
          checked={isChecked}
          onChange={e => onCheckboxChange(e, index)}
        >
          <Team>{title}</Team>
        </InputStyledCheckbox>
      </CheckboxWrapper>
    );
  };

  const showCheckboxes = () => {
    const checkboxes = document.getElementById("checkboxes");
    if (!isExpanded) {
      checkboxes.style.display = "block";
      setIsExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setIsExpanded(false);
    }
  };

  const addToTeams = () => {
    const { id: memberId } = user;

    if (isLoading) {
      return;
    }
    setIsLoading(true);
    var batch = new batchApiCall({
      continueOnError: true,
      errorCallback: function(ret) {
        setIsLoading(false);
        if (ret.result !== 2106) {
          setError(__("Error adding users."));
        }
      }
    });

    list.map(({ extra: { id: teamId, isChecked } }) => {
      const isUserInTeam = userTeams.find(({ team: { id } }) => id === teamId);

      if (isChecked && !isUserInTeam) {
        batch.addCall("account_teamlink", { userid: memberId, teamid: teamId });
      } else if (!isChecked && isUserInTeam) {
        batch.addCall("account_teamunlink", { userid: memberId, teamid: teamId });
      }
    });

    batch.execute(function() {
      if (error) {
        HFN.message(error, "error");
        setIsLoading(false);
      } else {
        fetchData();
        HFN.message(__("User added to team(s)."));
      }
      handleClose();
    });
  };

  const fetchData = () => {
    apiMethod(
      "account_users",
      {
        userpermissions: 1,
        withavatars: 1,
        tracksettings: 1,
        avatarsize: "128x128",
        usersettings: 1,
        showteams: 1,
        showeveryone: 1
      },
      (ret) => {
        setIsLoading(false);
        dispatch(loadUsers(ret.users));
      },
      { forceFresh: true }
    );
  };

  const { firstname: memberFirstName, lastname: memberLastName, email: memberEmail } = user;

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      cancelLabel={__("Close")}
      opened={isOpen}
      title={__("modal_add_user_to_teams_header", "Add User to team(s)")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          loading={isLoading}
          onClick={addToTeams}
        >
          {__("insert_invoice_info", "Add")}
        </Button>
      }
    >
      <BoxReset>
        <Style.User>
          <Style.UserLabel>{__("User")}</Style.UserLabel>
          <Style.UserData>
            <Style.Name>{`${memberFirstName} ${memberLastName}`}</Style.Name>
            <Style.Email>({memberEmail})</Style.Email>
          </Style.UserData>
        </Style.User>
      </BoxReset>
      <Style.UserLabel>{__("modal_add_user_to_teams_label_teams", "Team(s)")}</Style.UserLabel>
      <MultiSelectWrapper>
        <MultiSelect ref={wrapperRef}>
          <SelectBox className="selectBox" onClick={showCheckboxes}>
            <Select isExpanded={isExpanded}>
              <option>{__("Choose")}</option>
            </Select>
            <ArrowIconSelect position={isExpanded ? "DOWN" : "UP"} width="12.7" height="12.7" />
            <OverSelect />
          </SelectBox>
          <Options id="checkboxes">{list.map(renderTeamOption)}</Options>
        </MultiSelect>
      </MultiSelectWrapper>
    </ModalTemplate>
  );
};

export default InviteUsersToTeamsModal;

const MultiSelectWrapper = styled.div`
    margin-top: 4px;
  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }
`;

const MultiSelect = styled.div`
  width: 100%;
`;

const SelectBox = styled.div`
  position: relative;
`;

const OverSelect = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: normal;
  box-sizing: border-box;
  padding: 10px 15px 10px 10px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  color: ${props => (props.isExpanded ? "#999999" : "#000000")};
  border-bottom-left-radius: ${props => (props.isExpanded ? "0" : "6px")};
  border-bottom-right-radius: ${props => (props.isExpanded ? "0" : "6px")};
  box-shadow: ${props => (props.isExpanded ? "0" : "0 1px 2px 0 rgba(0, 0, 0, 0.07)")};
  appearance: none;
`;

const Options = styled.div`
  display: none;
  position: fixed;
  width: calc(100% - 60px);
  box-sizing: border-box;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #dddddd;
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Team = styled.div`
  margin-left: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  box-sizing: border-box;

  label {
    width: 100%;
    text-align: left;
    padding: 10px 10px 10px 20px;
  }

  &:hover {
    background-color: #eee;
  }

  span {
    margin: 10px;
  }
`;

const BoxReset = styled.div`
  margin-bottom: 17px;
`;

const ArrowIconSelect = styled(ArrowGrey)`
  position: absolute;
  right: 10px;
  transform: ${props => (props.position === "UP" ? "rotate(180deg)" : "rotate(0deg)")};
  top: 15px;
`;
