import React from "react";
import styled from "styled-components";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";

const BusinessLogsHeader = () => {
  return (
    <Wrapper>
      <Element />
      <MobileWrapper>
        <Element />
        <ElementActionBtn />
      </MobileWrapper>
    </Wrapper>
  );
};

export default BusinessLogsHeader;

const Wrapper = styled.div`
  padding: 0 0 var(--spacing-sm) var(--spacing-sm);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;


  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    padding: 0 var(--spacing-md);
  }
`;
const Element = styled.div`
    background-color: var(--surface-skeleton);
    border-radius: var(--radius-minimum);
    width: 500px;
    height: 32px;
    
    &.for-mobile {
        display: none;
    }

    @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
        width: 100%;
    }
`;
const ElementActionBtn = styled(Element)`
  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    width: 18%!important;
  }
`;
const MobileWrapper = styled.div`
    display: none;
    @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: space-between;
    }
    
    & ${Element} {
        width: 80%;
    }
`;