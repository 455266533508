import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { TDiv, TSpan } from "../intl";
import { Btn } from "../ButtonDefault";

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  min-height: 212px;
`;

export const CancelShareWrapper = styled(Wrapper)`
  min-height: auto;
  padding: 0;
`;

export const CryptoPassWrapper = styled(Wrapper)`
  min-height: auto;
  padding: 0;
`;

export const InviteOtherRegionWrapper = styled(Wrapper)`
  min-height: auto;
  padding: 0;
`;

export const InviteWithChangePermissionWrapper = styled(Wrapper)`
  min-height: auto;
  padding: 0;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 6px;

  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
`;

export const EmptyTable = styled(Table)`
  min-height: 150px;

  text-align: center;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  color: #999999;
  background-color: #fafafa;
`;

export const Row = styled.div`
  display: flex;
  padding-left: 10px;
  font-size: 13px;
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:nth-child(odd) {
    background-color: #fafafa;
  }
`;

export const Email = styled.div`
  margin-left: 10px;
  text-align: left;
  font-size: 13px;
`;

export const RemoveButton = styled.div`
  display: block;
  width: 9px;
  height: 9px;
  cursor: pointer;
  margin: 0 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-icons/cancel.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/cancel@2x.png")});
  }
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.success-dff-region {
    margin-top: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props => (props.disable ? 0.5 : 1)};
`;

export const CreatePassButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 26px;
  line-height: 10px;
  font-size: 12px;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 87px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #c3c3c3;
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
`;

export const MessageWrapper = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 15px;
  flex-direction: column;
  align-items: center;
  border: solid 1px #fcf2d4;
  background-color: #fff8df;
  border-radius: 5px;
`;

export const MessageSuccWrapper = styled(MessageWrapper)`
  box-sizing: border-box;
  width: 100%;
  padding: 17px;
  border: none;
  background-color: #fff;
  margin-bottom: 0;
  padding: 0;
`;

export const MessageIcon = styled.div`
  display: block;
  width: 22px;
  height: 26px;
  margin-bottom: 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-icons/orange-key.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/orange-key@2x.png")});
  }
`;

export const WarningIcon = styled(MessageIcon)`
  width: 20px;
  height: 24px;
  background-image: url(${require("../../../root/img/share-icons/attention.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/attention@2x.png")});
  }
`;

export const OkIcon = styled(MessageIcon)`
 margin-bottom: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${require("../../../root/img/ok-icon.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/ok-icon@2x.png")});
  }
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;

    @media (max-width: 500px) {
        display: none;
    }
`;

export const WrapperCreatePass = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled.div`
  padding: 26px 70px 30px 70px;
`;

export const TitleCreatePass = styled.div`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #000000;
`;

export const Strength = styled.div`
  font-size: 13px;
  text-align: right;
  color: ${props => {
    const colors = { grey: "#888888", red: "#ff2626", orange: "#ff9326", green: "#88cc17" };
    return colors[props.color] || "#888888";
  }};
  padding-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  font-size: 13px;
  padding: 0 14px;
  margin-bottom: 25px;
  border-radius: 5px;
  border: solid 1px #a1a1a1;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
`;

export const WrapperLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PassStrengWrap = styled.div`
  position: relative;
  top: -20px;
  /* left: 423px; */
`;

export const Checkmark = styled.span`
  position: absolute;
  right: 16px;
  bottom: 37px;
  width: 18px;
  height: 18px;

  &:after {
    content: "";
    position: absolute;
    display: block;
    left: 8px;
    top: 0px;
    width: 7px;
    height: 13px;
    border: solid #88cc17;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  color: #fc344c;
`;

export const MessageCancel = styled(props => <Message {...props} />)`
  margin-bottom: 20px;
`;

export const GoBackButton = styled.div`
  font-size: 13px;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  color: #17bed0;
`;

export const WrapperInput = styled.div`
  position: relative;
`;

export const Label = styled(TDiv)`
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
  text-align: left;
`;

export const StopAccessTitle = styled.div`
  display: block;
  font-size: 17px;
  margin-bottom: 25px;
  font-weight: bold;
  text-align: center;
`;

export const Message = styled.div`
  width: 100%;
  font-size: 13px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;

  a {
    color: #000;
  }
`;

export const StopAcessButton = styled(props => <Btn {...props} />)`
  width: auto;
  min-width: 120px;
  margin: 0 10px;
`;

export const FooterStopAccess = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  background: #eee;
  border-bottom: 1px solid #e9e9e9;
`;

export const InviteMoreButton = styled(Btn)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  margin-top: 30px;

  &.success-diff-region {
    margin-top: 20px;
  }
`;

export const SuccesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
