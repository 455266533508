// @flow
import React, { useEffect, useState } from "react";
import GooglePhotosLink from "./backupsaccounts/GooglePhotosLink";
import * as Style from "./preferences.style";
import * as CommonStyle from "../../components/CommonStyledSettings";
import GoogleDriveLink from "./backupsaccounts/GoogleDriveLink";
import OneDriveLink from "./backupsaccounts/OneDriveLink";
import DropBoxLink from "./backupsaccounts/DropBoxLink";
import DropBoxBusinessLink from "./backupsaccounts/DropBoxBusinessLink";
import FacebookLink from "./backupsaccounts/FacebookLink";
import InstagramLink from "./backupsaccounts/InstagramLink";

const BackupAccountsSetting = () => (
  <>
    <CommonStyle.Row>
      <CommonStyle.Title>{__("Backups")}</CommonStyle.Title>
    </CommonStyle.Row>
    <Style.TableWrapper>
      <thead>
        <Style.HeaderRow>
          <Style.HeaderCol style={{ width: "25%" }}> {__("App Name")}</Style.HeaderCol>
          <Style.HeaderCol style={{ width: "50%" }}> {__("Account")}</Style.HeaderCol>
          <Style.HeaderCol align="right" style={{ width: "25%" }}> {__("family_member_list_col_action", "Action")}</Style.HeaderCol>
        </Style.HeaderRow>
      </thead>
      <tbody>
        <GooglePhotosLink/>
        <GoogleDriveLink/>
        <OneDriveLink/>
        <DropBoxLink/>
        <DropBoxBusinessLink/>
        <FacebookLink/>
        <InstagramLink/>
      </tbody>
    </Style.TableWrapper>
  </>
);

export default BackupAccountsSetting;

