export const actionTitles = {
  EDIT: "Edit",
  LOG: "User Logs",
  RESET_PASSWORD: "Reset Password",
  VIEW_AS: "View As",
  LOGIN_AS: "login_as",
  DEACTIVATE: "Deactivate",
  ADD_PROFILE_PICTURE: "BA_add_profile_picture",
  EDIT_PROFILE_PICTURE: "BA_edit_profile_picture"
}

export const moreOptionsActions = [
  {
    title: actionTitles.EDIT,
    icon: "fa-light fa-edit smallIcon lightColorIcon",
  },
  {
    title: actionTitles.LOG,
    icon: "fa-light fa-rectangle-history-circle-user smallIcon lightColorIcon",
  },
  {
    title: actionTitles.RESET_PASSWORD,
    icon: "fa-light fa-unlock smallIcon lightColorIcon",
  },
  {
    title: actionTitles.VIEW_AS,
    icon: "fa-light fa-eye smallIcon lightColorIcon",
  },
  {
    title: actionTitles.LOGIN_AS,
    icon: "fa-light fa-arrow-right-to-bracket smallIcon lightColorIcon",
  },
  {
    title: actionTitles.DEACTIVATE,
    icon: "fa-light fa-ban smallIcon lightColorIcon",
  },
  {
    title: actionTitles.ADD_PROFILE_PICTURE,
    icon: "fa-light fa-pen smallIcon lightColorIcon",
  },
  {
    title: actionTitles.EDIT_PROFILE_PICTURE,
    icon: "fa-light fa-pen smallIcon lightColorIcon",
  }
]