// @flow

import React, { useState, useEffect, useRef } from "react";
import Componentify from "react-componentify";

import { List } from "immutable";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Btn } from "../ButtonDefault";
import InputStyledCheckbox from "../InputStyledCheckbox";
import Picker from "../Picker/Picker";
import PickerSelectedItems from "../Picker/PickerSelectedItems";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { abortAll } from "../../lib/XhrComponentUtils";
import { boldConverter } from "../../lib/componentifyConverters";
import { errorKeys } from "../../lib/errors";

const generalErrors = [2341, 2346, 2206, 2023, 2005, 2002, 2207];
const generalErrorsClose = [2008, 2003, 2352, 2208];
// 2005 - Directory does not exist.
// 2341 - Shared folders can't be placed inside backup folders.
// 2346 - You cannot add files/folders to this backup folder.
// 2206 - Can not copy folder into itself.
// 2023 - You cannot place a shared folder into another shared folder
// 2208 - The destination folder no longer exists.

type Props = {
  folder: any,
  fileList: Array<any>,
  opts: any,
  modalContainer?: any
};

const CopyModal = ({
  folder = { folderid: 0, name: "/", contents: null },
  fileList = [],
  opts = {},
  modalContainer
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [applyAll, setApplyAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copyList, setCopyList] = useState(List());
  const [itemsAlreadyExist, setItemsAlreadyExist] = useState(List());
  const [successMesssage, setSuccessMessage] = useState("");
  const xhrs = [];
  const xhrOverwrite = [];
  const iconRef = useRef(null);

  useEffect(() => {
    setIsOpen(true);
    setLoading(true);
  }, []);

  useEffect(() => {
    let list = List();
    fileList.forEach((item, index) => {
      let method = "";
      let params = { tofolderid: null, noover: 1 };

      if (item.isfolder) {
        params.folderid = item.folderid;
        method = "copyfolder";
      } else {
        params.fileid = item.fileid;
        params.toname = item.name;
        method = "copyfile";
        if (item.revisionid) {
          params.revisionid = item.revisionid;
        }
      }
      console.log("copyList.size", copyList);

      list = list.set(index, {
        method: method,
        params: params,
        meta: item,
        error: ""
      });
    });
    setCopyList(list);
    setLoading(false);

    console.log("copylist", list);
  }, []); //[]

  const handleClose = () => {
    setIsOpen(false);
    abortAll(xhrs);
    abortAll(xhrOverwrite);
  };

  const onPick = ({ data }) => {
    const { itemId = -1 } = data;
    if (itemId == -1) {
      return;
    }

    copyList.map(item => {
      return (item.params.tofolderid = itemId);
    });

    onCopy();
  };

  const onCopy = () => {
    let callbacksAtOnce = [];
    let successItems = [];
    let alreadyExist = [];
    let withErrors = [];
    let hasError = false;
    let generalErrorMessage = "";

    if (loading) {
      return;
    }

    setLoading(true);
    copyList.forEach((item, index) => {
      xhrs.push(
        apiMethod(
          item.method,
          item.params,
          res => {
            callbacksAtOnce.push(() => {
              console.log("SUCC>>>>>", res);
              successItems.push(item);
            });
          },
          {
            showErrorMessage: false,
            errorCallback: ret => {
              callbacksAtOnce.push(() => {
                hasError = true;
                if (ret.result === 2004) {
                  let errMessage = "";
                  if (item.method == "copyfolder") {
                    errMessage = __(
                      "folder_exists",
                      'Files or folders under folder "<b>%name%</b>" already exist in destination folder.',
                      { name: item.meta.name }
                    );
                  } else {
                    errMessage = __(
                      "file_exists",
                      "File <b>%name%</b> already exists in destination folder.",
                      { name: item.meta.name }
                    );
                  }

                  item.error = errMessage;
                  alreadyExist.push(item);
                } else if (generalErrorsClose.indexOf(ret.result) !== -1) {
                  const destinationFolder = HFN.data.fflookup["d" + item.params.tofolderid];

                  if (ret.result === 2008 && destinationFolder && !destinationFolder.ismine) {
                    generalErrorMessage = __(
                      "picker_shared_folder_full_quota",
                      "The owner of this folder has no available storage in their account. You won't be able to perform any action in this folder."
                    );
                  } else {
                    generalErrorMessage = __(errorKeys[ret.result]);
                  }
                } else if (generalErrors.indexOf(ret.result) !== -1) {
                  let error = errorKeys[ret.result];
                  if (ret.result === 2002) {
                    error = "error_2208"; // The destination folder no longer exists.
                  }
                  console.log("Error", error);
                  item.error = __(error);
                  withErrors.push(item);
                } else {
                  console.log("unhandle Error", ret);
                  generalErrorMessage = __("something_went_wrong_refresh_and_try_again");
                }
              });
            }
          }
        )
      );
    });

    $.when.apply($, xhrs).then(() => {
      let shouldClose = true;
      let copyListFilter = copyList;

      for (let n = 0; n < callbacksAtOnce.length; ++n) {
        callbacksAtOnce[n]();
      }

      if (alreadyExist.length !== 0) {
        shouldClose = false;
        setItemsAlreadyExist(List(alreadyExist));
      }

      if (withErrors.length !== 0) {
        shouldClose = false;
        copyListFilter = List(withErrors);
      }

      if (successItems.length) {
        setSuccessMessage(__("Items are copied."));
        successItems.forEach(item => {
          copyListFilter = copyListFilter.filter(el => el.meta.id !== item.meta.id);
        });
      }
      setCopyList(copyListFilter);

      if (!hasError) {
        HFN.message(__("Items are copied."));
      }

      if (generalErrorMessage.length || shouldClose || !copyListFilter.size) {
        generalErrorMessage.length && HFN.message(generalErrorMessage, "error");
        handleClose();
      }
      setLoading(false);
    });
  };

  const onSkipClick = () => {
    let copyListFilter = copyList;

    if (applyAll) {
      itemsAlreadyExist.forEach(item => {
        copyListFilter = copyListFilter.filter(el => {
          return el.meta.id !== item.meta.id;
        });
      });
    } else {
      const skipElement = itemsAlreadyExist.first();
      copyListFilter = copyListFilter.filter(el => {
        return el.meta.id !== skipElement.meta.id;
      });
    }

    if (applyAll) {
      setItemsAlreadyExist(List());
    } else {
      setItemsAlreadyExist(itemsAlreadyExist.delete(0));
    }

    setCopyList(copyListFilter);
    if (!copyListFilter.size) {
      handleClose();
      if (successMesssage) {
        HFN.message(successMesssage);
      }
    }
  };

  const overwrite = (item, callbacksAtOnce = []) => {
    delete item.params.noover;
    if (item.params.skipexisting) {
      delete item.params.skipexisting;
    }

    return apiMethod(
      item.method,
      item.params,
      res => {
        callbacksAtOnce.push(() => {
          console.log("copy SUCC>>>", res);
          setSuccessMessage(__("Items are copied."));
        });
      },
      {
        showErrorMessage: false,
        errorCallback: ret => {
          callbacksAtOnce.push(() => {
            console.log("copy ERROR>>>", ret);
          });
        }
      }
    );
  };

  const onOverwriteClick = () => {
    let callbacksAtOnce = [];
    let successItems = [];
    let copyListFilter = copyList;
    let hasError = false;

    const overwrite = (item) => {
      delete item.params.noover;
      if (item.params.skipexisting) {
        delete item.params.skipexisting;
      }
  
      xhrOverwrite.push(
        apiMethod(
          item.method,
          item.params,
          res => {
            callbacksAtOnce.push(() => {
              console.log("copy SUCC>>>", res);
              successItems.push(res);
            });
          },
          {
            showErrorMessage: false,
            errorCallback: ret => {
              callbacksAtOnce.push(() => {
                hasError = true;
                console.log("copy ERROR>>>", ret);
              });
            }
          }
        )
      );
    };

    if (applyAll) {
      itemsAlreadyExist.forEach(item => {
        copyListFilter = copyListFilter.filter(el => {
          return el.meta.id !== item.meta.id;
        });

        overwrite(item);
      });
    } else {
      const overwriteElement = itemsAlreadyExist.first();
      copyListFilter = copyListFilter.filter(el => {
        return el.meta.id !== overwriteElement.meta.id;
      });
      overwrite(overwriteElement);
    }

    $.when.apply($, xhrOverwrite).then(() => {
      for (let n = 0; n < callbacksAtOnce.length; ++n) {
        callbacksAtOnce[n]();
      }

      if (applyAll) {
        setItemsAlreadyExist(List());
      } else {
        setItemsAlreadyExist(itemsAlreadyExist.delete(0));
      }

      setCopyList(copyListFilter);
      if (!copyListFilter.size) {
        if (successItems.length) {
          HFN.message(__("Item(s) moved."));
        } else if (hasError) {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
        }
        handleClose();
      }
    });
  };

  const renderAlreadyExist = item => {
    return (
      <React.Fragment>
        <Style.ExistErrorMessage>
          <Componentify text={item.error || ""} converters={[boldConverter]} />
        </Style.ExistErrorMessage>
        <Style.CheckboxWrapper>
          <Style.CheckboxLabel for="apply">
            <InputStyledCheckbox
              id="apply"
              name="applyAll"
              size="small"
              checked={applyAll}
              onChange={e => setApplyAll(e.target.checked)}
            />
            <Style.CheckboxLabelText>{__("Apply for all")}</Style.CheckboxLabelText>
          </Style.CheckboxLabel>
        </Style.CheckboxWrapper>
        <Style.Footer>
          <Btn
            color="lightgray4"
            className="skip"
            style={{
              marginRight: "5px"
            }}
            disabled={loading}
            onClick={onSkipClick}
          >
            {__("Skip")}
          </Btn>
          <Btn
            color="red"
            className="overwrite"
            style={{
              marginLeft: "5px"
            }}
            disabled={loading}
            onClick={onOverwriteClick}
          >
            {__("Overwrite")}
          </Btn>
        </Style.Footer>
      </React.Fragment>
    );
  };

  const onRemoveRow = index => {
    if (copyList.size === 1) {
      handleClose();
    } else {
      setCopyList(copyList.delete(index));
    }
  };

  const renderMoveBody = () => {
    if (itemsAlreadyExist.size) {
      return renderAlreadyExist(itemsAlreadyExist.first());
    } else {
      return (
        <Body key="body">
          <LabelCopy>{__("selected_items", "Items")}</LabelCopy>
          <PickerSelectedItems list={copyList} onRemoveItem={onRemoveRow} />
          <LabelCopy>{__("copy_modal_copy_to", "Copy to")}</LabelCopy>
          <Picker
            folder={folder}
            opts={Object.assign(opts, { buttonText: "Copy" })}
            onPick={onPick}
            onCancel={handleClose}
          />
        </Body>
      );
    }
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen} container={modalContainer}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <HeaderCopy>{__("Copy")}</HeaderCopy>
          {loading ? (
            <Style.LoaderWrapper>
              <Style.Loader />
            </Style.LoaderWrapper>
          ) : null}
          {renderMoveBody()}
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default CopyModal;

const LabelCopy = styled(Style.Label)`
  height: 18px;
  margin-bottom: 5px;
`;

const HeaderCopy = styled(Style.Header)`
  margin-bottom: 40px;
`;

const ErrorMessage = styled.div`
  font-size: 15px;
  font-weight: normal;
  text-align: left;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  min-height: 450px;
  width: 420px;
  z-index: 1000;
  background-color: #fff;
`;

const Body = styled.div``;
