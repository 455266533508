// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Btn } from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import { linkConverter } from "../../lib/componentifyConverters";

const NoPdfReaderModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const url = "http://get.adobe.com/reader/otherversions/";
  const msg = __(
    "Your web browser cannot open this file because you do not have a PDF reader installed on your computer. Install one from: %url%"
  ).replace("%url%", url);

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>
            {__("No_pdf_reader_installed_title", "You do not have a PDF reader installed")}
          </Style.Header>
          <Style.Message>
            <Componentify text={msg} converters={[linkConverter]} />
          </Style.Message>
          <Style.Footer>
            <Btn
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Btn>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default NoPdfReaderModal;

const HeaderLogo = styled(Style.Header)`
  margin-bottom: 30px;
`;
