// @flow

import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import { validateEmail } from "../lib/utils";
import { getSharedContacts } from "../lib/shareFolder-utils";
import { TDiv } from "./intl";
import InputTextarea from "./InputTextarea";
import { Btn } from "./ButtonDefault";

type Props = {
  formTitle: string,
  buttonText: string,
  shouldRenderTextarea: boolean,
  apiCall: string,
  opts: object,
  beforeSubmit: () => void,
  onSuccess: () => void,
  onError: () => void
};

const InviteViaEmail = ({
  formTitle = __("share_folder_modal_send_to", "Send to"),
  buttonText = __("Send"),
  shouldRenderTextarea = false,
  apiCall,
  opts = {},
  type,
  beforeSubmit,
  onSuccess = () => {},
  onError = () => {}
}: Props) => {
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const myEmail = useSelector(({ pCloudUser }) => pCloudUser.userinfo.email);
  const userContacts = useSelector(({ pCloudUser }) => pCloudUser.userContacts);
  const bUserContacts = useSelector(({ business }) => business.users);

  const [contacts, setContacts] = useState([]);
  const [inputPrepared, setInputPrepared] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [vals, setVals] = useState(0);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [combo, setCombo] = useState({});
  const [qEngine, setqEngine] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const inputTexarea = useRef(null);
  const inputEmails = useRef(null);

  const textareMinHeight = 63;

  const prepareSendInput = useCallback(
    contacts => {
      console.log("Prepare send input >>>>>>>>>>>", contacts);
      if (inputPrepared || !inputEmails.current) {
        return;
      }

      setInputPrepared(true);

      const tokenizer = name => {
        const ret = Bloodhound.tokenizers.whitespace(name.replace(/\(?\)?/g, ""));

        if (ret.indexOf(name.replace(/\(?\)?/g, "")) == -1) {
          ret.push(name.replace(/\(?\)?/g, ""))
        };

        return ret;
      };

      let qEngine = new Bloodhound({
        name: "name",
        datumTokenizer: r => {
          const ret = Bloodhound.tokenizers.whitespace(r.name.replace(/\(?\)?/g, ""));

          if (ret.indexOf(r.name.replace(/\(?\)?/g, "")) == -1) {
            ret.push(r.name.replace(/\(?\)?/g, ""));
          }
          if (validateEmail(r.value) && ret.indexOf(r.value.replace(/\(?\)?/g, "")) == -1) {
            ret.push(r.value.replace(/\(?\)?/g, ""));
          }
          if (r.userobj) {
            ret.push(r.userobj.email);
          }

          return ret;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: contacts
      });

      const orig_get = qEngine.get;
      qEngine.get = (query, cb) => {
        return orig_get.apply(qEngine, [
          query,
          suggestions => {
            if (!suggestions) {
              return cb(suggestions)
            };
            suggestions.forEach(s => {
              const tokens = tokenizer(s.name.toLowerCase());
              let score = 0;

              for (var n = 0; n < tokens.length; ++n) {
                if (tokens[n].indexOf(query) == 0) {
                  score++
                };
              }
              s.exact_match = score;
            });
            console.log("suggestions!!!!", suggestions);
            suggestions.sort((a, b) => {
              return a.exact_match > b.exact_match ? -1 : a.exact_match < b.exact_match ? 1 : 0;
            });
            cb(suggestions);
          }
        ]);
      };

      qEngine.initialize();

      const ASCII_TAB = 9;
      const ASCII_CARRIAGE_RETURN = 13;
      const ASCII_ENTER = 188;
      const ASCII_SPACE = 32;

      const comboOptions = {
        place: inputEmails.current,
        name: "emails",
        width: 486,
        innerWidth: 475,
        sourceKey: "name",
        focusOnInit: false,
        // onlySuggested: true,
        source: qEngine,
        placeholder: __("Name or Email"),
        autocomplete: "off",
        maxNotFoundSymbols: 5,
        suggestOnFocus: false,
        splitBy: [ASCII_TAB, ASCII_CARRIAGE_RETURN, ASCII_ENTER, ASCII_SPACE],// tab, comma, enter, space
        minLength: 0,
        getShowVal: r => {
          if (validateEmail(r)) return HFN.strFitMail(r, 40);
          return HFN.strFit(r, 40);
        },
        onRemoveResultBox: (box, data) => {
          setVals(vals => --vals);
          box.find(".combo-res").tooltip("destroy");
        },
        fancyResultBox: (box, data) => {
          let ent;

          console.log("fancyResultBox: box.data()", box.data());
          setVals(vals => ++vals);
          setEmailInputValue('');

          if (data && data.data) {
            ent = data.data;
            if (ent.source == 2) {
              box.prepend('<img src="/img/fb-tag.png" width="16" height="16" class="inf">');
            } else if (ent.source == 3) {
              box.prepend('<img src="/img/gm-tag.png" width="16" height="16" class="inf">');
              box
                .parent()
                .tooltip("destroy")
                .attr("title", ent.value)
                .tooltip({ container: ".tomails" });
            } else if (ent.source == 1) {
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
            } else if (ent.source == 1001) {
              box.prepend(
                '<img src="' +
                  HFN.prepUrl(ent.userobj.avatar) +
                  '" width="16" height="16" class="inf">'
              );
              box
                .parent()
                .tooltip("destroy")
                .attr("title", ent.userobj.email)
                .tooltip({ container: ".tomails" });
            } else if (ent.source == 1002)
              box.prepend('<img src="/img/b/team.png" width="16" height="16" class="inf">');
          } else if (box.data("val")) {
            if (validateEmail(box.data("val"))) {
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
              box
                .parent()
                .tooltip("destroy")
                .attr("title", box.data("val"))
                .tooltip({ container: ".tomails" });
            }
          }
        },

        imgCloseFile: "",

        extraMulticompleteOpts: {
          suggestAt: inputEmails.current,
          boxRender: data => {
            const box = $("<div>"),
              img = $('<img width="32" height="32">');
            box
              .addClass("clearfix")
              .append($('<div class="iconw"></div>').append(img))
              .append(
                $('<div class="textpart"></div>')
                  .append($('<span class="title"></span>').text(data.name))
                  .append($('<span class="explain"></span>'))
              );

            if (data.source == "import") {
              if (data.value == "Facebook") {
                img.attr("src", "/img/fab.png");
                //box.addClass('fbimport');
              } else if (data.value == "Gmail") {
                img.attr("src", "/img/gmail.png");
                //box.addClass('gmailimport');
              }
              box.find(".explain").text(
                __("Import contacts from %source%", false, {
                  source: data.value
                })
              );
            } else if (data.source == 3) {
              img.attr("src", "/img/gmail.png");
              box.find(".explain").text(data.value);
            } else if (data.source == 2) {
              img.attr(
                "src",
                "https://graph.facebook.com/" + data.value + "/picture?type=square"
              );
              box.find(".iconw").append('<img src="/img/fb-over.png" class="smicon">');
              box.find(".explain").text(__("Facebook Message"));
            } else if (data.source == 1) {
              img.attr("src", "/img/or-mail.png");
              box.find(".explain").text(__("Send Email"));
            } else if (data.source == 1001) {
              img.attr("src", HFN.prepUrl(data.userobj.avatar));
              box.find(".explain").text(data.userobj.email);
            } else if (data.source == 1002) {
              img.attr("src", "/img/b/team.png");
              box.find(".explain").text(
                __("%num% member(s)", false, {
                  num: PCB.teamUserCount(data.teamobj)
                })
              );
            }
            console.log("boxRender", box);
            return box;
          }
        }
      };
      setqEngine(qEngine);
      setCombo(new combo2(comboOptions));
      
      $(document).on('change keydown paste input', '.tomails .edit > input[name="'+ comboOptions.name + '"]', function (e) {
        setEmailInputValue(this.value.trim());
      });
    },
    [inputPrepared]
  );

  useEffect(() => {
    const contacts = getSharedContacts(userContacts, bUserContacts, [], false);
    setContacts(contacts);
  }, [userContacts, bUserContacts]);

  useEffect(() => {
    if (inputPrepared && qEngine) {
      qEngine.clear();
      qEngine.local = contacts;
      qEngine.initialize(true);
    } else {
      prepareSendInput(contacts);
    }
  }, [contacts, inputPrepared, isBusiness, prepareSendInput, qEngine]);

  const onTextareaChange = () => {
    setTextareaValue(inputTexarea.current.value);
  };

  const isMe = value => {
    return myEmail === value;
  };

  const isDeactivatedOrDeteted = inputVal => {
    let result = false;

    contacts.forEach(conatct => {
      const { userobj } = conatct;
      if (userobj) {
        if (userobj.email === inputVal) {
          result = !userobj.active || userobj.frozen;
        }
      }
    });
    return result;
  };

  const onSubmitClick = () => {
    // Check if we have email values.
    if (emailInputValue == '' && vals === 0) {
      // Button is disabled.
      return false;
    }
    if (typeof gtag === "function" && type === 1) {
      gtag("event", "shared_link_settings_click", {
        action: "send email",
        category: "share",
        type: "email",
        location: "settings modal"
      })
    }

    if (beforeSubmit && typeof beforeSubmit === "function") {
      beforeSubmit(sendInvitations);
      return;
    }
    sendInvitations();
  };

  const sendInvitations = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let vals = combo.getFullVals(true);
    let message = shouldRenderTextarea ? inputTexarea.current.value : "";
    let manuallyAdded = [];
    let emails = [];
    let busers = [];

    if (vals && !vals.length) {
      return;
    }

    let hasErrorEmail = false;
    const duplicatedEmailsObj = [];

    for (let n = 0; n < vals.length; n++) {
      let error = "";
      let isGmail = false;
      let isBusinessUser = false;
      let emailCurrentVal = vals[n].val;

      if (vals[n].data && [1, 3].indexOf(vals[n].data.source) != -1) {
        // gmails
        isGmail = true;
        if (vals[n].data.value) {
          emailCurrentVal = vals[n].data.value;
        }
      } else if (vals[n].data && vals[n].data.source == 1001) {
        // business user
        isBusinessUser = true;
        if (isMe(vals[n].data.userobj.email)) {
          error = __("You can't send email to yourself.");
        } else if (isDeactivatedOrDeteted(vals[n].data.userobj.email)) {
          error = __("The user is inactive and can't receive mails.");
        }
        emailCurrentVal = vals[n].data.userobj.email;
      } else if (!validateEmail(emailCurrentVal)) {
        // not a valid email
        error = __("This is not a valid contact or an email.");
      }

      if (!hasErrorEmail && error !== "") {
        hasErrorEmail = true;
        setIsLoading(false);
      }

      if (error !== "") {
        setIsLoading(false);
        vals[n].obj
          .addClass("error")
          .find(".combo-res")
          .tooltip("destroy")
          .attr("title", __(error))
          .tooltip({ container: ".tomails" });
        continue;
      }

      // Check if this email is duplication to already saved in emails or busers
      const inEmails = emails.some((emailItem) => ((emailItem.data && emailItem.data.value) === emailCurrentVal) || ((emailItem.data && emailItem.data.val) === emailCurrentVal));
      const inBusers = busers.some((emailItem) => (emailItem.data && emailItem.data.email) === emailCurrentVal);

      if (inEmails || inBusers) {
        // Remove duplicated emails, because they will be sent later from the other item and handled with it.
        duplicatedEmailsObj.push(vals[n].obj);
        // This email is already existing for send, so skip it.
        continue;
      }
      
      if (isGmail) {
        emails.push({ data: vals[n].data, obj: vals[n].obj });
      } else if (isBusinessUser) {
        busers.push({ data: vals[n].data.userobj, obj: vals[n].obj });
      } else {
        emails.push({ data: { ...vals[n], source: 1 }, obj: vals[n].obj });
        
        if (!vals[n].data && manuallyAdded.indexOf(vals[n].val) == -1) {
          qEngine.add([{ name: vals[n].val, value: vals[n].val, source: 1 }]);
          manuallyAdded.push(vals[n].val);
        }
      }
    }

    if (hasErrorEmail) {
      setIsLoading(false);
      return;
    }

    if (!emails.length && !busers.length) {
      setIsLoading(false);
      return;
    }

    console.log("{ emails, busers }", { emails, busers });

    let allEmails = emails.concat(busers);
    let xhr = [];
    let successRet = [];
    let callbacksAtOnce = [];
    let manualToAdd = [];
    let hasError = false;
    let hasSuccess = false;

    allEmails.forEach(({ data, obj }) => {
      console.log("send email>>>>>>>>", { data, obj });
      const email = data.value || data.email || data.val;

      const params = {
        mail: email,
        mails: email,
        message: message,
        source: data.source,
        ...opts
      };

      xhr.push(
        apiMethod(
          apiCall,
          params,
          res => {
            console.log("SUCC>>>>>", res);
            hasSuccess = true;
            callbacksAtOnce.push(() => {
              successRet.push(res);
              // Link sent successfully. -> remove the combo item from input.
              obj.find(".rem").trigger("click");
            });

            if (manuallyAdded.indexOf(email) != -1) {
              manualToAdd.push(email);
            }
          },
          {
            errorCallback: ret => {
              setIsLoading(false);
              
              if (ret.result === 2019 || ret.result === 2024) {
                hasSuccess = true;
              }

              callbacksAtOnce.push(() => {
                onError();
                console.log("ERROR>>>", ret);
                if (ret.result === 2019 || ret.result === 2024) {
                  // Link sent successfully. -> remove the combo item from input.
                  obj.find(".rem").trigger("click");
                } else if (ret.result === 2018) {
                  hasError = true;
                  obj
                    .addClass("error")
                    .find(".combo-res")
                    .tooltip("destroy")
                    .attr("title", __("This is not a valid contact or an email."))
                    .tooltip({ container: ".tomails" });
                } else {
                  hasError = true;
                  obj
                    .addClass("error")
                    .find(".combo-res")
                    .tooltip("destroy")
                    .attr("title", __(ret.error))
                    .tooltip({ container: ".tomails" });
                }
              });
            },
            // Suppress showing error message, because it's handled by the email field.
            showErrorMessage: false
          }
        )
      );
    });

    $.when.apply($, xhr).then(() => {
      setIsLoading(false);
      for (let n = 0; n < callbacksAtOnce.length; ++n) {
        callbacksAtOnce[n]();
      }

      // Remove all duplcated labels in the input field. If there are errors on some they'll be shown only once.
      duplicatedEmailsObj.forEach((obj) => obj.find(".rem").trigger("click"));

      if (hasSuccess) {
        HFN.message(__("Link Sent!"));
      }

      if (!hasError && successRet.length !== 0) {
        onSuccess(successRet);
        setVals(0);
      }

      if (manualToAdd.length) {
        apiMethod("contactload", { source: 1, mails: manualToAdd.join(",") }, () => {});
        manualToAdd = [];
      }
    });
  };

  const autoGrowTextarea = () => {
    if (inputTexarea.current === null) {
      return;
    }
    if (inputTexarea.current.scrollHeight > textareMinHeight) {
      inputTexarea.current.style.height = "5px";
      inputTexarea.current.style.height = (inputTexarea.current.scrollHeight + 10) + "px";
    }
  }

  return (
    <Wrapper textareaMinHeight={textareMinHeight}>
      <React.Fragment>
        <Label id="share_folder_modal_send_to">{formTitle ? formTitle : __("Send to:")}</Label>
        <InputEmailWrapper>
          <div className="tomails" ref={inputEmails}/>
        </InputEmailWrapper>
        {shouldRenderTextarea ? (
          <InputTextarea
            className="textarea"
            innerRef={inputTexarea}
            name="message"
            value={textareaValue}
            placeholder={__("Your message (optional)")}
            autoComplete="off"
            onChange={onTextareaChange}
            onInput={autoGrowTextarea}
          />
        ) : null}

        <Footer>
          <Btn
            inline
            color="cyan"
            size="big"
            minWidth="120px"
            onClick={onSubmitClick}
            loading={isLoading}
            disabled={emailInputValue == '' && vals === 0}
            style={{ borderRadius: "3px" }}
          >
            {buttonText}
          </Btn>
        </Footer>
      </React.Fragment>
    </Wrapper>
  );
};

export default InviteViaEmail;

const Wrapper = styled.div`
  text-align: left;
  
  @media (max-width: 800px) {
    width: 100%;
  }

  .tomails {
    display: inline-block;
    width: 496px;
    font-size: 13px;
    
    @media (max-width: 800px) {
      width: 100%!important;
      min-width: auto;
    }

    .combo-contain {
      padding: 5px;
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      
      @media (max-width: 800px) {
        width: 100%!important;
      }

      .combo-contain-inner, input, .combo-wrap {
        @media (max-width: 800px) {
          width: 100%!important;
        }
      }
      
      .combo-wrap {
        height: 30px;
      }

      input {
        height: 28px;
        font-size: 14px;
        box-sizing: border-box;
      }

      input::placeholder {
        font-size: 13px;
      }

      .combo-res {
        height: 18px;
        line-height: 18px;
        margin: 0 5px 2px 0;
        border-radius: 12px;
        border: solid 1px #d1f0fb;
        background-color: #f4fdff;
        padding: 3px 10px;

        img.rem, div.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../root/img/share-icons/blue-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../root/img/share-icons/blue-close@2x.png")});
          }
        }

        .inf {
          margin: -1px 5px 0 0;
        }
      }

      .error {
        img.rem, div.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../root/img/share-icons/red-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../root/img/share-icons/red-close@2x.png")});
          }
        }
      }
    }
    .combo-contain.focu,
    .combo-contain.focu input {
      background: #fff !important;
    }
  }

  .textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #cacaca;
    resize: none;
    border-top: none;
    outline: none;
    padding: 10px 5px;
    font-size: 13px;
    min-height: ${(props) => {props.textareaMinHeight || 63}}px;
    box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
    font-family: "Roboto", sans-serif;

    &:focus {
      background-color: #fff;
    }
  }
`;

export const Label = styled(TDiv)`
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const InputEmailWrapper = styled.div`
  width: 496px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 36px;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #cacaca;
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  & ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
  
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: 20px;
`;
