// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import * as Style from "./preferences.style";
import * as CommonStyle from "../../components/CommonStyledSettings";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import DeleteTokenModal from "../Modals/DeleteTokenModal";
// import Loader from "../../Loader";

const ConnectedDevicesInfo = () => {
  const [devices, setDevices] = useState(null);
  const [deviceToRemove, setDeviceToRemove] = useState(null);

  useEffect(() => {
		apiMethod('listtokens', {}, ret => {
			const tokens = ret.tokens;

      if (!tokens) {
        return () => {};
      }

			tokens.sort((a, b) => {
				return new Date(b.created) - new Date(a.created);
			});

      setDevices(tokens);
		}, { forceFresh: true });
  }, []);

  const onDeviceRemove = (device) => {
    setDeviceToRemove(device);
  };

  const onDeviceRemoveSuccess = (removedTokenid) => {
    const devicesCopy = [...devices];
    const deviceObjToRemove = devicesCopy.findIndex(({ tokenid }) => tokenid == removedTokenid);
    devicesCopy.splice(deviceObjToRemove, 1);

    setDevices(devicesCopy);
  };

  const onCloseRemoveTokenModal = () => {
    setDeviceToRemove(null);
  }

  const renderTableRows = (data) =>
    data.map((device) => (
      <Style.BodyRow key={device.tokenid}>
        <Style.BodyCol>
          {device.device}
          {device.current ? (
            <strong>{__("Current Session")}</strong>
          ) : null}
        </Style.BodyCol>
        <Style.BodyCol>{new Date(device.created).toLocaleDateString()}</Style.BodyCol>
        <Style.BodyCol>{new Date(device.expires).toLocaleDateString()}</Style.BodyCol>
        <Style.BodyCol>
          <IconWrapper onClick={() => onDeviceRemove(device)}>
            <i className="fa-regular fa-xmark"/>
          </IconWrapper>
        </Style.BodyCol>
      </Style.BodyRow>
    ));

  return (
    <>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>
            {__("Devices")}
          </CommonStyle.Title>
          <CommonStyle.Description>
            {__("You are logged in with your pCloud account on following devices and web browsers")}
          </CommonStyle.Description>
        </div>
      </CommonStyle.Row>
      <TableWrapperStyled>
        <thead>
          <Style.HeaderRow>
            <Style.HeaderCol style={{ width: "60%" }}>
              {__("Device")}
            </Style.HeaderCol>
            <Style.HeaderCol style={{ width: "18%" }}>
              {__("Created")}
            </Style.HeaderCol>
            <Style.HeaderCol style={{ width: "18%" }}>
              {__("Expires")}
            </Style.HeaderCol>
            <Style.HeaderCol style={{ width: "4%" }}>
              {__("Delete")}
            </Style.HeaderCol>
          </Style.HeaderRow>
        </thead>

        <tbody>
          {devices ? (
            renderTableRows(devices)
          ) : (
            // <Style.BodyRow>
            //   <LoaderStyled>
            //     <Loader show={!!devices} />
            //   </LoaderStyled>
            // </Style.BodyRow>
            null
          )}
        </tbody>
      </TableWrapperStyled>
      <DeleteTokenModal
        opened={!!deviceToRemove}
        onClose={onCloseRemoveTokenModal}
        onSuccess={onDeviceRemoveSuccess}
        isCurrent={deviceToRemove?.current}
        id={deviceToRemove?.tokenid}
        deviceName={deviceToRemove?.device}
        createdDate={deviceToRemove?.created}
      />
    </>
  );
};

export default ConnectedDevicesInfo;

const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: var(--icon-base);
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: var(--color-base-white);
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: var(--color-functional-danger200);
    color: var(--color-functional-danger500);
  }
`;

const TableWrapperStyled = styled(Style.TableWrapper)`
  position: relative;
`;

const LoaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 80px);
`;