import React, { useEffect, useState } from "react";
import Modal, { OvalModalWrap, CloseModalIcon } from "./Modal";
import * as Style from "../components/Modals/styledComponents";
import { Btn } from "./ButtonDefault";
import { __ } from "../lib/translate";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import Componentify from "react-componentify";
import { brTagConverter } from "../lib/componentifyConverters";

const StorageQuotaWarningModal = (props) => {
    const [opened, setOpened] = useState(false);
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");

    var userId = HFN.config.user.userid ?? 0;
    var locationId = HFN.apiConfig.locationid;
    var subfix = "-"+userId+"-"+locationId;
    const FULL_QUOTA_MODAL = "isFullQuotaModalOpened"+subfix;
    const ALMOST_FULL_QUOTA_MODAL = "isAlmostFullQuotaModalOpened"+subfix;
    const LOW_QUOTA_MODAL = "isLowQuotaModalOpened"+subfix;

    useEffect(() => {
        var quotaFreeIndicator = props.ispremium
            ? props.freequotagb
            : props.percent;

        if (quotaFreeIndicator === 0) {
            setFullQuotaTexts();
        } else if (quotaFreeIndicator <= 1) {
            setAlmostFullQuotaTexts();
        } else if (quotaFreeIndicator <= 10) {
            setLowQuotaTexts();
        }
    }, []);

    const setFullQuotaTexts = () => {
        if (sessionStorage.getItem(FULL_QUOTA_MODAL)) {
            return;
        }

        setTitle(__("full_quota_modal_header"));
        setText(
            __(
                "Your storage is completely full, which means you can’t add, receive, or edit files.<br/><br/>Don’t worry! You can:"
            ) +
                "<br/>- " +
                __("Unlock more storage by upgrading your plan.") +
                "<br/>- " +
                __("Contact our support team for more information.") +
                "<br/><br/>" +
                __("Let’s get you back to full functionality!")
        );
        setOpened(true);
        sessionStorage.setItem(FULL_QUOTA_MODAL, 1);
    };

    const setAlmostFullQuotaTexts = () => {
        if (localStorage.getItem(ALMOST_FULL_QUOTA_MODAL)) {
            return;
        }

        setTitle(__("You\'re Almost Out of Space!"));
        setText(
            __(
                "Your storage quota is at its limit, and new uploads might not be saved.<br/><br/>Don’t worry! You can:"
            ) +
                "<br/>- " +
                __("Unlock more storage by upgrading your plan.") +
                "<br/>- " +
                __("Get assistance from our support team.") +
                "<br/><br/>" +
                __("Act now to keep everything running smoothly!")
        );
        setOpened(true);
        localStorage.setItem(ALMOST_FULL_QUOTA_MODAL, 1);
    };

    const setLowQuotaTexts = () => {
        if (localStorage.getItem(LOW_QUOTA_MODAL)) {
            return;
        }

        setTitle(__("Running Low on Space!"));
        setText(
            __(
                "Your storage quota is nearly full. Time to make room!<br/>Free up space by removing files or unlock more storage with an upgrade."
            )
        );
        setOpened(true);
        localStorage.setItem(LOW_QUOTA_MODAL, 1);
    };

    const onClose = () => {
        setOpened(false);
    };

    return (
        <Modal opened={opened} onClose={onClose} shouldCloseOnOverlayClick={false}>
            <OvalModalWrap>
                <CloseModalIcon onClick={onClose} />
                <Style.Container>
                    <Style.WrapperElements>
                        <Style.Header>{title}</Style.Header>
                        <Style.Element>
                            <Componentify
                                text={text}
                                converters={[brTagConverter]}
                            />
                        </Style.Element>
                    </Style.WrapperElements>
                    <Style.Footer>
                        <Btn
                            style={{ marginRight: "5px" }}
                            color="lightgray4"
                            onClick={onClose}
                        >
                            {__("Close")}
                        </Btn>
                        <Btn
                            style={{ marginLeft: "5px" }}
                            color="cyan"
                            onClick={() => {
                                onClose();
                                window.open(URLSITE + "cloud-storage-pricing-plans.html", "_blank")
                            }}
                        >
                            {__("quota_upgrade_btn")}
                        </Btn>
                    </Style.Footer>
                </Style.Container>
            </OvalModalWrap>
        </Modal>
    );
};

export default StorageQuotaWarningModal;
