import styled, { css, keyframes } from "styled-components";

export const ExploreCardsContainer = styled.div`
  width: 100%;
  ${({ $swipeable }) => $swipeable ? css`
    padding: 8px 0px;
  ` : css`
    height: calc(100% - var(--spacing-md));
    box-sizing: border-box;
  `}
`;

export const ExploreHeader = styled.h2`
  color: var(--text-secondary);
  font-family: Roboto;
  font-size: ${({ $isInMenu }) => $isInMenu ? 14 : 15}px;
  font-weight: 400;
  line-height: ${({ $isInMenu }) => $isInMenu ? 24 : 18}px;
  margin-left: ${({ $isInMenu }) => $isInMenu ? 24 : 0}px;
  @media only screen and (max-width: 460px) {
    ${({ $isMobileDevice, $isInMenu }) => ($isMobileDevice && !$isInMenu) && css`
      margin-left: 16px;
    `}
  }
`;

export const CircleIconWrapper = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: var(--spacing-sm);
  transition: all 500ms ease-in-out;

  ${({ $isSmall }) => $isSmall && css`
    width: 32px;
    height: 32px;
    margin-bottom: 0;
  `}
`;

export const StyledFontAwesomeIcon = styled.i`
  color: var(--color-base-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  transition: all 500ms ease-in-out;

  ${({ $isSmall }) => $isSmall && css`
    font-size: 16px;
  `}
`

export const DescriptionWrapper = styled.div`
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  bottom: var(--spacing-md);
  left: var(--spacing-md);
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: scale(${({ $isInMenu }) => $isInMenu ? "1" : "0"});
  transition: all 100ms ease-out;
  top: 50%; 
`

export const Name = styled.label`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  transition: all 100ms ease-in-out;
`

export const flyIn = keyframes`
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Card = styled.div`
  position: relative;
  background-color: var(--color-base-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ $toggled, $isInMenu }) => !$isInMenu ? "15%" : $toggled ? 'calc(100% - 16px)' : 'calc(100% - 48px)'};
  height: ${({ $toggled, $isInMenu }) => !$isInMenu ? 120 : $toggled ? 40 : 100}px;
  margin: 0 ${({ $toggled, $isInMenu, $swipeable }) => (!$swipeable && !$isInMenu) ? 0 : !$isInMenu ? 16 : $toggled ? 8 : 24}px;
  cursor: pointer;
  background-size: cover;
  overflow: hidden;
  box-sizing: border-box;

  .icon-circle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${({ $isInMenu }) => !$isInMenu && css`
      min-width: 200px;
      max-width: 240px;

    @media only screen and (max-width: 1400px) {
      width: 20%;
    }

    @media only screen and (max-width: 1150px) {
      width: 30%;
    }

    @media only screen and (max-width: 700px) {
      width: 40%;
    }

    @media only screen and (max-width: 460px) {
      max-width: unset;
      width: 100%;
      ${({ $isSingleCard }) => $isSingleCard && css`
        margin: 0 auto;
      `}
    }

    ${({ $isMobileDevice }) => !$isMobileDevice ? css`
        &:hover {
            ${CircleIconWrapper} {
                transform: scale(10);
            }

            .icon-circle {
                top: 4px;
            }

            ${StyledFontAwesomeIcon} {
                opacity: 0;
            }

            ${Name} {
                opacity: 0;
            }
        }
    `: css`
        max-width: none;
      @media only screen and (max-width: 460px) {
        width: calc(100% - 32px);
      }
    `}
  `}
      
  ${({ $isMobileDevice }) => !$isMobileDevice && css`
    &:hover {
      ${DescriptionWrapper} {
        opacity: 1;
        transform: scale(1);
        ${({ $isInMenu }) => $isInMenu && css`
          animation: ${flyIn} 0.2s ease-out forwards;
        `}
      }
      ${Description} {
        opacity: 1;
      }
    }     
  `}

  &.cryptofolder {
    background-color: #E9FCEB;
    background-image: url("/ZCDNURL/img/promo-cards/encryption.png");
  }

  &.backups {
    background-color: #E6F7F5;
    background-image: url("/ZCDNURL/img/promo-cards/backup.png");
  }

  &.publicfolder {
    background-color: #F2F0FF;
    background-image: url("/ZCDNURL/img/promo-cards/public-folder.png");
  }

  &.filerequests {
    background-color: #FCF2DA;
    background-image: url("/ZCDNURL/img/promo-cards/file-requests.png");
  }

  &.rewind {
    background-color: #E6F4FF;
    background-image: url("/ZCDNURL/img/promo-cards/rewind.png");
  }

  &.invitefriends {
    background-color: #F9EDFF;
    background-image: url("/ZCDNURL/img/promo-cards/invite-friends.png");
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px auto 0 auto;
`;

export const Dot = styled.div`
  width: ${({ $active }) => $active ? 50 : 3}px;
  height: 3px;
  border-radius: 7px;
  background-color: var(--color-grey-500);
  margin: 0 8px;
  cursor: pointer;
  transition: width ${({ $transitionDuration }) => $transitionDuration}ms ease-in-out, background-color ${({ $transitionDuration }) => $transitionDuration}ms ease-in-out;
  position: relative;
  ${({ $toggled, $active }) => (!$active && $toggled) && css`
    display: none;
  `};
  
  @media only screen and (max-width: 460px) {
    ${({ $isMobileDevice, $isInMenu }) => ($isMobileDevice && !$isInMenu) && css`
      width: ${({ $active }) => $active ? 50 : 6}px;
      height: 6px;
    `}
  }

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }
`;

export const DotFiller = styled.div`
  height: 100%;
  border-radius: 7px;
  background: var(--color-grey-600);
  width: ${({ width }) => width}%;
  transition: width 0.1s ease-in-out;
`;

export const CardsWrapper = styled.div`
  width: ${({ $width, $swipeable }) => $swipeable ? `${$width}px` : '100%'};
  overflow: hidden;
  position: relative;
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 100%;

  ${({ $swipeable }) => !$swipeable && css`
      flex: 1 1 0%;
      align-items: center;
      gap: var(--spacing-md);
      overflow-x: auto;
  `}
`;

export const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--color-base-white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--spacing-md);
  left: var(--spacing-md);
  opacity: 0;
  transition: all 100ms ease-out;
`

export const FontAwesomeIconArrow = styled.i`
  position: absolute;
  right: -6px;
  bottom: -8px;
  font-size: 16px;
  color: ${({ $isInMenu }) => $isInMenu ? "var(--color-base-black)" : "var(--color-base-white)"};
`