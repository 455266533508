//@flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import store from "../../lib/state/store";
import { modifyContentItem } from "../../lib/state/reducers/contentSlice";

import {
  SettingsWrapper,
  SettingsHeader,
  FileRequestSettingsTitle,
  FileRequestSettingsDescription,
  SettingOptionsContainer,
  InputWrapper,
  ToggleLabel,
  Error,
  FadeAnimationContainer
} from "./styledComponents";
import TopBar from "./TopBar";
import InputCheckbox from "../../components/InputCheckbox";
import { Btn } from "../../components/ButtonDefault";

import { __ } from "../../lib/translate";
import changeuploadlink from "../../api/changeuploadlink";
import { loadFileRequestData } from "../../lib/state/reducers/fileRequestsSlice";

type Props = {
  allSettingsBack: any,
  bodyHeight: string,
  parentRef: any
};

const FileRequestUploadLimit = ({ allSettingsBack, bodyHeight, parentRef }: Props) => {
  const linkData = useSelector(({ fileRequests }) => fileRequests.linkData);
  const { maxspace, uploadlinkid: linkId, expires, comment } = linkData;

  const [toggleState, setToggleState] = useState(!!maxspace);
  const [limit, setLimit] = useState(maxspace ? parseInt(maxspace / 1024 / 1024) : 1000);
  const [loading, setLoading] = useState(false);
  const [changesInited, setChangesInited] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const onLimitChange = e => {
    let value = e.target.value.replace(/[^\d]/g, "");

    if (value > 104857600) {
      setLimit(104857600);
    } else {
      setLimit(value.trim());
    }

    if (value.length) {
      setToggleState(true);
    } else {
      setToggleState(false);
    }

    setChangesInited(true);
  };

  const handleToggleChange = () => {
    if (loading) {
      return;
    }

    const newToggleState = !toggleState;

    setToggleState(newToggleState);
    setErrorMessage("");

    if (!!maxspace != newToggleState) {
      setChangesInited(true);
    } else {
      setChangesInited(false);
    }
  };

  const handleSubmit = () => {
    if (!changesInited) {
      return;
    }

    if (!limit && toggleState) {
      setErrorMessage(__("Upload limit can't be empty"));
      return;
    }
    setErrorMessage(__(""));
    setLoading(true);
    changePublink();
  };

  const changePublink = () => {
    const params = {
      uploadlinkid: linkId,
      comment: comment,
      deleteexpire: !expires
    };

    if (loading) {
      return;
    }

    if (limit && toggleState) {
      params.maxspace = parseInt(limit) * 1024 * 1024;
    } else {
      params.maxspace = 0;
    }
    setLoading(true);

    changeuploadlink(params, {
      onSuccess: (ret: any) => {
        var item = {
          id: ret.code,
          code: ret.code,
          name: ret.metadata.name,
          comment: ret.comment,
          link: ret.link,
          files: ret.files,
          space: ret.space,
          mail: ret.mail,
          modified: ret.created,
          uploadlinkid: ret.uploadlinkid,
          metadata: ret.metadata,
          icon: ret.metadata.icon,
          isfilerequest: true
        };
        if (ret.maxspace) {
          item.maxspace = ret.maxspace;
        }
        if (ret.expires) {
          item.expires = ret.expires;
        }

        store.dispatch(modifyContentItem(item));

        dispatch(loadFileRequestData(ret));
        setLoading(false);
        setChangesInited(false);
        HFN.message(__("shared_links_settings_updated"), "success");
      }
    });
  };

  const renderBody = () => {
    return (
      <SettingOptionsContainer className="limit">
        <Body>
          <InputWrapper>
            <InputCheckbox
              checked={toggleState}
              onChange={handleToggleChange}
              name="expdate-toggle"
              color="cyan"
              size="small"
            />
            <ToggleLabel>{__("file_request_settings_modal_limit_button", "Set limit")}</ToggleLabel>
          </InputWrapper>

          <LimitInputWrapper>
            <Input name="limit" type="text" value={limit} onChange={onLimitChange} />
            <TextMB>MB</TextMB>
          </LimitInputWrapper>
          <FadeAnimationContainer>
            <SwitchTransition>
              <CSSTransition
                key={errorMessage}
                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                classNames="fade"
              >
                {errorMessage ? <Error>{errorMessage}</Error> : <Error />}
              </CSSTransition>
            </SwitchTransition>
          </FadeAnimationContainer>
          <ButtonWrapper>
            <Btn
              loading={loading}
              disabled={!changesInited}
              borderRadius="4"
              minWidth="200px"
              color="cyan"
              onClick={handleSubmit}
            >
              {__("Save")}
            </Btn>
          </ButtonWrapper>
        </Body>
      </SettingOptionsContainer>
    );
  };

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef}>
      <TopBar allSettingsBack={allSettingsBack} />
      <SettingsHeader>
        <FileRequestSettingsTitle>{__("ul_upload_limit", "Upload limit")}</FileRequestSettingsTitle>
        <FileRequestSettingsDescription>
          {__("file_request_settings_modal_limit_desc", "Restrict how much users can upload from the file request.")}
        </FileRequestSettingsDescription>
      </SettingsHeader>
      {renderBody()}
    </SettingsWrapper>
  );
};

export default FileRequestUploadLimit;

const Body = styled.div`
  width: 200px;
`;

const ButtonWrapper = styled.div`
  a {
    width: 200px;
  }

  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;

const LimitInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  width: 200px;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #fff;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  margin-top: 10px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Input = styled.input`
  display: flex;
  flex: 1;
  font-size: 15px;
  outline: none;
  text-align: center;
  padding: 0;
  outline: none;
  border: none;
  transition: all 200ms ease-in-out;
  min-width: 0;
`;

const TextMB = styled.div`
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  transition: all 200ms ease-in-out;
`;
