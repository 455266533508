// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { updateCompanyInfo } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

const EditCompanyNameModal = ({ onSuccess = () => { }, isOpen = false, handleClose = () => { } }: { onSuccess: () => void }) => {
  const company = useSelector(
    ({ business: { accountInfo: { account: { company } = {} } = {} } = {} }) => company
  );

  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState(company || "");
  const dispatch = useDispatch();

  const onCompanyNameChange = ({ target }) => {
    setCompanyName(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSaveClick();
    }
  };

  const onSaveClick = () => {
    if (isLoading) {
      return;
    }
    const name = companyName.trim();
    setIsLoading(true);

    if (name.trim().length < 1) {
      HFN.message(__("Company can't be empty"), "error");
      return;
    }

    apiMethod(
      "account_modify",
      { name: name },
      () => {
        dispatch(updateCompanyInfo({ company: name }));
        handleClose();
        HFN.message(__("Company info updated."));
        setIsLoading(false);
      },
      {
        errorCallback: ({ error, result, field }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2103) {
            if (field === "name") {
              errorMessage = __("business_edit_user_company_name_error");
            }
          } else if (result === 1088) {
            errorMessage = __("error_phone_number", "Please, provide valid phone.");
          } else if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      opened={isOpen}
      title={__("BA_edit_company_name_title", "Edit Your Company Name")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          onClick={onSaveClick}
        >
          {__("Save")}
        </Button>
      }
    >
      <Style.Label>{__("Company", "Company")}</Style.Label>
      <InputText
        style={{ marginBottom: "20px" }}
        name="companyName"
        onChange={onCompanyNameChange}
        value={companyName}
        autoFocus
        onKeyDown={onEnter}
      />
    </ModalTemplate>
  );
};

export default EditCompanyNameModal;
