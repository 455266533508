// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { updateCompanyInfo } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

const EditCompanyPhoneModal = ({ onSuccess = () => { }, isOpen = false, handleClose = () => { } }: { onSuccess: () => void }) => {
  const phone = useSelector(
    ({ business: { accountInfo: { account: { phone } = {} } = {} } = {} }) => phone
  );

  const [isLoading, setIsLoading] = useState(false);
  const [companyPhone, setCompanyPhone] = useState(phone || "");
  const dispatch = useDispatch();

  const onCompanyPhoneChange = ({ target }) => {
    setCompanyPhone(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSaveClick();
    }
  };

  const onSaveClick = () => {
    if (isLoading) {
      return;
    }
    const phone = companyPhone.trim();
    setIsLoading(true);

    if (phone.trim().length < 1) {
      HFN.message(__("Phone can't be empty"), "error");
      return;
    }

    var pattern = new RegExp(/^\+?[\(\)\- 0-9]+$/);
    if ((phone.trim().length < 1 || phone.trim().length > 30) && !pattern.test(phone)) {
      HFN.message(__("error_phone_number", "Please, provide valid phone."), "error");
      return;
    }

    apiMethod(
      "account_modify",
      { phone: phone },
      () => {
        dispatch(updateCompanyInfo({ phone: phone }));
        handleClose();
        HFN.message(__("Company info updated."));
        setIsLoading(false);
      },
      {
        errorCallback: ({ error, result, field }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2103) {
            if (field === "name") {
              errorMessage = __("business_edit_user_company_name_error");
            }
          } else if (result === 1088) {
            errorMessage = __("error_phone_number", "Please, provide valid phone.");
          } else if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      opened={isOpen}
      title={__("BA_edit_company_phone_title", "Edit Your Company Phone")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          onClick={onSaveClick}
        >
          {__("Save")}
        </Button>
      }
    >
      <Style.Label>{__("Phone", "Phone")}</Style.Label>
      <InputText
        name="companyPhone"
        onChange={onCompanyPhoneChange}
        value={companyPhone}
        onKeyDown={onEnter}
      />
    </ModalTemplate>
  );
};

export default EditCompanyPhoneModal;
