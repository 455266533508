import React, { useState } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import { Btn } from "../ButtonDefault";
import { __ } from "../../lib/translate";
import { boldConverter } from "../../lib/componentifyConverters";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Text, Title } from "./sharedStyles";

const UploadLockCryptoModal = () => {
  const {
    isLockCryptoModalOpen,
    handleLockCryptoModalClose,
    handleLockCryptoYesClick,
  } = useUploadContext();

  return (
    <Modal align="top" animate shouldCloseOnOverlayClick={false} onClose={handleLockCryptoModalClose} opened={isLockCryptoModalOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleLockCryptoModalClose} />
        <Content>
          <React.Fragment>
            <Body>
              <Title>{__('upload_manager_cancel_title', 'Cancel upload')}</Title>
              <Text>
                <Componentify
                  text={__('upload_manager_crypto_lock', 'If you lock the Crypto folder now, all files that are currently being uploaded will be canceled.')}
                  converters={[boldConverter]}
                />
              </Text>
            </Body>
            <ButtonWrapper>
              <Btn inline color="lightgray4" onClick={handleLockCryptoModalClose}>
                {__('Cancel')}
              </Btn>
              <Btn inline color="cyan" onClick={handleLockCryptoYesClick}>
                {__('upload_manager_crypto_lock_success_action', 'Lock Crypto Folder')}
              </Btn>
            </ButtonWrapper>
          </React.Fragment>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default UploadLockCryptoModal;