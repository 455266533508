import React, {
	createContext,
	forwardRef,
	Fragment,
	useState,
	useRef,
	useCallback,
	useMemo,
	memo,
	useEffect
} from "react";
import { useSelector } from "react-redux";
import { VariableSizeList, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import debounce from "lodash/debounce";

import { useMenu } from '../../context/MenuContextProvider';
import { TEMPLATES } from "../../ContentData";
import type { ColumnsObjType } from "../../../../types/columns";
import {
	SELECT, TYPE_ICON, ACTIONS_COLUMN, NAME_SUB, SORT_DIRECTION_ASC, SORT_DIRECTION_DESC, TYPE, SHOW_COMMENTS,
	BROWSE_SHAREDLINKS, BROWSE_SHAREDWITHME, ACCEPT_SHARE, REJECT_SHARE,
	INITIALS,
	BROWSE_MEMBERS,
	MID_LIST_VIEW, TEAMS, BUSINESS_QUOTA, FA_ICON, LOG_DATA, BROWSE_SHAREDLINKS_TRAFFIC
} from "../../../../config/constants";
import { getViewType, getIconType, getContentOptions, transormOptionsToList, getItemRightIcons } from "../../../../lib/utils";
import FileIcon from "../../../FileIcon";
import {
	Row, Header, HeaderColumn, Column, StickyRowWrapperList, IconButton, CheckboxButton, SortIcon,
	AcceptButton, DeclineButton, OptionsTitle
} from "../../UI/Style";
import { OPTIONS } from "../../../../config/options";
import { __ } from "../../../../lib/translate";
import { LARGE_LIST_VIEW } from "../../../../config/constants";
import { getHeaderElements } from "../../ContentViewUtils";
import EmptyState from "../../UI/EmptyState";
import Credits from "../../UI/Footer/Credits";
import TeamsCell from "../Cells/TeamsCell";
import InitialsCell from "../Cells/InitialsCell";
import BusinessQuotaCell from "../Cells/BusinessQuotaCell";
import FaIconCell from "../Cells/FaIconCell";
import LogDataCell from "../Cells/LogDataCell";

const StickyListContext = createContext();
StickyListContext.displayName = "StickyListContext";

const TABLE_HEAD_ROW_HEIGHT = 39;
let folderListItemRowHeight = 40;
let folderListItemMidRowHeight = 48;
let folderListItemLargeRowHeight = 56;

const toggleSortDirection = (currentSortDirection) => {
	if (currentSortDirection === SORT_DIRECTION_ASC) {
		return SORT_DIRECTION_DESC;
	} else if (currentSortDirection === SORT_DIRECTION_DESC) {
		return SORT_DIRECTION_ASC
	}
}

const renderItemOptions = (item, showMenu, contentType, isMobileView, isMobileDevice, listWidth, isBusiness, overwriteOptionsOnClick) => {
	const ref = useRef(null);
	const cryptoV2IsActive = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptov2isactive);
	const isCollaborationEnabled = useSelector(({ pCloudUser }) => pCloudUser.collaboration || false);
    const maxMainOptionsCnt = (isMobileView || (contentType === BROWSE_SHAREDLINKS && listWidth < 770)) ? 0 : (([BROWSE_SHAREDLINKS, BROWSE_SHAREDLINKS_TRAFFIC].includes(contentType)) ? 4 : 3);
	const availableOptions = useMemo(() => getContentOptions({
		content: [item],
		isBusiness: isBusiness,
		cryptoV2IsActive: cryptoV2IsActive,
		isCollaborationEnabled: isCollaborationEnabled,
		maxMainOptionsCnt: maxMainOptionsCnt,
		contentType: contentType,
		isMobileDevice: isMobileDevice
	}), [item, isBusiness, cryptoV2IsActive, isCollaborationEnabled, maxMainOptionsCnt]);
	const items = useMemo(() => transormOptionsToList(availableOptions.secondary, [item], overwriteOptionsOnClick), [availableOptions.secondary, item]);

	const mainOptions = availableOptions.main ? availableOptions.main.map(option => {
		const optionData = OPTIONS[option] || {};
		const { icon, optionTitle, onClick } = optionData;

		return (
			<IconButton
				key={option}
				data-id={item.id}
				className={"listRow action-btn" + (isMobileDevice ? " mobile" : "")}
				onClick={() => onClick([item], null, "list item hover")}
				onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
				data-tooltip-content={__(...optionTitle)}
				data-tooltip-id="cv-tooltip"
			>
				<i className={"smallIcon " + icon} />
			</IconButton>
		);
	}) : null;

	const secondaryOptions = Object.keys(availableOptions.secondary).length ? (
		<IconButton
			ref={ref}
			key={`${item.id}-options`}
			className={"listRow action-btn" + (isMobileDevice ? " mobile" : "")}
			onClick={(event) => {
				event.preventDefault();

				showMenu(items, ref);
			}}
			onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
			data-tooltip-content={__("tooltip_list_show_more", "Show more actions for this item")}
			data-tooltip-id="cv-tooltip"
		>
			<i className="smallIcon fa-regular fa-ellipsis-vertical" />
		</IconButton>
	) : null;

	return (
		<>
			{mainOptions}
			{secondaryOptions}
		</>
	);
};

const renderListHeader = (columns, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, renderSelectByTypeMenu, isMobileView, isMobileDevice, listWidth, isLargeList) => {
	const countOfItemsForMobile = Object.keys(columns).filter((column) => {
		return !!columns[column].showOnMobile;
	});
	return (
		<Header className={
			isMobileDevice ?
				"headerWrapper mobile" + (
					countOfItemsForMobile.length > 1 ? ' multiple-cols' : ''
				) :
				"headerWrapper"
		}>
			{Object.keys(columns).map(id => {
				const { showOnMobile = false, showOnMobileOnly = false, hideBelowWidth = false, innerColumns = false } = columns[id];
				const showColumn = isMobileView ? (showOnMobile || showOnMobileOnly) : (!showOnMobileOnly && (hideBelowWidth !== false && listWidth < hideBelowWidth ? false : true));

				if (!showColumn) {
					return null;
				}

				const { sortable, columnTitle, tooltip, width, stretchWidth, contentKey, sortType, customClass = false } = columns[id];
				const isSortKey = sortIsSet && sortOptions && (sortOptions.sortBy === contentKey);
				const sortDirection = isSortKey ? sortOptions.sortDirection : SORT_DIRECTION_ASC;
				const folderFirst = sortOptions && sortOptions.folderFirst;
				const colClassList = [id + "-col"];
				const classList = [];

				if (isMobileDevice) {
					colClassList.push("mobile");
				}
				if (customClass) {
					colClassList.push(customClass);
				}
				if (id === SELECT) {
					classList.push("listCheckbox");
					if (isMobileDevice) {
						classList.push("mobile");
					}
					if ((selectedFolders.size || selectedFiles.size) && keysLen === (selectedFolders.size + selectedFiles.size)) {
						classList.push("checked");
					} else if (selectedFolders.size > 0 || selectedFiles.size > 0) {
						classList.push("intermediate");
					}
				}
				if (isLargeList) {
					colClassList.push("lgList");
				}
				if (!columns[SELECT]) {
					colClassList.push("noSelectCol");
				}
				if (innerColumns[TYPE_ICON] || innerColumns[FA_ICON]) {
					colClassList.push("hasInnerIcon");
				}
				if (innerColumns[INITIALS]) {
					colClassList.push("hasInnerIcon");
				}
				if (sortable) {
					colClassList.push("sortable");
				}

				return (
					<HeaderColumn
						key={id}
						{...(tooltip ?
							{
								"data-tooltip-content": __(...tooltip),
								"data-tooltip-id": "cv-tooltip",
								"data-tooltip-delay-show": 500
							} : {})
						}
						className={colClassList.join(" ")}
						onClick={() => {
							if (sortable) {
								onColumnSortClick(contentKey, (isSortKey ? toggleSortDirection(sortDirection) : sortDirection), folderFirst, sortType)
							}
						}}
						$stretchWidth={stretchWidth}
						$isSortKey={isSortKey}
					>
						{id === SELECT ? (
							<CheckboxButton className={classList.join(" ")} onClick={onAllItemsSelect} />
						) : null}
						{columns[id].showSelectByType ? renderSelectByTypeMenu("hover-menu-button no-margin") : null}
						<span>{columnTitle ? __(...columnTitle) : ''}</span>
						{(sortable && isSortKey) ? <SortIcon $sortDirection={sortDirection} $isSortKey={isSortKey} /> : null}
					</HeaderColumn>
				);
			})}
		</Header>
	)
};

const renderSingleRow = (isScrolling, columns, item, showMenu, onSingleItemSelect, contentType, isLargeList, isMobileView, isMobileDevice, listWidth, classList = [], selectedFolders = new Map(), selectedFiles = new Map(), overwriteOptionsOnClick = {}, handleRowHeightChange = () => {}) => {
	const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
	const shares = useSelector(({ sharedFolders }) => sharedFolders.sharedFolders.shares);
	const users = isBusiness ? useSelector(({ business }) => business.users) : [];

	return (
		<React.Fragment key={item.id}>
			{Object.keys(columns).map(id => {
				const { showOnMobile = false, showOnMobileOnly = false, hideBelowWidth = false } = columns[id];
				const showColumn = isMobileView ? (showOnMobile || showOnMobileOnly) : (!showOnMobileOnly && (hideBelowWidth !== false && listWidth < hideBelowWidth ? false : true));

				if (!showColumn) {
					return null;
				}

				const {
					sortable,
					clickable,
					getIsClickable,
					getIsDotted,
					icon,
					getIcon,
					width,
					stretchWidth,
					innerColumns,
					secondLine,
					contentKey,
					getFieldValue,
					formatMethod,
					onClick,
					customClass = false,
					ellipsisTooltip = false,
					showAdditionalTooltipKey = false
				} = columns[id];
				let { visibleOnHover, visibleOnHoverOnly } = columns[id];

				if (item.id && (selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString()))
					&& (selectedFolders.size + selectedFiles.size === 1)) {
					visibleOnHoverOnly = false;
				}

				if (isMobileView) {
					visibleOnHover = false;
					visibleOnHoverOnly = false;
				}

				let classNames = [id + '-col'];

				if (customClass) {
					classNames.push(customClass);
				}

				if (visibleOnHover) {
					classNames.push("visibleOnHover");
				} else if (visibleOnHoverOnly) {
					classNames.push("visibleOnHoverOnly");
				}

				const isClickable = getIsClickable ? getIsClickable(item) : clickable;

				if (isClickable) {
					classNames.push("clickable");
				}

				if (isMobileDevice) {
					classNames.push("mobile");
				}

				if (isLargeList) {
					classNames.push("lgList");
				}

				if (id === SELECT) {
					if (selectedFolders.size > 0 || selectedFiles.size > 0) {
						classNames.push("visible");
					}

					const selectClassNames = ["listCheckbox"];
					if (item.id && selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString())) {
						selectClassNames.push("checked");
					}
					if (isMobileDevice) {
						selectClassNames.push("mobile");
					}
					if (isLargeList) {
						selectClassNames.push("lgList");
					}

					return (
						<Column key={`${item.id}_${id}`} className={classNames.join(" ")}>
							<CheckboxButton
								className={selectClassNames.join(" ")}
								onClick={(event) => { onSingleItemSelect(item, true, event) }}
								onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
							/>
						</Column>
					);
				}

				if (id === TYPE_ICON) {
					const iconType = useMemo(() => getIconType(item), [item, isLargeList]);
					const isVideo = item.category === HFN.CATEGORY.VIDEO;
					const fileIconClassList = [];
					if (isVideo) {
						fileIconClassList.push("playButton");
					}

					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							{isLargeList && <div data-id={item.id} className={fileIconClassList.join(" ")} onClick={onClick ? (event) => onClick(event, item) : () => { }}>
								<FileIcon item={iconType} type={HFN.ICONS.SVG_LARGE_LIST} metadata={item} isScrolling={isScrolling} />
							</div>}
							{!isLargeList && <div data-id={item.id} className={fileIconClassList.join(" ")} onClick={onClick ? (event) => onClick(event, item) : () => { }}>
								<FileIcon item={iconType} type={HFN.ICONS.SVG_LIST} metadata={item} isScrolling={isScrolling} />
							</div>}
						</Column>
					);
				}
					if (id === FA_ICON) {
						return <FaIconCell
							key={`${item.id}_${id}`}
							icon={icon}
							getIcon={getIcon}
							id={id}
							stretchWidth={stretchWidth}
							item={item}
							classNames={classNames}
							contentKey={contentKey}
							onClick={onClick}
						/>;
					}

					if (id === LOG_DATA) {
						return <LogDataCell
							key={`${item.id}_${id}`}
							id={id}
							stretchWidth={stretchWidth}
							item={item}
							classNames={classNames}
							handleRowHeightChange={handleRowHeightChange}
						/>;
					}

				if (id === INITIALS) {
					return <InitialsCell
						key={`${item.id}_${id}`}
						isMobileDevice={isMobileDevice}
						id={id}
						stretchWidth={stretchWidth}
						item={item}
						classNames={classNames}
						contentKey={contentKey}
						onClick={onClick}
						getIsDotted={getIsDotted}
					/>;
				}

					if (id === BUSINESS_QUOTA) {
						return <BusinessQuotaCell
							key={`${item.id}_${id}`}
							id={id}
							stretchWidth={stretchWidth}
							item={item}
							classNames={classNames}
							contentKey={contentKey}
						/>;
					}

					if (id === TEAMS) {
						return <TeamsCell
							key={`${item.id}_${id}`}
							id={id}
							stretchWidth={stretchWidth}
							item={item}
							classNames={classNames}
							contentKey={contentKey}
						/>;
					}

				if (id === ACTIONS_COLUMN) {
					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")} >
							{renderItemOptions(item, showMenu, contentType, isMobileView, isMobileDevice, listWidth, isBusiness, overwriteOptionsOnClick)}
						</Column>
					);
				}

				if (innerColumns) {
					if (innerColumns[TYPE_ICON]) {
						classNames.push("hasInnerIcon");
					}
					if (innerColumns[INITIALS]) {
						classNames.push("hasInnerIcon");
					}
					if (TEMPLATES[contentType].COLUMNS && !TEMPLATES[contentType].COLUMNS[SELECT]) {
						classNames.push("noSelectCol");
					}
					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							{renderSingleRow(isScrolling, innerColumns, item, showMenu, onSingleItemSelect, contentType, isLargeList, isMobileView, isMobileDevice, listWidth, classList, selectedFolders, selectedFiles, overwriteOptionsOnClick, handleRowHeightChange)}
						</Column>
					);
				}

				if (contentType === BROWSE_SHAREDWITHME && id === TYPE) {
					return (item[contentKey] === "request" &&
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							<AcceptButton
								className="acceptButton"
								onClick={() => OPTIONS[ACCEPT_SHARE].onClick([item], null, "list item hover")}
								onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
							>
								<i
									className={OPTIONS[ACCEPT_SHARE].icon}
									data-tooltip-content={__(...OPTIONS[ACCEPT_SHARE].optionTitle)}
									data-tooltip-id="cv-tooltip"
								></i>
								<span>{__(...OPTIONS[ACCEPT_SHARE].optionTitle)}</span>
							</AcceptButton>
							<DeclineButton
								className="declineButton"
								onClick={() => OPTIONS[REJECT_SHARE].onClick([item], null, "list item hover")}
								onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
								data-tooltip-content={__(...OPTIONS[REJECT_SHARE].optionTitle)}
								data-tooltip-id="cv-tooltip"
							>
								<i className={OPTIONS[REJECT_SHARE].icon}></i>
							</DeclineButton>
						</Column>
					);
				}

				const value = getFieldValue ? getFieldValue(item) : item[contentKey];
				let formattedValue = '';
				let arrayValues = [];

				if (Array.isArray(value)) {
					arrayValues = value;
				} else {
					formattedValue = formatMethod ? formatMethod(value) : value;
				}

				if (contentKey && (getFieldValue || item[contentKey])) {
					const innerClassNames = ["truncate"];
					if (isClickable) {
						innerClassNames.push("clickable");
						classNames = classNames.filter(className => className !== "clickable");
					}

					let iconsAfterName = 0;
					let rightIcons = [];
					if (id === NAME_SUB) {
						if (isBusiness && !item.istime && !item.isdeleted && item.comments > 0) {
							iconsAfterName++;
						}
						rightIcons = useMemo(() => getItemRightIcons(item, shares, users), [item, shares, users]);
						iconsAfterName += rightIcons.length;

						if (!isMobileDevice && iconsAfterName > 0) {
							innerClassNames.push("hasRightIcon");
							innerClassNames.push("fa-" + iconsAfterName + "-icons");
						}
					}

					let secLineContent = "";
					if (secondLine) {
						const { secPrefix, secContentKey, secGetFieldValue, secFormatMethod, secShowOnMobileOnly, secShowColon } = secondLine;
						const secValue = secGetFieldValue ? secGetFieldValue(item) : item[secContentKey];

						if (
							(isMobileDevice && secShowOnMobileOnly) ||
							(!secShowOnMobileOnly)
						) {
							classNames.push('hasSecLine');
							secLineContent = (
								<span className="truncate subLine">
									{secPrefix ? __(...secPrefix) : ""}
									{secPrefix && secShowColon ? ":" : ""}
									{secPrefix ? <>&nbsp;</> : ""}
									{secFormatMethod ? secFormatMethod(secValue) : secValue}
									{(iconsAfterName > 0) ? (
										<>
											<>&nbsp;</>
											<span className="rightIconsWrapper">
												{isBusiness && !item.istime && !item.isdeleted && item.comments > 0 ?
													<span key="fa-message" className="rightIcon defaultCursor" >
														<i className="smallIcon fa-solid fa-message" />
													</span>
													: null}
												{rightIcons.map((icon) => (
													<span key={icon.icon} className="rightIcon defaultCursor" >
														<i className={"smallIcon fa-solid " + icon.icon} />
													</span>
												))}
											</span>
										</>
									) : null}
								</span>
							);
						}
					}

					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							<span
								className={innerClassNames.join(" ")}
								data-id={item.id}
								onClick={onClick ? (event) => onClick(event, item) : () => { }}
								{...((ellipsisTooltip > 0 && formattedValue.length > ellipsisTooltip) ?
									{
										"data-tooltip-content": formattedValue,
										"data-tooltip-id": "cv-tooltip",
										"data-tooltip-delay-show": 500
									} : (
										showAdditionalTooltipKey ? {
											"data-tooltip-content": item[showAdditionalTooltipKey] ?? '',
											"data-tooltip-id": "cv-tooltip",
											"data-tooltip-delay-show": 250
										} : {}
									))
								}
							>
								{
									arrayValues.length ?
										arrayValues.map(tmpValue => (<div>{tmpValue}</div>)) :
										formattedValue
								}

								{(!isMobileDevice && iconsAfterName > 0) ? (
									<span className="rightIconsWrapper">
										{isBusiness && !item.istime && !item.isdeleted && item.comments > 0 ?
											<span className="rightIcon">
												<IconButton
													className={"listRow" + (isMobileDevice ? " mobile" : "")}
													onClick={(event) => !isMobileDevice && overwriteOptionsOnClick[SHOW_COMMENTS] ? overwriteOptionsOnClick[SHOW_COMMENTS]({ id: item.id }, undefined, undefined, event) : null}
													onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
													data-tooltip-content={(item.comments != 1 ? __("view_total_comments", "View %total% comments").replace("%total%", item.comments) : __("view_comment", "View comment"))}
													data-tooltip-id="cv-tooltip"
												>
													<i className="smallIcon lightColorIcon fa-solid fa-message" />
												</IconButton>
											</span>
											: null}
										{rightIcons.map((icon) => (
											<span
												key={icon.icon}
												className={icon.onClick === false ? "rightIcon defaultCursor" : "rightIcon"}
											>
												{(icon.onClick !== false && !isMobileDevice) ?
													<IconButton
														className={"listRow" + (isMobileDevice ? " mobile" : "")}
														onClick={(event) => {
															onSingleItemSelect(item, false, event);
															icon.onClick(event, item);
														}}
														onDoubleClick={(event) => {
															event.preventDefault();
															event.stopPropagation();
														}}
														{...(icon.tooltip !== false ?
															{
																"data-tooltip-content": __(...icon.tooltip),
																"data-tooltip-id": "cv-tooltip"
															} : {})
														}
													>
														<i className={"smallIcon lightColorIcon fa-solid " + icon.icon} />
													</IconButton>
													:
													<i
														className={"smallIcon lightColorIcon fa-solid " + icon.icon}
														onClick={(event) => { onSingleItemSelect(item, false, event) }}
														{...(icon.tooltip !== false ?
															{
																"data-tooltip-content": __(...icon.tooltip),
																"data-tooltip-id": "cv-tooltip"
															} : {})
														}
													/>
												}
											</span>
										))}
									</span>
								) : null}
							</span>
							{secLineContent}
						</Column>
					);
				}

				if (contentKey && !getFieldValue && !item[contentKey]) {
					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							-
						</Column>
					);
				}
			}
			)}
		</React.Fragment>
	);
};

const ListRow = ({ isScrolling, data, index, style }) => {
	const { handleRowHeightChange, columns, showMenu, rowsCountBeforeItems, currentKeys, itemCount, contentData, contentType, onContentClick, onContentContextClick, onSingleItemSelect, selectedFolders, selectedFiles, headerGridItems, isLargeList, isMobileView, isMobileDevice, listWidth, listFullWidth, overwriteOptionsOnClick } = data;
	const isHeaderRow = index < rowsCountBeforeItems - 1;
	if (isHeaderRow) {
		return (
			<div className="headerBoxWrapper" style={{ ...style, width: listFullWidth }}>
				{headerGridItems[index].element({ isMobileDevice: isMobileDevice })}
			</div>
		);
	}

	const classList = ["selectable", "list-row"];
	const key = currentKeys[index - rowsCountBeforeItems];
	const item = contentData.items[key];

	if (item && item.id && (selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString()))) {
		classList.push("selected");
	}
	if (isLargeList) {
		classList.push("lgList");
	}
	if (isMobileDevice) {
		classList.push("mobile");
	}

	const styles = { ...style, width: listFullWidth };
	if (TEMPLATES[contentType].DISABLE_REACT_WINDOW && data.getRowHeight) {
		styles.height = data.getRowHeight(index);
	}

	return (item ?
		<Row
			className={classList.join(" ")}
			key={`${item.id}_row`}
			data-id={item.id}
			onClick={onContentClick}
			onDoubleClick={TEMPLATES[contentType].onClick ? (event) => TEMPLATES[contentType].onClick(event, item) : () => { }}
			onContextMenu={TEMPLATES[contentType].DISABLE_CONTEXT_MENU ? null : onContentContextClick}
			style={styles}
		>
			{renderSingleRow(isScrolling, columns, item, showMenu, onSingleItemSelect, contentType, isLargeList, isMobileView, isMobileDevice, listWidth, classList, selectedFolders, selectedFiles, overwriteOptionsOnClick, handleRowHeightChange)}
		</Row>
		:
		(
			index === itemCount - 1 &&
			contentType !== BROWSE_MEMBERS &&
			!TEMPLATES[contentType].DISABLE_REACT_WINDOW ?
				<Row
					className="credits"
					key="last-row"
					style={{ ...style, width: listFullWidth, top: "auto", bottom: 0 }}
				>
					<Credits />
				</Row> :
				null
		)
	);
};

const ItemWrapper = memo(({ data, index, style, isScrolling }) => {
	const { ItemRenderer, stickyIndices, ...rest } = data;

	if (stickyIndices && stickyIndices.includes(index)) {
		return null;
	}

	return <ItemRenderer isScrolling={isScrolling} index={index} style={style} data={rest} />;
}, areEqual);

const StickyRow = ({
	contentType,
	isMobileView,
  style,
  showStickyBar,
  isMobileOptionsOnTop,
  disableOptionsRow,
  renderContentOptions,
  renderListHeader
}) => {
  let classes = "header-sticky-row ";

  if (isMobileView && isMobileOptionsOnTop) {
    classes += " mobile-on-top";
  }

  if (showStickyBar) {
    classes += " sticky";
  }

  return (
    <StickyRowWrapperList style={style} className={classes}>
      {!disableOptionsRow && (
        <div className={"optionsRow" + (isMobileView ? " mobile" : "")}>
					{TEMPLATES[contentType].OPTIONS_TITLE && !isMobileView && (
						<OptionsTitle>
							{__(...TEMPLATES[contentType].OPTIONS_TITLE)}
						</OptionsTitle>
					)}
					{renderContentOptions()}
				</div>
      )}

      {renderListHeader}
    </StickyRowWrapperList>
  );
};

const innerElementType = forwardRef(({ children, style, ...rest }, ref) => (
  <StickyListContext.Consumer>
    {({
      stickyIndices,
      showStickyBar,
      isMobileOptionsOnTop,
      renderContentOptions,
      renderSelectByTypeMenu,
      headerGridItems,
      headerItemsHeight,
      disableOptionsRow,
      contentType,
      sortIsSet,
      sortOptions,
      keysLen,
      selectedFolders,
      selectedFiles,
      onAllItemsSelect,
      onColumnSortClick,
      isMobileView,
      isMobileDevice,
      listWidth,
      isLargeList,
      listFullWidth
    }) => (
      <div
				ref={ref}
				style={{ ...style, position: "relative", minHeight: TEMPLATES[contentType].DISABLE_REACT_WINDOW ? 0 : "100%" }}
				{...rest}
			>
				{TEMPLATES[contentType].OPTIONS_TITLE && isMobileView && (
					<OptionsTitle className="mobile">
						{__(...TEMPLATES[contentType].OPTIONS_TITLE)}
					</OptionsTitle>
				)}
        {stickyIndices.map((index) => (
          <StickyRow
						contentType={contentType}
						isMobileView={isMobileView}
            isMobileDevice={isMobileDevice}
            showStickyBar={showStickyBar}
            isMobileOptionsOnTop={isMobileOptionsOnTop}
            disableOptionsRow={disableOptionsRow}
            renderContentOptions={renderContentOptions}
            renderListHeader={renderListHeader(
              TEMPLATES[contentType].COLUMNS,
              sortIsSet,
              sortOptions,
              keysLen,
              selectedFolders,
              selectedFiles,
              onAllItemsSelect,
              onColumnSortClick,
              renderSelectByTypeMenu,
              isMobileView,
              isMobileDevice,
              listWidth,
              isLargeList
            )}
            key={index}
            style={{ top: headerItemsHeight, width: listFullWidth, height: headerGridItems[index].height }}
          />
        ))}

        {children}
      </div>
    )}
  </StickyListContext.Consumer>
));

const StickyList = ({ handleScroll, children, stickyIndices, showStickyBar, isMobileOptionsOnTop, disableOptionsRow, renderContentOptions, renderSelectByTypeMenu, headerGridItems, headerItemsHeight, contentType, isLargeList, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, isMobileView, isMobileDevice, listWidth, listFullWidth, listGridRef, listGridOuterRef, itemData, scrollTo, ...rest }) => {
	const itemRowHeight = isLargeList ? folderListItemLargeRowHeight : folderListItemRowHeight;
	const disableReactWindow = !!rest.disableReactWindow;

	useEffect(() => {
		if (listGridOuterRef.current && !disableReactWindow) {
			listGridOuterRef.current.tabIndex = -1;
			listGridOuterRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (scrollTo && listGridRef && listGridRef.current) {
			const scrollToIndex = itemData.currentKeys.indexOf(scrollTo);
			if (scrollToIndex !== -1) {
				listGridRef.current.scrollToItem(scrollToIndex + itemData.rowsCountBeforeItems, "center");
			}
		}
	}, [scrollTo]);

	const InnerElementComponent = innerElementType;
	const itemDataProps = { ItemRenderer: children, showStickyBar, isMobileOptionsOnTop, disableOptionsRow, renderContentOptions, contentType, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, headerGridItems, isMobileView, isMobileDevice, listWidth, listFullWidth, ...itemData };
	return (
		<StickyListContext.Provider value={{ ItemRenderer: children, stickyIndices, showStickyBar, isMobileOptionsOnTop, disableOptionsRow, renderContentOptions, renderSelectByTypeMenu, headerGridItems, headerItemsHeight, listGridRef, contentType, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, isMobileView, isMobileDevice, listWidth, isLargeList, listFullWidth }}>

			{disableReactWindow && <>
				{Array(rest.itemCount)
					.fill()
					.map((i, index) => {
						if (index === 0) {
							return <InnerElementComponent key={index} />;
						}
						return (
							<ItemWrapper
								key={index}
								index={index}
								data={itemDataProps}
							/>
						);
					})}
			</>}
			{!disableReactWindow && <VariableSizeList
				useIsScrolling
				ref={listGridRef}
				className="listGridWindow"
				itemData={itemDataProps}
				estimatedItemSize={itemRowHeight}
				onScroll={handleScroll}
				overscanCount={5}
				outerRef={listGridOuterRef}
				{...rest}
			>
				{ItemWrapper}
			</VariableSizeList>}
		</StickyListContext.Provider>
	)
};

const FolderList = ({
	contentType,
	sortIsSet,
	sortOptions,
	isMobileView,
	isMobileDevice,
	columns,
	currentKeys,
	contentData,
	contentDataInitDone,
	onContentClick,
	onContentContextClick,
	onAllItemsSelect,
	onColumnSortClick,
	onSingleItemSelect,
	selectedFolders,
	selectedFiles,
	renderContentOptions,
	renderSelectByTypeMenu,
	noItemsLabel,
	listGridRef,
	listGridOuterRef,
	hasExploreCards,
	scrollTo,
	overwriteOptionsOnClick,
	filters
}) => {
	const keysLen = currentKeys.length;

	if (!contentDataInitDone) {
		return null;
	}

	if (
		(!contentData || !contentData.items || !keysLen || !columns || !Object.keys(contentData.items).length) &&
		(!filters || Object.keys(filters).length === 0)
	) {
		return (
			<>
				<EmptyState noItemsLabel={noItemsLabel} />
			</>
		)
	}

	if (isMobileDevice) {
		folderListItemRowHeight = 56;
		folderListItemMidRowHeight = 56;
		folderListItemLargeRowHeight = 66;
	}
	const isMobileOptionsOnTop = !!TEMPLATES[contentType].MOBILE_OPTIONS_ON_TOP;
	const disableOptionsRow = !!TEMPLATES[contentType].DISABLE_OPTIONS_ROW;
	const LIST_FULL_WIDTH = isMobileDevice ? '100%' : 'calc(100% - var(--spacing-sm))';
	const VIEW_SORT_ROW_HEIGHT = isMobileDevice ? (
		isMobileOptionsOnTop ? 80 : 38
	) : 48;
	const FOOTER_HEIGHT = 60;
	const isLargeList = getViewType(contentType) === LARGE_LIST_VIEW;
	const isMidList = getViewType(contentType) === MID_LIST_VIEW;
	const { showMenu } = useMenu();
	const [showStickyBar, setShowStickyBar] = useState(true);
	const [isFirstResize, setIsFirstResize] = useState(true);
	const [listWidth, setListWidth] = useState(0);
	const headerGridItems = [
		...(TEMPLATES[contentType].HEADER_PROMO_BLOCKS ? getHeaderElements(contentType, contentData, hasExploreCards) : []),
		{
			height: VIEW_SORT_ROW_HEIGHT + (
				isMobileDevice ?
					0 :
					(disableOptionsRow ? 0 : TABLE_HEAD_ROW_HEIGHT)
			) // Views & sorting menus
		}
	];
	// if (TEMPLATES[contentType].VIEWS_MENU.length <= 1 && !TEMPLATES[contentType].SORTING_MENU) {
	// 	headerGridItems[headerGridItems.length - 1].height -= VIEW_SORT_ROW_HEIGHT;
	// }
	let headerItemsHeight = 0;
	headerGridItems.map((item) => {
		headerItemsHeight += item.height;
	});
	headerItemsHeight -= headerGridItems[headerGridItems.length - 1].height;
	let rowsCountBeforeItems = headerGridItems.length;

	useEffect(() => {
		const customResize = () => {
			const wrapper = document.getElementById("elements-container").getBoundingClientRect();
			handleGridResize(wrapper.width)
		};

		if (TEMPLATES[contentType].DISABLE_REACT_WINDOW) {
			window.addEventListener("resize", customResize);
			customResize();
		}
		return () => {
			if (TEMPLATES[contentType].DISABLE_REACT_WINDOW) {
				window.removeEventListener("resize", customResize);
			}
		}
	}, []);

	const handleScroll = (e) => {
		const { scrollOffset } = e;

		if (showStickyBar && (scrollOffset < headerItemsHeight)) { //  || scrollDirection === "forward"
			setShowStickyBar(false);
		} else if (!showStickyBar && scrollOffset >= headerItemsHeight) { // scrollDirection === "backward" && 
			setShowStickyBar(true);
		}
	};

	const handleGridResizeDebounced = useCallback(
		debounce((width) => {
			setListWidth(width);
		}, 150),
		[]
	);

	const handleGridResize = (width) => {
		if (isFirstResize) {
			setListWidth(width);
			setIsFirstResize(false);
		} else {
			handleGridResizeDebounced(width);
		}
	};

	const itemCount = keysLen + rowsCountBeforeItems + 1;

	const getRowHeight = useCallback((rowIndex) => {
		if (rowIndex < rowsCountBeforeItems) {
			return headerGridItems[rowIndex].height;
		} else {
			const key = currentKeys[rowIndex - rowsCountBeforeItems];
			const item = contentData.items[key];
			if (item && item.hasOwnProperty('newHeight')) {
				return item.newHeight;
			}

			return isLargeList ? folderListItemLargeRowHeight : isMidList ? folderListItemMidRowHeight : folderListItemRowHeight;
		}
	}, [headerGridItems, rowsCountBeforeItems]);

	const height = useMemo(() => {
		return document.body.offsetHeight -
			(document.getElementById('selection-container')?.getBoundingClientRect().top || 0) - 10
	});

	const handleRowHeightChange = (changedHeight) => {
		if (typeof listGridRef.current.resetAfterIndex === "function") {
			listGridRef.current.resetAfterIndex(0);
		}
	}

	const stickyProps = {
		handleScroll: handleScroll,
		showStickyBar: showStickyBar,
		isMobileOptionsOnTop: isMobileOptionsOnTop,
		renderContentOptions: renderContentOptions,
		renderSelectByTypeMenu: renderSelectByTypeMenu,
		headerGridItems: headerGridItems,
		headerItemsHeight: headerItemsHeight,
		disableOptionsRow: disableOptionsRow,
		listGridRef: listGridRef,
		listGridOuterRef: listGridOuterRef,
		innerElementType: innerElementType,
		stickyIndices: [rowsCountBeforeItems - 1],
		contentType: contentType,
		sortIsSet: sortIsSet,
		sortOptions: sortOptions,
		keysLen: keysLen,
		selectedFolders: selectedFolders,
		selectedFiles: selectedFiles,
		onAllItemsSelect: onAllItemsSelect,
		onColumnSortClick: onColumnSortClick,
		isMobileView: isMobileView,
		isMobileDevice: isMobileDevice,
		itemCount: itemCount,
		itemSize: getRowHeight,
		listWidth: listWidth,
		listFullWidth: LIST_FULL_WIDTH,
		scrollTo: scrollTo,
		isLargeList: isLargeList,
		itemData: {
			columns,
			showMenu,
			currentKeys,
			contentData,
			rowsCountBeforeItems,
			itemCount,
			onContentClick,
			onContentContextClick,
			onSingleItemSelect,
			isLargeList,
			handleRowHeightChange,
			overwriteOptionsOnClick,
			getRowHeight
		}
	};

	if (TEMPLATES[contentType].DISABLE_REACT_WINDOW) {
		return <StickyList
			disableReactWindow={true}
			{...stickyProps}
		>
			{ListRow}
		</StickyList>
	}

	return (
		<AutoSizer disableHeight onResize={({ width }) => handleGridResize(width)}>
			{({ width }) => (
				<StickyList
					{...stickyProps}
					height={height}
					width={width}
				>
					{ListRow}
				</StickyList>
			)}
		</AutoSizer>
	);
};

export default FolderList;
