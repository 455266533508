import React from "react";
import { Menu, usePlaybackRateOptions, useVideoQualityOptions, useMediaState, useAudioOptions, useCaptionOptions } from '@vidstack/react';
import { SettingsIcon } from "../../icons";
import { __ } from "../../../../lib/translate";

const PlyrMenu = ({
  label,
  hint,
  children,
  disabled,
  onCloseMenu = () => {},
  onOpenMenu = () => {}
}: {
  label: PlyrLayoutWord,
  hint?: string,
  disabled: boolean,
  children: React.ReactNode
}) => {
  const [open, setOpen] = React.useState(false);

  function onOpen() {
    setOpen(true);
    onOpenMenu();
  }

  function onClose() {
    setOpen(false);
    onCloseMenu();
  }

  return (
    <Menu.Root onOpen={onOpen} onClose={onClose}>
      <PlyrMenuButton label={label} open={open} hint={hint} disabled={disabled} />
      <Menu.Items>{children}</Menu.Items>
    </Menu.Root>
  );
};

const PlyrMenuButton = ({
  label,
  hint,
  open,
  disabled,
}: {
  label: PlyrLayoutWord,
  hint?: string,
  open: boolean,
  disabled: boolean
}) => {
  const buttonText = label,
    goBackText = 'Go back to previous menu';
  return (
    <Menu.Button
      className={`plyr__control plyr__control--${open ? 'back' : 'forward'}`}
      data-plyr="settings"
      disabled={disabled}
    >
      <span className="plyr__menu__label" aria-hidden={open ? 'true' : undefined}>
        {buttonText}
      </span>
      {hint ? <span className="plyr__menu__value">{hint}</span> : null}
      {open ? <span className="plyr__sr-only">{goBackText}</span> : null}
    </Menu.Button>
  );
};

const SettingsMenu = ({
  speed = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4],
  autoQualityText = __("Auto", "Auto")
}) => {

  const [qualityMenuOpen, setQualityMenuOpen] = React.useState(false);

  const captionsMenu = () => {
    const offText = __("Disabled", "Disabled"),
      options = useCaptionOptions({ off: offText }),
      hint = options.selectedTrack?.label ?? offText;
    return (
      <PlyrMenu label="Captions" hint={hint} disabled={options.disabled} className="captions-menu">
        <Menu.RadioGroup value={options.selectedValue}>
          {options.map(({ label, value, select }) => (
            <Menu.Radio className="plyr__control" value={value} onSelect={select} key={value}>
              <span>{label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </PlyrMenu>
    );
  };

  const audioMenu = () => {
    const defaultText = __("Default", "Default"),
    $track = useMediaState('audioTrack'),
      options = useAudioOptions();
    return (
      <PlyrMenu label="Audio" hint={$track?.label ?? defaultText} disabled={options.disabled}>
        <Menu.RadioGroup value={options.selectedTrack?.id + ":" + options.selectedTrack?.kind + "-" + options.selectedValue}>
          {options.map(({ label, value, select, track }) => (
            <Menu.Radio className="plyr__control" value={track?.id + ":" + track?.kind + "-" + value} onSelect={select} key={track?.id + ":" + track?.kind + "-" + value}>
              <span>{label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </PlyrMenu>
    );
  };

  const qualityMenu = () => {
    const autoText = autoQualityText,
      options = useVideoQualityOptions({ auto: autoText, sort: 'descending' }),
      currentQuality = options.selectedQuality?.height,
      hint =
        options.selectedValue !== 'auto' && currentQuality
          ? (currentQuality > 0 ? `${currentQuality}p` : __("video_player_quaility_original_size", "Original"))
          : `${autoText !== false ? autoText : ``}${currentQuality > 0 ? (currentQuality ? ` (${currentQuality}p)` : '') : __("video_player_quaility_original_size", "Original")}`;
    return (
      <PlyrMenu label={__("Quality", "Quality")} hint={hint} disabled={options.disabled && !qualityMenuOpen} onOpenMenu={() => setQualityMenuOpen(true)} onCloseMenu={() => setQualityMenuOpen(false)}>
        <Menu.RadioGroup value={options.selectedValue}>
          {options.map(({ label, value, select }) => (
            <Menu.Radio className="plyr__control" value={value} onSelect={select} key={value}>
              <span>{label === "0p" ? __("video_player_quaility_original_size", "Original") : label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </PlyrMenu>
    );
  }

  const speedMenu = () => {
    const normalLabel = __("Normal", "Normal"),
      options = usePlaybackRateOptions({ rates: speed, normalLabel }),
      hint = options.selectedValue === '1' ? normalLabel : options.selectedValue + 'x';
    return (
      <PlyrMenu label={__("Speed", "Speed")} hint={hint} disabled={options.disabled}>
        <Menu.RadioGroup value={options.selectedValue}>
          {options.map(({ label, value, select }) => (
            <Menu.Radio className="plyr__control" value={value} onSelect={select} key={value}>
              <span>{label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </PlyrMenu>
    );
  };

  return (
    <div className="plyr__controls__item plyr__menu">
      <Menu.Root>
        <Menu.Button className="plyr__control" data-plyr="settings">
          <>
            <SettingsIcon />
            <span className="plyr__tooltip">Settings</span>
          </>
        </Menu.Button>
        <Menu.Items className="plyr__menu__container" placement="top end">
          <div>
            <div>
                <>
                  {audioMenu()}
                  {captionsMenu()}
                  {speedMenu()}
                  {qualityMenu()}
                </>
            </div>
          </div>
        </Menu.Items>
      </Menu.Root>
    </div>
  );
}

export default SettingsMenu;