// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Btn } from "../ButtonDefault";
import * as Style from "./styledComponents";
import FileIcon from "../FileIcon";

import apiConfig from "../../api/config";
import { __ } from "../../lib/translate";
import { getIconType } from "../../lib/utils";
import { ICONS } from "../../lib/icons";

const RestoreRevisionTimeModal = ({
  metas = {},
  tm_key = "",
  time,
  encrypted
}: {
  metas: aArray<any>,
  tm_key: string,
  time: any,
  encrypted: boolean
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      console.log("ADD event on keyup RestoreRevisionTimeModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup RestoreRevisionTimeModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]); //[isOpen, onEnter]

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        restoreRevision();
      }
    },
    [restoreRevision]
  );

  const restoreRevision = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    HFN.createRestoreFolder(time, encrypted, function(folderid) {
      console.log("folderid", folderid);

      let batch = new batchApiCall({
        sequential: false,
        continueOnError: false,
        maxConcurentCalls: 5,
        errorCallback: function(ret) {
          if (ret.error === 2008) {
            HFN.openModalFullQuota();
          } else {
            HFN.message(ret.error, "error");
          }
          setIsLoading(false);
        }
      });

      for (let n = 0; n < metas.length; ++n) {
        let b_method;
        let b_params = {
          tofolderid: folderid,
          tmkey: tm_key
        };

        if (metas[n].isfolder) {
          b_method = "tm_copyfolder";
          b_params.folderid = metas[n].folderid;
        } else {
          b_method = "tm_copyfile";
          b_params.fileid = metas[n].fileid;
        }

        batch.addCall(b_method, b_params, { timeout: 600000 });
      }

      batch.execute(function() {
        let openUrl = location.protocol + "//" + location.host + "/#page=filemanager&folder=" + folderid;
        HFN.message(
          __("items_restored_open_location", "Item(s) Restored. %openUrl%[Open Location]", {
            openUrl: openUrl
          }),
          "success",
          false
        );
        setIsOpen(false);
      });
    });
  });

  const renderRow = item => {
    const { id, name, icon } = item;

    return (
      <Style.Row key={id}>
        <FileIcon item={icon} type={ICONS.SVG_LIST} metadata={item} />
        <Style.NameEllipsis>{name}</Style.NameEllipsis>
      </Style.Row>
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("restore_items", "Restore Items")}</Style.Header>
          <Style.Label>{__("items_to_restore", "Items to restore")}</Style.Label>
          <BoxTeam>{metas.map(renderRow)}</BoxTeam>
          <Style.Footer>
            <Btn
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Btn>
            <Btn
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              onClick={restoreRevision}
              disabled={isLoading}
              loading={isLoading}
            >
              {__("Restore")}
            </Btn>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default RestoreRevisionTimeModal;

const BoxTeam = styled(Style.Box)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 10px;
  font-size: 15px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  & > div:only-child {
    padding-bottom: 0px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
