import "@vidstack/react/player/styles/base.css";
import "@vidstack/react/player/styles/plyr/theme.css";

import "./less/gallery.less";

import React, { useState, useEffect, useRef, useMemo, useCallback, useLayoutEffect } from "react";

import { 
  MediaPlayer, 
  MediaProvider, 
  MediaErrorDetail, 
  MediaVolumeChange, 
  MediaSeekingEvent, 
  MediaWaitingEvent,
  MediaEndedEvent,
  MediaAutoPlayFailEventDetail,
  MediaAutoPlayFailEvent,
  MediaSeekingRequestEvent, 
  useMediaRemote, 
  isHLSProvider, 
  MediaProviderAdapter, 
  MediaProviderSetupEvent,
  MediaProviderChangeEvent, 
  MediaErrorEvent,
  MediaCanPlayEvent,
  MediaCanPlayDetail,
  MediaPlayEvent,
  HLSErrorEvent, 
  ErrorData,
  MediaPlayRequestEvent,
  Controls
} from "@vidstack/react";

import HLS from 'hls.js'; 
import { PlyrLayout, plyrLayoutIcons } from '@vidstack/react/player/layouts/plyr';

// Custom controls
import MuteAndVolumeMenu from "./video/controls/MuteAndVolumeMenu";
import MuteBtn from "./video/controls/MuteBtn";
import ProgressTimeSlider from "./video/controls/ProgressTimeSlider";
import GoogleChromeCastButton from "./video/controls/GoogleChromeCastButton";
import SettingsMenu from "./video/controls/SettingsMenu";
import PlayLargeButton from "./video/controls/PlayLargeButton";
// import SeekingLabel from "./video/controls/SeekingLabel";
import { PlayIcon, PauseIcon, FullscreenIcon, FullscreenExitIcon, PipIcon, PipExitIcon, AirPlayIcon, CaptionsOnIcon, CaptionsOffIcon } from "./icons";

import VideoPlayerLocalStorage from "./video/storage/VideoPlayerLocalStorage";
import { detectIsMobile } from "../../lib/utils";

import { HLS_CONFIG, HLS_VIDEO_VARIANTS, HLS_DEFAULT_VIDEO_QUALITY, MUTED_ON_AUTOPLAY_FAILED_STORAGE_KEY } from "./constants";
import { getProperVideoSize } from "./utils";

type Props = {
  videoData: {
    id: string,
    name: string,
    videoUrls: [any],
    thumbUrl: string,
    thumbSize: string,//<width>x<height>
    thumbType: string,
    maxWidth: number,
    maxHeight: number,
    startPlayerLoading: boolean,
    afterError: boolean,
    originalWidth: number,
    originalHeight: number,
    rotate: number
  },
  autoPlay: boolean,
  onAutoPlayFailed: () => void,
  autoPlayFailed: boolean,
  onVideoEnded: (video: videoData) => void,
  slideshowActive: boolean,
  slideshowPlaying: boolean,
  videoLoading: boolean,
  setVideoLoading: (loading: boolean) => void,
  onPause: () => void,
  onPlay: () => void,
  onFullscreenChange: (isFullscreen: boolean) => void,
  onEnterFullscreenRequest: () => void,
  defaultDocumentTitle: string,
  onError: ({autoPlay: boolean}) => void,
  onSuccessLoadingThumb: () => void,
  seek: number,
  onDraggingVideoProgress: (dragging: boolean) => void,
  noSwipeGesture: boolean,
  onPlayWithMissingVideoSource: () => void,
  checkForSwipeAction: () => "next" | "previous" | false 
};

const VideoContainer = React.forwardRef(
(
  {
    videoData,
    autoPlay = false,
    onAutoPlayFailed = () => {},
    autoPlayFailed = false,
    onVideoEnded = () => {},
    slideshowActive = false,
    slideshowPlaying = false,
    videoLoading = false,
    setVideoLoading = () => {},
    onPause = () => {},
    onPlay = () => {},
    onFullscreenChange = () => {},
    onEnterFullscreenRequest = () => {},
    defaultDocumentTitle = "",
    onError = () => {},
    onSuccessLoadingThumb = () => {},
    seek = 0,
    onDraggingVideoProgress = () => {},
    noSwipeGesture = true,
    onPlayWithMissingVideoSource = () => {},
    checkForSwipeAction = () => false
  }: Props,
  ref
) => {

  // !!!Important don't use const vidstackMediaPlayerProps = useStore(MediaPlayerInstance, vidstackMediaPlayer);
  // This will trigger undesired rerenders -> instead use ref.current?.state -> 
  // https://www.vidstack.io/docs/player/core-concepts/state-management?styling=default-theme#avoiding-renders

  const [disableKeyboardActions, setDisableKeyboardActions] = useState(true);
  const [videoDuration, setVideoDuration] = useState(-1);

  const remote = useMediaRemote();
  
  const lastPlayingTime = useRef(0);

  const playVideoAfterquailityChanged = useRef(null);

  const volumeLevelAndMuted = useRef(null);

  const volumeSliderRef = useRef(null);
  const disableSendAnalyticsDataForPause = useRef(false);

  const disableErrorHandling = useRef(false);

  const [videoDataForPlayer, setVideoDataForPlayer] = useState(videoData);
  
  const [playerControlsHidden, setPlayerControlsHidden] = useState(false);

  // If true and muted => shows Unmute icon.
  const [mutedDueToAutoPlayFailed, setMutedDueToAutoPlayFailed] = useState(() => localStorage.getItem(MUTED_ON_AUTOPLAY_FAILED_STORAGE_KEY) === "1");

//  const [seekingLabel, setSeekingLabel] = useState(null);

  const storage = useMemo(() => new VideoPlayerLocalStorage(), []);

  const isMobile = useMemo(() => !!detectIsMobile(), []);

  const defaultHLSVideoVariant = useMemo(() => {
    return {
      landscape: HLS_VIDEO_VARIANTS.landscape.find((v) => v.type === HLS_DEFAULT_VIDEO_QUALITY),
      portrait: HLS_VIDEO_VARIANTS.portrait.find((v) => v.type === HLS_DEFAULT_VIDEO_QUALITY),
    }
  }, []);

  const originalVideoSizes = useRef();
  const lastUsedQuality = useRef();
  const currentHLSPath = useRef("");
  const lastHLSPathError = useRef("");
  const mediaErrorsCnt = useRef(0);

  const preventToggleControls = useRef(0);

  // const endedVideoCheckTimeout = useRef(null);

  const videoPlayerIcons = useMemo(() => {
    return {
      ...plyrLayoutIcons,
      Play: PlayIcon,
      Pause: PauseIcon,
      EnterFullscreen: FullscreenIcon,
      ExitFullscreen: FullscreenExitIcon,
      EnterPiP: PipIcon,
      ExitPiP: PipExitIcon,
      AirPlay: AirPlayIcon,
      CaptionsOn: CaptionsOnIcon,
      CaptionsOff: CaptionsOffIcon
    };
  }, []);

  // Important to be passed to MediaPlayer as separate variable through useMemo!
  // Otherwise each rerender calls new MediaMetadata(...) which increases the CPU usage especially on Safari.
  const thumbForMediaSessionAPI = useMemo(() => videoDataForPlayer ? [{
    src: videoDataForPlayer.thumbUrl,
    sizes: videoDataForPlayer.thumbSize,
    type: videoDataForPlayer.thumbType,
  }] : (thumbForMediaSessionAPI || null), [videoDataForPlayer]);

  useEffect(() => {
    return async () => {
      // Rollback document.title
      document.title = defaultDocumentTitle;

      ref.current?.qualities.removeEventListener('add', onAddQuality);
      
      await exitPIP();
    }
  }, []);

  useEffect(() => {
    preventToggleControls.current = false;

    currentHLSPath.current = videoData && videoData.HLSpath ? videoData.HLSpath : "";

    if (videoData !== videoDataForPlayer) {
      // It's used to keep the previous quality only if coming after error for now.
      lastUsedQuality.current = ref.current?.state.quality;

      if (videoData && videoDataForPlayer && videoData.id === videoDataForPlayer.id && ((videoData.videoUrls.length > 0 && videoDataForPlayer.videoUrls.length === 0) || videoData.afterError)) {
        // Same video, but now we have videoUrls for this video or coming after error for it.
        if (videoData.afterError && videoData.HLSpath === "") {
          // Non-HLS video.
          setVideoDataForPlayer(null);
        } else {
          setVideoDataForPlayer(videoData);
        }

        if (videoData.afterError) {
          preventToggleControls.current = true;
        }

      } else {
        // Check if video has been played.
        if (autoPlay || autoPlayFailed || (ref.current?.state.played && ref.current?.state.played.length > 0) || (ref.current?.state.buffered && ref.current?.state.buffered.length > 0) || ref.current?.state.started || (ref.current.el && ref.current.el.hasAttribute("data-starting"))) {
          // Calling setVideoDataForPlayer to null will force destroy of player to be reloaded for next video.
          // This helps us to apply load="play" or autoPlay. 
          // Load="play" shows us only the thumbnail without buffering any video data.
          setVideoDataForPlayer(null);
        } else {
          setVideoDataForPlayer(videoData);
        }
   
      }
    } else {
      if (autoPlay && ref.current && !ref.current?.state.started) {
        if (!disableKeyboardActions) {
          setDisableKeyboardActions(true);
        }
        ref.current.el.setAttribute("data-starting", "");
      }
    }
  }, [videoData]);

  useLayoutEffect(() => {
    // If load="custom" was used and we have already received video urls.
    const requireStartPlayerLoading = videoDataForPlayer && videoDataForPlayer.startPlayerLoading && videoDataForPlayer.videoUrls.length > 0;

    if (requireStartPlayerLoading) {
      ref.current?.startLoading();
    }
  }, [videoDataForPlayer])

  useEffect(() => {
    if (videoData && videoDataForPlayer === null) {
      setVideoDataForPlayer(videoData);
      exitPIP();
    }

    if (videoDataForPlayer !== null) {
      document.title = videoDataForPlayer.name + " - pCloud";
      mediaErrorsCnt.current = 0;
    }
    
    const requireStartPlayerLoading = videoDataForPlayer && videoDataForPlayer.startPlayerLoading && videoDataForPlayer.videoUrls.length > 0;
    
    if (!requireStartPlayerLoading) {
      removeDataStartingAndEnableKeyboard();
    }

    if (!autoPlay && videoLoading) {
      setVideoLoading(false);
    }
    
    if (ref.current?.qualities) {
      // !!! Important don't use "current" !!! "next": Trigger a quality level switch for next fragment. This could eventually flush already buffered next fragment.
      ref.current.qualities.switch = "next";
    }
    
    ref.current?.qualities.removeEventListener('add', onAddQuality);
    if (videoDataForPlayer !== null) {
      originalVideoSizes.current = { 
        width: videoDataForPlayer.originalWidth, 
        height: videoDataForPlayer.originalHeight, 
        rotate: videoDataForPlayer.rotate,
        qualityToSet: videoDataForPlayer.afterError && lastUsedQuality.current ? lastUsedQuality.current : null
      };
      
      ref.current?.qualities.addEventListener('add', onAddQuality);
    }

    // Subscribe for updates without triggering renders.
    return ref.current?.subscribe(({ currentTime }) => {
      lastPlayingTime.current = currentTime > 0 ? currentTime : lastPlayingTime.current;
      
      if (slideshowPlaying && currentTime > 0 && currentTime === ref.current?.state.duration && ref.current.el) {
        // Prevents Play button to be shown for a while when video is ending.
        ref.current.el.setAttribute("data-ended", "");
      }
    });
  }, [videoDataForPlayer]);

  useEffect(() => {
    if (!slideshowPlaying) {
      return;
    }

    // Playing slideshow.
    if (ref.current && !ref.current.state.playing && ref.current.state.canPlay) {
      ref.current.play();
    } else if (autoPlay && ref.current && !ref.current?.state.playing) {
      // We have to reload the player to play due to browser's policy: "User should interact with the player first."
      if (videoDataForPlayer.videoUrls.length === 0) {
        // Show loading
        if (!ref.current?.state.started) {
          ref.current.el.setAttribute("data-starting", "");
        }
        // Trigger "getmediatranscodelink" to set HLS video url.
        // We'll receive new videoData which hook will trigger setVideoDataForPlayer(videoData);
        onPlayWithMissingVideoSource();
      } else {
        setVideoDataForPlayer(null);
      }
    }
  }, [slideshowPlaying, autoPlay]);

  const removeDataStartingAndEnableKeyboard = () => {
    // Remove loading if shown from previous video.
    if (!autoPlay && ref.current?.el) {
      ref.current.el.removeAttribute("data-starting");
      ref.current.el.removeAttribute("data-error");
      if (disableKeyboardActions) {
        setDisableKeyboardActions(false);
      }
    }
  };

  const exitPIP = async () => {
    if (document.pictureInPictureElement) {
      try {
        await ref.current.exitPictureInPicture();
      } catch (e) {}
    }
  }

  const onStartedVideoEvent = (nativeEvent) => {
    setVideoLoading(false);
    
    if (ref.current.el) {
      ref.current.el.removeAttribute("data-starting");
      ref.current.el.removeAttribute("data-error");
      setDisableKeyboardActions(false);
    }
    
    if (autoPlay && remote && seek > 0) {
      if (!(seek > ref.current?.state.duration)) {
        remote.seek(seek, nativeEvent);
      }

      lastPlayingTime.current = 0;
    } else {
      // We want to apply it only once afterError.
      preventToggleControls.current = false;
    }
  }

  /**
   * HLS providers only.
   * Sets default quality, disable AUTO quality and calculate the actual video size for each variant.
   */
  const onAddQuality = useCallback((event) => {
    if (!isHLSProvider(ref.current.provider)) {
      return;
    }

    let selectThisQuality = false;

    const landscapeOrPortrait = event.detail.height > event.detail.width  ? "portrait" : "landscape";

    if (defaultHLSVideoVariant && defaultHLSVideoVariant[landscapeOrPortrait] && event.detail.height === defaultHLSVideoVariant[landscapeOrPortrait].height && !originalVideoSizes.current.qualityToSet) {
      selectThisQuality = true;
    }

    // const actualVideoSize = getProperVideoSize(originalVideoSizes.current.width || 0, originalVideoSizes.current.height || 0, event.detail.width, event.detail.height, originalVideoSizes.current.rotate);
    // event.detail.width = actualVideoSize.width;
    event.detail.height = Math.min(event.detail.width, event.detail.height);

    if (selectThisQuality) {
      // !!! Important don't use "current" !!! "next": Trigger a quality level switch for next fragment. This could eventually flush already buffered next fragment.
      ref.current.qualities.switch = "next";
      // Set 960x540 as our default quality size for playing videos.
      event.detail.selected = true;
    } else if (originalVideoSizes.current.qualityToSet && event.detail.width === originalVideoSizes.current.qualityToSet.width && event.detail.height === originalVideoSizes.current.qualityToSet.height) {
      // After error we are here. Keep the old quality.
      // !!! Important don't use "current" !!! "next": Trigger a quality level switch for next fragment. This could eventually flush already buffered next fragment.
      ref.current.qualities.switch = "next";
      event.detail.selected = true;
    }
  }, []);

  const onProviderSetupVideoEvent = async (provider: MediaProviderAdapter, nativeEvent: MediaProviderSetupEvent) => {
    // Non-HLS only. -> mp4 is here.
    if (!isHLSProvider(provider) && ref.current?.state.canSetQuality && ref.current?.state.qualities && ref.current?.state.qualities.length > 1) {
      // TODO set lastUsedQuality if there is such videoDataForPlayer.afterError && lastUsedQuality.current ? lastUsedQuality.current : null
      // Change quality to be the best after "Original".
      let bestNonOriginal = null;
      for (let i = 0; i < ref.current.state.qualities.length; i++) {
        if (!ref.current?.state.qualities[i].isoriginal && (!bestNonOriginal || ref.current?.state.qualities[i].height > bestNonOriginal.height)) {
          bestNonOriginal = ref.current?.state.qualities[i];
        }
      }

      if (bestNonOriginal) {
        // It changes the quality.
        bestNonOriginal.selected = true;
      }
    }

    // Save initial volume object. -> Used only for Google Analytics data.
    if (typeof ref.current?.state.volume !== "undefined" && typeof ref.current?.state.muted !== "undefined") {
      const storageDataVolume = await storage.getVolume();
      const storageDataMuted = await storage.getMuted();
      volumeLevelAndMuted.current = {
        volume: storageDataVolume !== null ? storageDataVolume : ref.current.state.volume, 
        muted: storageDataMuted !== null ? storageDataMuted : ref.current.state.muted
      };
    }
  };

  const onProviderChangeVideoEvent = (provider: MediaProviderAdapter | null, nativeEvent: MediaProviderChangeEvent,) => {
    if (isHLSProvider(provider)) {
      // Set hls.js to be used locally (i.e., not over a CDN).
      provider.library = HLS;
      // Fine Tuning HLS support
      provider.config = HLS_CONFIG;
    }
  };

  const onPlayVideoEvent = (nativeEvent: MediaPlayEvent) => {
    if (preventToggleControls.current) {
      // Prevent calling controls.show();
      nativeEvent.stopImmediatePropagation();
    }

    sendAnalyticsData("play");

    document.title = String.fromCharCode(9654) + " " + videoDataForPlayer.name + " - pCloud";
   // onPlay();
  }

  const onPauseVideoEvent = () => {
    // Add this delay, because pause is called also when video is ended.
    // We want only "complete" to be sent in such case.
    setTimeout(() => {
      if (!ref.current?.state.ended && !disableSendAnalyticsDataForPause.current) {
        sendAnalyticsData("pause");
      } // Else we've already sent "complete" event.
      disableSendAnalyticsDataForPause.current = false;
    }, 100);

    // Just in case it's not cleaned after seeking.
    preventToggleControls.current = false;
    
    document.title = videoDataForPlayer.name + " - pCloud";
    onPause();
  }

  const onEndedVideoEvent = (nativeEvent: MediaEndedEvent) => {
    sendAnalyticsData("complete");
    
    if (!ref.current?.state.paused) {
      const videoEl = ref.current.el.querySelector('video');

      if (videoEl && videoEl.readyState === 1 && ref.current.provider && ref.current.provider.ctx) {
        // Safari required only, because if we seek to the end the video sometimes remains in readyState === 1 and paused state is not applied properly,
        // due to vidstack's issue -> you can check:
        // #onPause(event: Event) {
          // Avoid seeking events triggering pause.
          // if (this.#media.readyState === 1 && !this.#waiting) return;
          // ...
        // }
        ref.current.provider.ctx.notify("pause", undefined, nativeEvent);
      } else {
        ref.current.pause(nativeEvent);
      }
    }

    document.title = videoData.name + " - pCloud";
    lastPlayingTime.current = 0;

    if (slideshowActive) {
      disableSendAnalyticsDataForPause.current = true;
    }

    if (slideshowActive && ref.current?.state.fullscreen) {
      // Wait the video to exit the fullscreen and then activeSlideshow useEffect will exit the slideshow fullscreen as well.
      ref.current?.exitFullscreen().finally(() => {
        onVideoEnded(videoDataForPlayer);
      });
    } else {
      onVideoEnded(videoDataForPlayer);
    }
  };

  /**
   * disableHLSVideoRetries = true -> Skip retry with HLS for those errors.
   */
  const onErrorVideoEvent = (detail: MediaErrorDetail, nativeEvent: MediaErrorEvent, disableHLSVideoRetries = false) => {
    // alert("onError: " + JSON.stringify(detail));
    // Sometimes we continue receiving errors for the old video URL while we are retrying.
    if (disableErrorHandling.current) {
      return;
    }

    disableErrorHandling.current = true;

    setVideoLoading(false);

    if (ref.current.el) {
      // Show loading.
      ref.current.el.setAttribute("data-error", "");
    }
    
    const isHLS = ref.current.provider && isHLSProvider(ref.current.provider);
    onError({autoPlay: true, mediaType: "video", lastPlayingTime: lastPlayingTime.current, disableHLSVideoRetries: (disableHLSVideoRetries || !isHLS)});
  };

  const onHlsErrorVideoEvent = (data: ErrorData, nativeEvent: HLSErrorEvent) => {
    // TODO comment this debug.
    // alert("HLSerror:" + data.details);
    console.log("HLSError: ", data, data.details, nativeEvent, videoDataForPlayer);

    if (data.fatal) {
      // Check if this error is for the currently loaded video URL and it's not already sent for retry.
      const errorURL = data.url || (data.response && data.response.url);
      if (lastHLSPathError.current !== "" && currentHLSPath.current !== "" && lastHLSPathError.current !== currentHLSPath.current && errorURL && errorURL.includes(currentHLSPath.current)) {
        // We have a new error refering to our current HLS URL.
        disableErrorHandling.current = false;
        mediaErrorsCnt.current = 0;
      }

      if (!disableErrorHandling.current) {
        lastHLSPathError.current = currentHLSPath.current;
      }
    }

    // mediaError handling.
    // We are not checking only for data.fatal, because sometimes it stucks on non receiving more fatal errors.
    // For example it sometimes stucks on "[info] rebuffering expected, optimal quality level -1." without raising more errors.
    if (!disableErrorHandling.current && data.type === "mediaError") {
      // Skip these errors from increasing mediaErrorsCnt.
      const whitelistedMediaErrors = [
        "bufferStalledError",
        "bufferNudgeOnStall"
      ];

      if (!(data && data.details && whitelistedMediaErrors.includes(data.details))) {
        mediaErrorsCnt.current++;
      }

      // Vidstack tries to fix the error by calling HLS.recoverMediaError();
      // Unfortunately it doesn't handle if the same error is thrown again, so it's better to stop calling HLS.recoverMediaError() forever.
      if (mediaErrorsCnt.current >= 3) {
        onErrorVideoEvent(null, null, true);
      }
    }

    // networkError handling.
    // Check for 404 response code, which means the video transcoding is broken.
    // Check for 500 -> API doesn't work.
    if (data.type === "networkError" && data.response && data.response.code && [404, 500].includes(data.response.code)) {
      // Call the error video event here, because we want to pass extra info: disableHLSVideoRetries = true.
      onErrorVideoEvent(null, null, true);
      sendCrashlyticsReportOnHLSError(data, nativeEvent);
    }
    // else onErrorVideoEvent will be invoked for fatal HLS errors internally by Vidstack player.
  };

  const sendCrashlyticsReportOnHLSError = (data: ErrorData, nativeEvent: HLSErrorEvent) => {
    try {
      const err = new CustomEvent("error", { detail: nativeEvent.detail });
      err.message = videoDataForPlayer !== null ? "HLSError: " + JSON.stringify({...videoDataForPlayer, locationid: HFN.apiConfig.locationid}) : "HLS error occurred.";
      err.filename = data.url || nativeEvent.detail?.response.url || currentHLSPath.current;
      if (data.error && data.error.stack) {
        err.error = { stack: data.error.stack }
      }

      window.dispatchEvent(err);
    } catch (e) {}
  };

  const onHlsUnsupportedVideoEvent = () => {
    onErrorVideoEvent(null, null, true);
  };

  const onErrorLoadingThumb = () => {
    setVideoLoading(false);

    onError({autoPlay: false, mediaType: "videoThumb"});
  };

  const onLoadStartVideoEvent = () => {
    // Enable error handling again for the new URL.
    disableErrorHandling.current = false;
  };

  const onControlsVisibilityChange = (isVisible) => {
    if (!isMobile) {
      return;
    }

    setPlayerControlsHidden(!isVisible);
  };

  // This can be used if quality AUTO is active to prevent stopping video on resized window.
  // Fix for: After resizing window quality is changed, but it's not continue playing.
  const onQualityChangeVideoEvent = (quality) => {
    if (ref.current?.state.playing) {
      playVideoAfterquailityChanged.current = true;
    }
  };

  const onCanPlayVideoEvent = (detail: MediaCanPlayDetail, nativeEvent: MediaCanPlayEvent) => {
    if (preventToggleControls.current) {
      // Prevent calling controls.show();
      nativeEvent.stopImmediatePropagation();
    }
    // This can be used if quality AUTO is active to prevent stopping video on resized window.
    // Fix for: After resizing window quality is changed, but it's not continue playing.
    // if (ref.current && !ref.current.state.playing) {
    //   ref.current.play();
    // }
    
    // playVideoAfterquailityChanged.current = false;
  };

  const onSeekingVideoEvent = (currentTime: number, nativeEvent: MediaSeekingEvent) => {
    if (!preventToggleControls.current) {
      // Show controls, because when keyTarget is document, they are not shown.
      ref.current.controls.show(0, nativeEvent);

      // Hide after defaultDelay
      ref.current.controls.hide(undefined, nativeEvent);
    } else {
      // We want to apply it only once afterError.
      preventToggleControls.current = false;
    }
    
    // Safari required only, because we don't receive seeked event after video is seeked to the end.
    if (isHLSProvider(ref.current.provider) && currentTime > 0 && currentTime === ref.current?.state.duration) {
      ref.current.pause(nativeEvent);
      ref.current.el.removeAttribute("data-seeking");
    }

    // clearTimeout(endedVideoCheckTimeout.current);
    // endedVideoCheckTimeout.current = setTimeout(() => {
    //   // 100ms and no ended state. Probably we won't receive onEndedVideoEvent...
    //   if (isHLSProvider(ref.current.provider) && currentTime > 0 && currentTime === ref.current?.state.duration && !ref.current.state?.ended) {
    //     onEndedVideoEvent();
    //   }
    // }, 100);
  };

  /**
   * Safari required only, because we don't receive seeked event after video is seeked to the end.
  */
  const onWaitingVideoEvent = (nativeEvent: MediaWaitingEvent) => {
    if (isHLSProvider(ref.current.provider) && ref.current?.state.currentTime > 0 && ref.current?.state.currentTime === ref.current?.state.duration) {
      ref.current.el.setAttribute("data-ended", "");
    }
  };

  // const onMediaSeekingRequestVideoEvent = (time: number, nativeEvent: MediaSeekingRequestEvent) => {
  //   if (nativeEvent && nativeEvent.originEvent && nativeEvent.originEvent.key && (nativeEvent.originEvent.key === "Right" || nativeEvent.originEvent.key === "Left") && seekingLabel !== nativeEvent.originEvent.key) {
  //     setSeekingLabel(nativeEvent.originEvent.key);
  //   }
  // }

  const onSeekedVideoEvent = (currentTime: number, nativeEvent: MediaSeekingEvent) => {
  //   setSeekingLabel(null);
  };

  const onVolumeChangeVideoEvent = async (volume: MediaVolumeChange) => {
    if (!volume.muted) {
      localStorage.removeItem(MUTED_ON_AUTOPLAY_FAILED_STORAGE_KEY);
      setMutedDueToAutoPlayFailed(false);
    }

    if (!volumeSliderRef.current?.state.dragging) {
      if (volume.volume !== volumeLevelAndMuted.current?.volume) {
        sendAnalyticsData("volume", volume.volume);
      }
  
      if (volume.muted !== volumeLevelAndMuted.current?.muted && volume.muted) {
        sendAnalyticsData("mute");
      }
  
      volumeLevelAndMuted.current = volume;
    }
  };

  const onFullscreenChangeVideoEvent = (isFullscreen: boolean) => {
    if (isFullscreen) {
      sendAnalyticsData("fullscreen", "on");
    }

    onFullscreenChange(isFullscreen);
  };

  const onRateChangeVideoEvent = (rate: number) => {
    sendAnalyticsData("playrate change", rate);
  };

  const onHlsManifestLoadedVideoEvent = (data: ManifestLoadedData, nativeEvent: HLSManifestLoadedEvent) => {
    console.log("Manifest: ", data);
  }

  const onMediaPlayRequestVideoEvent = (nativeEvent: MediaPlayRequestEvent) => {
    // Prevent playing video while we are swiping to next/prev gallery item.
    if (checkForSwipeAction() !== false) {
      // We are swiping to next/prev gallery item.
      nativeEvent.stopImmediatePropagation();
      return;
    }
    
    // Fix seeked event issue when we are seeking to the end and the state is wrongly not ended, but we are actually on the end of video.
    if (ref.current?.state.currentTime > 0 && ref.current?.state.currentTime === ref.current?.state.duration && nativeEvent.triggers && nativeEvent.triggers.hasType("seeked")) {
      // Prevent calling play().
      nativeEvent.stopImmediatePropagation();
    }

    // console.log("pvm ", ref.current?.state.ended, ref.current?.state.currentTime, ref.current?.state.duration);
    
    // Show loading if video is starting for first time.
    // This way we hold the loading and poster shown until 1.ts is fully downloaded.
    // Otherwise black screen is shown until 1.ts is downloaded.
    if (!ref.current?.state.started) {
      ref.current.el.setAttribute("data-starting", "");
    }

    if (videoDataForPlayer.videoUrls.length === 0) {
      // Trigger "getmediatranscodelink" to set HLS video url.
      // We do this here, because calling this API call starts transcoding task.
      // We should not call "getmediatranscodelink" before user really intend to watch it.
      onPlayWithMissingVideoSource();
    }
  };

  const onMediaPauseRequestVideoEvent = () => {
    removeDataStartingAndEnableKeyboard();
  };

  const onAutoPlayFailVideoEvent = (detail: MediaAutoPlayFailEventDetail, nativeEvent: MediaAutoPlayFailEvent) => {
    if (!(detail && detail.error && /NotAllowedError/.test(detail.error))) {
      return;
    }

    // NotAllowedError -> we are here.
    let refreshVideoData = autoPlay;
    if (videoData && !videoData.autoPlayMuted) {
      videoData.autoPlayMuted = true;
      // Autoplay failed. Let's try to autoplay again, but this time start video muted.
      // Refresh the player.
      setVideoDataForPlayer(null);
      refreshVideoData = false;

      localStorage.setItem(MUTED_ON_AUTOPLAY_FAILED_STORAGE_KEY, "1");
      setMutedDueToAutoPlayFailed(true);
    }

    // refreshVideoData is true if autoplay failed after muted try -> refresh the video, but this time with autoPlay = false.
    onAutoPlayFailed({refreshVideoData});

    // Send crashlytics report.
    try {
      const err = new CustomEvent("error", { detail: detail?.error || "Auto play failed." });
      err.message = (videoDataForPlayer !== null ? "AutoPlayError: " + JSON.stringify({...videoDataForPlayer, locationid: HFN.apiConfig.locationid}) : "AutoPlayError occurred.") + navigator?.appVersion;
      err.filename = currentHLSPath.current;
      window.dispatchEvent(err);
    } catch (e) {}
  };

  const onPlayFailVideoEvent = (error) => {
    removeDataStartingAndEnableKeyboard();
  };

  const onDurationChangeVideoEvent = (duration, nativeEvent) => {
    // Safari required only, because it gives no <video>.duration after onLevelLoaded and vidstack doesn't handle it.
    // We're using preferManagedMediaSource: false due to this issue: https://github.com/vidstack/player/issues/1521. 
    // If this is fixed and we switch to preferManagedMediaSource: true, then remove this fix too.
    if (ref.current.state.duration > 0 && duration !== ref.current.state.duration && ref.current.provider && isHLSProvider(ref.current.provider)) {
      setVideoDuration(duration);
      // console.log("pvm duration:" + duration, ref.current.state.duration);
    }
  };

  const sendAnalyticsData = (action, eventValue) => {
    if (typeof gtag === "function") {
      const params = {
        action: action,
        category: "video"
      };

      if (typeof eventValue !== "undefined") {
        params.eventValue = eventValue;
      }

      gtag("event", "media_preview_click", params);
    }
  };

  const renderVideoPlayer = () => {
    if (!videoDataForPlayer) {
      return null;
    }

    return (
      <div 
        className={`video-wrapper${slideshowActive ? " active-slideshow" : ""}${slideshowPlaying ? " playing-slideshow" : ""}`} 
        style={{
          "background": videoLoading ? "unset" : "#000",
          "--plyr-player-visibility": videoLoading ? "hidden" : "visible",
          "--plyr-video-aspect-ratio" : videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? `${videoDataForPlayer.maxWidth} / ${videoDataForPlayer.maxHeight}` : "16 / 9",
          "--plyr-video-max-width": videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? `${videoDataForPlayer.maxWidth}px` : "inherit",
          "--plyr-video-max-height": videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? `${videoDataForPlayer.maxHeight}px` : "inherit",
          "--plyr-video-object-fit": videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? "contain" : "scale-down",
          "--plyr-video-pointer-events": isMobile && playerControlsHidden ? "unset" : "none"
        }}>
        <MediaPlayer
          playsInline={true}
          ref={ref} 
          title={videoDataForPlayer.name}
          src={videoDataForPlayer.videoUrls}
          poster={(!autoPlay || (slideshowPlaying)) && videoDataForPlayer.thumbUrl !== "" ? videoDataForPlayer.thumbUrl : null}
          posterLoad="eager"
          autoPlay={autoPlay}
          load={autoPlay ? "visible" : (videoDataForPlayer.videoUrls.length > 0 && !videoDataForPlayer.startPlayerLoading ? "play" : "custom")}
          streamType="on-demand"
          storage={storage}
          onEnded={onEndedVideoEvent}
          onStarted={onStartedVideoEvent}
          keyTarget="document"
          muted={!!videoDataForPlayer.autoPlayMuted}
          onPlay={onPlayVideoEvent}
          onPause={onPauseVideoEvent}
          fullscreenOrientation="none"
          onFullscreenChange={onFullscreenChangeVideoEvent}
          onMediaEnterFullscreenRequest={onEnterFullscreenRequest}
          duration={videoDuration}// videoDataForPlayer.duration
          onControlsChange={onControlsVisibilityChange}
          // logLevel="warn"// || "debug" || "info"
          artwork={thumbForMediaSessionAPI}
          onError={onErrorVideoEvent}
          onProviderSetup={onProviderSetupVideoEvent}
          onProviderChange={onProviderChangeVideoEvent}
          onVolumeChange={onVolumeChangeVideoEvent}
          onSeeking={onSeekingVideoEvent}
          onRateChange={onRateChangeVideoEvent}
          onHlsError={onHlsErrorVideoEvent}
          onHlsUnsupported={onHlsUnsupportedVideoEvent}
          onHlsManifestLoaded={onHlsManifestLoadedVideoEvent}
          viewType="video"
          onMediaPlayRequest={onMediaPlayRequestVideoEvent}
          onMediaPauseRequest={onMediaPauseRequestVideoEvent}
          onLoadStart={onLoadStartVideoEvent}
          keyDisabled={disableKeyboardActions}
          onWaiting={onWaitingVideoEvent}
          onAutoPlayFail={onAutoPlayFailVideoEvent}
          onPlayFail={onPlayFailVideoEvent}
          // onSeeked={onSeekedVideoEvent}
          // onMediaSeekingRequest={onMediaSeekingRequestVideoEvent}
          // onQualityChange={onQualityChangeVideoEvent}
          onCanPlay={onCanPlayVideoEvent}
          onDurationChange={onDurationChangeVideoEvent}
        >
          <MediaProvider />
          
          <PlyrLayout
            icons={videoPlayerIcons}
            displayDuration={true}
            controls={
              [
                'progress',
                'play',
                'play-large',
                'current-time',
                'pip',
                'airplay',
                'captions',
                'settings',
                'fullscreen'
              ]
            }
            slots={{
              timeSlider: <ProgressTimeSlider vidstackMediaPlayer={ref} onDraggingVideoProgress={onDraggingVideoProgress} noSwipeGesture={noSwipeGesture} />,
              afterCurrentTime: !isMobile ? <MuteAndVolumeMenu ref={volumeSliderRef} onVolumeSliderDragEnd={(value) => onVolumeChangeVideoEvent({volume: value / 100, muted: ref.current?.state.muted})} /> : null, //<MuteBtn />,
              beforeSettings: <GoogleChromeCastButton />,
              // Prevent auto quality option to be shown.
              settings: <SettingsMenu speed={[0.5, 1, 1.5, 2]} autoQualityText={false}/>,
              playLargeButton: <PlayLargeButton />,
              // afterPlayLargeButton: <SeekingLabel value={seekingLabel} />
            }}
          >
          </PlyrLayout>
          
          {mutedDueToAutoPlayFailed ? 
            <Controls.Root className="custom-controls">
              <Controls.Group className="plyr__controls controls-on-top">
                <MuteBtn unmutedHidden={true} pressedLabel={__("Unmute")} />
              </Controls.Group>
            </Controls.Root>
          : null}
          
        </MediaPlayer>
        {!autoPlay && videoDataForPlayer.thumbUrl !== "" ? <img src={videoDataForPlayer.thumbUrl} className="hidden-poster-for-error-event" onError={onErrorLoadingThumb} onLoad={onSuccessLoadingThumb} /> : null}
      </div>
    );
  };

  return renderVideoPlayer();
});

export default VideoContainer;