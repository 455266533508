// @flow
import React, { useState } from "react";
import { useSelector } from "react-redux";

import * as CommonStyle from "../../components/CommonStyledSettings";
import Button from "../ButtonDefault";
import { __ } from "../../lib/translate";
import EnterPass from "./changecryptopass/EnterPass";
import SetNewPass from "./changecryptopass/SetNewPass";
import FlowManager from "../../containers/TwoFactorAuth/FlowManager";
import { OvalModalClose } from "../Modal";
import ModalCallCenter from "../ContentView/UI/ModalCallCenter";
import hashManager from "../../lib/hashManager";
import { PAGES } from "../../config/constants";

const ChangeCryptoPassSetting = () => {
  const page = hashManager.getState('page');
  const cryptoSetup = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptosetup);
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const canUserManage = account?.permissions.user_manage;
  const [currentFlow, setCurrentFlow] = useState([]);
  const DEFAULT_FLOW = [
    EnterPass,
    SetNewPass
  ]

  const onCloseClick = () => {
    setCurrentFlow([]);
  };

  const onChangeCryptoPassClick = () => {
    setCurrentFlow(DEFAULT_FLOW);
  }

  if (!cryptoSetup) {
    return null;
  }

  return (
    <>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__("heading_change_crypto_pass")}</CommonStyle.Title>
          <CommonStyle.Description>{__('crypto_enter_password_owner_desc')}</CommonStyle.Description>
        </div>

        <Button
          onClick={onChangeCryptoPassClick}
          disabled={!canUserManage && page !== PAGES.SETTINGS}
        >{__("Change")}</Button>
      </CommonStyle.Row>
      <FlowManager
        flow={currentFlow}
        onClose={onCloseClick}
        onSuccess={onCloseClick}
        closeButton={OvalModalClose}
        style={{ marginTop: 0 }}
      />
    </>
  )
};

export default ChangeCryptoPassSetting;
