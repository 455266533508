import styled, { css } from "styled-components";

export const Row = styled.div`
  width: 100%;
  min-height: 68px;
  padding: var(--spacing-md) var(--spacing-md);
  box-sizing: border-box;
  display: flex;
  gap: var(--spacing-md);
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-base-white);
  border-bottom: var(--border-size) solid var(--border-secondary);
  position: relative;
  white-space: break-spaces;

  @media (max-width: 768px) {
    gap: var(--spacing-sm);
  }

  .BaseButton {
    text-align: right;
    white-space: normal;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: var(--font-size-14);
  font-weight: 700;
  line-height: 20px;
  color: var(--text-primary);
`;

export const Description = styled.div`
  font-size: var(--font-size-14);
  font-weight: 400;
  line-height: 20px;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-xs);

  @media (max-width: 768px) {
    font-size: var(--font-size-13);
  }

  ${({ $isClickable }) => $isClickable && css`
    cursor: pointer;

    &:hover {
      color: var(--text-link);
    }
  `}
`;

export const Button = styled.button`
  font-size: var(--font-size-14);
  font-weight: 500;
  text-align: right;
  color: var(--text-link);
  cursor: pointer;
  flex-shrink: 0;
  line-height: 20px;
  border: none;
  background: unset;
  padding: 0;

  i {
    margin-left: var(--spacing-sm);
    font-size: var(--icon-base);
    position: relative;
    top: 1px;
  }

  &:disabled {
    color: var(--color-grey-600);
    cursor: default;
  }
`;

export const RedButton = styled(Button)`
  color: var(--color-functional-danger500);
`;

export const BorderedButton = styled(Button)`
  border-radius: var(--radius-full);
  border: 1px solid var(--text-link);
  padding: var(--spacing-sm) var(--spacing-lg);
`;