import React, { useEffect, useRef, useState } from "react";
import { Column } from "../../UI/Style";
import styled from "styled-components";
import calculateSize from "../../../../lib/calculateTextWidth";
import hashManager from "../../../../lib/hashManager";
import { __ } from "../../../../lib/translate";

const TeamsCell = ({ stretchWidth, item, classNames, contentKey, id }) => {
  const [showItems, setShowItems] = useState([]);
  const [tooltipItems, setTooltipItems] = useState([]);
  const itemRef = useRef(null);

  const moreText = __("More").toLowerCase();

  useEffect(() => {
    if (item[contentKey].teams < 1 || !itemRef.current) {
      return;
    }

    const containerWidth = itemRef.current.offsetWidth;
    const tmpItems = [];

    item[contentKey].forEach((row) => {
      const tmpSize = calculateSize(row.team.name, {
        font: "'Roboto',sans-serif",
        fontSize: "14px",
        fontWeight: "400"
      });
      tmpItems.push({
        key: row.team.id,
        name: row.team.name,
        width: tmpSize.hasOwnProperty("width") ? tmpSize.width : 0
      });
    });

    const tmpShowItems = [];
    const tmpTooltipItems = [];
    const divider = 5;
    let currentWidth = 0;
    const tmpMore = item[contentKey].length + "+ " + moreText;
    const tmpMoreSize = calculateSize(tmpMore, {
      font: "'Roboto',sans-serif",
      fontSize: "14px",
      fontWeight: "400"
    });
    const tmpMoreWidth = tmpMoreSize.hasOwnProperty("width") ? tmpMoreSize.width : 0;

    tmpItems.forEach((item, index) => {
      if (index === 0) {
        currentWidth += item.width + divider;
        tmpShowItems.push({
          name: item.name,
          id: item.key
        });
        return;
      }

      currentWidth += item.width + divider;

      if (currentWidth < containerWidth) {
        tmpShowItems.push({
          name: item.name,
          id: item.key
        });
      } else if (currentWidth < containerWidth * 2 - tmpMoreWidth * 2) {
        tmpShowItems.push({
          name: item.name,
          id: item.key
        });
      } else {
        tmpTooltipItems.push(item.name)
      }
    });

    setShowItems(tmpShowItems);
    setTooltipItems(tmpTooltipItems);
  }, [item[contentKey]]);

  const goToTeam = (id) => {
    hashManager.pushState({ page: "b_team", id: id }, 2);
  };

  const goToUser = () => {
    hashManager.pushState({ page: "b_user", "tab-user": "userinfo", id: item.id });
  };

  return (
    <CustomColumn $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
      <Wrapper ref={itemRef}>
        {showItems.map((row, i) => {
          return (
            <Span
              key={i}
              onClick={
                row.id
                  ? () => {
                      goToTeam(row.id);
                    }
                  : null
              }
              className={row.id ? "clickable" : null}
            >
              {row.name + (showItems.length - 1 > i ? ", " : "")}
            </Span>
          );
        })}
        {showItems.length > 0 && showItems.length < item[contentKey].length && (
          <>
            {", "}
            <Span
              onClick={goToUser}
              className="clickable view-more"
              data-tooltip-content={tooltipItems.join(', ')}
              data-tooltip-id="cv-tooltip"
            >
              {item[contentKey].length - showItems.length + "+ " + moreText}
            </Span>
          </>
        )}
      </Wrapper>
    </CustomColumn>
  );
};

export default TeamsCell;

const CustomColumn = styled(Column)`
  width: 260px;
  min-width: 260px;
`;

const Wrapper = styled.div`
  width: 245px;
  min-width: 245px;
  color: var(--text-primary);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Span = styled.span`
  &.clickable {
    cursor: pointer;
  }
  &.view-more {
    text-decoration: underline;
  }
`;
