import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import * as Style from "./SharedInvitationComponents";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import WarnIcon from "../../root/img/svg/warning_invitation.svg";
import Componentify from "react-componentify";
import { boldConverter } from "../lib/componentifyConverters";
import styled from "styled-components";
import { Btn } from "../components/ButtonDefault";

const ResetCrypto = ({ code }) => {
  const [error, setError] = useState("");
  const [shouldKill, setShouldKill] = useState(false);
  const [resetDone, setResetDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (code) {
      apiMethod(
        "crypto_resetinfo",
        { code },
        (ret) => {
          setEmail(ret.email);
          setLoading(false);
        },
        {
          errorCallback: (ret) => {
            setError(ret.error);
            setLoading(false);
          },
        }
      );
    }
  }, [code]);

  const cryptoReset = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      apiMethod(
        "crypto_delete",
        { code, device: navigator.userAgent },
        () => {
          setResetDone(true);
          setLoading(false);
        },
        {
          errorCallback: (ret) => {
            setError(ret.error);
            setLoading(false);
          },
        }
      );
    },
    [code]
  );

  const kill = useCallback(() => {
    setLoading(true);
    setShouldKill(true);

    apiMethod(
      "crypto_invalidate",
      { code, device: navigator.userAgent },
      () => {
        setLoading(false);
      },
      {
        errorCallback: (ret) => {
          setError(ret.error);
          setLoading(false);
        },
      }
    );
  }, [code]);

  const renderForm = () => (
    <>
      <Style.Message>
        <Style.ComponentifyWrapper>
          <Componentify
            text={__(
              'You are about to reset the crypto of your account "<b>%email%</b>". This action is <b>final</b> and it will <b>delete all</b> your currently <b>encrypted files</b>.',
              'You are about to reset the crypto of your account "<b>%email%</b>". This action is <b>final</b> and it will <b>delete all</b> your currently <b>encrypted files</b>.'
            ).replace("%email%", email)}
            converters={[boldConverter]}
          />
          <br />
          <span>{__("Do you want to proceed?", "Do you want to proceed?")}</span>
        </Style.ComponentifyWrapper>
      </Style.Message>
      <Style.Footer>
          <Btn
              color="cyan"
              borderRadius={8}
              style={{
                  marginRight: "5px"
              }}
              onClick={cryptoReset}
          >
              {__("Reset", "Reset")}
          </Btn>
          <Btn
              color="lightgray4"
              borderRadius={8}
              style={{
                  marginLeft: "5px"
              }}
              onClick={kill}
          >
              {__("Cancel", "Cancel")}
          </Btn>
      </Style.Footer>
    </>
  );

  const renderNoCode = () => (
    <Style.WarningMessage>
      <WarnIcon />
      <Style.ComponentifyWrapper>
        <Componentify text={error || __("invalid_data_arrived")} converters={[boldConverter]} />
      </Style.ComponentifyWrapper>
    </Style.WarningMessage>
  );

  const renderResetDone = () => (
    <Style.Message>{__("Your crypto has been reset.", "Your crypto has been reset.")}</Style.Message>
  );

  const renderKill = () => (
    <Style.Message>
      <Style.ComponentifyWrapper>
        <Componentify
          text={__("Your crypto has <b>not</b> been reset. This link has been invalidated.", "Your crypto has <b>not</b> been reset. This link has been invalidated.")}
          converters={[boldConverter]}
        />
      </Style.ComponentifyWrapper>
    </Style.Message>
  );

  if (loading) {
    return (
      <Style.LoadingWrapper>
        <div />
      </Style.LoadingWrapper>
    );
  }

  let content;
  if (!code || error) content = renderNoCode();
  else if (resetDone) content = renderResetDone();
  else if (shouldKill) content = renderKill();
  else content = renderForm();

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      <Style.Title>{__("Reset Crypto", "Reset Crypto")}</Style.Title>
      {content}
    </Style.Container>
  );
};

ResetCrypto.propTypes = {
  code: PropTypes.string,
};

export default ResetCrypto;
