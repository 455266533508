// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { __ } from "../../../lib/translate";

import Button from "../../../components/ButtonDefault";
import { B_USERS } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  numOfUsers: number,
  min: number,
  max: number,
  disabled: Boolean,
  onChange: () => void
};

const UsersInput = ({
  numOfUsers = B_USERS.MIN,
  min = B_USERS.MIN,
  max = B_USERS.MAX,
  disabled = false,
  onChange = () => {}
}: Props) => {
  const [disabledMin, setDisabledMin] = useState(false);
  const [disabledMax, setDisabledMax] = useState(false);

  useEffect(() => {
    setDisabledMin(disabled || numOfUsers <= min);
    setDisabledMax(disabled || numOfUsers >= max);
  }, [disabled, max, min, numOfUsers]);

  const onChangeInput = e => {
    let value = e.target.value;

    if (value !== "" && value >= 0 && value > max) {
      value = max;
    }

    onChange(value ? parseInt(value) : value);
    console.log("On change value", value);
  };

  const validateUsersNumber = e => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);
    let value = e.target.value.replace(/\s+/g, "");

    value = value.trim();
    if (!/[0-9 ]/.test(pressedCharacter)) {
      e.preventDefault();
    }
  };

  const onBtnUpClick = () => {
    if (disabled) {
      return;
    }
    const oldValue = parseFloat(numOfUsers || 0);
    let newVal = 0;
    if (oldValue >= max) {
      newVal = oldValue;
    } else {
      newVal = oldValue + 1;
    }
    onChange(parseInt(newVal));
  };

  const onBtnDownClick = () => {
    if (disabled) {
      return;
    }
    const oldValue = parseFloat(numOfUsers || 0);
    let newVal = 0;
    if (oldValue <= min) {
      newVal = oldValue;
    } else {
      newVal = oldValue - 1;
    }
    onChange(parseInt(newVal));
  };

  return (
    <Wrapper>
      <Title>{__("Users")}:</Title>
      <ButtonWrapper>
        <Button styled="MinusIconBtn" disabled={disabledMin} onClick={onBtnDownClick}>
          <i className="smallIcon fa-regular fa-minus"></i>
        </Button>
        <InputUsers
          type="number"
          min={min}
          max={max}
          value={numOfUsers}
          onInput={onChangeInput}
          onKeyPress={validateUsersNumber}
          onPaste={validateUsersNumber}
          autoFocus
          disabled={disabled}
        />
        <Button styled="PlusIconBtn" disabled={disabledMax} onClick={onBtnUpClick}>
          <i className="smallIcon fa-regular fa-plus"></i>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default UsersInput;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
`;

const Title = styled.div`
  font-size: var(--font-size-14);
  font-weight: 400;
  color: var(--text-secondary);
  line-height: 20px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
  border-radius: 21px;
  background: var(--surface-primary);
  box-sizing: border-box;
  width: 240px;
  max-width: 100%;
`;

const InputUsers = styled.input`
  font-size: var(--font-size-18);
  width: 50px;
  font-weight: 700;
  border: none;
  box-shadow: none;
  line-height: 32px;
  background-color: transparent;
  outline: none;
  text-align: center;
  /* Firefox */
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
