import React, { useEffect, useState } from "react";
import { BROWSE_B_USER_TEAMS_CONTENT } from "../../../config/constants";
import ContentView from "../../../components/ContentView/ContentView";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setContentItems } from "../../../lib/state/reducers/contentSlice";
import SkeletonTable from "../../../components/ContentView/Templates/SkeletonTable";
import { xhrs as businessXHRS } from "../../../lib/state/businessState";

const Teams = ({ user }) => {
  const [tableLoaded, setTableLoaded] = useState(false);
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all(businessXHRS).then(() => {
      if (user && user.teams) {
        const activeTeams = user.teams.filter((item) => item.team.active);
        const rows = activeTeams.map(function(item) {
          return Object.assign({
            ...item.team,
            head: !!item.link.head,
            teamId: item.team.id,
            teamName: item.team.name,
            userFirstname: user.firstname,
            userLastname: user.lastname,
            userEmail: user.email,
            userId: user.id
          }, {isBusinessUserTeams: true, canTeamManage: account.permissions.team_manage});
        });
        dispatch(setContentItems({ contents: rows } ));
      }

      setTableLoaded(true);
    });
  }, [user]);

  return (
    <Wrapper>
      {tableLoaded ? (
        <ContentView contentType={BROWSE_B_USER_TEAMS_CONTENT} />
      ) : (
        <SkeletonTable contentType={BROWSE_B_USER_TEAMS_CONTENT} />
      )}
    </Wrapper>
  );
};

export default Teams;

const Wrapper = styled.div`
  height: calc(100%);
    & #selection-container {
        box-sizing: border-box;
        padding: var(--spacing-sm) 0 0 var(--spacing-sm);
    }
`;
