// @flow

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import $ from "jquery";

import apiMethod from "../../api/apiMethod";
import { abortAll } from "../../lib/XhrComponentUtils";
import errors from "../../lib/errors";
import { __ } from "../../lib/translate";
import { validateEmail, formatSizeInGb } from "../../lib/utils";
import {
  MIN_USER_QUOTA,
  QUOTA_STEP,
  MAX_MESSAGE_LENGTH
} from "@pcloud/web-utilities/dist/config/constants";

import Button, { ButtonCentered } from "../../components/ButtonDefault";
import Slider from "../../components/Slider";
import StorageManagment from "./StorageManagment";
import { LoaderFamily, CloseButtonFamily } from "./SharedFamilyComponents";
import { ButtonWrapper } from "../../components/UploadManager/sharedStyles";
import { CSSTransition } from "react-transition-group";
import { AnimatePresence, motion } from "framer-motion";
import { InputMessage } from "../../styles/sharedStyles";

const InviteFamilyForm = ({
  disabled,
  isInModal,
  onClose = () => { },
  onSuccessInviteFamilyForm,
  familyItems = []
}) => {

  const { token, quotaRedux, usedQuota, ownerEmail } = useSelector(({ pCloudUser }) => {
    const { token, userinfo: { quota, usedquota, email } = {} } = pCloudUser;
    return { token, quotaRedux: quota, usedQuota: usedquota, ownerEmail: email };
  });

  const [contactsLoaded, setContactsLoaded] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [quota, setQuota] = useState(0);
  const [inviteLink, setInviteLink] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [animationHasEnded, setAnimationHasEnded] = useState(false);

  const inputPreparedRef = useRef(false);
  const qEngineRef = useRef(null);
  const comboRef = useRef(null);
  const xhrListRef = useRef([]);
  const inputEmails = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    getContactList();

    const timer = setTimeout(() => {
      setAnimationHasEnded(true);
    }, 300);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  const sendInvitations = () => {
    if (disabled) {
      return;
    }
    let vals = comboRef.current.getFullVals(true);
    let emails = [];
    let fb = [];
    let fbNames = [];
    let message = textAreaRef.current?.value;
    let manuallyAdded = [];

    console.log("!! full vals", vals);
    console.log("!! full message", message);

    for (let n = 0; n < vals.length; ++n) {
      if (vals[n].data && vals[n].data.source == 2) {
        fb.push({ id: vals[n].data.value, obj: vals[n].obj });
        fbNames.push(vals[n].data.name);
      } else if (vals[n].data && vals[n].data.source == 3) {
        emails.push({ email: vals[n].data.value, obj: vals[n].obj });
      } else if (validateEmail(vals[n].val)) {
        emails.push({ email: vals[n].val, obj: vals[n].obj });
        if (!vals[n].data && manuallyAdded.indexOf(vals[n].val) == -1) {
          qEngineRef.current.add([{ name: vals[n].val, value: vals[n].val, source: 1 }]);
          manuallyAdded.push(vals[n].val);
        }
      } else {
        vals[n].obj
          .addClass("error")
          .find(".combo-res")
          .tooltip("destroy")
          .attr("title", __("This is not a valid contact or an email."));
      }
    }

    if (message.length > MAX_MESSAGE_LENGTH) {
      HFN.message(
        __("error_2245", "Maximum message length is 500 characters."),
        "error"
      );
      return;
    }

    if (!emails.length && !fb.length) {
      HFN.message(__("error_invalid_email"), "error");
      return;
    }

    if (emails[0].email === ownerEmail) {
      HFN.message(
        __(
          "error_cannot_enter_your_own_email",
          "You cannot enter your own email address in this field"
        ),
        "error"
      );
      return;
    }

    if (familyItems.length) {
      const existActiveMember = familyItems.filter((item) => {
        return item.email === emails[0].email && !item.invitationid;
      });
      if (existActiveMember.length) {
        HFN.message(
          __('error_2307', 'Invite request already exists'),
          "error"
        );
        return;
      }
    }

    setIsLoading(true);

    const email = emails[0].email;
    console.log({ quota });
    apiMethod(
      "fm_sendinvitation",
      { auth: token, mail: email, quota, message },
      ({ result, link, invitationid }) => {
        setInviteLink(link);
        setIsLoading(false);
        setInvitedEmail(email);
        emails[0].obj.remove();
        comboRef.current.onRemove();
        HFN.message("family_resend_message");
        onSuccessInviteFamilyForm({ email, invitationid, quota, invitelink: link });
      },
      {
        errorCallback: ({ result, error }) => {
          setIsLoading(false);
          emails[0].obj
            .addClass("error")
            .find(".combo-res")
            .tooltip("destroy")
            .tooltip(/*{ container: '.tomails' }*/);

          if (result === 2314) {
            HFN.message(
              __(
                "family_error_max_members",
                "You can't invite any more members"
              ),
              "error"
            );
            return;
          }

          if (result === 1064) {
            HFN.message(__("error_invalid_email"), "error");
            return;
          }

          if (errors[result]) {
            HFN.message(errors[result], "error");
          } else {
            HFN.message(
              __("something_went_wrong_refresh_and_try_again"),
              "error"
            );
            throw new Error(error);
          }
        }
      }
    )
  }

  const getContactList = () => {
    apiMethod(
      "contactlist",
      { auth: token },
      ret => {
        setContactsLoaded(true);
        setContacts(ret.contacts)
      },
      {
        errorCallback: ret => {
          setContactsLoaded(false);
          setContacts([]);

          if (errors[result]) {
            HFN.message(errors[result], "error");
          } else {
            HFN.message(
              __("something_went_wrong_refresh_and_try_again"),
              "error"
            );
            throw new Error(error);
          }
        }
      }
    )
  }

  const prepareSendInput = (contacts) => {
    if (inputPreparedRef.current || !inputEmails.current) {
      return;
    }

    inputPreparedRef.current = true;

    let filteredContacts = HFN.filterMailContacts(contacts);
    let qEngine;
    let combo;

    setTimeout(() => {
      let tokenizer = function (name) {
        let ret = Bloodhound.tokenizers.whitespace(name.replace(/\(?\)?/g, ""));
        if (ret.indexOf(name.replace(/\(?\)?/g, "")) == -1)
          ret.push(name.replace(/\(?\)?/g, ""));
        return ret;
      };

      qEngine = new Bloodhound({
        name: "name",
        datumTokenizer: function (r) {
          let ret = Bloodhound.tokenizers.whitespace(
            r.name.replace(/\(?\)?/g, "")
          );
          if (ret.indexOf(r.name.replace(/\(?\)?/g, "")) == -1)
            ret.push(r.name.replace(/\(?\)?/g, ""));
          return ret;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: filteredContacts
      });

      qEngineRef.current = qEngine;

      let orig_get = qEngine.get;
      qEngine.get = function (query, cb) {
        return orig_get.apply(qEngine, [
          query,
          function (suggestions) {
            if (!suggestions) return cb(suggestions);
            suggestions.forEach(function (s) {
              let tokens = tokenizer(s.name.toLowerCase()),
                score = 0;
              for (let n = 0; n < tokens.length; ++n) {
                if (tokens[n].indexOf(query) == 0) score++;
              }
              s.exact_match = score;
            });
            console.log(suggestions);
            suggestions.sort(function (a, b) {
              return a.exact_match > b.exact_match
                ? -1
                : a.exact_match < b.exact_match
                  ? 1
                  : 0;
            });
            cb(suggestions);
          }
        ]);
      };

      qEngine.initialize();

      let comboOptions = {
        place: inputEmails.current,
        name: "emails",
        sourceKey: "name",
        disabled: disabled,
        source: qEngine,
        placeholder: __(
          "family_invite_form_email_input",
          "Enter your family member's email address"
        ),
        maxNotFoundSymbols: 5,
        maxItems: 1,
        splitBy: [9, 32, 13, 188],
        getShowVal: function (r) {
          return r;
        },

        fancyResultBox: function (box, data) {
          let ent;

          console.log(box.data());

          if (data && data.data) {
            ent = data.data;
            if (ent.source == 2)
              box.prepend(
                '<img src="/img/fb-tag.png" width="16" height="16" class="inf">'
              );
            else if (ent.source == 3)
              box.prepend(
                '<img src="/img/gm-tag.png" width="16" height="16" class="inf">'
              );
            else if (ent.source == 1)
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
          } else if (box.data("val")) {
            if (validateEmail(box.data("val")))
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
          }
        },

        imgCloseFile: "/img/close-label.png",

        extraMulticompleteOpts: {
          suggestAt: inputEmails.current,
          boxRender: function (data) {
            let box = $("<div>"),
              img = $('<img width="32" height="32">');

            console.log("fkhsajkfhjhfjhasfjd ", box);

            box
              .addClass("clearfix")
              .append($('<div class="iconw"></div>').append(img))
              .append(
                $('<div class="textpart"></div>')
                  .append($('<span class="title"></span>').text(data.name))
                  .append($('<span class="explain"></span>'))
              );
            console.log("Data fsjahf ", data);

            if (data.source == "import") {
              if (data.value == "Facebook") {
                img.attr("src", "/img/fab.png");
              } else if (data.value == "Gmail") {
                img.attr("src", "/img/gmail.png");
              }
              box.find(".explain").text(
                __("Import contacts from %source%", false, {
                  source: data.value
                })
              );
            } else if (data.source == 3) {
              img.attr("src", "/img/gmail.png");
              box.find(".explain").text(data.value);
            } else if (data.source == 2) {
              img.attr(
                "src",
                "https://graph.facebook.com/" +
                data.value +
                "/picture?type=square"
              );
              box
                .find(".iconw")
                .append('<img src="/img/fb-over.png" class="smicon">');
              box.find(".explain").text(__("Facebook Message"));
            } else if (data.source == 1) {
              img.attr("src", "/img/or-mail.png");
              box.find(".explain").text(__("Send Email"));
            }

            return box;
          }
        }
      };

      combo = new combo2(comboOptions);
      comboRef.current = combo;
    }, 0);
  }

  const freeOwnerQuota = quotaRedux - usedQuota;
  let maxValue = 0;
  if (usedQuota < MIN_USER_QUOTA) {
    maxValue = freeOwnerQuota - MIN_USER_QUOTA;
  } else {
    maxValue = freeOwnerQuota;
  }

  if (contactsLoaded) {
    prepareSendInput(contacts);
  } else {
    return <LoaderFrom />;
  }

  if (maxValue < MIN_USER_QUOTA) {
    inputPreparedRef.current = false;
    return null;
  }

  return (
    <Wrapper $isInModal={isInModal}>
      {isInModal && <Title disabled={disabled}>{__("family_invite_member", "Invite family member")}</Title>}
      <Form>
        <div className="tomails" ref={inputEmails} />
        <InputMessage
          ref={textAreaRef}
          disabled={disabled}
          type="text"
          placeholder={__(
            "family_invite_form_msg_input",
            "Enter a message (optional)"
          )}
        />
      </Form>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <StorageManagment
            isModal
            startValue={MIN_USER_QUOTA}
            minQuota={MIN_USER_QUOTA}
            maxValue={maxValue}
            getQuota={(q) => setQuota(q)}
            shouldDisableSlider={disabled}
            minStartValue={MIN_USER_QUOTA}
          />
        </motion.div>
      </AnimatePresence>


      {isInModal ?
        <ButtonWrapper>
          <StyledButton onClick={onClose} styled="ModalCancelButton" >
            {__('Close', 'Close')}
          </StyledButton>
          <StyledButton disabled={disabled} loading={isLoading} onClick={sendInvitations} styled="ModalPrimaryButton">
            {__("family_invite_form_button", "Send Invite")}
          </StyledButton>
        </ButtonWrapper> :
        <StyledButtonCentered disabled={disabled} loading={isLoading} onClick={sendInvitations} styled="ModalPrimaryButton">
          {__("family_invite_form_button", "Send Invite")}
        </StyledButtonCentered>
      }
    </Wrapper>
  );
}

export default InviteFamilyForm;

export const Wrapper = styled.div`
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  font-family: Roboto;
  margin: 0 auto;

  ${({ $isInModal }) => !$isInModal && css`
    max-width: 500px;
  `}

  a {
    margin-top: 0 !important;
  }

  .tomails {
    width: 100%;
    border: 1px solid var(--border-primary, #E4E5E7);
    border-radius: 8px;
    background: var(--surface-secondary);
    &:focus-within {
      border: 1px solid var(--color-primary-500, #17BED0);
    }

    .combo-contain {
      width: 100% !important;
      min-height: 40px;
      background: var(--surface-secondary);
      font-size: 14px;
      padding: 15px;
      border-radius: 8px;
      border: none;
      outline: none;
      box-sizing: border-box;
      padding: 6px;
      box-shadow: none;

      .combo-wrap {
        width: 100%;
        display: block;
      }

      input {
        height: 28px;
        padding: 0 10px;
        font-size: 14px;
        box-sizing: border-box;
        width: 100% !important;


        &::-webkit-input-placeholder {
          color: var(--text-secondary);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        &::-moz-placeholder {
          color: var(--text-secondary);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: var(--text-secondary);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        &::placeholder {
          color: var(--text-secondary);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .combo-res {
        height: 26px;
        line-height: 18px;
        background: #f2f2f2;
        border-radius: 12px;
        padding: 3px 5px;
        margin: 0 5px 2px 0;
        max-width: 100%;
        box-sizing: border-box;

        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;  
          line-height: 16px;
        }
        
        .inf {
          margin: -1px 5px 0 0;
        }
      }
    }
    .combo-contain.focu,
    .combo-contain.focu input {
      background: #fff !important;
    }
  }
`;

export const Title = styled.div`
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  color: var(--color-base-black, #000);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 177.778% */
  margin-bottom: var(--spacing-lg);
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  margin: 20px 0;
`;

const LoaderFrom = styled(LoaderFamily)`
  margin: 20px 0 0 -25px;
`;

const Form = styled.div`
  & textarea {
    margin: var(--spacing-md) 0 var(--spacing-xl) 0;
  }
`;

const StyledButtonCentered = styled(ButtonCentered)`
  border-radius: 20px;
`;

const StyledButton = styled(Button)`
  font-size: 14px !important;
  width: 100%;
  max-width: unset !important;
`;
