import React, { useEffect, useRef, useState } from 'react'
import * as CommonStyle from "../../../components/CommonStyledSettings"
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import apiMethod from '../../../api/apiMethod';
import { apiMethodUrl, prepUrl } from '../../../api/utils';
import { Menu, MenuItem } from '@szhsin/react-menu'
import DeleteLogoModal from '../../../components/Modals/Business/DeleteLogoModal';
import { updateUserBranding } from '@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice';

const Customization = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
  const fileInputRef = useRef();
  const brandingLogo = userInfo?.account?.branding?.logo && prepUrl(userInfo.account.branding.logo);
  const [isDeleteLogoOpen, setIsDeleteLogoOpen] = useState(false)

  const onAddLogo = () => {
    fileInputRef.current.click();
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const xhr = new XMLHttpRequest();

    xhr.open("POST", apiMethodUrl(null, 'account_branding_logo_upload', {}), true);
    xhr.responseType = 'json';

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        const ret = xhr.response;
        if (ret.result) {
          HFN.message(ret.error, 'error');
        } else {
          HFN.message(__('logo_uploaded', 'Logo Uploaded.'));
          apiMethod('account_branding', {}, (ret) => {
            dispatch(updateUserBranding({ bgcolor: ret.bgcolor, logo: ret.logo }));
          }, {
            forceFresh: true
          });
        }
      }
    };

    xhr.onerror = function () {
      HFN.message('An error occurred while uploading the logo.', 'error');
    };

    xhr.send(formData);
  };

  return (
    <>
      <DeleteLogoModal isOpen={isDeleteLogoOpen} handleClose={() => setIsDeleteLogoOpen(false)} />
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('business_customization_title', 'Company logo')}</CommonStyle.Title>
          <CommonStyle.Description>{__('business_customization_description', 'Personalize your Business account with your company logo. All changes will apply to your team. Format: png. Max size: 20kb. Width: 120-180px. Height: 30-50px')}</CommonStyle.Description>
        </div>
        {brandingLogo && <img src={brandingLogo} alt='branding logo' />}
        <input type="file" style={{ display: "none" }} onChange={handleFileChange} ref={fileInputRef} />
        {!brandingLogo ? <CommonStyle.Button onClick={onAddLogo}>{__('business_customization_action_add_logo', 'Add logo')}</CommonStyle.Button> :
          <Menu
            align="end"
            transition
            menuButton={
              <CommonStyle.Button>
                {__("Edit")}
              </CommonStyle.Button>
            }
          >
            <StyledMenuItem
              onClick={onAddLogo}
            >
              <i class="fa-light fa-file-upload smallIcon lightColorIcon" />
              {__("Upload")}
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => setIsDeleteLogoOpen(true)}
            >
              <i class="fa-light fa-trash-alt smallIcon lightColorIcon" />
              {__("Delete")}
            </StyledMenuItem>
          </Menu>
        }
      </CommonStyle.Row>
      <Spacing />
    </>
  )
}

export default Customization

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: 8px;
`

const Spacing = styled.div`
  padding-bottom: 50px;
`