import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import { TARGET_DOWNLOAD, TARGET_LIST_NAME_KEY, TARGET_TRAFFIC, TARGET_VIEWS } from "../constants";
import { __ } from "../../../lib/translate";
import { getLastMonthPeriod, getLastWeekPeriod, toBeautyDate, toParseDt } from "../../../lib";
import {
  AlertWrapper,
  ButtonWrapper,
  ChartContainer,
  ChartWrapper,
  DateButton,
  DatesWrapper,
  Header,
  HeaderActions,
  OverallWrapper,
  TrafficButton
} from "../styles";
import hashManager from "../../../lib/hashManager";
import Loader from "../../../components/Loader";
import apiMethod from "../../../api/apiMethod";
import { calculateTrafficData, generateMarkers, getQuotaTickValues, getTickValues } from "../utils";
import useWindowDimensions from "../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../config/constants";
import HoverMenu from "../../../components/ContentView/UI/HoverMenu";
import { RowColumnDataItem, RowColumnDataWrapper } from "../../../styles/sharedStyles";
import Button from "../../../components/ButtonDefault";
import { useSelector } from "react-redux";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import Alert, { AlertContent } from "../../../components/Alert";
import { Tooltip } from "react-tooltip";
import CustomTickX from "./CustomTickX";
import CustomTickY from "./CustomTickY";

const StatsBarChart = ({
  showOverallData = true,
  showTrafficButton = true,
  showAlerts = true,
  forceMobile = false,
  disableBarClick = false,
  linkid = null,
  directlink = null,
  auth = null,
  traffic,
  setTraffic,
  trafficPeriod,
  setTrafficPeriod
}) => {
  const { bodyWidth } = useWindowDimensions();
  const isMobileView = bodyWidth < MOBILE_WIDTH_BREAKPOINT || forceMobile;
  const [formattedTickQuota, setFormattedTickQuota] = useState(null);
  const [trafficSummary, setTrafficSummary] = useState(null);
  const [lastMonthTrafficSummary, setLastMonthTrafficSummary] = useState(null);
  const [chartTarget, setChartTarget] = useState(TARGET_TRAFFIC);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const publicLinkQuota = useSelector(({ pCloudUser }) => pCloudUser.userinfo.publiclinkquota);

  const formatedData = useMemo(() => {
    return traffic.map((item) => {
      return { ...item, value: item[chartTarget] };
    });
  }, [chartTarget, traffic]);
  const isDateIncrementDisabled = trafficPeriod && toParseDt(trafficPeriod.to) >= toParseDt(new Date());
  const tickValues = useMemo(() => {
    if (!formatedData.length) {
      return [];
    }
    if (chartTarget === TARGET_TRAFFIC) {
      const tickQuotaObj = getQuotaTickValues(formatedData);
      setFormattedTickQuota(tickQuotaObj)
      return Object.keys(tickQuotaObj);
    }
    return getTickValues(formatedData);
  }, [formatedData]);
  const maxValue = useMemo(() => Math.max(...formatedData.map((item) => item.value)), [formatedData]);

  useEffect(() => {
    const dates = isMobileView ? getLastWeekPeriod() : getLastMonthPeriod();
    setTrafficPeriod({
      from: dates.start,
      to: dates.end
    });
  }, [isMobileView]);

  useEffect(() => {
    if (!trafficPeriod) {
      return;
    }
    fetchChartData(trafficPeriod.from, trafficPeriod.to, (result) => {
      setTraffic(result);
      const summary = calculateTrafficData(result);
      setTrafficSummary(summary);
      if (showAlerts && !isMobileView && !lastMonthTrafficSummary) {
        setLastMonthTrafficSummary(summary);
      }
    });

    if (showAlerts && isMobileView && !lastMonthTrafficSummary) {
      const lastMonth = getLastMonthPeriod();
      fetchChartData(lastMonth.start, lastMonth.end, (result) => {
        if (!lastMonthTrafficSummary) {
          const summary = calculateTrafficData(result);
          setLastMonthTrafficSummary(summary);
        }
      });
    }
  }, [trafficPeriod]);

  const fetchChartData = (from, to, callback = () => {}) => {
    const params = {
      bdate: toParseDt(from),
      edate: toParseDt(to),
      directlink: 1,
      groupby: "date"
    };

    if (auth) {
      params.auth = auth;
    }

    if (linkid) {
      params.linkids = linkid;
    }

    if (directlink) {
      params.directlink = directlink;
    }

    const tmpData = {};
    for (let i = trafficPeriod.from.getTime(); i <= trafficPeriod.to.getTime(); i += 3600 * 24 * 1000) {
      tmpData[HFN.toParseDt(i)] = {
        dt: HFN.toParseDt(i),
        views: 0,
        traffic: 0,
        downloads: 0
      };
    }

    apiMethod("getpublinkstats", params, function(ret) {
      for (let n = 0; n < ret.stats.length; ++n) {
        tmpData[ret.stats[n].dt] = ret.stats[n];
      }
      const tmpArray = ob2ar(tmpData);
      callback(tmpArray);
    });
  };

  const renderCustomTooltip = ({ data }, withWrapper = true) => {
    const renderWrapper = () => (
      <>
        {__("Downloads") + ": " + (data.downloads || 0)}
        <br />
        {__("views", "Views") + ": " + (data.views || 0)}
        <br />
        {__("Traffic") + ": " + renderQuota(data.traffic || 0)}
      </>
    );

    if (withWrapper) {
      return <div className="cv-tooltip visible-on-mobile">{renderWrapper()}</div>;
    }

    return renderWrapper();
  };

  const handleClick = (params) => {
    if (disableBarClick) {
      return;
    }
    hashManager.pushState({ dt: params.data.dt });
  };

  const changeTrafficPeriod = (action = "") => {
    let newFrom, newTo;
    switch (action) {
      case "decrement":
        newFrom = new Date(trafficPeriod.from);
        newTo = new Date(trafficPeriod.to);
        if (isMobileView) {
          newFrom.setDate(newFrom.getDate() - 7);
          newTo.setDate(newTo.getDate() - 7);
        } else {
          newFrom.setMonth(newFrom.getMonth() - 1);
          newTo.setMonth(newTo.getMonth() - 1);
        }
        break;
      case "increment":
        if (isDateIncrementDisabled) {
          return null;
        }
        newFrom = new Date(trafficPeriod.from);
        newTo = new Date(trafficPeriod.to);
        if (isMobileView) {
          newFrom.setDate(newFrom.getDate() + 7);
          newTo.setDate(newTo.getDate() + 7);
        } else {
          newFrom.setMonth(newFrom.getMonth() + 1);
          newTo.setMonth(newTo.getMonth() + 1);
        }
        break;
    }
    if (newFrom && newTo) {
      hashManager.removeState("dt");
      setTrafficPeriod({
        from: newFrom,
        to: newTo
      });
    }
  };

  if (!traffic.length || !trafficPeriod) {
    return <Loader show={true} />;
  }

  const renderHeader = () => {
    const iconSetup = {
      open: <i className="smallIcon fa-regular fa-chevron-up" style={{ color: "var(--text-primary)" }} />,
      close: <i className="smallIcon  fa-regular fa-chevron-down" style={{ color: "var(--text-primary)" }} />
    };

    return (
      <Header $forceMobile={forceMobile} className={isMobileView ? "mobile" : ""}>
        <DatesWrapper>
          {toBeautyDate(trafficPeriod.from) + " - " + toBeautyDate(trafficPeriod.to)}
          {/*
          <DateButton onClick={() => changeTrafficPeriod("decrement")}>
            <i className="fa-solid fa-chevron-left" />
          </DateButton>
          <DateButton $disabled={isDateIncrementDisabled} onClick={() => changeTrafficPeriod("increment")}>
            <i className="fa-solid fa-chevron-right" />
          </DateButton>
          */}
        </DatesWrapper>
        <HeaderActions>
          <HoverMenu
            triggerOnClick={true}
            align={"end"}
            iconSetup={iconSetup}
            menuComponent={<TrafficButton>{__(TARGET_LIST_NAME_KEY[chartTarget])}</TrafficButton>}
            menuItems={[
              {
                id: TARGET_TRAFFIC,
                label: [TARGET_LIST_NAME_KEY[TARGET_TRAFFIC]],
                onClick: () => setChartTarget(TARGET_TRAFFIC)
              },
              {
                id: TARGET_DOWNLOAD,
                label: [TARGET_LIST_NAME_KEY[TARGET_DOWNLOAD]],
                onClick: () => setChartTarget(TARGET_DOWNLOAD)
              },
              {
                id: TARGET_VIEWS,
                label: [TARGET_LIST_NAME_KEY[TARGET_VIEWS]],
                onClick: () => setChartTarget(TARGET_VIEWS)
              }
            ]}
          />
          {showTrafficButton && renderGetTrafficBtn()}
        </HeaderActions>
      </Header>
    );
  };

  const renderGetTrafficBtn = () => {
    if (!lastMonthTrafficSummary) {
      return null;
    }
    if (
      isBusiness ||
      lastMonthTrafficSummary.sumInPeriodByte / publicLinkQuota >= 0.8 ||
      (!HFN.config.label.canBuyTraffic && !HFN.config.canBuyTraffic())
    ) {
      return;
    }
    return (
      <ButtonWrapper>
        <Button
          onClick={() => {
            window.open(URLSITE + "additional-traffic.html");
          }}
          styled="SuccessBorderedButton"
        >
          {__("get_traffic_button", "Get traffic")}
        </Button>
      </ButtonWrapper>
    );
  };

  const renderAlerts = () => {
    if (!lastMonthTrafficSummary) {
      return null;
    }

    let alert;

    if (lastMonthTrafficSummary.sumInPeriodByte >= publicLinkQuota) {
      alert = __("Your links are limited due to reaching your monthly traffic quota.");
    } else if (lastMonthTrafficSummary.sumInPeriodByte / publicLinkQuota >= 0.8) {
      alert = __("low_traffic_get_more_traffic", "You are low on download link traffic. Upgrade to get more traffic.");
    }

    if (!alert) {
      return;
    }

    return (
      <AlertWrapper className={isMobileView ? "mobile" : ""}>
        <Alert
          theme={"warning"}
          multiline={true}
          centerText={true}
          children={
            <AlertContent>
              {alert}
              <Button
                onClick={() => {
                  window.open(URLSITE + "additional-traffic.html");
                }}
                minWidth={190}
                styled="DarkBorderedButton"
              >
                {__("get_traffic_button", "Get traffic")}
              </Button>
            </AlertContent>
          }
        />
      </AlertWrapper>
    );
  };

  return (
    <>
      {showAlerts && renderAlerts()}
      <ChartWrapper>
        {renderHeader()}
        <ChartContainer className={isMobileView ? "mobile" : ""}>
          <ResponsiveBar
            data={formatedData}
            keys={["value"]}
            layers={["grid", "axes", "markers", "bars", "legends", "annotations"]}
            indexBy="dt"
            margin={{ top: 16, right: 24, bottom: 24, left: 80 }}
            padding={0.2}
            colors="#20BEC6"
            maxValue={tickValues ? tickValues[tickValues.length - 1] : undefined}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              renderTick: (tick) => (
                <CustomTickX
                  {...tick}
                  formatedData={formatedData}
                  renderCustomTooltip={renderCustomTooltip}
                  disableBarClick={disableBarClick}
                  onClick={(value) => handleClick({ data: { dt: value } })}
                />
              )
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: tickValues,
              renderTick: (tick) => (
                <CustomTickY
                  {...tick}
                  chartTarget={chartTarget}
                  formattedTickQuota={formattedTickQuota}
                />
              )
            }}
            enableGridY={false}
            markers={generateMarkers(tickValues)}
            animate={true}
            label={false}
            tooltip={renderCustomTooltip}
            onClick={handleClick}
            onMouseEnter={(_datum, event) => {
              if (!disableBarClick) {
                event.currentTarget.style.cursor = "pointer";
              }
            }}
          />
        </ChartContainer>
        {showOverallData && (
          <OverallWrapper>
            <RowColumnDataWrapper>
              <RowColumnDataItem>
                <span>{trafficSummary.sum}</span>
                {__("Traffic")}
              </RowColumnDataItem>
              <RowColumnDataItem>
                <span>{trafficSummary.avg}</span>
                {__("Daily average")}
              </RowColumnDataItem>
              <RowColumnDataItem>
                <span>{trafficSummary.periodMax}</span>
                {__("Daily maximum")}
              </RowColumnDataItem>
            </RowColumnDataWrapper>
          </OverallWrapper>
        )}
        <Tooltip id="chart-axis-tooltip" className="cv-tooltip visible-on-mobile" noArrow={true} />
      </ChartWrapper>
    </>
  );
};

export default StatsBarChart;
