//  @flow

import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { getBillingInfoCall } from "../../../api/business";
import { __ } from "../../../lib/translate";
import { currencyToSign, loadScript } from "../../../lib/utils";
import { getBusinessPlanName } from "../../../lib/plansInfo";
import { SubscriptionUpdate } from "../../../lib/SubscriptionUpdate";
import { cardIconsComponents } from "../../PaymentForm/SharedStyledComponents";
import { PROVIDERS, PROVIDERS_IDS, SUBSC_PERIOD, SUBSC_STATUS } from "@pcloud/web-utilities/dist/config/constants";

import * as CommonStyle from "../../../components/CommonStyledSettings";
import { Loading } from "../../../components/ManageSubscription/styledComponents";
import PaypalIcon from "@pcloud/web-utilities/dist/resources/images/svg/paypal-logo.svg";
import Button, { Btn } from "../../../components/ButtonDefault";
import FlowManager from "../../TwoFactorAuth/FlowManager";
import PaymentFormStep from "./PaymentFormStep";
import StartSubscriptionStep from "./StartSubscriptionStep";
import SuccessMessageStep from "./SuccessMessageStep";
import ManageUsersQuotaStep from "./ManageUsersQuotaStep";
import ChangeCardStep from "./ChangeCardStep";
import CancelSubscriptionStep from "./CancelSubscriptionStep";
import ResponsiveEmail from "../../ResponsiveEmail";
import { setProcessingProfile, updateBillingInfo } from "../../../lib/state/reducers/businessSlice";
import hashManager from "../../../lib/hashManager";
import Loader from "../../../components/Loader";

const modalFlows = {
  payNow: [StartSubscriptionStep, PaymentFormStep, SuccessMessageStep],
  upgrade: [ManageUsersQuotaStep, PaymentFormStep, SuccessMessageStep],
  updateUsers: [ManageUsersQuotaStep, PaymentFormStep, SuccessMessageStep],
  changeCard: [ChangeCardStep, SuccessMessageStep],
  cancelSubs: [CancelSubscriptionStep, SuccessMessageStep]
};

const SubscriptionDetails = () => {
  const dispatch = useDispatch();
  const billingInfo = useSelector(({ business }) => business.billingInfo);
  const canManage = useSelector(
    ({ pCloudUser }) => pCloudUser.userinfo.account && pCloudUser.userinfo.account.permissions.account_manage
  );
  const paymentSource = useSelector(({ pCloudUser }) => pCloudUser.paymentSource);
  const {
    billing = {},
    payer = {},
    subscriptions = [],
    is_trial,
    memberinfo = {},
    pending_members = {},
    processing = false
  } = billingInfo;
  const { 
    provider: billingProviderId,
    period: billingPeriod,
    currency: billingCurrency,
    until: expDate,
    businessplanid: planId
  } = billing;
  const {
    brand: payerBrand,
    card: payerCard,
    expmonth: payerExpmonth,
    expyear: payerExpyear,
    email: payerEmail
  } = payer;
  const { maximum: maximumMembers } = memberinfo;
  const {
    applying: applyingPendingMembers,
    count: countPendingMembers,
    price: pricePendingMembers
  } = pending_members;
  const notInTrial = billingProviderId != PROVIDERS_IDS.TRIAL && billingProviderId != PROVIDERS_IDS.PROMOCODE;
  const notInSalesTrial = billingProviderId != PROVIDERS_IDS.SALES && billingProviderId != PROVIDERS_IDS.RESELLER;
  const providerName = PROVIDERS[billingProviderId] || "";

  const activeSubs = useMemo(() => subscriptions.length ? subscriptions.filter(subsc => subsc.status === 1) : [], [subscriptions]);
  const currentSubs = useMemo(() => (subscriptions.length && subscriptions[0]) || {}, [subscriptions]);

  const [currentFlow, setCurrentFlow] = useState([]);
  const [currentFlowName, setCurrentFlowName] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [price, setPrice] = useState("");
  const [period, setPeriod] = useState(billingPeriod);
  const [members, setMembers] = useState(maximumMembers);
  const [endTrial, setEndTrial] = useState(false);
  const [prorationtime, setProrationtimeCb] = useState(0);
  const [braintreeLoading, setBraintreeLoading] = useState(false);
  const [loadedBraintreeScripts, setLoadedBraintreeScripts] = useState(false);
  const [shouldRefreshPaypalButton, setShouldRefreshPayPalButton] = useState(false);

  useEffect(() => {
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/client.min.js", () => {});
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/paypal-checkout.min.js", () => {});
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/data-collector.min.js", () => {});
    setLoadedBraintreeScripts(true);
  }, []);

  useEffect(() => {
    if (currentFlowName !== "") {
      setShouldRefreshPayPalButton(false);
    }
  }, [currentFlowName]);

  useEffect(() => {
    if (payerEmail && loadedBraintreeScripts) {
      createBraintreeButton();
    } else {
      setBraintreeLoading(false);
    }
  }, [billingProviderId, payerEmail, loadedBraintreeScripts]);

  useEffect(() => {
    if (shouldRefreshPaypalButton) {
      createBraintreeButton();
    }
  }, [shouldRefreshPaypalButton]);

  useEffect(() => {
    if (Object.keys(currentSubs).length) {
      const isPayPalSubs = currentSubs.billingprovider === PROVIDERS_IDS.PAYPAL;
      const isBraintreeSubs = currentSubs.billingprovider === PROVIDERS_IDS.BRAINTREE;
      const isActiveSubs = currentSubs.status !== SUBSC_STATUS.CANCELED;
      const diffBillingProvider = currentSubs.billingprovider !== billingProviderId;

      if ((isPayPalSubs || isBraintreeSubs) && diffBillingProvider && isActiveSubs) {
        dispatch(setProcessingProfile());
      }
    }
  }, [billingProviderId, currentSubs, dispatch]);

  useEffect(() => {
    const isPayPal = PROVIDERS_IDS.PAYPAL === billingProviderId;
    if (is_trial && !isPayPal) {
      if (hashManager.getState("pay")) {
        onActiveNowClick();
      }
      setTimeout(() => {
        $(".active-btn")
          .off("click")
          .on("click", onActiveNowClick);
        if (HFN.config.isMobile()) {
          $(".trialinfo-bar-holder").on("click", onActiveNowClick);
        }
      }, 0);
    } else {
      setTimeout(() => {
        HFN.removeTrialBusinessInfoBar();
      }, 0);
    }
  }, [billingProviderId, is_trial]);

  if (!billingInfo.memberinfo) {
    return <Loader />;
  }

  const createBraintreeButton = () => {
    if (billingProviderId !== PROVIDERS_IDS.BRAINTREE) {
      return;
    }

    const params = {
      provider: "braintree",
      period: period,
      storage: 0,
      traffic: 0,
      isBusiness: true,
      members: members,
      endTrial: endTrial,
    };

    if (planId) {
      params.businessplanid = planId;
    }

    const apiCall = new SubscriptionUpdate(params);
    setBraintreeLoading(true);

    apiCall.getTokenBraintree({
      refresh: false,
      paypalButtonId: "#paypal-button-table",
      label: "pay",
      callback: () => {},
      onReady: () => {
        setBraintreeLoading(false);
      },
      buttonHeight: 26
    });
  };

  const onActiveNowClick = () => {
    setCurrentFlow(modalFlows["payNow"]);
    setCurrentFlowName("payNow");
  };

  const onPayNowButtonClick = () => {
    setCurrentFlow(modalFlows["payNow"]);
    setCurrentFlowName("payNow");
  };

  const onUpdateUsersClick = () => {
    setCurrentFlow(modalFlows["updateUsers"]);
    setCurrentFlowName("updateUsers");
  };

  const onUpgradeClick = () => {
    setCurrentFlow(modalFlows["upgrade"]);
    setCurrentFlowName("upgrade");
  };

  const onCancelSubsClick = () => {
    setCurrentFlow(modalFlows["cancelSubs"]);
    setCurrentFlowName("cancelSubs");
  };

  const onCloseClick = () => {
    // UPDATE UI WITH NEW CARD
    // TO DO: add event for change card
    if (currentFlowName === "changeCard") {
      setTimeout(() => {
        getBillingInfoCall(ret => {
          delete ret.result;
          dispatch(updateBillingInfo(ret));
        });
      }, 1000);
    }

    setCurrentFlow([]);
    setShouldRefreshPayPalButton(true);
    setCurrentFlowName("");
    setPeriod(billingPeriod);
    setMembers(maximumMembers);
    setEndTrial(false);
    setProrationtimeCb(0);
    setPrice("");
    setCurrency("USD");
  };

  const onChangeCardClick = () => {
    setCurrentFlow(modalFlows["changeCard"]);
    setCurrentFlowName("changeCard");
  };

  const getData = () => {
    const dataOut = [];

    // First ROW Status
    const isMonthly = billingPeriod == SUBSC_PERIOD.MONTHLY;
    const providers = [PROVIDERS_IDS.STRIPE, PROVIDERS_IDS.SAFECHARGE, PROVIDERS_IDS.BRAINTREE, PROVIDERS_IDS.PAYPAL];
    const hasProvider = providers.indexOf(currentSubs.billingprovider) !== -1;
    const isPayPal = PROVIDERS_IDS.PAYPAL === billingProviderId;
    const isBankTransfer = PROVIDERS_IDS.SALES === billingProviderId && !is_trial;
    const isSubsTrial = currentSubs.status === SUBSC_STATUS.PROCESSING;
    const period = __(`${isMonthly ? "Monthly" : "Yearly"} Subscription`);
    const isExpired = new Date(expDate).getTime() < new Date().getTime();
    let header = __("business_billing_status", "Status");
    let status = is_trial ? __("business_billing_status_trial", "Free trial") : period;
    let statusButton = "";

    console.log("BUISNESS_STATUS:", { payer, activeSubs, currentSubs, billingProviderId, processing });

    if (is_trial) {
      status = !activeSubs.length && hasProvider && !isSubsTrial ? __("Inactive") : status;
      statusButton = isPayPal || processing ? "" : renderPayNowButton();
    } else if (!activeSubs.length) {
      status = !isBankTransfer || isExpired ?  __("Inactive") : __("business_status_bank_transfer", "Bank transfer");
      statusButton = !isBankTransfer || isExpired ? renderPayNowButton() : "";
    } else if (isMonthly) {
      statusButton = (
        <BtnWithLabelWrapper flexdirection="column">
          <BtnLabel>{__("save_x_percents", "Save %num_percents%%").replace("%num_percents%", "20")}</BtnLabel>
          <Button className="desktop-only" onClick={onUpgradeClick} styled="BorderedButton">{__("update_to_annual", "Update to Annual")}</Button>
          <Button className="mobile-only" onClick={onUpgradeClick}>{__("update_to_annual", "Update to Annual")}</Button>
        </BtnWithLabelWrapper>
      );
    }
    if (processing) {
      status = __("Processing profile");
      statusButton = "";
    }

    dataOut.push({
      title: header,
      description: status,
      action: statusButton
    });

    // Secong ROW plan name
    dataOut.push({
      title: __("plan_name", "Plan"),
      description: getBusinessPlanName(planId),
      action: null
    });

    // Third ROW Users
    dataOut.push({
      title: __("Users"),
      description: maximumMembers,
      action: canManage && !is_trial && activeSubs.length ? (
        <Button onClick={onUpdateUsersClick}>
          {__("b_billing_manage_user_quota_header", "Manage user quota")}
        </Button>
      ) : null
    });

    // Fourth ROW Billing Provider
    if (notInTrial && notInSalesTrial) {
      let payerInfo = "";
      let paymentProvider = "";
      let titleDescWrapperClassName = "";
      if (Object.keys(payer).length) {
        const isStripe = billingProviderId == PROVIDERS_IDS.STRIPE;
        const isSafecharge = billingProviderId == PROVIDERS_IDS.SAFECHARGE;
        const isBraintree = billingProviderId == PROVIDERS_IDS.BRAINTREE;

        if (isStripe || isSafecharge) {
          const brand = payerBrand ? payerBrand.replace(/\s+/g, "") : "";
          const CardIcon = cardIconsComponents[brand.toLowerCase()]
            ? cardIconsComponents[brand.toLowerCase()]
            : cardIconsComponents["default"];
          const hasExpired =
            new Date().getTime() > new Date(payerExpyear, payerExpmonth, 0).getTime();
          const expirationDate = `(${__("Expire: ")} ${payerExpmonth}/${payerExpyear})`;
          const expiredText = __("Expired");

          payerInfo = (
            <React.Fragment>
              <PayerInfo hasExpired={hasExpired}>
                <CardIcon className="billing-icon" />
                <CardInfo>
                  <span>{`**** **** **** ${payerCard}`}</span>
                  <ExpireCard hasExpired={hasExpired}>
                    {hasExpired ? `(${expiredText})` : expirationDate}
                  </ExpireCard>
                </CardInfo>
              </PayerInfo>
            </React.Fragment>
          );
          paymentProvider = (
            <Button onClick={onChangeCardClick}>
              {__("b_billing_change_method", "Change Method")}
            </Button>
          );
        } else if (isBraintree) {
          payerInfo = renderEmail(payerEmail);
          titleDescWrapperClassName = "row-with-email";
          paymentProvider = renderChangeEmailBraintree();
        } else {
          payerInfo = renderEmail(payerEmail);
          titleDescWrapperClassName = "row-with-email";
          paymentProvider = "";
        }

        dataOut.push({
          title: __("Billing Provider"),
          description: payerInfo,
          action: paymentProvider,
          titleDescWrapperClassName: titleDescWrapperClassName
        });
      }
    }

    // FifthRow ROW Pending users
    if (Object.keys(pending_members).length) {
      dataOut.push({
        title: __("Pending users"),
        description: applyingPendingMembers ? __("Applying") : `${countPendingMembers} ${__("members for")} ${currencyToSign(billingCurrency)} ${pricePendingMembers}`,
        action: null
      });

      // ????
      // if (!applyingPendingMembers && canManage && !is_trial) {
      //   fifthRow[2] = <Button onClick={redeemUserQuota}>{__("Redeem Pending Users")}</Button>;
      // }
    }

    // SixthRow - add start date of subscription
    dataOut.push({
      title: __("business_billing_start_date"),
      description: HFN.formatDt(currentSubs.startperiod) || "N/A",
      action: null
    });

    // SeventhRow next renewal date of subscription
    dataOut.push({
      title: activeSubs.length || currentSubs.status === SUBSC_STATUS.PROCESSING ? __("Next renewal date") : __("business_billing_paid_until"),
      description: activeSubs.length || currentSubs.status === SUBSC_STATUS.PROCESSING ? (HFN.formatDt(currentSubs.endperiod) || "N/A") : (HFN.formatDt(expDate) || "N/A"),
      action: !processing && currentSubs.cancancel ? (
        <Button onClick={onCancelSubsClick}>
          {__("cancel_subs_modal_bnt_cancel", "Cancel subscription")}
        </Button>
      ) : null
    });

    return dataOut;
  };

  const renderRow = (data, index) => {
    return (
      <CommonStyle.Row key={index + "rowKey"}>
        <TitleDescWrapper className={data?.titleDescWrapperClassName}>
          {data.title ?
            <CommonStyle.Title>{data.title}</CommonStyle.Title>
          : null}
          {data.description ?
            <CommonStyle.Description className="row-desc">{data.description}</CommonStyle.Description>
          : null}
        </TitleDescWrapper>
        {data.action}
      </CommonStyle.Row>
    );
  };

  const renderPayNowButton = () => {
    return (
      <Button styled="BorderedButton" onClick={onPayNowButtonClick}>
        {__("pay_now_button", "Pay now")}
      </Button>
    );
  };

  const renderEmail = (email) => {
    return (
      <>
        <PaypalIcon />
        <ResponsiveEmailWrapper>
          <ResponsiveEmail email={email}/>
        </ResponsiveEmailWrapper>
      </>
    );
  };

  const renderChangeEmailBraintree = () => {
    return (
      <BtnWithLabelWrapper>
        <BtnLabel>{__("Change", "Change")}:</BtnLabel>
        <BraintreeButtonContainer>
          {braintreeLoading ? (
            <BlueButtonLoading>
              <Loading />
            </BlueButtonLoading>
          ) : null}
          <div id="paypal-button-table" />
        </BraintreeButtonContainer>
      </BtnWithLabelWrapper>
    );
  };

  return (
    <React.Fragment>
      {getData().map(renderRow)}
      <FlowManager
        flow={currentFlow}
        currentFlowName={currentFlowName}
        onClose={onCloseClick}
        provider={providerName.toLowerCase()}
        price={price}
        setPrice={setPrice}
        currency={currency}
        setCurrency={setCurrency}
        period={period}
        providerId={billingProviderId}
        members={members}
        setMembers={setMembers}
        setPeriod={setPeriod}
        endTrial={endTrial}
        setEndTrial={setEndTrial}
        prorationtime={prorationtime}
        setProrationtimeCb={setProrationtimeCb}
        expDate={HFN.formatDt(expDate) || "N/A"}
      />
    </React.Fragment>
  );
};

export default SubscriptionDetails;

const TitleDescWrapper = styled.div`
  &.row-with-email {
    min-width: 0;

    .row-desc {
      flex-direction: row;

      svg {
        flex-shrink: 0;
      }
    }
  }
`;

const PayerInfo = styled.span`
  display: flex;
  align-items: center;
  opacity: ${props => (props.hasExpired ? 0.5 : 1)};
  line-height: 28px;
  gap: var(--spacing-sm);

  .billing-icon {
    margin-right: 0;
    aspect-ratio: 28 / 22;
    width: unset;
    height: 18px;
    background-position: center center;
    background-size: cover;
    animation: none;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const ExpireCard = styled.span`
  color: ${props => (props.hasExpired ? "#f73c3c" : "#000")};
`;

const BtnWithLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  flex-direction: ${({flexdirection}) => flexdirection ? flexdirection : "row"};

  button.mobile-only {
    display: none;
  }

  @media (max-width: 768px) {
    align-items: flex-end;
    flex-direction: column;
    gap: 2px;

    button.mobile-only {
      display: block;
    }

    button.desktop-only {
      display: none;
    }
  }
`;

const BtnLabel = styled.div`
  font-size: var(--font-size-14);
  font-weight: 500;
  line-height: 20px;
  color: var(--text-primary);
`;

const BlueButtonLoading = styled(Btn)`
  background-color: #0070ba;
  height: 26px;
  cursor: default;
  pointer-events: none;
  margin-top: 0px;
  position: absolute;
  right: 0px;
  z-index: 1;
  float: none;
`;

const ResponsiveEmailWrapper = styled.div`
  overflow: hidden;
`;

export const BraintreeButtonContainer = styled.div`
  width: 90px;
  height: 26px;
  position: relative;

  #paypal-button-table {
    line-height: 1;
    z-index: 0;
    position: relative;

    .paypal-buttons {
      min-width: 0px !important;
    }
    .paypal-button-row {
      width: 25px !important;
    }
  }
`;