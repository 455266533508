import React, { useEffect, useState } from "react";
import {
  MODAL_BUSINESS_CHANGE_TEAM_HEAD,
  MODAL_BUSINESS_EDIT_TEAM,
  MODAL_BUSINESS_MEMBER_MANAGE_QUOTA,
  MODAL_BUSINESS_STATUS_TEAM,
  MODAL_BUSINESS_TEAM_INVITE_USER,
  MODAL_BUSINESS_TEAM_USER_UNLINK,
  MODAL_BUSINESS_USER_INVITE_TEAMS,
  MODAL_BUSINESS_ACTIVATE_USER,
  MODAL_BUSINESS_DEACTIVATE_USER,
  MODAL_BUSINESS_COPY_INVITE_LINK,
  MODAL_BUSINESS_CANCEL_INVITATION,
  MODAL_BUSINESS_CREATE_NEW_TEAM,
  MODAL_BUSINESS_INVITE_USER_TO_ACCOUNT
} from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import SetTeamHeadModal from "../../../components/Modals/Business/SetTeamHeadModal";
import { setModalAction } from "../../../lib/state/reducers/contentSlice";
import ChangeTeamStatusModal from "../../Modals/Business/ChangeTeamStatusModal";
import EditTeamNameModal from "../../Modals/Business/EditTeamNameModal";
import RemoveUserFromTeamModal from "../../Modals/Business/RemoveUserFromTeamModal";
import InviteUsersToTeamModal from "../../Modals/Business/InviteUsersToTeamModal";
import InviteUsersToTeamsModal from "../../Modals/Business/InviteUsersToTeamsModal";
import ManageMemberQuotaModal from "../../Modals/Business/ManageMemberQuotaModal";
import ActivateUserModal from "../../Modals/Business/ActivateUserModal";
import DeactivateMemberModal from "../../Modals/Business/DeactivateMemberModal";
import CopyInviteLinkModal from "../../Modals/Business/CopyInviteLinkModal";
import CancelInvitationModal from "../../Modals/Business/CancelInvitationModal";
import CreateNewTeamModal from "../../Modals/Business/CreateNewTeamModal";
import InviteUsersToAccountModal from "../../Modals/Business/InviteUsersToAccountModal";

const modalActionsList = {
  [MODAL_BUSINESS_CHANGE_TEAM_HEAD]: SetTeamHeadModal,
  [MODAL_BUSINESS_EDIT_TEAM]: EditTeamNameModal,
  [MODAL_BUSINESS_STATUS_TEAM]: ChangeTeamStatusModal,
  [MODAL_BUSINESS_TEAM_USER_UNLINK]: RemoveUserFromTeamModal,
  [MODAL_BUSINESS_TEAM_INVITE_USER]: InviteUsersToTeamModal,
  [MODAL_BUSINESS_USER_INVITE_TEAMS]: InviteUsersToTeamsModal,
  [MODAL_BUSINESS_MEMBER_MANAGE_QUOTA]: ManageMemberQuotaModal,
  [MODAL_BUSINESS_ACTIVATE_USER]: ActivateUserModal,
  [MODAL_BUSINESS_DEACTIVATE_USER]: DeactivateMemberModal,
  [MODAL_BUSINESS_COPY_INVITE_LINK]: CopyInviteLinkModal,
  [MODAL_BUSINESS_CANCEL_INVITATION]: CancelInvitationModal,
  [MODAL_BUSINESS_CREATE_NEW_TEAM]: CreateNewTeamModal,
  [MODAL_BUSINESS_INVITE_USER_TO_ACCOUNT]: InviteUsersToAccountModal,
};

const ModalCallCenter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalState, setModalState] = useState(false);
  const modalActionStore = useSelector(({ content }) => content.modalAction);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !modalActionStore ||
      !modalActionStore.hasOwnProperty("modal") ||
      !modalActionsList.hasOwnProperty(modalActionStore.modal)
    ) {
      return;
    }
    setModalState({
      modal: modalActionStore.modal,
      props: modalActionStore.props || {}
    });
    setIsOpen(true);
  }, [modalActionStore]);

  const resetModalAction = () => {
    dispatch(setModalAction({}));
    setModalState(false);
  };

  const Modal = !!modalState ? modalActionsList[modalState.modal] : null;
  return Modal ? (
    <Modal
      {...modalState.props}
      isOpen={isOpen}
      resetModalAction={resetModalAction}
      handleClose={() => {
        setIsOpen(false);
      }}
    />
  ) : null;
};

export default ModalCallCenter;
