export const INVITE_USER_TEXTS = {
  freeUser: {
    title: ['enter_challenge', 'Enter the Challenge!'],
    subTitle: ["invite_friends_win_500gb_lifetime", "Invite as many friends you can, get 1 GB each and enter to win 500GB space for a lifetime."]
  }
};

export const TERMS = {
  free_quota_increase_up_to_10GB:
    "Free quota can be increased up to a maximum of 10 GB.",
  count_all_invited:
    "Users invited before free quota has reached it's maximum are counted for the challenge.",
  user_can_win_only_once: "One user can win only once.",
  reset_stats_every_month:
    "Stats are reset every month and are starting from 0.",
  only_users_with_verified_emails_count:
    "Only invited users with verified emails count as a successful invite.",
  random_picked_winner: "The winner is picked on a random basis.",
  every_invite_increases_chance_by_1:
    "Every successful invite increases your chance to win by 1.",
  preventing_abuse_same_ip_address:
    "For preventing abuse we will not be counting more that 10 people who are both invited and registered from the same IP address.",
};