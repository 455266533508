// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { errorKeys } from "../../../lib/errors";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import {
  BUSINESS_MIN_FREE_OWNER_QUOTA,
  BUSINESS_QUOTA_STEP,
  BUSINESS_MIN_USER_QUOTA
} from "@pcloud/web-utilities/dist/config/constants";

import ManageMemberQuotaSlider from "../../../containers/Business/MemberQuotaManagement/ManageMemberQuotaSlider";
import { editUser, loadAccountInfo } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";
import { getAccountInfoCall } from "../../../api/business";

type Props = {
  id: number,
  firstname: boolean,
  lastname: number,
  email: string,
  quota: number,
  usedquota: number
};

const ManageMemberQuotaModal = ({
  id = 0,
  firstname = "",
  lastname = "",
  email = "",
  quota = 0,
  usedquota = 0,
  resetModalAction = () => {}
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldChangeQuota, setShouldChangeQuota] = useState(true);
  const [canProceed, setCanProceed] = useState(false);
  const [updatedQuota, setUpdatedQuota] = useState(0);
  const accountInfo = useSelector(({ business }) => business.accountInfo);
  const { account: { owner = {} } = {} } = accountInfo;
  const userQUota = useSelector(({ pCloudUser }) => pCloudUser.userinfo.quota);
  const businessMemners = useSelector(({ business }) => business.users);
  const currenMember = businessMemners.find(member => member.id === parseInt(id)) || {};
  const { quota: memberQuota = 0, usedquota: memberUsedQuota = 0 } = currenMember;
  const { quota: accountQuota, usedquota: accountUsedQuota } = owner;
  const freeAccountQuota = accountQuota - accountUsedQuota;
  const dispatch = useDispatch();

  useEffect(() => {
    canChangeMemberQuota();
  }, [canChangeMemberQuota]);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        changeStorage();
      }
    },
    [changeStorage]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const canChangeMemberQuota = () => {
    const ownerMinQuota = (accountQuota % BUSINESS_QUOTA_STEP) + BUSINESS_MIN_USER_QUOTA;
    const hasAvailableOwnerQuota = hasAvailableFreeQuota(
      freeAccountQuota,
      accountUsedQuota,
      ownerMinQuota
    );
    const memberFreeQuota = memberQuota - memberUsedQuota;
    const hasAvailableMemberQuota = hasAvailableFreeQuota(
      memberFreeQuota,
      memberUsedQuota,
      BUSINESS_MIN_USER_QUOTA
    );

    if (!hasAvailableOwnerQuota && !hasAvailableMemberQuota) {
      setShouldChangeQuota(false);
    }
  };

  const hasAvailableFreeQuota = (freeQuota, usedQuota, minQuota) => {
    if (usedQuota < minQuota) {
      return freeQuota - minQuota > BUSINESS_QUOTA_STEP;
    } else {
      return freeQuota > BUSINESS_QUOTA_STEP;
    }
  };

  const getUpdatedQuota = updatedQuota => {
    setCanProceed(updatedQuota != memberQuota);
    setUpdatedQuota(updatedQuota);
  };

  const getMaxValue = () => {
    const freeAccountQuota = accountQuota - accountUsedQuota;
    if (freeAccountQuota > BUSINESS_MIN_FREE_OWNER_QUOTA) {
      return memberQuota + freeAccountQuota - BUSINESS_MIN_FREE_OWNER_QUOTA;
    } else {
      return memberQuota;
    }
  };

  const changeStorage = useCallback(() => {
    if (isLoading || !canProceed) {
      return;
    }

    setIsLoading(true);

    apiMethod(
      "account_userchangequota",
      { userid: id, quota: updatedQuota },
      () => {
        getAccountInfoCall((accountInfoResponse) => {
          delete accountInfoResponse.result;
          accountInfoResponse.can_invite_account = accountInfo.can_invite_account;
          dispatch(loadAccountInfo(accountInfoResponse));
          dispatch(editUser({...currenMember, quota: updatedQuota}));
          const owner = accountInfoResponse?.account?.owner;
          if (owner) {
            dispatch(editUser({
              id: owner.id,
              quota: owner.quota,
              usedquota: owner.usedquota,
            }));
          }
        });
        HFN.message(__("family_manage_quota_updated"));
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);

          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );
  });

  const maxValue = Math.ceil(getMaxValue() / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;
  const minStartValue = memberUsedQuota
    ? Math.ceil(memberUsedQuota / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP
    : BUSINESS_QUOTA_STEP;
  const startValue = Math.ceil(memberQuota / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;

  console.log("owenerQuota !!!!!!!", renderQuota(accountQuota));
  console.log("owner free quota!!!!!!", renderQuota(freeAccountQuota));
  console.log("userQuota!!!!!!!", renderQuota(userQUota));
  console.log("memberQuota!!!!!!!", renderQuota(memberQuota));
  console.log("member used quota", renderQuota(memberUsedQuota));
  console.log("member free quota", renderQuota(memberQuota - memberUsedQuota));
  console.log("ownerUsedQuota", renderQuota(accountUsedQuota));
  console.log("startValue", renderQuota(startValue));
  console.log("maxValue", renderQuota(maxValue));
  console.log("maxValue bytes", maxValue);
  console.log("minStartValue", renderQuota(minStartValue));


  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      opened={isOpen}
      title={__("manage_business_storage_header", "Manage user's storage")}
      buttonsComponent={
        <Button
          loading={isLoading}
          styled="ModalPrimaryButton"
          disabled={!canProceed}
          onClick={changeStorage}
        >
          {__("Save")}
        </Button>
      }
    >
      <Style.User>
        <Style.UserLabel>{__("User")}</Style.UserLabel>
        <Style.UserData>
          <Style.Name>{`${firstname} ${lastname}`}</Style.Name>
          <Style.Email>({email})</Style.Email>
        </Style.UserData>
      </Style.User>
      <Wrapper>
        <Quota>
          <ManageMemberQuotaSlider
            updatedQuota={updatedQuota}
            startValue={startValue}
            minStartValue={minStartValue}
            maxValue={maxValue > startValue ? maxValue : startValue}
            getStorageValue={getUpdatedQuota}
            usedquota={usedquota}
            quota={quota}
            disabled={!shouldChangeQuota}
          />
        </Quota>
        {!shouldChangeQuota && <NotEnoughSpace>{__("family_accountfull_tooltip_error")}</NotEnoughSpace>}
      </Wrapper>
    </ModalTemplate>
  );
};

export default ManageMemberQuotaModal;

const Wrapper = styled.div`
    margin-top: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
`;

const Quota = styled.div`
    width: 100%;
`;

const NotEnoughSpace = styled.div`
    width: 100%;
    display: flex;
    padding: 16px;
    box-sizing: border-box;
    gap: 10px;
    align-self: stretch;
    border: 1px solid var(--color-functional-warning200);
    background: var(--color-functional-warning100);

    color: var(--text-secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
`;