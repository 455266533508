import React, { useState, useEffect, useCallback } from "react";

import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { loadTeams, loadUsers } from "../../../lib/state/reducers/businessSlice";
import { useDispatch } from "react-redux";
import ModalTemplate from "../ModalTemplate";

const SetTeamHeadModal = (props) => {
  const {
    userId: userId,
    userEmail: userEmail,
    userFirstname: fName,
    userLastname: lName,
    teamName: teamName,
    teamId: teamId,
    head: head,
    resetModalAction = () => {}
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        changeStatus();
      }
    },
    [changeStatus]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const changeStatus = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    let headStatus = 1;
    let successMessage = __("modal_set_team_head_message", "User set as head.");

    if (head) {
      headStatus = 0;
      successMessage = __("modal_set_team_head_message_removed", "User removed as head");
    }

    apiMethod(
      "account_teamlink_head",
      { userid: userId, teamid: teamId, head: headStatus },
      () => {
        HFN.message(__(successMessage));
        fetchData();
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  })

  const fetchData = () => {
    apiMethod(
      "account_teams",
      {
        showeveryone: 1,
        showlinks: 1,
        showusers: 1,
        showeveryone: 1,
        withavatars: 1,
        teamsettings: 1
      },
      (ret) => {
        dispatch(loadTeams(ret.teams))
      },
      { forceFresh: true }
    );
    apiMethod(
      "account_users",
      {
        userpermissions: 1,
        withavatars: 1,
        tracksettings: 1,
        avatarsize: "128x128",
        usersettings: 1,
        showteams: 1,
        showeveryone: 1
      },
      (ret) => {
        dispatch(loadUsers(ret.users));
      },
      { forceFresh: true }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      onAnimationCloseComplete={resetModalAction}
      opened={isOpen}
      title={__("modal_set_team_head_header", "Change head status")}
      buttonsComponent={
        <Button
          styled={head ? "ModalDangerButton" : "ModalPrimaryButton"}
          onClick={changeStatus}
        >
          {head ? __("family_remove", "Remove") : __("set_button", "Set")}
        </Button>
      }
    >
      <Style.Label>{head ? __("Remove team head") : __("Set as Head")}</Style.Label>
      <Style.Box className="responsive fix-layout">
        <Style.WrapperElements>
          <Style.ElementBold className="nowrap">{__("Name")}</Style.ElementBold>
          <Style.ElementBold className="nowrap">{__("Email")}</Style.ElementBold>
          <Style.ElementBold className="nowrap">{__("Team")}</Style.ElementBold>
        </Style.WrapperElements>
        <Style.WrapperElements>
          <Style.ElementUnderLine>
            <b>{`${fName} ${lName}`}</b>
          </Style.ElementUnderLine>
          <Style.ElementUnderLine>{userEmail}</Style.ElementUnderLine>
          <Style.ElementUnderLine>{teamName}</Style.ElementUnderLine>
        </Style.WrapperElements>
      </Style.Box>
    </ModalTemplate>
  );
};

export default SetTeamHeadModal;