//  @flow

import React, { useMemo } from "react";
import useTabs from "../../../components/Tabs/hooks/useTabs";
import * as SETTINGS from "../businessBillingConstants";
import { StyledTabs, StyledTab, TabsAndContentWrapper, TabsAndContentWrapperInner, TabContent} from "../../../components/Tabs/tabs.style";
import { __ } from "../../../lib/translate";
import SubscriptionDetails from "./SubscriptionDetails";
import InvoiceDetails from "../../../components/Settings/InvoiceDetails";
import PaymentHistoryInfo from "../../../components/Settings/PaymentHistoryInfo";
import styled from "styled-components";

const BillingPageContainer = () => {
  const tabs = useMemo(() => SETTINGS.DEFAULT_TABS, []);

  const {
    activeTab,
    onTabClick
  } = useTabs({
    tabs,
    defaultActiveTabId: SETTINGS.SUBSCRIPTION_DETAILS_SETTINGS,
    activeTabHashParamName: "tab"
  });

  const renderTabContent = () => {
    if (activeTab === SETTINGS.SUBSCRIPTION_DETAILS_SETTINGS) {
      return <SubscriptionDetails />;
    }

    if (activeTab === SETTINGS.INVOICE_DETAILS_SETTINGS) {
      return (
        <React.Fragment>
          <InvoiceDetails />
          <PaymentHistoryInfo />
        </React.Fragment>
      );
    }
  };

  return (
    <BillingContainer>
      <TabsAndContentWrapper>
        <TabsAndContentWrapperInner className="inner-animation animation-type-fade">
          <StyledTabs
            value={activeTab}
            onChange={onTabClick}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {tabs.map(({ tabId, tabTitle }) => (
              <StyledTab label={__(tabTitle)} />
            ))}
          </StyledTabs>
          <TabContent>
            <div>{renderTabContent()}</div>
          </TabContent>
        </TabsAndContentWrapperInner>
      </TabsAndContentWrapper>
    </BillingContainer>
  );
};

export default BillingPageContainer;

const BillingContainer = styled.div`
  padding-bottom: var(--spacing-2xl);
`;
