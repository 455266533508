// @flow

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import { myUrlLocation } from "@pcloud/web-utilities/dist/config";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { formatDt } from "../../lib/utils";
import * as Style from "./preferences.style";
import * as CommonStyle from "../../components/CommonStyledSettings";

import { Menu, MenuItem,  } from '@szhsin/react-menu';
import { updateBillingInfo } from "../../lib/state/reducers/businessSlice";

const showErrorAlert = (...args) => {
  HFN.message(...args);
};

const getSourceName = (provider: string, instrument: string): string => {
  const providerInstrumentToSource = {
    Braintree: { paypalaccount: "PayPal", creditcard: "Credit Card" },
    SafeCharge: { creditcard: "Credit Card", apm: "Local Payment" }
  };
  const providerToSource = {
    Stripe: "Credit Card"
  };

  if (providerInstrumentToSource[provider] && providerInstrumentToSource[provider][instrument]) {
    return providerInstrumentToSource[provider][instrument];
  } else if (providerToSource[provider]) {
    return providerToSource[provider];
  }

  return provider;
};

const PaymentHistoryInfo = () => {
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const userSettings = useSelector(({ pCloudUser }) => pCloudUser.userSettings || {});
  const { payments: billingHistory = [], billingInfo = [] } = userSettings;
  const [loading, setLoading] = useState(false);
  const [hasCompleteInfo, setHasCompleteInfo] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const billingKeysCompany = ["billingAddress", "country", "company"];
    const billingKeysPerson = ["billingAddress", "country", "names"];
    const hasCompleteInfo =
      billingKeysCompany.every(key => !!billingInfo[key]) ||
      billingKeysPerson.every(key => !!billingInfo[key]);

    setHasCompleteInfo(hasCompleteInfo);
  }, [billingInfo]);

  const issueInvoice = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod("issueinvoice", {
        paymentid: paymentId
      }, ({ invoicecode: invoiceCode }) => {
        const findPaymentById = paymentId => currentPayment => currentPayment.paymentId === paymentId;
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];

        invpayment.invoiceCode = invoiceCode;
        dispatch(updateBillingInfo([...billingHistory]));
        setLoading(false);
      }, {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const issueReceipt = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod("issuereceipt", {
        paymentid: paymentId
      }, ({ receiptcode: receiptCode }) => {
        const findPaymentById = paymentId => currentPayment => currentPayment.paymentId === paymentId;
        // imp. syntax
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];

        invpayment.receiptCode = receiptCode;
        dispatch(updateBillingInfo([...billingHistory]));
        setLoading(false);
      }, {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const renderGetInvoiceButton = (payment, showFullText, mobileMenuItem = false) => {
    if (!hasCompleteInfo) {
      return (
        <>
          {!mobileMenuItem ?
            <Style.Link disabled={1} inline data-tooltip-id={`no_invoice_info_for_payment_${payment.paymentId}`}>
              {__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}
            </Style.Link>
          :
            <MenuItem disabled={true} data-tooltip-id={`no_invoice_info_for_payment_${payment.paymentId}`}>
              <MenuIcon>
                <i className="smallIcon lightColorIcon fa-light fa-file-invoice" />
              </MenuIcon>
              <MenuLabel>{__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}</MenuLabel>
            </MenuItem>
          }
          <Tooltip
            id={`no_invoice_info_for_payment_${payment.paymentId}`}
            className={`cv-tooltip${mobileMenuItem ? ` visible-on-mobile` : ``}`}
            noArrow={true}
          >
            {__(
              "no_invoice_info_for_payment",
              "Please fill all missing fields to be able to generate an invoice."
            )}
          </Tooltip>
        </>

      );
    } else if (payment.invoiceCode) {
      return (
        !mobileMenuItem ?
          <Style.Link
            href={myUrlLocation("invoice/show?code=" + payment.invoiceCode)}
            target="_blank"
            size="medium"
            inline
          >
            {__(showFullText ? "ph_show_invoice_full" : "ph_show_invoice")}
          </Style.Link>
        :
          <MenuItem
            href={myUrlLocation("invoice/show?code=" + payment.invoiceCode)}
            target="_blank"
          >
            <MenuIcon>
              <i className="smallIcon lightColorIcon fa-light fa-file-invoice" />
            </MenuIcon>
            <MenuLabel>{__(showFullText ? "ph_show_invoice_full" : "ph_show_invoice")}</MenuLabel>
          </MenuItem>
      );
    } else if (payment.canCreateInvoice) {
      return (
        !mobileMenuItem ?
          <Style.Link
            size="medium"
            inline
            onClick={() => issueInvoice(payment)}
          >
            {__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}
          </Style.Link>
        :
          <MenuItem onClick={() => issueInvoice(payment)}>
            <MenuIcon>
              <i className="smallIcon lightColorIcon fa-light fa-file-invoice" />
            </MenuIcon>
            <MenuLabel>{__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}</MenuLabel>
          </MenuItem>
      );
    } else {
      return (
        <>
        {!mobileMenuItem ?
          <Style.Link disabled={1} inline data-tooltip-id={`no_invoice_for_payment_${payment.paymentId}`} size="medium" >
            {__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}
          </Style.Link>
          :
          <MenuItem disabled={true} data-tooltip-id={`no_invoice_for_payment_${payment.paymentId}`}>
            <MenuIcon>
              <i className="smallIcon lightColorIcon fa-light fa-file-invoice" />
            </MenuIcon>
            <MenuLabel>{__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}</MenuLabel>
          </MenuItem>
        }
          <Tooltip
            id={`no_invoice_for_payment_${payment.paymentId}`}
            className={`cv-tooltip${mobileMenuItem ? ` visible-on-mobile` : ``}`}
            noArrow={true}
          >
            {__(
              "no_invoice_for_payment",
              "Unfortunately, you can no longer issue an invoice for this payment."
            )}
          </Tooltip>
        </>
      );
    }
  };

  const hasBillingHistory = billingHistory.length;
  const billingHistorySorted = [...billingHistory];
  //sort billing history by date
  hasBillingHistory &&
  billingHistorySorted.sort(function(a, b) {
      return new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime();
    });

  return (
    <React.Fragment>
      {hasBillingHistory ? (
        <div>
          {!isBusiness ?
            <CommonStyle.Row>
              <CommonStyle.Title>{__("ph_billing_history", "Payment History:")}</CommonStyle.Title>
            </CommonStyle.Row>
          : null}

          <Style.TableWrapper isBusiness={isBusiness}>
            <thead>
              <Style.HeaderRow>
                <Style.HeaderCol className="desktop-only" style={{ width: "23.33%" }}>{__("ph_date", "Date")}</Style.HeaderCol>
                <Style.HeaderCol className="desktop-only" style={{ width: "23.33%" }}>{__("ph_type", "Type")}</Style.HeaderCol>
                <Style.HeaderCol className="desktop-only" style={{ width: "24.33%" }}>{__("ph_price", "Price")}</Style.HeaderCol>
                <Style.HeaderCol className="desktop-only" style={{ width: "21.33%" }}>{__("ph_getshow", "Invoice")}</Style.HeaderCol>
                <Style.HeaderCol className="desktop-only"> {__("ph_receipt", "Receipt")}</Style.HeaderCol>
                <Style.HeaderCol className="mobile-only"> {__("ph_date", "Date")}</Style.HeaderCol>
              </Style.HeaderRow>
            </thead>
            <tbody>
              {billingHistorySorted.map((payment, index) => {
                return (
                  <Style.BodyRow key={payment.paymentId}>
                    <Style.BodyCol className="desktop-only">{formatDt(payment.paymentDate)}</Style.BodyCol>
                    <Style.BodyCol className="desktop-only">{getSourceName(payment.billingProvider, payment.instrument)}</Style.BodyCol>
                    <Style.BodyCol className="desktop-only">{payment.amount} {payment.currency}</Style.BodyCol>
                    <Style.BodyCol className="desktop-only">{renderGetInvoiceButton(payment)}</Style.BodyCol>
                    <Style.BodyCol className="desktop-only">
                      {payment.receiptCode ? (
                        <Style.Link
                          href={myUrlLocation("receipt/show?code=" + payment.receiptCode)}
                          target="_blank"
                          inline
                        >
                          {__("ph_show_receipt", "Show")}
                        </Style.Link>
                      ) : (
                        <Style.Link
                          inline
                          onClick={() => issueReceipt(payment)}
                        >
                          {__("ph_get_receipt", "Get")}
                        </Style.Link>
                      )}
                    </Style.BodyCol>
                    <Style.BodyCol className="mobile-only">
                      <RowWrapperMobile>
                        <DatePriceWrapperMobile>
                          <DateMobile>{formatDt(payment.paymentDate)}</DateMobile>
                          <PriceMobile>{payment.amount} {payment.currency}</PriceMobile>
                        </DatePriceWrapperMobile>

                        <Menu
                          menuButton={
                            <MoreOptionsIconButtonMobile>
                              <i className="baseIcon fa-regular fa-ellipsis-vertical" />
                            </MoreOptionsIconButtonMobile>
                          }
                          overflow="auto"
                          position="initial"
                          viewScroll="close"
                          align="end"
                        >
                          {payment !== -1 ? (
                            <React.Fragment>
                            {renderGetInvoiceButton(payment, true, true)}

                            {payment.receiptCode ? (
                              <MenuItem
                                href={myUrlLocation("receipt/show?code=" + payment.receiptCode)}
                                target="_blank"
                              >
                                <MenuIcon>
                                  <i className="smallIcon lightColorIcon fa-light fa-receipt" />
                                </MenuIcon>
                                <MenuLabel>{__("ph_show_receipt_full", "Show Receipt")}</MenuLabel>
                              </MenuItem>
                            ) : (
                              <MenuItem onClick={() => issueReceipt(payment)}>
                                <MenuIcon>
                                  <i className="smallIcon lightColorIcon fa-light fa-receipt" />
                                </MenuIcon>
                                <MenuLabel>{__("ph_get_receipt_full", "Get Receipt")}</MenuLabel>
                              </MenuItem>
                            )}
                            </React.Fragment>
                          ) : null}
                        </Menu>
                      </RowWrapperMobile>
                    </Style.BodyCol>
                  </Style.BodyRow>
                );
              })}
            </tbody>
          </Style.TableWrapper>
        </div>
      ) : (
        null
      )}
    </React.Fragment>
  );
};

export default PaymentHistoryInfo;

const RowWrapperMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .szh-menu {
     .szh-menu__item--disabled {
      color: var(--color-grey-500);
      i {
        color: var(--color-grey-500);
      }
     }

     a {
      text-decoration: none;
    }
  }
`;

const DatePriceWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateMobile = styled.div`
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-primary);
`;
const PriceMobile = styled.div`
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--text-secondary);
`;

const MoreOptionsIconButtonMobile = styled.div`
cursor: pointer;
border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
width: 20px;
height: 20px;
padding: 8px;
color: var(--text-primary);

&:hover {
  background: var(--color-grey-400);
}
`;

const MenuLabel = styled.div`
  margin-left: var(--spacing-sm);
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  text-align: center;
  color: var(--color-icon-menu);
`;