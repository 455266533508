import React, { useEffect, useMemo } from "react";
import useTabs from "../../../components/Tabs/hooks/useTabs";
import * as SETTINGS from "../businessBillingConstants";
import {
  StyledTabs,
  StyledTab,
  TabsAndContentWrapper,
  TabsAndContentWrapperInner,
  TabContent,
  OuterScrollWrapper,
  ActionWrapper
} from "../../../components/Tabs/tabs.style";
import Info from "./Info";
import UserPermissions from "../BusinessAccountSettings/UserPermissions";
import ChangeCryptoPassSetting from "../../../components/Settings/ChangeCryptoPassSetting";
import { useDispatch, useSelector } from "react-redux";
import hashManager from "../../../lib/hashManager";
import { __ } from "../../../lib/translate";
import { loadBreadcrumb, setModalAction } from "../../../lib/state/reducers/contentSlice";
import Button from "../../../components/ButtonDefault";
import { MOBILE_WIDTH_BREAKPOINT, MODAL_BUSINESS_TEAM_INVITE_USER } from "../../../config/constants";
import useWindowDimensions from "../../../lib/hooks/useWindowDimensions";
import { BUSINESS_USER_PERMISSIONS } from "../businessBillingConstants";

const BusinessTeamContainer = () => {
  const { account, cryptosetup, cryptov2isactive } = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
  const { bodyWidth } = useWindowDimensions();
  const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
  const tabs = useMemo(() => {
    const SettingsTabsCopy = SETTINGS.BUSINESS_TEAM_TABS;
    if (cryptosetup && account?.owner && cryptov2isactive && !(SettingsTabsCopy.find((tab) => tab.tabId === SETTINGS.BUSINESS_TEAM_CRYPTO))) {
      SettingsTabsCopy.push(SETTINGS.TEAM_CRYPTO);
    }
    return SettingsTabsCopy;
  }, []);
  const entityId = hashManager.getState("id");
  const dispatch = useDispatch();
  const teams = useSelector(({ business }) => business.teams);
  const team = teams?.find((item) => item?.id === Number(entityId));
  const teamCanInvite = team ? team.can_invite : false;

  const { activeTab, onTabClick } = useTabs({
    tabs,
    defaultActiveTabId: SETTINGS.BUSINESS_TEAM_INFO,
    activeTabHashParamName: "tab-team"
  });

  useEffect(() => {
    if (team) {
      let statusString = "";
      if (team.id) {
        statusString = " (" + (team.active ? __("Active") : __("Deactivated")) + ")";
      }

      dispatch(
        loadBreadcrumb([
          {
            id: "0",
            name: __("Teams"),
            customRedirectToState: {
              page: "b_teams"
            },
            isBusinessTeamInfo: true
          },
          {
            ...team,
            id: team.id.toString(),
            name: team.name,
            nameSubfix: statusString,
            isBusinessTeamInfo: true
          }
        ])
      );
    }
  }, [team]);

  const renderTabContent = () => {
    if (activeTab === SETTINGS.BUSINESS_TEAM_INFO) {
      return <Info team={team} />;
    } else if (activeTab === SETTINGS.BUSINESS_TEAM_PERMISSIONS && team?.id !== 0) {
      return <UserPermissions />;
    } else if (activeTab === SETTINGS.BUSINESS_TEAM_CRYPTO) {
      return <ChangeCryptoPassSetting />;
    }
  };

  return (
    <TabsAndContentWrapper $withActionBar={true}>
      <TabsAndContentWrapperInner className="inner-animation animation-type-fade">
        {team && team.id !== 0 && team.members && team.members.length > 0 && teamCanInvite && (
          <ActionWrapper>
            <Button
              styled={isMobile ? "BorderedButtonSmall" : "BorderedButton"}
              onClick={() => {
                dispatch(setModalAction({
                  modal: MODAL_BUSINESS_TEAM_INVITE_USER,
                  props: {
                    teamName: team.name,
                    teamId: team.id
                  }
                }));
              }}
            >
              {__("Add users to team")}
            </Button>
          </ActionWrapper>
        )}
        <StyledTabs
          value={activeTab}
          onChange={onTabClick}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs.map(({ tabId, tabTitle }) => {
            return (
              <StyledTab
                key={tabId}
                label={__(tabTitle)}
                style={team?.id === 0 && tabId === BUSINESS_USER_PERMISSIONS ? { display: 'none' } : null}
              />
            );
          })}
        </StyledTabs>
        <OuterScrollWrapper>
          <TabContent>{renderTabContent()}</TabContent>
        </OuterScrollWrapper>
      </TabsAndContentWrapperInner>
    </TabsAndContentWrapper>
  );
};

export default BusinessTeamContainer;
