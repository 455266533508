// @flow

import * as React from "react";

import type { Folder } from "../../types/filesystem";
import PublicFolderTop from "./Top";
import PublicFolderFeatures from "./Features";
import { Button } from "../../components/ButtonDefault";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { AlertWrapper, Buttons, FolderLink, LinkIcon, OptionsWrapper, SettingsWrapper } from "./styles";
import hashManager from "../../lib/hashManager";
import Alert, { AlertContent } from "../../components/Alert";
import { __ } from "../../lib/translate";
import { useRef } from "react";
import { useMenu } from "../../components/ContentView/context/MenuContextProvider";
import { copyTextToClipboard } from "../../lib";

type PublicFolderSettingsProps = {
  folder: Folder,
  cancreate: boolean,
  directlink: string,
  onDeleteFolder: () => void,
  deletingFolder: boolean,
  token: string,
  ispremium: boolean,
  trialexpiretime?: string
};

const PublicFolderSettings = ({
  folder,
  directlink,
  onDeleteFolder,
  deletingFolder,
  ispremium
}: PublicFolderSettingsProps) => {
  const { showMenu } = useMenu();
  const optionsRef = useRef();

  const onOptionsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const items = [
      {
        //done info
        icon: 'fa-regular fa-link',
        iconLight: 'fa-light fa-link',
        label: ["copy_link", "Copy link"],
        onClick: () => {
          copyTextToClipboard(directlink, __("Link copied."));
        }
      },
      {
        //done info
        icon: 'fa-regular fa-folder-open',
        iconLight: 'fa-light fa-folder-open',
        label: ["open_your_public_folder", "Open your public folder"],
        onClick: () => {
          hashManager.pushState({
            page: "filemanager",
            folder: folder.folderid
          });
        }
      },
      {
        //done info
        icon: 'fa-regular fa-ban',
        iconLight: 'fa-light fa-ban',
        label: ["deactivate_public_folder_action", "Deactivate Public Folder"],
        onClick: onDeleteFolder
      }
    ];

    if (!ispremium) {
      items.push({
        //done info
        icon: 'fa-regular fa-gem',
        iconLight: 'fa-light fa-gem',
        label: ["upgrade", "Upgrade"],
        onClick: () => {
          window.open(URLSITE + "cloud-storage-pricing-plans.html");
        }
      });
    }

    showMenu(items, optionsRef);
  };

  return (
    <>
      <PublicFolderTop folder={folder} />
      <AlertWrapper>
        <Alert
          theme={"info"}
          multiline={true}
          centerText={true}
          children={
            <AlertContent>
              {__("open_your_public_info", "Your Public Folder has been set up and is ready to use in My pCloud.")}
              <Button
                onClick={() => {
                  hashManager.pushState({
                    page: "filemanager",
                    folder: folder.folderid
                  });
                }}
                styled="InfoBorderedButton"
              >
                {__("open_your_public_folder", "Open your public folder")}
              </Button>
            </AlertContent>
          }
        />
      </AlertWrapper>
      <SettingsWrapper>
        <FolderLink>
          <LinkIcon>
            <i className="fa-solid fa-link" />
          </LinkIcon>
          <a href={directlink} target="_blank">
            {directlink}
          </a>
        </FolderLink>
        <OptionsWrapper ref={optionsRef} onClick={onOptionsClick}>
          <i className="baseIcon fa-regular fa-ellipsis-vertical" />
        </OptionsWrapper>
      </SettingsWrapper>
      <PublicFolderFeatures />
    </>
  );
};
export default PublicFolderSettings;
