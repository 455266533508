import React, { useCallback, useMemo } from "react";
import { OPTIONS } from "../../../../config/options";
import { FILTER, GRID_FILTER_CHECK, GRID_FILTER_DIVIDER, GRID_FILTER_LIST } from "../../../../config/constants";
import { TEMPLATES } from "../../ContentData";
import HoverMenu from "../../UI/HoverMenu";
import { useDispatch, useSelector } from "react-redux";
import { changeContentFilter } from "../../../../lib/state/reducers/contentSlice";

const RenderGridFilter = ({ contentType }) => {
  const dispatch = useDispatch();
  const filters = useSelector(({ content }) => content.filters);
  const itemCount = TEMPLATES[contentType].FILTER.length;

  const hasFilterSelected = useMemo(() => {
    let returnStatus = false;
    TEMPLATES[contentType].FILTER.forEach(filter => {
      if (!filter.check || filter.name === 'search') {
        return;
      }

      const existFilter = filters[filter.name];
      if (existFilter) {
        if (existFilter === true) {
          returnStatus = true;
        } else {
          Object.keys(existFilter).forEach((key) => {
            if (existFilter[key]) {
              returnStatus = true;
            }
          });
        }
      }
    });
    return returnStatus;
  }, [filters]);

  const getItems = useCallback(() => {
    const tmpItems = [];
    TEMPLATES[contentType].FILTER.forEach((item) => {
      tmpItems.push(buildFilterItem(item));
    });

    return tmpItems;
  }, [filters]);

  const buildFilterItem = (item) => {
    let currentFilterValue;
    switch (item.type) {
      case GRID_FILTER_DIVIDER:
        return { divider: true };
        break;
      case GRID_FILTER_CHECK:
        currentFilterValue = filters.hasOwnProperty(item.name) ? filters[item.name] : false;
        return {
          icon: item.icon,
          label: item.optionTitle,
          onClick: () => onFilterClick(item, currentFilterValue),
          active: currentFilterValue
        };
        break;
      case GRID_FILTER_LIST:
        currentFilterValue = filters.hasOwnProperty(item.name) ? filters[item.name] : {};
        const submenu = [];
        const listData = item.getData();
        
        listData.forEach((subItem) => {
          submenu.push({
            label: [subItem.name],
            onClick: () => onFilterClick(item, currentFilterValue, subItem),
            active: !!currentFilterValue[subItem.id]
          });
        });
        return {
          icon: item.icon,
          label: item.optionTitle,
          counter: Object.keys(currentFilterValue).filter(id => {
            return !!currentFilterValue[id];
          }).length,
          submenu: submenu,
          submenuFocused: [
            {
              focus: true,
              icon: "fa-regular fa-check-double",
              label: ["select_all", "Select all"],
              active: !!currentFilterValue.all,
              onClick: () => {
                currentFilterValue.all = !currentFilterValue.all;
                listData.forEach((subItem) => {
                  currentFilterValue[subItem.id] = !!currentFilterValue.all
                });
                dispatch(changeContentFilter({
                  name: item.name,
                  value: currentFilterValue
                }));
              }
            },
            { divider: true, focus: true }
          ]
        };
        break;
    }
  };

  const onFilterClick = (item, value, subItem = {}) => {
    switch (item.type) {
      case GRID_FILTER_CHECK:
        dispatch(changeContentFilter({
          name: item.name,
          value: !value
        }));
        break;
      case GRID_FILTER_LIST:
        const currentStatus = !!value[subItem.id];
        value.all = false;
        value[subItem.id] = !currentStatus;
        dispatch(changeContentFilter({
          name: item.name,
          value: value
        }));
        break;
    }
  };
  return (
    <HoverMenu
      menuButtonTooltip={["search_through", "Filter"]}
      menuButton={{
        icon: OPTIONS[FILTER].iconSolid,
        active: hasFilterSelected,
      }}
      menuItems={getItems()}
      triggerOnClick={true}
      closeOnAnchorClick={itemCount <= 1}
      menuKey="view-type-menu"
    />
  );
};

export default RenderGridFilter;
