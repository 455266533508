// @flow
import React, { useRef, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { Menu, MenuItem } from "@szhsin/react-menu";

import FolderHomeIcon from "../../../../../../root/img/dlink/breadcrumb/folder-home-modern.svg";
import FolderDropdownIcon from "../../../../../../root/img/dlink/breadcrumb/folder-dropdown-modern.svg";
import { useMenu } from "../../../context/MenuContextProvider";
import { useSelector } from "react-redux";
import {
    getContentOptions,
    getHashPage,
    transormOptionsToList
} from "../../../../../lib";
import { getShortBreadcrumbData } from "./index";
import hashManager from "../../../../../lib/hashManager";

const BreadcrumbDropdown = ({
    breadcrumbData = {},
    prevFolder = null,
    breadcrumbLength
}) => {
    const [baseParams, setBaseParams] = useState({ page: getHashPage() });

    useEffect(() => {
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    const handleHashChange = () => {
        const hashPage = getHashPage();
        setBaseParams({ ...baseParams, page: hashPage });
    };

    const keys = Object.keys(breadcrumbData);
    const folders = getShortBreadcrumbData(breadcrumbData, baseParams.page);

    const folder = breadcrumbData[folders[folders.length - 1].key];
    const { showMenu } = useMenu(null);
    const optionsRef = useRef();
    const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
    const cryptoV2IsActive = useSelector(
        ({ pCloudUser }) => pCloudUser.userinfo.cryptov2isactive
    );
    const isCollaborationEnabled = useSelector(
        ({ pCloudUser }) => pCloudUser.collaboration || false
    );
    const availableOptions = useMemo(() => {
        const options = getContentOptions({
            content: [folder],
            isBusiness: isBusiness,
            cryptoV2IsActive: cryptoV2IsActive,
            isCollaborationEnabled: isCollaborationEnabled,
            maxMainOptionsCnt: 0,
            isMobileDevice: HFN.config.isMobile()
        });

        if (!isBusiness) {
            delete options.secondary.fileSection;
        } else if (
          options &&
          !folder.isBusinessTeamInfo &&
          !folder.isBusinessUser
        ) {
            // Keep "Add comment" and "View comments"
            const fileSection = [];
            if (options.secondary.fileSection?.indexOf("comment") !== -1) {
                fileSection.push("comment");
            }

            if (options.secondary.fileSection?.indexOf("showComments") !== -1) {
                fileSection.push("showComments");
            }

            options.secondary.fileSection = fileSection;
        }
        return options;
    }, [folder, isBusiness, cryptoV2IsActive, isCollaborationEnabled]);

    const items = useMemo(
        () => transormOptionsToList(availableOptions.secondary, [folder]),
        [availableOptions.secondary, folder]
    );

    const onFolderClick = (folderId) => {
        const stateParams = Object.assign({}, baseParams);

        if (folderId > 0) stateParams.folder = folderId;

        if (hashManager.getState("backupdesktop")) {
            stateParams.backupdesktop = 1;
        }

        if (hashManager.getState("backupmobile")) {
            stateParams.backupmobile = 1;
        }

        if (hashManager.getState("tpl")) {
            stateParams.tpl = hashManager.getState("tpl");
        }

        if (hashManager.getState("time")) {
            stateParams.time = hashManager.getState("time");
        }

        hashManager.pushState(stateParams, 2);
    };

    const renderHomeIcon = () => {
        if (baseParams.page === "trash") {
            return (
                <FontAwesomeIconGradient className="fa-solid fa-trash-alt" />
            );
        }

        if (baseParams.page === "time") {
            return (
                <FontAwesomeIconGradient className="fa-solid fa-clock-rotate-left" />
            );
        }

        if (baseParams.page === "filemanager" && folder.encrypted) {
            return (
                <FontAwesomeIconGradient className="fa-solid fa-shield-keyhole" />
            );
        }

        return <FolderHomeIcon />;
    };

    const renderItem = ({ id, name }, index, prevFolder) => {
        const isLastItem = keys.length - 1 === index;
        return (
            <MenuItem
                key={id}
                onClick={() => {
                    if (
                      !isLastItem &&
                      prevFolder.hasOwnProperty("customRedirectToState")
                    ) {
                        hashManager.pushState(
                            prevFolder.customRedirectToState,
                            2
                        );
                        return;
                    }
                    if (!isLastItem) {
                        onFolderClick(id);
                    }
                }}
            >
                <RowIcon>
                    {index === 0 ? renderHomeIcon() : <FolderDropdownIcon />}
                </RowIcon>
                <Row>
                    <Text className={isLastItem ? "current" : ""}>{name}</Text>
                </Row>
            </MenuItem>
        );
    };

    const onOptionsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        showMenu(items, optionsRef);
    };

    const currentFolder = folders.slice(-1)[0];

    return (
        <Wrapper>
            <ButtonBack
                onClick={() => {
                    if (typeof gtag === "function") {
                        gtag("event", "navigation_click", {
                            action: "breadcrumb back button"
                        });
                    }
                    if (prevFolder.hasOwnProperty("customRedirectToState")) {
                        hashManager.pushState(
                            prevFolder.customRedirectToState,
                            2
                        );
                        return;
                    }
                    if (breadcrumbLength > 1 && prevFolder) {
                        onFolderClick(prevFolder.folderid);
                    } else {
                        HFN.referrer.redirectBack();
                    }
                }}
            >
                <i className="mediumIcon fa-regular fa-chevron-left" />
            </ButtonBack>
            <Menu
                align="start"
                position="initial"
                menuButton={
                    <FolderName>
                        <span>{currentFolder.name}</span>
                    </FolderName>
                }
                transition
            >
                {folders.map((folder, index) =>
                    renderItem(folder, index, prevFolder)
                )}
            </Menu>
            {!folder.disableOptions && (
                <OptionsWrapper
                    ref={optionsRef}
                    onClick={(e) => onOptionsClick(e, items)}
                >
                    <i className="baseIcon fa-regular fa-ellipsis-vertical" />
                </OptionsWrapper>
            )}
        </Wrapper>
    );
};

export default BreadcrumbDropdown;

const Wrapper = styled.div`
    display: flex;
    min-width: 0;
    padding-right: 50px;
    align-items: center;

    .szh-menu {
        max-height: 280px;
        overflow-y: auto;
    }

    .szh-menu__item {
        padding: var(--spacing-sm) var(--spacing-md);
    }
`;
const FontAwesomeIconGradient = styled.i`
    background: linear-gradient(180deg, #156a73 0%, #001c1f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const RowIcon = styled.span`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--spacing-sm);
`;
const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 200px;
`;
const Text = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    color: var(--text-primary);

    &.current {
        color: var(--text-secondary);
    }
`;
const ButtonBack = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: var(--radius-full);
    background: #f0f4f5;
`;
const OptionsWrapper = styled.div`
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1023px) {
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
`;
const FolderName = styled.div`
    font-size: 18px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--text-primary);
`;
