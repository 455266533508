import { useMemo } from 'react';
import { PAGES } from '../../../../config/constants';
import { permissionsKeys } from '../constants';

const useEntitySettings = ({ business, page, entityId }) => {
  return useMemo(() => {
    let businessAccountInfoSettings = business?.accountInfo?.account?.settings ? { ...business?.accountInfo?.account?.settings } : undefined;

    let entitySettings = undefined;
    let entity = undefined;

    if (page === PAGES.B_USER) {
      entity = business?.users?.find((user) => user.id === Number(entityId));

      if (entity?.tracksettings) {
        const { tracksettings } = entity;

        for (const key in tracksettings) {
          businessAccountInfoSettings[key] = tracksettings[key].default;
        }
      }

      entitySettings = entity?.settings ? { ...entity.settings } : undefined;

    } else if (page === PAGES.B_TEAM) {
      entity = business?.teams?.find((team) => team.id === Number(entityId));
      entitySettings = entity?.settings ? { ...entity.settings } : undefined;
    } else {
      entitySettings = businessAccountInfoSettings;
    }

    return { entitySettings, entity, businessAccountInfoSettings };
  }, [business, page, entityId]);
};

export default useEntitySettings;
