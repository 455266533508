// @flow

import * as React from "react";
import styled from "styled-components";

import ResponsiveWrap from "./ResponsiveWrap";
import InputText from "./InputText";
import InputEmail from "./InputEmail";
import InputSelect from "./InputSelect";
import Textarea from "./Textarea";
import { ButtonCentered } from "./ButtonDefault";
import { __ } from "../lib/translate";
import { validateEmail, getParameterByName } from "../lib/utils";
import browser from "../lib/browser";

import apiMethod from "../api/apiMethod";

type ContactFormState = {
  name: string,
  email: string,
  message: string,
  submiting: boolean,
  reason: number,
  error: string,
  success: boolean
};

const ContactReasons: Array<{ value: number, text: string, disabled?: boolean }> = [
  { value: 0, text: "Please select an option", disabled: true },
  { value: 1, text: "I have a Technical issue" },
  { value: 2, text: "I have a Payment question" },
  { value: 3, text: "I have a Suggestion" },
  { value: 5, text: "Two factor authentication issue" },
  { value: 4, text: "Other Questions" }
];

export default class ContactForm extends React.Component<{ token?: string, email?: string, reason?: string }, ContactFormState> {
  constructor(props: { token?: string }) {
    super(props);
    const { email, reason } = this.props;

    this.state = {
      name: "",
      email: email ? email : "",
      message: "",
      submiting: false,
      error: "",
      reason: reason ? 5 : 0,
      hasReasonUrl: reason ? true : false,
      success: false
    };

    (this: any)._submit = this._submit.bind(this);
  }

  _input(name: string, value: any) {
    this.setState({ [name]: value });
  }

  _submit(e: Event) {
    e.preventDefault();

    const canSubmit = this._canSubmit();

    if (canSubmit === true) {
      const { token } = this.props;
      const { name, email, reason, message } = this.state;
      const nameString =
        (name ? name : "N/A") +
        ", UA: " +
        browser.name +
        " (" +
        browser.version +
        (browser.mobile ? "-mobile" : "") +
        "), view: " +
        (typeof HFN !== "undefined" && typeof HFN.config.type !== "undefined"
          ? HFN.config.type
          : "N/A");
      const params = {
        name: nameString,
        mail: email,
        reason: getReasonText(reason),
        reason2: reason,
        message: message,
        auth: token
      };

      apiMethod(
        "feedback",
        params,
        ret => {
          this.setState({ success: true });
        },
        { errorCallback: ret => this.setState({ error: ret.error }) }
      );

      this.setState({ submiting: true });
    } else {
      this.setState({ error: canSubmit });
    }
  }

  _canSubmit(): true | string {
    const { name, email, reason, message } = this.state;

    if (name.length < 5) {
      return __("missing_name", "Please enter a name.");
    }

    if (!email.length) {
      return __("missing_email", "Enter an email.");
    }

    if (!validateEmail(email)) {
      return __("Invalid email");
    }

    if (message.length < 5) {
      return __("Missing message.");
    }

    if (!reason) {
      return __("Please, select a reason to contact us.");
    }

    return true;
  }

  render() {
    const { name, email, message, submiting, error, reason, hasReasonUrl, success } = this.state;

    return (
      <ResponsiveWrap>
        <Wrap>
          <Title>{__("wv_contact_title", "Tell us what you think!")}</Title>
          <Subtitle>
            {__(
              "wv_contact_subtitle",
              "We appreciate your feedback and work constantly on improving our service."
            )}
          </Subtitle>

          {success && <Success>Thank you for your feedback.</Success>}
          {!success && (
            <div>
              <Error>&nbsp;{error && error}&nbsp;</Error>
              <form onSubmit={this._submit}>
                <InputText
                  name="name"
                  placeholder={__("your_name", "Your name")}
                  value={name}
                  onChange={e => this._input("name", e.target.value)}
                />
                <InputEmail
                  name="email"
                  placeholder={__("your_email", "Your email")}
                  value={email}
                  onChange={e => this._input("email", e.target.value)}
                />
                {!hasReasonUrl && <InputSelect
                  name="reason"
                  placeholder={__("Reason to contact us")}
                  options={ContactReasons}
                  value={reason}
                  onChange={e => this._input("reason", e.target.value)}
                />}
                <TArea
                  name="message"
                  placeholder={__("your_message", "Enter your message")}
                  value={message}
                  onChange={e => this._input("message", e.target.value)}
                />
                <ContactFormMessage>
                  {__("info_for_communication_with_you", "The information you provide with this form will be used solely for communication with you and to help us answer your inquiries.")}
                </ContactFormMessage>
                <ButtonCentered onClick={this._submit} loading={submiting} disabled={submiting}>
                  {__("send_your_message", "Send your message")}
                </ButtonCentered>
              </form>
            </div>
          )}
        </Wrap>
      </ResponsiveWrap>
    );
  }
}

function getReasonText(reason: number): string {
  return ContactReasons.filter(({ value }) => value === parseInt(reason, 10))[0].text;
}

const Success = styled.div`
  text-align: center;
  margin: 20px 0 10px 0;
  font-size: 15px;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  font-size: 14px;
  line-height: 27px;
`;

const Wrap = styled.div`
  margin-top: 20px;
  & form > input,
  & form > textarea,
  & form > select {
    margin-bottom: 10px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  margin: 0 0 7px 0;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #666;
  font-weight: normal;
  text-align: center;
  margin: 0 0 0 0;
`;

const ContactFormMessage = styled.div`
  color: #909090;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  padding: 0px 0 15px;
`;

const TArea = styled(Textarea)`min-height: 100px;`;
