// @flow

import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import styled from "styled-components";
import { hiDPI } from "polished";
import { __ } from "../../lib/translate";
import { siteUrl } from "@pcloud/web-utilities/dist/config";
import { Btn } from "../../components/ButtonDefault";
import { Header, Title, Description } from "./CommonComponents";
import Tooltip from "../../components/Tooltip";

const BUTTON_STYLE = {
  minWidth: "180px",
  margin: "0px 4px"
};

const PREVIEW_IMAGES = [
  "/img/branding-settings/1.png",
  "/img/branding-settings/2.png",
  "/img/branding-settings/3.png",
  "/img/branding-settings/4.png"
];

type Props = {
  isPremium: boolean,
  downloadLinksCount: number,
  onEnable: () => void
};

type State = {
  imageID: number
};

class EnableStep extends PureComponent<Props, State> {
  static defaultProps = {};
  image: ?HTMLElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      imageID: 0
    };

    (this: any).changeImageNext = this.changeImageNext.bind(this);
    (this: any).changeImagePrev = this.changeImagePrev.bind(this);
    (this: any).onUpgrade = this.onUpgrade.bind(this);
    (this: any).onButtonClick = this.onButtonClick.bind(this);
  }

  componentDidMount() {
    PREVIEW_IMAGES.forEach(src => {
      const img = new Image();
      img.src = src;
    });

    const image = findDOMNode(this.image);
    image.style.opacity = 1;
  }

  changeImageNext() {
    const image = findDOMNode(this.image);
    const currentImage = this.state.imageID;
    let nextImage;

    if (currentImage === PREVIEW_IMAGES.length - 1) {
      nextImage = 0;
    } else {
      nextImage = currentImage + 1;
    }

    image.style.opacity = 0;

    setTimeout(() => {
      this.setState({
        imageID: nextImage
      });
    }, 400);
  }

  changeImagePrev() {
    const image = findDOMNode(this.image);
    const currentImage = this.state.imageID;
    let prevImage;

    if (currentImage === 0) {
      prevImage = PREVIEW_IMAGES.length - 1;
    } else {
      prevImage = currentImage - 1;
    }

    image.style.opacity = 0;

    setTimeout(() => {
      this.setState({
        imageID: prevImage
      });
    }, 400);
  }

  onButtonClick() {
    const { onEnable, downloadLinksCount } = this.props;
    const disabled = !Boolean(downloadLinksCount);

    if (disabled) {
      return;
    }

    onEnable();
  }

  onUpgrade() {
    window.location.href = siteUrl("cloud-storage-pricing-plans.html");
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.imageID !== prevState.imageID) {
      const image: any = findDOMNode(this.image);
      image.style.opacity = 1;
    }
  }

  render() {
    const { isPremium, downloadLinksCount, onEnable } = this.props;
    const { imageID } = this.state;

    let title = __("branding-intro-title");
    let description = "";
    let buttons = [];
    let imageSrc = PREVIEW_IMAGES[imageID];

    const disabled = !Boolean(downloadLinksCount);

    if (isPremium) {
      description = __("branding-intro-description-premium");
      buttons = [
        <Tooltip key="1" title={disabled ? __("branding_warning_nolinks") : ""}>
          <Btn
            disabled={disabled}
            color="cyan"
            style={BUTTON_STYLE}
            inline={true}
            onClick={this.onButtonClick}
          >
            {__("branding-intro-button1-premium", "Set up")}
          </Btn>
        </Tooltip>
      ];
    } else {
      description = __("branding-intro-description-free");
      buttons = [
        <Tooltip key="1" title={disabled ? __("branding_warning_nolinks") : ""}>
          <Btn
            disabled={disabled}
            color="cyan"
            key="1"
            style={BUTTON_STYLE}
            inline={true}
            onClick={this.onButtonClick}
          >
            {__("branding-intro-button1-free", "Try")}
          </Btn>
        </Tooltip>,
        <Btn
          color="green"
          key="2"
          style={BUTTON_STYLE}
          inline={true}
          onClick={this.onUpgrade}
        >
          {__("branding-intro-button2-free", "Upgrade")}
        </Btn>
      ];
    }

    return (
      <React.Fragment>
        <Header>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Header>
        <ButtonContainer>{buttons}</ButtonContainer>
        <ImageContainer>
          <Back onClick={this.changeImagePrev} />
          <PreviewImage ref={ref => (this.image = ref)} src={imageSrc} />
          <Next onClick={this.changeImageNext} />
        </ImageContainer>
      </React.Fragment>
    );
  }
}

export default EnableStep;

const Wrapper = styled.div`
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  margin: 34px 0px;
  text-align: center;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const PreviewImage = styled.img`
  opacity: 0;
  display: inline-block;
  width: 980px;
  height: auto;
  transition: opacity 0.4s ease-in-out;

  @media (max-width: 1240px) {
    width: 100%;
  }
`;

const Back = styled.div`
  display: inline-block;
  width: 33px;
  height: 33px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: -16.5px;
  top: 50%;
  margin-top: -16.5px;
  cursor: pointer;
  z-index: 10;
  background-image: url(${require("../../../root/img/branding-settings/back.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/back@2x.png")});
  }
  background-color: #fff;
`;

const Next = styled.div`
  display: inline-block;
  width: 33px;
  height: 33px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: -16.5px;
  top: 50%;
  margin-top: -16.5px;
  cursor: pointer;
  z-index: 10;
  background-image: url(${require("../../../root/img/branding-settings/next.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/next@2x.png")});
  }
`;
