import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import { useSelector } from "react-redux";
import apiMethod from "../../api/apiMethod";
import { abortAll } from "../../lib/XhrComponentUtils";
import DeactivatePublicFolderPage from "../../components/Modals/DeactivatePublicFolderPage";
import Loader from "../../components/Loader";
import PublicFolderSettings from "./Settings";
import PublicFolderCreate from "./Create";
import { MenuProvider } from "../../components/ContentView/context/MenuContextProvider";
import ReusableMenu from "../../components/ContentView/UI/ReusableMenu";

const PublicFolder = () => {
  const xhrs = [];
  const [init, setInit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const ispremium = useSelector(({ pCloudUser }) => pCloudUser.userinfo.premium || pCloudUser.userinfo.business);
  const { folder, directlink, ...state } = data;

  useEffect(() => {
    xhrs.push(
      apiMethod(
        "getpublicfolder",
        { auth: token },
        (ret) => {
          setInit(true);
          setData({
            folder: ret.metadata,
            directlink: ret.directlink,
            cancreate: ret.cancreate,
            hasfolder: ret.hasfolder
          });
        },
        {
          forceFresh: true,
          errorCallback: (ret) => {
            setInit(true);
          }
        }
      )
    );
    return () => {
      if (xhrs) {
        abortAll(xhrs);
      }
    };
  }, []);

  const _deletePublicFolder = () => {
    if (data.folder) {
      setData({ ...data, deletingFolder: true });
      xhrs.push(
        apiMethod(
          "deactivatepublicfolder",
          { auth: token },
          () => {
            setData({ ...data, deletingFolder: false, folder: undefined });
            setIsOpen(false);

            if (!ispremium) {
              setData({ ...data, cancreate: false });
            }
          },
          {
            errorCallback: (ret) => {
              HFN.message(ret.error, "error");
              setData({ ...data, deletingFolder: false });
              setIsOpen(false);
            }
          }
        )
      );
    }
  };

  const _createPublicFolder = () => {
    setData({ ...data, creatingFolder: true });

    xhrs.push(
      apiMethod(
        "createpublicfolder",
        { auth: token },
        (ret) => {
          setData({
            ...data,
            folder: ret.metadata,
            directlink: ret.directlink,
            creatingFolder: false
          });
        },
        {
          errorCallback: (ret) => {
            HFN.message(ret.error, "error");
            setData({ ...data, creatingFolder: false });
          }
        }
      )
    );
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  if (!init) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <DeactivatePublicFolderPage
        isOpen={isOpen}
        isPremium={ispremium}
        onDeactivateConfirm={_deletePublicFolder}
        onClose={handleCloseModal}
      />
      {!!folder && directlink && (
        <MenuProvider>
          <ReusableMenu />
          <PublicFolderSettings
            {...state}
            token={token}
            ispremium={ispremium}
            directlink={directlink}
            folder={folder}
            onDeleteFolder={handleOpenModal}
          />
        </MenuProvider>
      )}
      {!folder && <PublicFolderCreate {...state} ispremium={ispremium} onCreateFolder={_createPublicFolder} />}
    </Wrapper>
  );
};

export default PublicFolder;
