// @flow

import React from "react";
import styled from "styled-components";

import { BUSINESS_QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";
import AdvancedSlider from "../../../components/AdvancedSlider";
import { __ } from "../../../lib/translate";
import { formatSizeInGb } from "../../../lib";

type Props = {
  users: number,
  startValue: number,
  minStartValue: number,
  maxValue: number,
  getStorageValue: () => void
};

const ManageMemberQuotaSlider = ({
  users = 1,
  updatedQuota = 0,
  startValue,
  minStartValue,
  maxValue,
  getStorageValue,
  disabled = false
}: Props) => {
  return (
    <StorageSlider>
      <AdvancedSlider
        users={users}
        minValue={0}
        startValue={startValue}
        minStartValue={minStartValue}
        maxValue={maxValue}
        step={BUSINESS_QUOTA_STEP}
        disabled={disabled}
        getSliderValue={getStorageValue}
      />
      <Label>
        {__("manage_business_storage_quota", "Storage quota")}: <span>{formatSizeInGb(updatedQuota, 0)}</span>
      </Label>
    </StorageSlider>
  );
};

export default ManageMemberQuotaSlider;

const StorageSlider = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    width: 100%;
`;

const Label = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    & span {
        font-weight: 700;
    }
`;
