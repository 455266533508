// @flow
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Componentify from "react-componentify";
import styled from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../../api/apiMethod";
import {
  isBusinessShare,
  getUserInitials,
  getBusinessAvatarSrc,
  getEntityName,
  isRequest,
  geyRowKey,
  calcPermissions,
  canChange,
  isBusinessNotManaged
} from "../../lib/shareFolder-utils";
import { boldConverter } from "../../lib/componentifyConverters";

import { __ } from "../../lib/translate";
import { TAB_INDEX } from "@pcloud/web-utilities/dist/config/constants";
import {
  Label,
  Message,
  StopAccessTitle,
  StopAcessButton,
  FooterStopAccess
} from "./StyleInviteFolder";
import { AvatarWrap } from "../UserAvatar";
import PermissionButton from "./PermissionButton";
import { Btn } from "../ButtonDefault";
import { TSpan, TDiv } from "../intl";
import * as Style from "../Modals/styledComponents";

type Props = {
  isMine: boolean,
  onTabClick: number => void
};

const ManageShareTab = ({ isMine, onTabClick }: Props) => {
  const folderShares = useSelector(({ sharedFolders: { folderShares } }) => folderShares);
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const accountId = useSelector(
    ({ pCloudUser: { userinfo: { account: { accountId } = {} } = {} } }) => accountId
  );
  const userId = useSelector(({ pCloudUser }) => pCloudUser.userinfo.userid);
  const [showStopAccess, setShowStopAccess] = useState(false);
  const [stopAccessForShare, setStopAccessForShare] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onRemoveShareClick = (share, hasPermission) => {
    if (!hasPermission) {
      return;
    }

    setShowStopAccess(true);

    if (share) {
      setStopAccessForShare(share);
    }
  };

  const onCancelAccessClick = () => {
    setShowStopAccess(false);
    setStopAccessForShare(null);
  };

  const onStopAccessButtonClick = () => {
    if (stopAccessForShare) {
      stopSharesCall([stopAccessForShare], true);
    } else {
      stopSharesCall(folderShares);
    }
  };

  const stopAccessForShareCall = share => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const params: {
      auth: string,
      shareid?: number,
      sharerequestid?: number,
      teamshareids?: number,
      usershareids?: number
    } = { auth: token };
    let method = "account_stopshare";

    if (isBusinessShare(share)) {
      if (share.teamobj) {
        params.teamshareids = share.shareid;
      } else params.usershareids = share.shareid;
    } else {
      if (!isRequest(share, userId)) {
        method = "removeshare";
        params.shareid = share.shareid;
      } else {
        params.sharerequestid = share.sharerequestid;
        method = "cancelsharerequest";
      }
    }

    return new Promise((resolve, reject) => {
      apiMethod(
        method,
        params,
        res => {
          resolve(res);
        },
        {
          errorCallback: ({ error }) => {
            setIsLoading(false);
            reject(error);
          }
        }
      );
    });
  };

  const stopSharesCall = (stopShares, showMessage) => {
    stopShares
      .reduce((prev, share) => {
        return prev.then(() => stopAccessForShareCall(share));
      }, Promise.resolve(1))
      .then(() => {
        setShowStopAccess(false);
        setStopAccessForShare(null);
        if (showMessage) {
          HFN.message(__("Share removed."));
        } else {
          HFN.message(__("Share stopped."));
        }
      });
  };

  const changePermission = (share, permissionid) => {
    let method = "";
    const { team, shareid } = share;
    const params: {
      teamshareids?: number,
      teampermissions?: number,
      usershareids?: number,
      userpermissions?: number,
      shareid?: number,
      permissions?: number
    } = { auth: token };

    if (isBusinessShare(share)) {
      method = "account_modifyshare";

      if (team) {
        params.teamshareids = shareid;
        params.teampermissions = permissionid;
      } else {
        params.usershareids = shareid;
        params.userpermissions = permissionid;
      }
    } else {
      method = "changeshare";
      params.shareid = shareid;
      params.permissions = permissionid;
    }

    apiMethod(
      method,
      params,
      res => {
        console.log(res);
        HFN.message("Share Modified.");
      },
      {
        forceFresh: true,
        cacheTime: 0,
        errorCallback: ({ error }) => {
          throw new Error(error);
        }
      }
    );
  };

  const renderStopAccess = () => {
    let message = __(
      "stop_access_modal_message",
      "Invited collaborators will no longer have access to this folder."
    );

    if (stopAccessForShare) {
      message = __(
        "This action will terminate the access of <b>%name%</b> to this folder."
      ).replace("%name%", getEntityName(stopAccessForShare, accountId, userId, true));
    }

    return (
      <WrapperStopAccess>
        <StopAccessTitle>{__("Stop access")}</StopAccessTitle>
        <Message>
          <Componentify text={message} converters={[boldConverter]} />
        </Message>
        <Style.Footer>
          <Btn color="lightgray4" style={{ marginRight: "5px"}} onClick={onCancelAccessClick}>
            {__("Cancel")}
          </Btn>
          <Btn color="red" style={{ marginLeft: "5px"}} onClick={onStopAccessButtonClick}>
            {__("Stop access")}
          </Btn>
        </Style.Footer>
      </WrapperStopAccess>
    );
  };

  const renderRowShare = share => {
    const { tomail = "" } = share;
    const email = tomail || share?.userobj?.email;
    const hasPermission = !isBusinessNotManaged(share, userId);

    return (
      <Row key={geyRowKey(share, userId)}>
        <EmailWrapper type="type" disable={!canChange(share, userId)}>
          {(isBusinessShare(share) && getBusinessAvatarSrc(share)) ? (
            <Avatar src={getBusinessAvatarSrc(share)} />
          ) : (
            <AvatarWrap>{getUserInitials(email)}</AvatarWrap>
          )}
          <Email>{getEntityName(share, accountId, userId)}</Email>
        </EmailWrapper>

        <ButtonWrapper>
          <PermissionButton
            permission={calcPermissions(share)}
            disable={!canChange(share, userId)}
            canReshare={isBusinessShare(share)}
            getPermission={permissionid => {
              changePermission(share, permissionid);
            }}
          />
          <RemoveButton
            hasPermission={hasPermission}
            onClick={() => onRemoveShareClick(share, hasPermission)}
          />
        </ButtonWrapper>
      </Row>
    );
  };

  const renderFooter = () => {
    return (
      <Style.Footer>
        {isMine ? (
          <Btn color="lightgray4" style={{ marginRight: "5px"}} onClick={() => onRemoveShareClick(null, true)}>
            {__("Stop access")}
          </Btn>
        ) : null}
        <Btn color="cyan" style={{ marginLeft: `${isMine ? "5px": "0"}`}} onClick={() => onTabClick(TAB_INDEX.INVITE)}>
          {__("share_folder_invite_more_people_btn", "Invite more")}
        </Btn>
      </Style.Footer>
    );
  };

  const renderInvitedUsers = () => {
    console.log("SHARes", folderShares);
    return (
      <React.Fragment>
        <Table>{folderShares.map(renderRowShare)}</Table>
        {renderFooter()}
      </React.Fragment>
    );
  };

  const renderNoInvitedUser = () => {
    return (
      <React.Fragment>
        <EmptyBox>
          <AddMoreIcon />
          <EmptyText id="share_folder_no_invitations_message">
            {
              'You haven\'t invited anyone yet. Click "Invite to folder" to share this folder with more people.'
            }
          </EmptyText>
        </EmptyBox>
      </React.Fragment>
    );
  };

  const hasInvitedUsers = folderShares.length !== 0;

  if (showStopAccess) {
    return renderStopAccess();
  }

  return (
    <Wrapper>
      <Label id="share_folder_already_invited">Who has access:</Label>
      {hasInvitedUsers ? renderInvitedUsers() : renderNoInvitedUser()}
    </Wrapper>
  );
};

export default ManageShareTab;

const Wrapper = styled.div`
  position: relative;
  /* padding-top: 10px; */
`;

const WrapperStopAccess = styled.div`
  position: absolute;
  top: 29px;
  right: 0;
  width: 100%;
  height: 100%;
  height: auto;
  min-height: auto;
  background-color: #fff;
  border-radius: 21px;
  padding: 0 30px 30px;
  box-sizing: border-box;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  max-height: 210px;
  overflow: auto;
`;

const EmptyBox = styled.div`
  display: flex;
  min-height: 181px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;

  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #eeeeee;
  background-color: #fafafa;
`;

const Row = styled.div`
  display: flex;
  padding-left: 10px;
  font-size: 13px;
  width: 100%;
  height: 42px;
  min-height: 42px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  
  @media (max-width: 500px) {
    flex-direction: column;
    height: 84px;
    min-height: auto;
    align-items: start;
    padding: 5px 0 5px 10px;
    gap: 4px;
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:nth-child(odd) {
    background-color: #fafafa;
  }
`;

const Email = styled.div`
  margin-left: 10px;
  text-align: left;
  font-size: 13px;
`;

const RemoveButton = styled.div`
  display: block;
  width: 9px;
  height: 9px;
  margin: 0 15px;
  cursor: ${props => (props.hasPermission ? "pointer" : "auto")};
  opacity: ${props => (props.hasPermission ? "1" : "0.35")};

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-icons/cancel.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/cancel@2x.png")});
  }
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: ${props => (props.justifyContent ? "space-between" : "center")};
  align-items: center;
`;

const StopAccessWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StopAccessFolder = styled.div`
  display: block;
  width: 24px;
  height: 22px;
  cursor: pointer;
  margin-right: 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-icons/stop-folder.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/stop-folder@2x.png")});
  }
`;

const StopAccessFolderSpan = styled.span`
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
`;

const InviteMoreWrapper = styled.div`
  min-width: 180px;
`;

const InviteMoreButton = styled(Btn)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
    justify-content: end;
  }
`;

const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props => (props.disable ? 0.5 : 1)};
  & > div:first-child {
    flex-shrink: 0;
  }
`;

const AddMoreIcon = styled.div`
  display: block;
  width: 64px;
  height: 64px;
  margin-bottom: 20px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-icons/add-more.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/add-more@2x.png")});
  }
`;

const EmptyText = styled(TDiv)`
  font-size: 13px;
  text-align: center;
  color: #999999;
`;

const StopAccessMessage = styled(Message)`
  padding-bottom: 20px;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
`;
