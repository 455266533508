// @flow

import React from "react";
import styled from "styled-components";
import { PROVIDERS_NAMES } from "@pcloud/web-utilities/dist/config/constants";
import ChangePaymentContainer from "../../../components/ManageSubscription/ChangePaymentContainer";
import { ModalContainer, ModalHeader } from "../../../components/Modals/ModalsStyled";
import ModalTemplate from "../../../components/Modals/ModalTemplate";

type Props = {
  provider: string,
  onSuccess: () => void
};

const ChangeCardStep = ({ provider, onSuccess = () => {} }: Props) => {
  const updateCreditCardInfo = () => {
    if (provider === PROVIDERS_NAMES.SAFECHARGE) {
      if (typeof SFCardNumber !== "undefined") {
        SFCardNumber.destroy();
        SFCardNumber = undefined;
        if (typeof cardExpiry !== "undefined") {
          cardExpiry.destroy();
          cardExpiry = undefined;
        }
        if (typeof cardCvc !== "undefined") {
          cardCvc.destroy();
          cardCvc = undefined;
        }
      }
    }
    onSuccess();
  };

  return (
    <ModalTemplate
      externalModal
      key="changeCardStep"
      title={__("change_your_credit_card", "Change your credit card")}
      containerProps={{style: {gap: 0}}}
    >
      <ChangePaymentContainerWrapper>
        <ChangePaymentContainer
          color="green"
          provider={provider}
          updateCreditCardInfo={updateCreditCardInfo}
          showHeader={false}
        />
      </ChangePaymentContainerWrapper>
    </ModalTemplate>
  );
};

export default ChangeCardStep;

const ChangePaymentContainerWrapper = styled.div`
  .change-payment-container {
    width: 100%;
    .payment-error-message {
      margin-top: 18px;
    }
  }
`;
