import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { createPortal } from "react-dom";
import Selecto from "react-selecto";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import { MenuProvider, useMenu } from './context/MenuContextProvider';
import ReusableMenu from "./UI/ReusableMenu";
import HoverMenu from "./UI/HoverMenu";
import {
  OptionsButton,
  ListGridCointainer,
  Content,
  SelectionOptions,
  OptionIcon,
  OptionsClearButton,
  IconButton,
  ActionButton,
  SharedLinksMenu, ActionButtonsWrapper
} from "./UI/Style";
import Pagination from "../Pagination";
import ContentTemplate from "./ContentTemplate";
import { OPTIONS } from "../../config/options";
import type {
  contentTypes,
  Sort
} from "./ContentData";
import { TEMPLATES, DEFAULT_SORT } from "./ContentData";
import { sortContentData, setLocalStorageSort, getSortMenu, getSharedLinksOptions, setLocalStorageContentType, filterContentData, addItemToSelection } from "./ContentViewUtils";
import {
  getViewType,
  getContentOptions,
  transormOptionsToList,
  getHashPage,
  getHashComment,
  getHashItemInfo
} from "../../lib/utils";
import useWindowDimensions from '../../lib/hooks/useWindowDimensions';
import { __ } from "../../lib/translate";
import {
  MOVE,
  COPY,
  SHARE_LINK,
  DOWNLOAD_ARCHIVE,
  DELETE,
  MOBILE_WIDTH_BREAKPOINT,
  GRID_VIEW,
  GRID_ASPECT_RATIO_VIEW,
  BROWSE_ALBUM,
  BROWSE_PLAYLIST,
  MODIFIED,
  CREATED,
  BROWSE_SHAREDLINKS,
  BROWSE_CONTENT,
  SHAREDLINK_GENERAL_BRANDING,
  SHAREDLINK_STATS,
  SHAREDLINK_GET_TRAFFIC,
  BROWSE_MEMBERS,
} from "../../config/constants";
import FileIcon from "../FileIcon";
import {
  ONBOARDING_ID_FILE_VIEW_OPTIONS
} from "../HighlightOnboardingComponent/constants";
import FileViewOptionContent from "../HighlightOnboardingComponent/Contents/FileViewOptionContent";
import HighlightOnboardingComponent from "../HighlightOnboardingComponent";
import { addItem } from "../../lib/state/reducers/onboardingSlice";
import { changeContentFilter, setContentItemKeys } from "../../lib/state/reducers/contentSlice";

import useItemsInfo from "./hooks/useItemsInfo";
import hashManager from "../../lib/hashManager";
import { Button } from "../ButtonDefault";
import RenderGridFilter from "./Templates/Filters/RenderGridFilter";
import { runFilterData } from "./Templates/Filters/helpers";
import ModalCallCenter from "./UI/ModalCallCenter";

const ITEMS_PER_PAGE = 500000;

type ContentViewProps = {
  contentType: contentTypes,
  folderData: Array,
  contentIsDraggable: boolean,
  contentIsSelectable: boolean
};

const ContentViewInner = ({
  contentType,
  folderData,
  contentIsDraggable = true,
  contentIsSelectable = true
}: ContentViewProps) => {
  const [page, setPage] = useState(getHashPage());
  const [selectedFolders, setSelectedFolders] = useState(new Map());
  const [selectedFiles, setSelectedFiles] = useState(new Map());
  const [currentItemKeys, setCurrentItemKeys] = useState([]);
  const [sortOptions, setSortOptions] = useState(DEFAULT_SORT);
  const [sortIsSet, setSortIsSet] = useState(false);
  const [contentKeys, setContentKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const cryptoV2IsActive = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptov2isactive);
  const isCollaborationEnabled = useSelector(({ pCloudUser }) => pCloudUser.collaboration || false);
  const rawData = useSelector(({ content }) => content.itemData);
  const filters = useSelector(({ content }) => content.filters);
  const pageInfo = useSelector(({ content }) => content.pageInfo) || undefined;
  const { explorecards, explorecardsleft } = pageInfo;
  const hasExploreCards = !explorecardsleft && explorecards.length > 0;

  const initialFiltratedItems = useMemo(() => {
    let items = {...rawData};
    if (TEMPLATES[contentType].FILTER && TEMPLATES[contentType].FILTER.length) {
      items.items = runFilterData(items.items, TEMPLATES[contentType].FILTER, filters);
    }
    return items;
  }, []);
  const [contentData, setContentData] = useState(initialFiltratedItems);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [contentDataInitDone, setContentDataInitDone] = useState(false);
  const lastSelectedItem: string = useRef("");
  const { showMenu } = useMenu();
  const dispatch = useDispatch();
  const [currentViewType, setCurrentViewType] = useState(getViewType(contentType));
  const listGridContainerRef = useRef(null);
  const listGridRef = useRef(null);
  const listGridOuterRef = useRef(null);
  const selectoRef = useRef(null); // TODO: Finish drag sort or remove ref
  const scrollingRef = useRef(null); // TODO: Finish drag sort or remove ref
  const { bodyWidth } = useWindowDimensions();
  const isMobileView = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
  const isMobileDevice = HFN.config.isMobile();
  const selectionIndex = useRef(0);
  const ignoreHideContextMenuOnResize = useRef(false);
  const disableOptionsRow = !!TEMPLATES[contentType].DISABLE_OPTIONS_ROW;

  setLocalStorageContentType(contentType);

  useEffect(() => {
    console.log('contentData changed', contentData); // TODO remove
    let sortOpts = sortOptions;
    if (!sortIsSet) {
      const prevSort = getPreviousSort();

      if (prevSort && prevSort[contentType]) {
        sortOpts = prevSort[contentType];
        setSortOptions(prevSort[contentType]);
      } else if (TEMPLATES[contentType].DEFAULT_SORT) {
        sortOpts = TEMPLATES[contentType].DEFAULT_SORT;
        setSortOptions(TEMPLATES[contentType].DEFAULT_SORT);
      }
      setSortIsSet(true);
    }

    let newContentKeys = Object.keys(contentData.items || []);
    if (typeof newContentKeys !== 'undefined' && newContentKeys.length) {
      newContentKeys = sortContentData(newContentKeys, sortOpts, contentData.items, TEMPLATES[contentType].COLUMNS[sortOpts.sortBy]);
      setContentKeys(newContentKeys);
      dispatch(setContentItemKeys(newContentKeys));

      if (newContentKeys.length > ITEMS_PER_PAGE) {
        const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
        const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
        newContentKeys = newContentKeys.slice(indexOfFirstItem, indexOfLastItem);

        setCurrentPage(currentPage);
      }
      setCurrentItemKeys(newContentKeys);

      let lastSelectedFolder = null;
      let lastSelectedFile = null;
      let missingSelectedFolder = false;
      let missingSelectedFile = false;
      let changedSelectedFile = false;

      if (selectedFolders.size) {
        selectedFolders.forEach((item, id) => {
          if (!newContentKeys.includes(id)) {
            selectedFolders.delete(id);
            missingSelectedFolder = true;
          } else {
            // Update item data with new contentData, because it could be changed.
            Object.assign(item, contentData.items[id]);
            lastSelectedFolder = item;
          }
        })
      }

      if (selectedFiles.size) {
        selectedFiles.forEach((item, id) => {
          if (!newContentKeys.includes(id)) {
            selectedFiles.delete(id);
            missingSelectedFile = true;
          } else {
            if (!isEqual(item, contentData.items[id])) {
              changedSelectedFile = true;
              selectedFiles.set(id, contentData.items[id]);
            }
            lastSelectedFile = contentData.items[id];
          }
        })
      }

      if (missingSelectedFolder) {
        // Shallow copy map to trigger selectedFolders hook change.
        const currentSelectedFolders = new Map();
        selectedFolders.forEach((item, id) => {
          currentSelectedFolders.set(id, item);
        });

        setSelectedFolders(currentSelectedFolders);
      }

      if (missingSelectedFile || changedSelectedFile) {
        // Shallow copy map to trigger selectedFiles hook change.
        const currentSelectedFiles = new Map();
        selectedFiles.forEach((item, id) => {
          currentSelectedFiles.set(id, item);
        });

        setSelectedFiles(currentSelectedFiles);
      }

      if (missingSelectedFolder || missingSelectedFile) {
        // Fix lastSelectedItem.current to refer to last slected item.
        if (lastSelectedFolder && lastSelectedFile) {
          lastSelectedItem.current = lastSelectedFolder.selectionIndex > lastSelectedFile.selectionIndex ? lastSelectedFolder.id : lastSelectedFile.id;
        } else {
          lastSelectedItem.current = lastSelectedFolder ? lastSelectedFolder.id : (lastSelectedFile ? lastSelectedFile.id : "");
        }
      }
    } else {
      setContentKeys([]);
      dispatch(setContentItemKeys(newContentKeys));
      setCurrentItemKeys([]);
      setCurrentPage(1);
      setSelectedFolders(new Map());
      setSelectedFiles(new Map());
      // Reset selection index.
      selectionIndex.current = 0;
    }

    if (listGridRef.current && typeof listGridRef.current.resetAfterIndices === "function") {
      listGridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true
      });
    }

    setContentDataInitDone(true);
  }, [contentData]);

  const gridHeaderHeight = useSelector(({ content }) => content.gridHeaderHeight);
  useEffect(() => {
    if (listGridRef.current) {
      if (typeof listGridRef.current.resetAfterIndices === "function") {
        listGridRef.current.resetAfterIndices({
          columnIndex: 0,
          rowIndex: 0,
          shouldForceUpdate: true
        });
      } else if (typeof listGridRef.current.resetAfterIndex === "function") {
        listGridRef.current.resetAfterIndex(0);
      }

      if (hasExploreCards) {
        document.querySelector('.header-sticky-row')?.classList.remove('sticky')
      }
    }
  }, [currentViewType, gridHeaderHeight, hasExploreCards]);

  useEffect(() => {
    if (contentType !== BROWSE_MEMBERS) {
      HFN.audioPlayer.setup();
    }

    if (!TEMPLATES[contentType].DISABLE_REACT_WINDOW) {
      dispatch(addItem(ONBOARDING_ID_FILE_VIEW_OPTIONS));
    }

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        setSelectedFolders(new Map());
        setSelectedFiles(new Map());
        // Reset selection index.
        selectionIndex.current = 0;
      }
    };

    const handleMouseUp = (event) => {
      clearSelectedItems();
    };

    const handleMouseContentContainerUp = (event) => {
      if (event.target && listGridContainerRef.current) {
        const disableGridFocus = event.target.closest(".disableGridFocus");
        const audioPlayer = event.target.closest("#audioplayer-container");
        const contentGrid = event.target.closest("#content-grid");
        const contentRightSection = event.target.closest("#content-right-section");
        const contentHeaderWrapper = event.target.closest(".headerWrapper");
        const headerBoxWrapper = event.target.closest(".headerBoxWrapper");

        if (contentGrid) {
          if (!disableGridFocus && listGridOuterRef.current && !audioPlayer) {
            listGridOuterRef.current.focus();
          }

          if ((event.target.classList.length === 0 && !contentHeaderWrapper)
            || headerBoxWrapper
            || event.target.classList.contains("itemFolder")
            || event.target.classList.contains("itemFile")
            || event.target.classList.contains("gridTitleWrapper")
            || event.target.classList.contains("gridTitle")
            || event.target.classList.contains("credits")
            || event.target.classList.contains("innerContent")
          ) {
            clearSelectedItems();
          }
        } else if (!contentGrid && !contentRightSection) {
          clearSelectedItems();
        }
      }
    };

    const elem = listGridContainerRef.current;

    const handleHashChange = () => {
      setPage(getHashPage());
    };

    window.addEventListener('keydown', handleEsc);
    window.addEventListener('hashchange', handleHashChange);

    const leftMenu = document.querySelector('.left.lnav');
    if (leftMenu) {
      leftMenu.addEventListener('mouseup', handleMouseUp);
    }

    const contentTopBar = document.querySelector('#content-top-bar');
    if (contentTopBar) {
      contentTopBar.addEventListener('mouseup', handleMouseUp);
    }

    const contentContainer = document.querySelector('#content-container');
    if (contentContainer) {
      contentContainer.addEventListener('mouseup', handleMouseContentContainerUp);
    }

    if (elem) {
      elem.addEventListener('keydown', handleListGridKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
      window.removeEventListener('hashchange', handleHashChange);

      if (leftMenu) {
        leftMenu.removeEventListener('mouseup', handleMouseUp);
      }
      if (contentTopBar) {
        contentTopBar.removeEventListener('mouseup', handleMouseUp);
      }
      if (contentContainer) {
        contentContainer.removeEventListener('mouseup', handleMouseUp);
      }
      if (elem) {
        elem.removeEventListener('keydown', handleListGridKeyDown);
      }
    };
  }, []);

  useEffect(() => {
    if (!["tab-songs", "tab-artists", "tab-albums", "tab-playlists", "bookmarks", "filerequests",
      "publinks-tab", "myshares-tab", "requests-tab", "revisions", "b_users", "b_user", "b_teams", "b_team"].includes(page)) {
      return;
    }

    const handleKeyUp = debounce((e) => {
      let target;

      try {
        target = e.target?.closest("#search-bar");
      } catch (e) {
        if ('search' in filters) {
          dispatch(changeContentFilter({
            name: 'search',
            value: ''
          }));
        }
        return;
      }

      if (!target || target.value === '') {
        if ('search' in filters) {
          dispatch(changeContentFilter({
            name: 'search',
            value: ''
          }));
        }
        return;
      }

      dispatch(changeContentFilter({
        name: 'search',
        value: target.value
      }));
    }, 150);

    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [page, filters]);

  useEffect(() => {

    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }

    const tmpData = Object.assign({}, rawData);

    if (TEMPLATES[contentType].FILTER && TEMPLATES[contentType].FILTER.length) {
      tmpData.items = runFilterData(tmpData.items, TEMPLATES[contentType].FILTER, filters);
      setContentData(tmpData);
    }

    if (filters.hasOwnProperty("search") && filters.search !== "") {
      tmpData.items = runFilterData(
        tmpData.items,
        [
          {
            name: "search"
          }
        ],
        filters
      );
    }

    setContentData(tmpData);
  }, [filters, rawData]);

  useEffect(() => {
    if (!isBusiness && (getHashComment() || getHashItemInfo())) {
      document.querySelector("html").style.setProperty("--items-info-content-width", "0px");
      document.querySelector("html").style.setProperty("--items-info-content-right-mobile", "-100%");
    }
  }, [isBusiness]);

  const onContentClick = event => {
    const parent = event.currentTarget;
    const currentClick = event.target;
    const isCheckbox = (currentClick.tagName === "INPUT" && currentClick.type === "checkbox") || currentClick.classList.contains("input-checkbox");
    const contentId = parent.getAttribute("data-id");
    const shiftKeyClicked = event.shiftKey && !TEMPLATES[contentType].DISABLE_MULTISELECT;
    const continueSelect = (event.ctrlKey || event.metaKey) && !TEMPLATES[contentType].DISABLE_MULTISELECT;

    if (parent.classList.contains("selectable") && !isCheckbox) {
      if (shiftKeyClicked) {
        onShifKeySelect(contentData.items[contentId], lastSelectedItem.current);
      } else {
        const skipOpenItemsInfoPanel = !continueSelect && (currentClick.classList.contains("action-btn") || (currentClick.parentElement && currentClick.parentElement.classList.contains("action-btn")));
        onSingleItemSelect(contentData.items[contentId], continueSelect, false, skipOpenItemsInfoPanel);
      }
    }
  }

  // TODO: Finish drag sort or remove func
  const startScrolling = (direction) => {
    if (scrollingRef.current === null) {
      scrollingRef.current = requestAnimationFrame(() => handleScroll(direction));
    }
  };

  // TODO: Finish drag sort or remove func
  const stopScrolling = () => {
    if (scrollingRef.current !== null) {
      cancelAnimationFrame(scrollingRef.current);
      scrollingRef.current = null;
    }
  };

  // TODO: Finish drag sort or remove func
  const handleScroll = (direction) => {
    if (direction !== null && listGridRef.current && listGridRef.current.state) {
      const scrollOffset = listGridRef.current.state.scrollOffset;
      const newOffset = scrollOffset + direction * 2;

      listGridRef.current.scrollTo(newOffset);

      selectoRef.current.checkScroll();

      scrollingRef.current = requestAnimationFrame(() => handleScroll(direction));
    }
  };

  // TODO: Finish drag sort or remove func
  const handleSelectStart = (e) => {
    const continueSelect = e.inputEvent.ctrlKey || e.inputEvent.metaKey;

    if (continueSelect) {
      //
    } else {
      clearSelectedItems();
    }
  }

  // TODO: Finish drag sort or remove func
  const handleDrag = (e) => {
    if (listGridRef.current && listGridRef.current._outerRef) {
      selectoRef.current.findSelectableTargets();

      const topOffset = 50;
      const bottomOffset = 10;
      const listElement = listGridRef.current._outerRef;
      const { top, bottom } = listElement.getBoundingClientRect();

      if (e.clientY < top + topOffset) {
        startScrolling(-1);
      }

      else if (e.clientY > bottom - bottomOffset) {
        startScrolling(1);
      } else {
        stopScrolling();
      }
    }
  };

  const onSelectEnd = event => {
    // TODO: Finish drag sort or remove func
    // stopScrolling();

    if (!event.isClick) {
      const elements = [];
      const continueSelect = event.inputEvent.ctrlKey || event.inputEvent.metaKey;
      event.selected.forEach(el => {
        const contentId = el.dataset.id;
        if (contentId) {
          elements.push(contentId);
        }
      });

      if (elements.length) {
        onMultipleItemsSelect(elements, continueSelect);
      }
    }
  }

  const onContentContextClick = event => {
    event.preventDefault();
    event.stopPropagation();

    const parent = event.currentTarget;
    const contentId = parent.getAttribute("data-id");
    let currentSelection = [];

    if (selectedFolders.has(contentId) || selectedFiles.has(contentId)) {
      currentSelection = [...selectedFolders.values(), ...selectedFiles.values()];
    } else {
      // skipOpenItemsInfoPanel = true.
      onSingleItemSelect(contentData.items[contentId], false, false, true);
      currentSelection = [contentData.items[contentId]];
    }

    const availableOptions = getContentOptions({
      content: currentSelection,
      isBusiness: isBusiness,
      cryptoV2IsActive: cryptoV2IsActive,
      isCollaborationEnabled: isCollaborationEnabled,
      maxMainOptionsCnt: 0,
      contentType: contentType,
      isMobileDevice: isMobileDevice
    });

    if (Object.keys(availableOptions.secondary).length) {
      const items = transormOptionsToList(availableOptions.secondary, currentSelection, overwriteOptionsOnClick);
      showMenu(items, { x: event.clientX, y: event.clientY });
    }
  }

  const onColumnSortClick = (sortBy, sortDirection, folderFirst, sortType) => {
    const newSortOptions = {
      sortBy,
      sortDirection,
      folderFirst,
      sortType
    }

    setLocalStorageSort(contentType, sortBy, sortDirection, folderFirst, sortType);
    setSortOptions(newSortOptions);

    const newContentKeys = sortContentData(contentKeys, newSortOptions, contentData.items, TEMPLATES[contentType].COLUMNS[sortBy]);
    dispatch(setContentItemKeys(newContentKeys));

    if (newContentKeys.length > ITEMS_PER_PAGE) {
      const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
      const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

      setCurrentPage(currentPage);
      setCurrentItemKeys(newContentKeys.slice(indexOfFirstItem, indexOfLastItem));
    } else {
      setCurrentItemKeys(newContentKeys);
    }

    if (listGridOuterRef.current) {
      listGridOuterRef.current.focus();
    }
  }

  const handleListGridKeyDown = (event) => {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }

    const options = {
      c: COPY,
      m: MOVE,
      l: SHARE_LINK,
      d: DOWNLOAD_ARCHIVE,
      x: DELETE
    }

    if (event.key === 'a') {
      event.preventDefault();
      if (!TEMPLATES[contentType].DISABLE_MULTISELECT) {
        selectAllItemsRef.current();
      }
      if (typeof gtag === "function") {
        gtag("event", "content_options_click", {
          action: "select all",
          location: "keyboard shortcut"
        })
      }
    } else if (((selectedFoldersRef && selectedFoldersRef.current && selectedFoldersRef.current.size)
      || (selectedFilesRef && selectedFilesRef.current && selectedFilesRef.current.size))
      && Object.keys(options).indexOf(event.key) !== -1) {
      event.preventDefault();

      const currentSelection = [...selectedFoldersRef.current.values(), ...selectedFilesRef.current.values()];
      const availableOptions = getContentOptions({
        content: currentSelection,
        isBusiness: isBusiness,
        cryptoV2IsActive: cryptoV2IsActive,
        isCollaborationEnabled: isCollaborationEnabled,
        maxMainOptionsCnt: 0,
        contentType: contentType,
        rawOutput: true
      });

      if (availableOptions.includes(options[event.key])) {
        OPTIONS[options[event.key]].onClick(currentSelection, null, "keyboard shortcut");
      }
    }
  };

  const handlePageChange = newPage => {
    $(window).scrollTop(0);
    const indexOfLastItem = newPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

    setCurrentPage(newPage);
    setCurrentItemKeys(contentKeys.slice(indexOfFirstItem, indexOfLastItem));
  };

  const getPreviousSort = (): Sort => {
    if (localStorage && localStorage.getItem("sortOptions")) {
      const prevSortData = JSON.parse(localStorage.getItem("sortOptions"));
      if (prevSortData[contentType]) {
        return prevSortData;
      }
    }

    return false;
  }

  const onShifKeySelect = (itemClicked, lastSelected) => {
    let currentSelectedFolders = new Map();
    let currentSelectedFiles = new Map();
    let selectionIds = [];
    const currentSelectionId = itemClicked.id;
    const isFolder = itemClicked.isfolder;
    const lastSelectionIndex = currentItemKeys.indexOf(lastSelected);
    const currentSelectionIndex = currentItemKeys.indexOf(currentSelectionId);

    if (lastSelectionIndex < currentSelectionIndex) {
      selectionIds = currentItemKeys.slice(lastSelectionIndex, currentSelectionIndex + 1);
    } else {
      selectionIds = currentItemKeys.slice(currentSelectionIndex, lastSelectionIndex + 1);
    }

    selectionIds.map((id) => {
      if (contentData.items[id].isfolder) {
        addItemToSelection(currentSelectedFolders, id, contentData.items[id], selectionIndex);
      } else {
        addItemToSelection(currentSelectedFiles, id, contentData.items[id], selectionIndex);
      }
    });
    lastSelectedItem.current = currentSelectionId;

    setSelectedFolders(currentSelectedFolders);
    setSelectedFiles(currentSelectedFiles);
  }

  const onMultipleItemsSelect = (items: Array, continueSelect) => {
    const currentSelectedFolders = continueSelect ? new Map(selectedFolders) : new Map();
    const currentSelectedFiles = continueSelect ? new Map(selectedFiles) : new Map();

    if (!continueSelect) {
      // Reset selection index.
      selectionIndex.current = 0;
    }

    if (items && Array.isArray(items)) {
      items.forEach((el, index) => {
        if (contentData.items[el].isfolder) {
          addItemToSelection(currentSelectedFolders, el, contentData.items[el], selectionIndex);
        } else {
          addItemToSelection(currentSelectedFiles, el, contentData.items[el], selectionIndex);
        }
        if (index === items.length - 1) {
          lastSelectedItem.current = el;
        }
      })
    }

    setSelectedFolders(currentSelectedFolders);
    setSelectedFiles(currentSelectedFiles);
  }

  const onAllItemsSelect = () => {
    if (selectedFolders.size + selectedFiles.size === 0) {
      // Select all.
      selectAllItemsRef.current();
    } else {
      // Deselect all.
      setSelectedFolders(new Map());
      setSelectedFiles(new Map());
      // Reset selection index.
      selectionIndex.current = 0;
    }
  }

  const onAllFoldersSelect = (clearFiles = false) => {
    const newSelectedFolders = new Map();

    if (selectedFolders.size === 0) {
      currentItemKeys.map((id) => {
        if (contentData.items[id].isfolder) {
          addItemToSelection(newSelectedFolders, id, contentData.items[id], selectionIndex);
        }
      });
    }
    lastSelectedItem.current = null;

    setSelectedFolders(newSelectedFolders);
    if (clearFiles) {
      setSelectedFiles(new Map());
    }
  }

  const onAllFilesSelect = (clearFolders = false) => {
    const newSelectedFiles = new Map();

    if (selectedFiles.size === 0) {
      currentItemKeys.map((id) => {
        if (!contentData.items[id].isfolder) {
          addItemToSelection(newSelectedFiles, id, contentData.items[id], selectionIndex);
        }
      });
    }
    lastSelectedItem.current = null;

    setSelectedFiles(newSelectedFiles);
    if (clearFolders) {
      setSelectedFolders(new Map());
    }
  }

  const selectAllItems = () => {
    const newSelectedFolders = new Map();
    const newSelectedFiles = new Map();
    // Reset selection index.
    selectionIndex.current = 0;

    currentItemKeys.map((id) => {
      if (contentData.items[id].isfolder) {
        addItemToSelection(newSelectedFolders, id, contentData.items[id], selectionIndex);
      } else {
        addItemToSelection(newSelectedFiles, id, contentData.items[id], selectionIndex);
      }
    });

    lastSelectedItem.current = null;

    setSelectedFolders(newSelectedFolders);
    setSelectedFiles(newSelectedFiles);
  }

  const selectAllItemsRef = useRef(selectAllItems);
  selectAllItemsRef.current = selectAllItems;

  const selectedFoldersRef = useRef(selectedFolders);
  const selectedFilesRef = useRef(selectedFiles);
  selectedFoldersRef.current = selectedFolders;
  selectedFilesRef.current = selectedFiles;

  const onSingleItemSelect = (item, continueSelect, event = false, skipOpenItemsInfoPanel = false) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!item.id) {
      return;
    }

    const currentSelectedFolders = continueSelect ? new Map(selectedFolders) : new Map();
    const currentSelectedFiles = continueSelect ? new Map(selectedFiles) : new Map();
    const idStr = item.id.toString();

    if (!continueSelect) {
      // Reset selection index.
      selectionIndex.current = 0;
    }

    if (item.isfolder) {
      if (currentSelectedFolders.has(idStr)) {
        currentSelectedFolders.delete(idStr);
      } else {
        if (!(isMobileDevice && selectedFolders.has(idStr))) {
          addItemToSelection(currentSelectedFolders, idStr, item, selectionIndex);
          lastSelectedItem.current = idStr;
        }
      }
    } else {
      if (currentSelectedFiles.has(idStr)) {
        currentSelectedFiles.delete(idStr);
      } else {
        if (!(isMobileDevice && selectedFiles.has(idStr))) {
          addItemToSelection(currentSelectedFiles, idStr, item, selectionIndex);
          lastSelectedItem.current = idStr;
        }
      }
    }

    skipOpenItemsInfoPanelRef.current = skipOpenItemsInfoPanel;

    setSelectedFolders(currentSelectedFolders);
    setSelectedFiles(currentSelectedFiles);
  }

  const {
    OVERWRITE_OPTIONS_ONCLICK: overwriteOptionsOnClick,
    initialItemKey,
    renderToggleRightSidePanelSetting,
    skipOpenItemsInfoPanelRef
  } = useItemsInfo({
    contentData,
    currentFolderData: folderData,
    selectedFiles,
    selectedFolders,
    contentDataInitDone,
    onSingleItemSelect,
    isMobileView,
    isBusiness,
    ignoreHideContextMenuOnResize,
    currentPage: page
  });

  const scrollToItemKey = useRef(!!hashManager.getState('file') ? hashManager.getState('file') : initialItemKey);

  useEffect(() => {
    // Scroll to file if it's in the hash &file=<itemId>
    if (!!hashManager.getState('file') && scrollToItemKey.current && contentData.items[scrollToItemKey.current]) {
      onSingleItemSingleSelect(contentData.items[scrollToItemKey.current]);
    }
  }, [contentDataInitDone]);

  // TODO: Finish drag sort or remove func
  const onSingleItemSingleSelect = (item) => {
    const currentSelectedFolders = new Map(selectedFolders);
    const currentSelectedFiles = new Map(selectedFiles);

    if (item.isfolder) {
      if (!currentSelectedFolders.has(item.id)) {
        addItemToSelection(currentSelectedFolders, item.id, contentData.items[item.id], selectionIndex);
      }
    } else {
      if (!currentSelectedFiles.has(item.id)) {
        addItemToSelection(currentSelectedFiles, item.id, contentData.items[item.id], selectionIndex);
      }
    }

    setSelectedFolders(currentSelectedFolders);
    setSelectedFiles(currentSelectedFiles);
  }

  // TODO: Finish drag sort or remove func
  const onSingleItemDeselect = (item) => {
    const currentSelectedFolders = new Map(selectedFolders);
    const currentSelectedFiles = new Map(selectedFiles);

    if (item.isfolder) {
      if (currentSelectedFolders.has(item.id)) {
        currentSelectedFolders.delete(item.id);
      }
    } else {
      if (currentSelectedFiles.has(item.id)) {
        currentSelectedFiles.delete(item.id);
      }
    }

    setSelectedFolders(currentSelectedFolders);
    setSelectedFiles(currentSelectedFiles);
  }

  const clearSelectedItems = () => {
    setSelectedFolders(new Map());
    setSelectedFiles(new Map());
    // Reset selection index.
    selectionIndex.current = 0;
  }

  const filterSelectedItems = (category) => {
    setSelectedFolders(new Map());
    setSelectedFiles(filterContentData(currentItemKeys, contentData, category, selectionIndex));
  }

  const renderSelectionMenu = () => {
    const [wrapperWidth, setWrapperWidth] = useState(
      (listGridContainerRef && listGridContainerRef.current)
        ? listGridContainerRef.current.innerWidth
        : bodyWidth
    );
    const [isFirstResize, setIsFirstResize] = useState(true);
    const ref = useRef(null);

    useEffect(() => {
      const handleResize = debounce((width) => {
        setWrapperWidth(width);
        setIsFirstResize(false);
      }, 150);

      const observer = new ResizeObserver((entries) => {
        const width = entries[0].contentRect.width;
        if (isFirstResize) {
          setWrapperWidth(width);
        } else {
          handleResize(width);
        }
      });

      if (listGridContainerRef.current) {
        observer.observe(listGridContainerRef.current);
      }

      return () => {
        listGridContainerRef.current && observer.unobserve(listGridContainerRef.current);
      };
    }, []);

    const selLength = selectedFolders.size + selectedFiles.size;
    const currentSelection = [...selectedFolders.values(), ...selectedFiles.values()];

    let optionsCnt = 100;
    if (wrapperWidth) {
      for (let i = 1; i < optionsCnt; ++i) {
        if (wrapperWidth < (260 * (i + 1))) {
          optionsCnt = i;
          break;
        }
      }
    }

    const availableOptions = useMemo(() => {
      return (wrapperWidth && selLength) ? getContentOptions({
        content: currentSelection,
        isBusiness: isBusiness,
        cryptoV2IsActive: cryptoV2IsActive,
        isCollaborationEnabled: isCollaborationEnabled,
        maxMainOptionsCnt: optionsCnt,
        contentType: contentType
      }) : {};
    }, [selectedFolders, selectedFiles, isBusiness, cryptoV2IsActive, isCollaborationEnabled, optionsCnt, selLength]);

    const items = useMemo(() => transormOptionsToList(availableOptions.secondary, currentSelection, overwriteOptionsOnClick), [availableOptions.secondary, selectedFolders, selectedFiles]);

    if (!wrapperWidth) {
      return null;
    }

    const mainOptions = availableOptions.main ? availableOptions.main.map(option => {
      const optionData = OPTIONS[option] || {};
      const { iconLight, optionTitle } = optionData;
      const onClick = overwriteOptionsOnClick[option] ?? optionData.onClick;

      return (
        <OptionsButton
          className="top"
          key={option}
          onClick={() => onClick(currentSelection, null, "header selection menu")}
          onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
        >
          <OptionIcon>
            <i className={"smallIcon lightColorIcon " + iconLight} />
          </OptionIcon>
          <span className="label">
            {__(...optionTitle)}
          </span>
        </OptionsButton>
      );
    }) : null;

    const secondaryOptions = availableOptions.secondary ? (Object.keys(availableOptions.secondary).length ? (
      <OptionsButton
        ref={ref}
        key="options"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          showMenu(items, ref);
        }}
        onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
      >
        <OptionIcon>
          <i className="baseIcon fa-regular fa-ellipsis-vertical" />
        </OptionIcon>
      </OptionsButton>
    ) : null) : null;

    return selLength ? (
      <>
        <OptionsClearButton onClick={() => clearSelectedItems()}>
          <OptionIcon className="clearButton">
            <i className="smallIcon fa-regular fa-close" />
          </OptionIcon>
          {selLength} {__(selLength === 1 ? "selected_one" : "selected_multiple")}
        </OptionsClearButton>
        {mainOptions}
        {secondaryOptions}
      </>
    ) : null;
  };

  const renderSelectByTypeMenu = (menuButtonClassName = "", filesOnly = false) => {
    const button = {
      icon: false
    };

    const items = [];

    if (!filesOnly) {
      items.push({
        iconComponent: <FileIcon item={HFN.ICO.FOLDER} type={HFN.ICONS.SVG_DARKER_DROPDOWN} />,
        label: ["Folders"],
        onClick: () => {
          onAllFoldersSelect(true);
        }
      });
      items.push({
        iconComponent: <FileIcon item={HFN.CATEGORY.UNKNOWN} type={HFN.ICONS.SVG_DARKER_DROPDOWN} />,
        label: ["Files"],
        onClick: () => {
          onAllFilesSelect(true);
        }
      });
    }

    items.push(...[
      {
        iconComponent: <FileIcon item={HFN.CATEGORY.IMAGE} type={HFN.ICONS.SVG_DARKER_DROPDOWN} />,
        label: ["Images"],
        onClick: () => {
          filterSelectedItems(HFN.CATEGORY.IMAGE);
        }
      },
      {
        iconComponent: <FileIcon item={HFN.CATEGORY.AUDIO} type={HFN.ICONS.SVG_DARKER_DROPDOWN} />,
        label: ["Audio"],
        onClick: () => {
          filterSelectedItems(HFN.CATEGORY.AUDIO);
        }
      },
      {
        iconComponent: <FileIcon item={HFN.CATEGORY.VIDEO} type={HFN.ICONS.SVG_DARKER_DROPDOWN} />,
        label: ["Video"],
        onClick: () => {
          filterSelectedItems(HFN.CATEGORY.VIDEO);
        }
      },
      {
        iconComponent: <FileIcon item={HFN.CATEGORY.DOCUMENT} type={HFN.ICONS.SVG_DARKER_DROPDOWN} />,
        label: ["Documents"],
        onClick: () => {
          filterSelectedItems(HFN.CATEGORY.DOCUMENT);
        }
      },
      {
        iconComponent: <FileIcon item={HFN.CATEGORY.ARCHIVE} type={HFN.ICONS.SVG_DARKER_DROPDOWN} />,
        label: ["Archives"],
        onClick: () => {
          filterSelectedItems(HFN.CATEGORY.ARCHIVE);
        }
      },
    ]);

    return <HoverMenu
      menuButton={button}
      menuItems={items}
      triggerOnClick={true}
      menuButtonClassName={menuButtonClassName}
      menuButtonIconClass="smallIcon fa-regular"
      menuButtonTooltip={["select_items_by_type", "Select items by type"]}
      menuKey="select-by-type-menu"
    />;
  };

  const renderViewTypeMenu = () => {
    const button = {
      icon: OPTIONS[currentViewType].iconSolid
    };

    const items = [];
    TEMPLATES[contentType].VIEWS_MENU.map(viewType => {
      items.push({
        icon: OPTIONS[viewType].icon,
        label: OPTIONS[viewType].optionTitle,
        onClick: () => {
          setCurrentViewType(viewType);
          sessionStorage.filemanagerTemplate = OPTIONS[viewType].template;
          localStorage.filemanagerTemplate = OPTIONS[viewType].template;
          if (event.target.tagName !== 'INPUT' && listGridOuterRef.current) {
            listGridOuterRef.current.focus();
          }
        },
        params: [],
        active: viewType === currentViewType
      });
    });

    return <HoverMenu menuButtonTooltip={["tooltip_switch_view", "Switch view options"]} menuButton={button} menuItems={items} triggerOnClick={true} menuKey="view-type-menu" />;
  };

  const renderSortMenu = () => {
    const { button, items } = useMemo(() => getSortMenu(sortOptions, onColumnSortClick, currentViewType, TEMPLATES[contentType].DISABLE_FOLDERS_FIRST ?? false, TEMPLATES[contentType].USE_DATE_CREATED_SORT ?? false, TEMPLATES[contentType].DISABLE_NAME_SORT ?? false), [sortOptions, currentViewType, contentType]);

    return <HoverMenu menuButtonTooltip={["tooltip_sort_items", "Sort all items by"]} menuButton={button} menuItems={items} triggerOnClick={true} menuKey="sort-menu" />;
  };

  const renderSharedLinksButtons = () => {
    const canBuyTraffic = HFN.config.canBuyTraffic();
    return (
      <>
        {canBuyTraffic &&
          <ActionButton className="hideOnSelection" onClick={OPTIONS[SHAREDLINK_GET_TRAFFIC].onClick}>
            {__(...OPTIONS[SHAREDLINK_GET_TRAFFIC].optionTitle)}
          </ActionButton>
        }
        <IconButton
          className="menuButton hideOnSelection brandingButton"
          onClick={OPTIONS[SHAREDLINK_GENERAL_BRANDING].onClick}
          data-tooltip-content={__(...OPTIONS[SHAREDLINK_GENERAL_BRANDING].optionTitle)}
          data-tooltip-id="cv-tooltip"
        >
          <i className={OPTIONS[SHAREDLINK_GENERAL_BRANDING].icon} />
        </IconButton>
        <IconButton
          className="menuButton hideOnSelection"
          onClick={OPTIONS[SHAREDLINK_STATS].onClick}
          data-tooltip-content={__(...OPTIONS[SHAREDLINK_STATS].optionTitle)}
          data-tooltip-id="cv-tooltip"
        >
          <i className={OPTIONS[SHAREDLINK_STATS].icon} />
        </IconButton>
      </>
    );
  }

  const renderGridMenus = () => {
    return <div className="menusWrapper">
      {!isMobileView && contentType === BROWSE_CONTENT && renderToggleRightSidePanelSetting()}
      {!isMobileDevice && contentType === BROWSE_SHAREDLINKS && renderSharedLinksButtons()}
      {isMobileDevice && TEMPLATES[contentType].ACTION_BUTTONS && renderActionButton()}
      {TEMPLATES[contentType].FILTER && TEMPLATES[contentType].FILTER.length && <RenderGridFilter contentType={contentType} />}
      {TEMPLATES[contentType].VIEWS_MENU.length > 1 && (
        <HighlightOnboardingComponent
          id={ONBOARDING_ID_FILE_VIEW_OPTIONS}
          content={[<FileViewOptionContent />]}
          notShowAfterClose={true}
          pulseAnimation={false}
          pulseCircle={false}
          showOverlay={true}
          place={"top-right"}
          borderRadius={"9999px"}
          delay={500}
        >
          {renderViewTypeMenu()}
        </HighlightOnboardingComponent>
      )}
      {TEMPLATES[contentType].SORTING_MENU && renderSortMenu()}
    </div>
  };

  const renderActionButton = () => {
    const buttons = TEMPLATES[contentType].ACTION_BUTTONS.filter((tmpItem => {
      return !tmpItem.showIf || tmpItem.showIf();
    }) );

    if (!buttons.length) {
      return null;
    }

    return (
      <ActionButtonsWrapper className="hideOnSelection">
        {buttons.map((props) => {
          const {
            label,
            actionButtonInfo = null,
            type = '',
            typeMobile = null,
            onButtonClick = () => {},
          } = props;

          const actionButtonInfoData = actionButtonInfo ?
            actionButtonInfo() :
            {isDisabled: false, tooltip: ''};
          const isDisabled = actionButtonInfoData?.isDisabled ?? false;

          return (
            <Button
              styled={isMobileView ? (typeMobile || type) : type}
              disabled={isDisabled}
              onClick={!isDisabled ? onButtonClick : null}
              data-tooltip-content={actionButtonInfoData.tooltip}
              data-tooltip-id="action-button-tooltip"
            >
              {__(...label)}
            </Button>
          );
        })}
        <Tooltip id="action-button-tooltip" className="cv-tooltip visible-on-mobile" place="right" noArrow={true} />
      </ActionButtonsWrapper>
    );
  };

  const renderContentOptions = () => {
    return (
      <SelectionOptions id={!isMobileDevice ? "selection-options" : ""} className={(!isMobileDevice && (selectedFolders.size + selectedFiles.size) > 0) ? "hasSelection" : ""}>
        {!isMobileDevice && TEMPLATES[contentType].ACTION_BUTTONS && renderActionButton()}
        {!isMobileDevice && renderSelectionMenu()}
        {renderGridMenus()}
      </SelectionOptions>
    )
  };

  const contentClassList = [];
  if ([BROWSE_ALBUM, BROWSE_PLAYLIST].includes(contentType)) {
    contentClassList.push("audioSideviewContainer");

    if (isMobileDevice) {
      contentClassList.push("mobile");
    }
  }

  const selectionContainerClassList = [];
  const showSharedLinksMenu = isMobileDevice && contentType === BROWSE_SHAREDLINKS && currentItemKeys.length > 0;
  if (showSharedLinksMenu) {
    selectionContainerClassList.push("actions-line");
  }

  return (
    <ListGridCointainer ref={listGridContainerRef} tabIndex={0} style={{ marginLeft: (!isMobileDevice && [GRID_VIEW, GRID_ASPECT_RATIO_VIEW].includes(currentViewType) ? -8 : 0) }}>
      {isMobileDevice && !disableOptionsRow &&
        <SelectionOptions id="selection-options" className={((selectedFolders.size + selectedFiles.size) > 0) ? "hasSelection hasMobileSelection" : "mobile"}>
          {renderSelectionMenu()}
        </SelectionOptions>
      }
      {showSharedLinksMenu &&
        <SharedLinksMenu>
          {renderSharedLinksButtons()}
        </SharedLinksMenu>
      }
      <div id="selection-container" className={selectionContainerClassList.join(" ")} key="selection-container">
        {!isMobileDevice && !TEMPLATES[contentType].DISABLE_SELECTO && (currentItemKeys.length > 0) &&
          <Selecto
            className="selecto"
            container={listGridRef.current?._outerRef}
            dragContainer={listGridRef.current?._outerRef}
            selectableTargets={['#elements-container .selectable']}
            selectByClick={false}
            preventClickEventOnDrag={false}
            hitRate="20px"
            ratio={0}
            onSelect={e => {
              e.added.forEach(el => {
                el.classList.add("hovered");
              });
              e.removed.forEach(el => {
                el.classList.remove("hovered");
              });
            }}
            onSelectEnd={onSelectEnd}
            key="Selecto"
          // TODO: Finish drag sort or remove
          /*
          ref={selectoRef}
          onDrag={handleDrag}
          onSelectStart={handleSelectStart}
          scrollOptions={{ container: listGridRef.current?._outerRef }}
          onSelect={e => {
            console.log(e);
            e.added.forEach(el => {
              const itemId = el.dataset.id;
              console.log('added', contentData.items[el.dataset.id].name);
              // el.classList.add("selected");
              onSingleItemSingleSelect(contentData.items[el.dataset.id]);
            });
            e.removed.forEach(el => {
              const itemId = el.dataset.id;
              // el.classList.remove("selected");
              console.log('removed', contentData.items[el.dataset.id].name);
              onSingleItemDeselect(contentData.items[el.dataset.id]);
            });
          }}
          */
          />
        }
        <Content id="elements-container" key="elements-container" className={contentClassList.join(" ")}>
          <ContentTemplate
            listGridRef={listGridRef}
            listGridOuterRef={listGridOuterRef}
            contentType={contentType}
            viewType={currentViewType}
            columns={TEMPLATES[contentType].COLUMNS}
            contentData={contentData}
            contentDataInitDone={contentDataInitDone}
            currentKeys={currentItemKeys}
            selectedFolders={selectedFolders}
            selectedFiles={selectedFiles}
            sortOptions={sortOptions}
            sortIsSet={sortIsSet}
            isMobileView={isMobileView}
            isMobileDevice={isMobileDevice}
            onSingleItemSelect={onSingleItemSelect}
            onContentClick={onContentClick}
            onContentContextClick={onContentContextClick}
            onAllItemsSelect={onAllItemsSelect}
            onAllFoldersSelect={onAllFoldersSelect}
            onAllFilesSelect={onAllFilesSelect}
            onColumnSortClick={onColumnSortClick}
            renderContentOptions={renderContentOptions}
            renderGridMenus={renderGridMenus}
            renderSelectByTypeMenu={renderSelectByTypeMenu}
            hasExploreCards={hasExploreCards}
            scrollTo={scrollToItemKey.current}
            overwriteOptionsOnClick={overwriteOptionsOnClick}
            filters={filters}
            lastSelectedItem={lastSelectedItem.current}
          />
        </Content>
      </div>

      {contentKeys.length > ITEMS_PER_PAGE ? (
        <Pagination
          numberOfItems={contentKeys.length}
          itemsPerPage={ITEMS_PER_PAGE}
          getCurrentPage={handlePageChange}
        />
      ) : null}

      {createPortal(
        <Tooltip id="cv-tooltip" className="cv-tooltip visible-on-mobile" noArrow={true} />,
        document.body
      )}

      <ReusableMenu listGridContainerRef={listGridContainerRef} ignoreHideOnResize={ignoreHideContextMenuOnResize} />

    </ListGridCointainer>
  );
};

const ContentView = ({ ...props }) => {
  return (
    <MenuProvider>
      <ContentViewInner {...props} />
    </MenuProvider>
  );
};

export default ContentView;
