import { animated } from "@react-spring/web";
import ReactDOMServer from "react-dom/server";
import React from "react";

const CustomTickX = ({ value, onClick, animatedProps, formatedData, disableBarClick, renderCustomTooltip }) => {
  const targetItem = formatedData.find((item) => item.dt === value);
  const hasTooltip = targetItem && (targetItem.views || targetItem.downloads || targetItem.traffic);
  return (
    <animated.g
      transform={animatedProps.transform}
      onClick={() => onClick(value)}
      style={{
        cursor: disableBarClick ? "auto" : "pointer",
        outline: "0",
        opacity: animatedProps.opacity
      }}
      data-tooltip-id="chart-axis-tooltip"
      data-tooltip-html={
        hasTooltip
          ? ReactDOMServer.renderToStaticMarkup(<>{renderCustomTooltip({ data: targetItem }, false)}</>)
          : null
      }
    >
      <rect x={-15} y={0} width={30} height={20} fill="transparent" />
      <line x1="0" x2="0" y1="0" y2="5" stroke="rgb(119, 119, 119)" strokeWidth={1} />
      <animated.text
        textAnchor="middle"
        dominantBaseline="middle"
        transform={animatedProps.textTransform}
        style={{
          fill: hasTooltip ? "var(--text-primary)" : "var(--text-secondary)",
          fontSize: 11,
          transform: "translateY(15px)"
        }}
      >
        {value.split("-")[2]}
      </animated.text>
    </animated.g>
  );
};

export default CustomTickX;