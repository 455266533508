import React, { useState, useEffect } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";
import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import { Btn } from "../ButtonDefault";
import { __ } from "../../lib/translate";
import { boldConverter } from "../../lib/componentifyConverters";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Text, Title } from "./sharedStyles";
import InputText from "../InputText";

const UploadRemoteModal = () => {
  const {
    isUploadRemoteModalOpen,
    handleUploadRemoteModalClose,
    handleUploadRemoteModalYesClick,
    handleOpenState,
    isOpened,
  } = useUploadContext();
  const [remoteUrl, setRemoteUrl] = useState('');

  useEffect(() => {
    setRemoteUrl('');
  }, [isUploadRemoteModalOpen])


  return (
    <Modal align="top" animate shouldCloseOnOverlayClick={false} onClose={handleUploadRemoteModalClose} opened={isUploadRemoteModalOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleUploadRemoteModalClose} />
        <Content>
          <React.Fragment>
            <Body>
              <Title>{__('upload_manager_remote_upload_title', 'Upload from URL')}</Title>
              <Text>
                <Componentify
                  text={__(
                    "upload_manager_remote_upload_description",
                    "Upload files from the web to your account. Enter the URL of the file and let us handle the rest."
                  )}
                  converters={[boldConverter]}
                />
              </Text>
              <StyledInputText value={remoteUrl} onChange={(e) => setRemoteUrl(e.target.value)} placeholder={__('upload_manager_remote_upload_input_placeholder', 'Paste file URL here')} />
            </Body>
            <ButtonWrapper>
              <Btn inline color="lightgray4" onClick={handleUploadRemoteModalClose}>
                {__('Cancel', 'Cancel')}
              </Btn>
              <Btn inline color="cyan" onClick={() => {
                handleUploadRemoteModalYesClick(remoteUrl);
                if (!isOpened) {
                  handleOpenState();
                }
              }}>
                {__('Upload', 'Upload')}
              </Btn>
            </ButtonWrapper>
          </React.Fragment>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default UploadRemoteModal;

const StyledInputText = styled(InputText)`
  margin-bottom: 48px;
  padding: 8px;
  outline: 0;
  color: rgba(0, 0, 0, 0.40);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.70);
  height: 36px;
`