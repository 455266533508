import React, { useEffect, useState } from "react";
import ContentView from "../../../components/ContentView/ContentView";
import { BROWSE_B_TEAMS_CONTENT } from "../../../config/constants";
import SkeletonTable from "../../../components/ContentView/Templates/SkeletonTable";
import { useDispatch, useSelector } from "react-redux";
import { loadContent, setContentItems } from "../../../lib/state/reducers/contentSlice";
import styled from "styled-components";
import apiMethod from "../../../api/apiMethod";


const BusinessTeams = () => {
  const [tableLoaded, setTableLoaded] = useState(false);
  const teams = useSelector(({ business }) => business.teams);
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const dispatch = useDispatch();

  useEffect(() => {
    let xhr;
    if (!teams.length) {
      xhr = apiMethod(
        "account_teams",
        { showeveryone: 1, showlinks: 1, showusers: 1, showeveryone: 1, withavatars: 1, teamsettings: 1 },
        ret => {
          loadTable(ret.teams);
        },
        { forceFresh: true }
      )
    } else {
      loadTable(teams);
    }

    return () => {
      if (xhr) {
        xhr.abort();
      }
    }
  }, [teams]);

  const loadTable = (teamsData) => {
    const rows = teamsData.map(function(team) {
      return Object.assign(team, {isBusinessTeam: true, canTeamManage: account.permissions.team_manage});
    });
    dispatch(loadContent(true));
    dispatch(setContentItems({ contents: rows } ));
    setTableLoaded(true);
  }

  return (
    <Wrapper>
      {tableLoaded ? (
        <ContentView contentType={BROWSE_B_TEAMS_CONTENT} />
      ) : (
        <SkeletonTable contentType={BROWSE_B_TEAMS_CONTENT} />
      )}
    </Wrapper>
  );
};

export default BusinessTeams;

const Wrapper = styled.div`
    height: calc(100%);
`;
