// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
import { Btn } from "../ButtonDefault";
import * as Style from "./styledComponents";
import InputSelect from "../InputSelect";

import ArrowGrey from "../../../root/img/svg/arrow-grey.svg";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { formatSize } from "../../lib/utils";
import { prepUrl } from "../../api/utils";
import { errorKeys } from "../../lib/errors";

type Props = {
  data: Array<any>
};

const DownloadVideoModal = ({ data }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [downloadVariantToLink, setDownloadVariantToLink] = useState([]);
  const [variantSelected, setVariantSelected] = useState(-1);
  const [isLoadingVariants, setIsLoadingVariants] = useState(true);

  const { fileid, revisionid } = data;

  useEffect(() => {
    let params = {
      fileid: fileid,
      forcedownload: 1
    };

    if (revisionid) {
      params.revisionid = revisionid;
    }

    apiMethod(
      "getvideolinks",
      params,
      ret => {
        let videoLinksArr = [];
        let n;
        for (n = 0; n < ret.variants.length; ++n) {
          if (!ret.variants[n].videocodec) {
            continue;
          }

          let v = ret.variants[n];
          let name =
            v.width +
            "x" +
            v.height +
            " (" +
            formatSize(v.size) +
            ")" +
            " - " +
            v.videocodec +
            " (" +
            v.videobitrate +
            "kbps), " +
            v.audiocodec +
            " (" +
            v.audiobitrate +
            "kbps)" +
            (v.isoriginal ? ", Original" : "") +
            (v.rotate ? ", Rotated:" + v.rotate + String.fromCharCode(176) : "");

          videoLinksArr.push({
            text: name,
            value: prepUrl(ret.variants[n])
          });
        }

        setDownloadVariantToLink(videoLinksArr);
        videoLinksArr.length && setVariantSelected(videoLinksArr[0].value);
        setIsLoadingVariants(false);
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
          throw new Error(error);
        }
      }
    );
  }, [data, fileid, revisionid]); //[data]

  const onCloseClick = () => {
    setIsOpened(false);
  };

  const onDownloadConvertedClick = () => {
    window.open(variantSelected);
    HFN.message(__("download_started","Download has started."));
    setIsOpened(false);
  };

  const getVariantsVideo = downloadVariantToLink => {
    let result = [];

    for (let index in downloadVariantToLink) {
      result.push({
        text: downloadVariantToLink[index].text,
        value: downloadVariantToLink[index].value
      });
    }
    return result;
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>{__("Download Video")}</Header>
          <Style.Container style={{ padding: "5px 30px 0px 30px" }}>
            <Box>
              <Style.Label>{__("Variants")}</Style.Label>
              {!isLoadingVariants ? (
                downloadVariantToLink.length ? (
                  <SelectBox className="selectBox">
                    <InputSelect
                      style={{ paddingRight: "35px" }}
                      name="download-converts"
                      options={getVariantsVideo(downloadVariantToLink)}
                      value={variantSelected}
                      onChange={e => setVariantSelected(e.target.value)}
                    />
                  </SelectBox>
                ) : (
                  <DisabledText>{__("No converted files available for this video.")}</DisabledText>
                )
              ) : (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
            </Box>
          </Style.Container>
        </Body>
        <Style.Footer>
          <Btn style={{ marginRight: "5px" }} color="lightgray4" onClick={onCloseClick}>
            {__("Close")}
          </Btn>
          {downloadVariantToLink.length ? (
            <Btn style={{ marginLeft: "5px" }} color="cyan" onClick={onDownloadConvertedClick}>
              {__("Download")}
            </Btn> ) : null
          }
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" opened={isOpened} onClose={onCloseClick}>
      <OvalModalWrap>
        <CloseModalIcon onClick={onCloseClick} />
        <Style.Content>{renderContent()}</Style.Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default DownloadVideoModal;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;

const SelectBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const ArrowIconSelect = styled(ArrowGrey)`
  position: absolute;
  right: 10px;
  transform: ${props => (props.position === "UP" ? "rotate(180deg)" : "rotate(0deg)")};
  top: 15px;
`;

const DisabledText = styled.span`
  color: #ddd;
  float: left;
`;

const Box = styled.label`
  width: 100%;
  cursor: pointer;
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 90px;
`;
