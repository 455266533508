import React, { useState } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "./../Modal";
import { Btn } from "./../ButtonDefault";
import { __ } from "../../lib/translate";
import { boldConverter } from "../../lib/componentifyConverters";
import RadioButton from "../RadioButton";
import { UPLOAD_OPTIONS } from "./constants";
import InputStyledCheckbox from "@pcloud/web-utilities/dist/resources/components/InputStyledCheckbox";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Text, Title } from "./sharedStyles";

const UploadOptionsModal = () => {
  const {
    uploadQueueExisting,
    handleUploadOptionsModalYesClick,
    handleUploadOptionsModalClose
  } = useUploadContext();
  const [selectedValueExistingItems, setSelectedValueExistingItems] = useState(UPLOAD_OPTIONS.REPLACE);
  const [shouldApplyForAll, setShouldApplyForAll] = useState(false);

  const firstItem = uploadQueueExisting[0];
  if (!firstItem) {
    return null;
  }
  const { name, id, optionsModalId } = firstItem;

  const handleChange = (event) => {
    setSelectedValueExistingItems(event.target.value);
  };

  return (
    <Modal align="top" animate shouldCloseOnOverlayClick={false} onClose={() => handleUploadOptionsModalClose(id)} opened={uploadQueueExisting.length}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={() => handleUploadOptionsModalClose(id)} />
        <Content>
          <React.Fragment>
            <StyledBody>
              <Title>{__('upload_manager_options_title', 'Upload options')}</Title>
              <StyledText>
                <Componentify
                  text={__('upload_manager_options_description', '<b>%itemname%</b> already exist in this folder. To continue select one of the following options.').replace('%itemname%', name)}
                  converters={[boldConverter]}
                />
              </StyledText>

              <RadioButton
                label={__('upload_manager_options_replace', 'Replace existing items')}
                sublabel={__('upload_manager_options_replace_description', 'Replacing the items won’t change sharing settings.')}
                value={UPLOAD_OPTIONS.REPLACE}
                onChange={handleChange}
                checked={selectedValueExistingItems === UPLOAD_OPTIONS.REPLACE}
                defaultChecked
              />
              <RadioButton
                label={__('upload_manager_options_duplicate', 'Duplicate')}
                value={UPLOAD_OPTIONS.DUPLICATE}
                onChange={handleChange}
                checked={selectedValueExistingItems === UPLOAD_OPTIONS.DUPLICATE}
              />
              <RadioButton
                label={__('upload_manager_options_skip', 'Skip')}
                value={UPLOAD_OPTIONS.SKIP}
                onChange={handleChange}
                checked={selectedValueExistingItems === UPLOAD_OPTIONS.SKIP}
              />
              <SeparatingLine />
              <InputStyledCheckbox
                checked={shouldApplyForAll}
                onChange={(e) => setShouldApplyForAll(e.target.checked)}
                text={__('Apply for all', 'Apply for all')}
              />
            </StyledBody>
            <StyledButtonWrapper>
              <Btn inline color="lightgray4" onClick={() => handleUploadOptionsModalClose(id)}>
                {__("Cancel")}
              </Btn>
              <Btn inline color="cyan" onClick={() => handleUploadOptionsModalYesClick(selectedValueExistingItems, shouldApplyForAll, id, optionsModalId)}>
                {__("Continue")}
              </Btn>
            </StyledButtonWrapper>
          </React.Fragment>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default UploadOptionsModal;

const StyledBody = styled(Body)`
  align-items: flex-start;
`
const StyledButtonWrapper = styled(ButtonWrapper)`
  margin-top: 16px;
`

const SeparatingLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #E4E5E7;
  margin-bottom: 24px;
`

const StyledText = styled(Text)`
  > :first-child {
    word-break: break-all;
  }
`