// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Btn } from "../ButtonDefault";
import * as Style from "./styledComponents";
import InputSelect from "../InputSelect";
import InputStyledCheckbox from "../InputStyledCheckbox";

import ArrowGrey from "../../../root/img/svg/arrow-grey.svg";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { prepUrl } from "../../api/utils";
import { errorKeys } from "../../lib/errors";

const variantImageMapDefault = [
  {
    value: "0",
    text: "Normal Desktop",
    disabled: true
  },
  {
    value: "800 x 600",
    text: "800 x 600"
  },
  {
    value: "1024 x 768",
    text: "1024 x 768"
  },
  {
    value: "1280 x 1024",
    text: "1280 x 1024"
  },
  {
    value: "1400 x 1050",
    text: "1400 x 1050"
  },
  {
    value: "1",
    text: "Wide Desktop",
    disabled: true
  },
  {
    value: "1024 x 600",
    text: "1024 x 600"
  },
  {
    value: "1280 x 800",
    text: "1280 x 800"
  },
  {
    value: "1366 x 768",
    text: "1366 x 768"
  },
  {
    value: "1400 x 900",
    text: "1400 x 900"
  },
  {
    value: "1600 x 900",
    text: "1600 x 900"
  },
  {
    value: "1680 x 1050",
    text: "1680 x 1050"
  },
  {
    value: "1920 x 1080",
    text: "1920 x 1080"
  },
  {
    value: "1920 x 1200",
    text: "1920 x 1200"
  },
  {
    value: "2",
    text: "Mobile / Tablet",
    disabled: true
  },
  {
    value: "320 x 480",
    text: "320 x 480"
  },
  {
    value: "480 x 800",
    text: "480 x 800"
  },
  {
    value: "540 x 960",
    text: "540 x 960"
  },
  {
    value: "720 x 1280",
    text: "720 x 1280"
  },
  {
    value: "1080 x 1920",
    text: "1080 x 1920"
  },
  {
    value: "640 x 960",
    text: "640 x 960"
  },
  {
    value: "640 x 1136",
    text: "640 x 1136"
  },
  {
    value: "3",
    text: "Banners",
    disabled: true
  },
  {
    value: "120 x 60",
    text: "120 x 60"
  },
  {
    value: "120 x 90",
    text: "120 x 90"
  },
  {
    value: "120 x 240",
    text: "120 x 240"
  },
  {
    value: "120 x 900",
    text: "120 x 900"
  },
  {
    value: "125 x 125",
    text: "125 x 125"
  },
  {
    value: "160 x 600",
    text: "160 x 600"
  },
  {
    value: "180 x 90",
    text: "180 x 90"
  },
  {
    value: "180 x 150",
    text: "180 x 150"
  },
  {
    value: "234 x 60",
    text: "234 x 60"
  },
  {
    value: "240 x 400",
    text: "240 x 400"
  },
  {
    value: "250 x 250",
    text: "250 x 250"
  },
  {
    value: "300 x 100",
    text: "300 x 100"
  },
  {
    value: "300 x 250",
    text: "300 x 250"
  },
  {
    value: "300 x 600",
    text: "300 x 600"
  },
  {
    value: "336 x 280",
    text: "336 x 280"
  },
  {
    value: "468 x 60",
    text: "468 x 60"
  },
  {
    value: "720 x 300",
    text: "720 x 300"
  },
  {
    value: "728 x 90",
    text: "728 x 90"
  }
];

type Props = {
  data: Array<any>,
  modalContainer?: any
};

const DownloadImageSizesModal = ({ data, modalContainer  }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [variantSelected, setVariantSelected] = useState(variantImageMapDefault[1].value);
  const [variantImageMap, setVariantImageMap] = useState([...variantImageMapDefault]);
  const [shouldCropImage, setShouldCropImage] = useState(false);

  const { width, height, revisionid, fileid } = data;

  useEffect(() => {
    if (width && height) {
      const newArr = variantImageMapDefault.filter(option => {
        let size = option.value.split("x").map(item => {
          return item.trim();
        });

        return (size[0] < width && size[1] < height) || option.disabled;
      });

      setVariantImageMap(newArr);
    }
  }, [data]); //[data]

  const onCloseClick = () => {
    setIsOpened(false);
  };

  const onDownloadConvertedClick = () => {
    let size = variantSelected.split("x").map(item => {
      return item.trim();
    });

    let toProper = num => {
      if (num % 4 || num % 5) {
        do {
          num--;
        } while (num % 4 || num % 5);
      }
      return num;
    };

    let params = {
      fileid: fileid,
      size: toProper(size[0]) + "x" + toProper(size[1]),
      forcedownload: 1
    };

    if (revisionid) {
      params.revisionid = revisionid;
    }

    if (shouldCropImage) {
      params.crop = 1;
    }

    apiMethod(
      "getthumblink",
      params,
      ret => {
        window.open(prepUrl(ret));

        HFN.message(__("converted_file","File has been converted."), "success", false, true, false, false);
        setIsOpened(false);
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
          throw new Error(error);
        }
      },
      { async: false }
    );
  };

  const renderContent = () => {
    let originalSize = width && height ? width + " x " + height : "";

    return (
      <React.Fragment>
        <Body>
          <Header>{__("Download Image Sizes")}</Header>
          <Style.Container style={{ padding: "5px 30px 0px 30px", display: "flex" }}>
            {originalSize ? (
              <WrapperBox>
                <Style.Label>{__("Original size")}</Style.Label>
                <Box style={{ marginRight: "8px" }}>{originalSize}</Box>
              </WrapperBox>
            ) : null}
            <WrapperBox>
              <Style.Label>{__("Fit size")}</Style.Label>
              <SelectBox className="selectBox">
                <InputSelect
                  style={{ paddingRight: "35px" }}
                  name="download-converts"
                  options={variantImageMap}
                  value={variantSelected}
                  onChange={e => setVariantSelected(e.target.value)}
                />
              </SelectBox>
            </WrapperBox>
          </Style.Container>
        </Body>
        <Style.CheckboxWrapper>
          <Style.CheckboxLabel for="crop">
            <InputStyledCheckbox
              id="crop"
              name="crop"
              size="small"
              checked={shouldCropImage}
              onChange={e => setShouldCropImage(e.target.checked)}
            />
            <Style.CheckboxLabelText>
              {__("Crop parts of the image to exactly fit the size.")}
            </Style.CheckboxLabelText>
          </Style.CheckboxLabel>
        </Style.CheckboxWrapper>
        <Style.Footer>
          <Btn style={{ marginRight: "5px" }} color="lightgray4" onClick={onCloseClick}>
            {__("Close")}
          </Btn>
          <Btn style={{ marginLeft: "5px" }} color="cyan" onClick={onDownloadConvertedClick}>
            {__("Download")}
          </Btn>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" opened={isOpened} onClose={onCloseClick} container={modalContainer}>
      <OvalModalWrap>
        <CloseModalIcon onClick={onCloseClick} />
        <Content>{renderContent()}</Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default DownloadImageSizesModal;

const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;

const SelectBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const ArrowIconSelect = styled(ArrowGrey)`
  position: absolute;
  right: 10px;
  transform: ${props => (props.position === "UP" ? "rotate(180deg)" : "rotate(0deg)")};
  top: 15px;
`;

const WrapperBox = styled.div`
  display: block;
  max-width: 100%;
  width: 100%;
  padding: 11px 0;
`;

const Box = styled.div`
  font-size: 15px;
  font-weight: normal;
  border-radius: 6px;
  border: 1px solid #eee;
  text-align: start;
  line-height: 24px;
  padding: 9px;
`;
