import React, { useRef, useState } from 'react'
import * as CommonStyle from "../../../components/CommonStyledSettings"
import styled, { css } from 'styled-components'
import { __ } from '../../../lib/translate'
import { useSelector } from 'react-redux'
import hashManager from '../../../lib/hashManager'
import { userInitials } from '../../../lib/user-utils'
import { renderQuota } from '@pcloud/web-utilities/dist/plans/utils.plans'
import ProgressSmart from '../../../components/ProgressSmart'
import { calculateProgress } from '../../../components/LeftMenu/helpers'
import { prepDt } from '../../../lib'
import InviteUsersToTeamsModal from '../../../components/Modals/Business/InviteUsersToTeamsModal'
import { Menu, MenuItem } from '@szhsin/react-menu'
import { actionTitles, moreOptionsActions } from './constants'
import EditUserModal from '../../../components/Modals/Business/EditUserModal'
import ResetMemberPassModal from '../../../components/Modals/Business/ResetMemberPassModal'
import ActivateUserModal from '../../../components/Modals/Business/ActivateUserModal'
import DeactivateMemberModal from '../../../components/Modals/Business/DeactivateMemberModal'
import { apiMethodUrl, prepUrl } from '../../../api/utils'
import apiMethod from '../../../api/apiMethod'
import ModalCallCenter from "../../../components/ContentView/UI/ModalCallCenter";
import useWindowDimensions from '../../../lib/hooks/useWindowDimensions'
import { MOBILE_WIDTH_BREAKPOINT } from '../../../config/constants'
import { buildUserinfo } from '../utils'
import { MenuIcon, MenuLabel } from "../../../components/ContentView/UI/Style";
import Loader from "../../../components/Loader";

const Info = () => {
  const [isInviteUserToTeamsModalOpen, setIsInviteUserToTeamsModalOpen] = useState(false)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [isResetMemberPassOpen, setIsResetMemberPassOpen] = useState(false)
  const [isActivateUserModalOpen, setIsActivateUserModalOpen] = useState(false)
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] = useState(false)
  const [viewerPopup, setViewerPopup] = useState(null)
  const profileImageInputRef = useRef(null)
  const business = useSelector(({ business }) => business)
  const userId = hashManager.getState('id')
  const user = business?.users?.find((user) => user.id === Number(userId))
  const { bodyWidth } = useWindowDimensions();
  const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const isOwnProfile = account.id === parseInt(userId);
  const canUserManage = account.permissions.user_manage;
  const teamLength = user ? user.teams.length - 1 : 0;

  // Function to handle the file input's change event
  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    let data = new FormData()
    data.append('file', file);

    const url = apiMethodUrl(null, 'account_upload_avatar', { userid: userId });
    const xhr = new XMLHttpRequest();

    // Set up the request
    xhr.open("POST", url, true);

    // Handle the response
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        const response = JSON.parse(xhr.responseText);
        if (response.result) {
          HFN.message(response.error, 'error');
        } else {
          HFN.message(__('Avatar Uploaded.'));
          // PCB.refreshAll();
          // editUser
        }
      } else {
        HFN.message(`Error uploading avatar: ${xhr.statusText}`);
      }
    };

    // Handle network errors
    xhr.onerror = function () {
      console.error("Request failed.");
    };

    // Send the request with the file data
    xhr.send(data);
  };

  const onActionClick = (actionTitle) => {
    switch (actionTitle) {
      case actionTitles.EDIT:
        setIsEditUserModalOpen(true)
        break;
      case actionTitles.LOG:
        hashManager.pushState({ page: "b_logs", userids: user.id });
        break;
      case actionTitles.RESET_PASSWORD:
        setIsResetMemberPassOpen(true)
        break;
      case actionTitles.VIEW_AS:
        if (viewerPopup) {
          viewerPopup.close();
        }
        apiMethod('account_viewtoken', { userid: user.id }, (ret) => {
          setViewerPopup(window.open(location.protocol + '//' + location.host + '/#page=filemanager&authtoken=' + ret.token + '&viewas=1&locationid=' + HFN.apiConfig.locationid, 'View as: "' + buildUserinfo(user) + '"'))
        }, { async: false });
        break;
      case actionTitles.LOGIN_AS:
        if (viewerPopup) {
          viewerPopup.close();
        }
        apiMethod('account_logintoken', { userid: user.id }, (ret) => {
          setViewerPopup(window.open(location.protocol + '//' + location.host + '/#page=filemanager&authtoken=' + ret.token + '&viewas=1&loginas=1&locationid=' + HFN.apiConfig.locationid, 'Login as: "' + buildUserinfo(user) + '"'))
        }, { async: false });
        break;
      case actionTitles.DEACTIVATE:
        if (user.active && !user.frozen)
          setIsDeactivateUserModalOpen(true)
        else {
          setIsActivateUserModalOpen(true)
        }
        break;
      case actionTitles.ADD_PROFILE_PICTURE:
        clickFileInput()
        break;
      case actionTitles.EDIT_PROFILE_PICTURE:
        clickFileInput()
        break;
      default:
        break;
    }
  }

  const clickFileInput = () => {
    profileImageInputRef.current.click()
  }

  if (!user) {
    return <Loader />;
  }

  return (
    <>
      <InviteUsersToTeamsModal user={user} isOpen={isInviteUserToTeamsModalOpen} handleClose={() => setIsInviteUserToTeamsModalOpen(false)} />
      <EditUserModal userId={user.id} fName={user.firstname} lName={user.lastname} currPosition={user.position} isOpen={isEditUserModalOpen} handleClose={() => setIsEditUserModalOpen(false)} />
      <ResetMemberPassModal memberId={user.id} memberFirstName={user.firstname} memberLastName={user.lastname} memberEmail={user.email} isOpen={isResetMemberPassOpen} handleClose={() => setIsResetMemberPassOpen(false)} />
      <ActivateUserModal memberId={user.id} memberFirstName={user.firstname} memberLastName={user.lastname} memberEmail={user.email} isOpen={isActivateUserModalOpen} handleClose={() => setIsActivateUserModalOpen(false)} />
      <DeactivateMemberModal memberId={user.id} memberFirstName={user.firstname} memberLastName={user.lastname} memberEmail={user.email} isOpen={isDeactivateUserModalOpen} handleClose={() => setIsDeactivateUserModalOpen(false)} />
      <CommonStyle.Row>
        <div>
          <InitialsContainer>
            <ProfileInitials
              $isMobile={isMobile}>
              {user.avatar && !user.avatar.isdefault ? <ProfileImage src={prepUrl(user.avatar)} /> : userInitials(user).trim()}
              {(canUserManage || isOwnProfile) && (
                <>
                  <input
                    type="file"
                    ref={profileImageInputRef}
                    style={{ display: 'none' }}
                    onChange={handleProfileImageChange}
                  />
                  <EditProfileContainer
                    $isMobile={isMobile}
                    onClick={clickFileInput}
                    data-tooltip-id="edit-image-tooltip"
                    data-tooltip-content={user.avatar ? __(actionTitles.EDIT_PROFILE_PICTURE) : __(actionTitles.ADD_PROFILE_PICTURE)}
                  >
                    <i className={`fa-solid fa-pen ${isMobile ? 'baseIcon' : 'bigIcon'}`} />
                  </EditProfileContainer>
                </>
              )}
            </ProfileInitials>
            <div>
              <UserName>{user.firstname + " " + user.lastname}</UserName>
              <CommonStyle.Description>{user.email}</CommonStyle.Description>
              <CommonStyle.Description>{__("Position")}: {(user.position && user.position !== "0" ? user.position : __("Not defined", "Not defined"))} </CommonStyle.Description>
            </div>
          </InitialsContainer>
        </div >
        {(canUserManage || isOwnProfile) && (
          <Menu
            align="end"
            transition
            menuButton={isMobile ? <i className='smallIcon fa-regular fa-ellipsis-vertical' /> : <CommonStyle.Button>{__('More Options')}</CommonStyle.Button>}
          >
            {moreOptionsActions.map((action) => {
              if (!canUserManage && ![actionTitles.EDIT, actionTitles.EDIT_PROFILE_PICTURE].includes(action.title)) {
                return;
              }
              if (action.title === actionTitles.EDIT_PROFILE_PICTURE && !user.avatar) {
                return null
              } else if (action.title === actionTitles.ADD_PROFILE_PICTURE && user.avatar) {
                return null
              }
              return (
                <StyledMenuItem
                  key={action.title}
                  onClick={() => { onActionClick(action.title) }}
                >
                  <MenuIcon><i className={action.icon} /></MenuIcon>
                  <MenuLabel>{__(action.title)}</MenuLabel>
                </StyledMenuItem>
              )
            })}
          </Menu>
        )}
      </CommonStyle.Row >
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('Storage')}</CommonStyle.Title>
          <UsedStorage>
            {__('used_quota').replace('%used%', renderQuota(user.usedquota)).replace('%total%', renderQuota(user.quota))}
          </UsedStorage>
          <ProgressSmart
            fractions={calculateProgress(user.quota, user.usedquota)}
            separator={{ width: "2px", backgroundColor: "#fff" }}
            progressHeight="4px"
          />
        </div>
      </CommonStyle.Row>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('Registration', 'Registration')}</CommonStyle.Title>
          <CommonStyle.Description>{prepDt(user.assigned)} </CommonStyle.Description>
        </div>
      </CommonStyle.Row>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('Last Activity')}</CommonStyle.Title>
          <CommonStyle.Description>{prepDt(user.lastactivity)} </CommonStyle.Description>
        </div>
      </CommonStyle.Row>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('Membership', 'Membership')}</CommonStyle.Title>
          <CommonStyle.Description>
            {teamLength === 0 ? __("user_no_team", "User is not a member of a team.") : __("BA_user_has_member", "User is a member of %teams% teams.").replace("%teams%", teamLength)}
          </CommonStyle.Description>
        </div>
        {canUserManage && (
          <CommonStyle.Button onClick={() => setIsInviteUserToTeamsModalOpen(true)}>{__('Add User to Teams')}</CommonStyle.Button>
        )}
      </CommonStyle.Row>
    </>
  )
}

export default Info

const ProfileSharedContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 38px;
  
  ${({ $isMobile }) => $isMobile && css`
    font-size: 13px;
    width: 38px;
    height: 38px;
  `}
`

const EditProfileContainer = styled(ProfileSharedContainer)`
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.55) 100%);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  position: absolute;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Ensures it's inaccessible when not visible */
  transition: opacity 0.5s ease, visibility 0.5s ease;
  cursor: pointer;
`;

const ProfileInitials = styled(ProfileSharedContainer)`
  background: var(--color-primary-700);
  flex-shrink: 0;
  color: var(--color-base-white);
  position: relative;
  margin: auto 0;

  &:hover {
    ${EditProfileContainer} {
      opacity: 1; /* Gradually become visible */
      visibility: visible; /* Makes it accessible after animation starts */
    }
  }
`;


const InitialsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const UserName = styled(CommonStyle.Title)`
  font-size: var(--font-size-18);
  line-height: 32px;
`

const UsedStorage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  color: var(--color-grey-600);
  font-size: var(--font-size-12);
  min-width: 200px;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
`

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`