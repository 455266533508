// @flow

import React, { useEffect } from "react";
import styled from "styled-components";

import { __ } from "../../lib/translate";

import {
  ModalContainer,
  ModalHeader,
  ModalButtonsWrapper,
  ModalContent
} from "../../components/Modals/ModalsStyled";
import { Button } from "../../components/ButtonDefault";
import { Label } from "./SharedFamilyComponents";
import type { Member, Invite } from "./types";

type Props = {
  title: string,
  currentItem: Member | Invite,
  onClose: () => void
};

const CopyLinkModal = ({
                         title = "Copy link",
                         currentItem = "",
                         onClose = () => {
                         }
                       }: Props) => {

  useEffect(() => {
    copyToClipboard();
  }, []);

  const copyToClipboard = () => {
    const inviteLinkInput = document.getElementById("inviteLink");
    inviteLinkInput && inviteLinkInput.select();
    document.execCommand("copy");
    HFN.message(__("family_invitation_link_copy"));
  };

  const { invitelink } = currentItem;

  return (
    <ModalContainer className="with-gap">
      <ModalHeader>{__(title)}</ModalHeader>
      <ModalContent>
        <Label>{__("Link")}</Label>
        <InviteLink
          id="inviteLink"
          type="text"
          name="inviteLink"
          value={invitelink}
          onClick={copyToClipboard}
          readOnly
        />
      </ModalContent>
      <ModalButtonsWrapper>
        <Button
          color="lightgray4"
          styled="ModalCancelButton"
          onClick={onClose}
        >
          {__("Close")}
        </Button>
        <Button
          styled="ModalPrimaryButton"
          onClick={copyToClipboard}
        >
          {__("shared_links_settings_copy_link", "Copy Link")}
        </Button>
      </ModalButtonsWrapper>
    </ModalContainer>
  );
};

export default CopyLinkModal;

const InviteLink = styled.input`
    width: 100%;
    height: 32px;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 5px;
    border: solid 1px #61d2df;
    background-color: #ffffff;
    box-sizing: border-box;
`;
