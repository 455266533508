import React, { useMemo } from "react";
import { ChipCloseIcon, ChipIcon, ChipLabel, ChipWrapper } from "../styles";
import { strFit, strFitMail } from "../../../lib";

const Chip = (props) => {
  const { option, onDelete, defaultIcon, validationRules, ...other } = props;
  const errorData = useMemo(() => {
    let status = false;
    let message = '';
    if (Array.isArray(validationRules) && validationRules.length) {
      for (let rule of validationRules) {
        if (rule.check && !rule?.check(option.label, option)) {
          status = true;
          message = rule.message || "";
        }
      }
    }
    return {
      status,
      message
    };
  });

  const showValue = () => {
    if (validateEmail(option.label)) {
      return strFitMail(option.label, 40);
    }

    return strFit(option.label, 40);
  }

  return (
    <ChipWrapper
      data-tooltip-id="multi-select-suggester-tooltip"
      data-tooltip-content={
        errorData.message ?
          errorData.message :
          option.label.length > 40 ? option.label : null
      }
      $hasError={errorData.status}
      {...other}
    >
      {!!option.iconContent || !!option.icon ? (
        <ChipIcon>
          {!!option.iconContent && option.iconContent}
          {!!option.icon && <i className={option.icon} />}
        </ChipIcon>
      ) : !!defaultIcon ? (
        <ChipIcon>
          <i className={defaultIcon} />
        </ChipIcon>
      ) : null}
      <ChipLabel>{showValue()}</ChipLabel>
      <ChipCloseIcon className="fa-regular fa-close" onClick={onDelete} />
    </ChipWrapper>
  );
};

export default Chip;
