import { useState, useEffect } from 'react';

const initialErrorState = {
  hint: false,
  password: false,
  repeatPassword: false
};

const usePasswordValidation = (passwordValue, repeatPasswordValue, hintValue, requirementsAreMet) => {
  const [errors, setErrors] = useState(initialErrorState);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);

  useEffect(() => {
    const hintCheck = hintValue.trim().indexOf(passwordValue.trim()) !== -1;

    if (
      passwordValue.length < 8 ||
      passwordValue !== repeatPasswordValue ||
      !requirementsAreMet ||
      hintCheck
    ) {
      setErrors({
        ...errors,
        hint:
          hintCheck && passwordValue.length > 0
            ? __(
              "Your Hint must not contain the Passphrase.",
              "Your Hint must not contain the Crypto Pass"
            )
            : false,
        repeatPassword:
          passwordValue !== repeatPasswordValue && repeatPasswordValue.length > 0
            ? __(
              "both passwords must be exact match.",
              "Both passwords must be exact match."
            )
            : false
      });

      setDisabledSubmitBtn(true);
    } else {
      setDisabledSubmitBtn(false);
      setErrors(initialErrorState);
    }
  }, [passwordValue, repeatPasswordValue, hintValue, requirementsAreMet, errors, initialErrorState]);

  return { errors, disabledSubmitBtn };
};

export default usePasswordValidation;
