// @flow

import React, { useEffect, useState } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import * as Style from "./styledComponents";
import { Btn } from "../ButtonDefault";
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";

import { __ } from "../../lib/translate";
import { boldConverter } from "../../lib/componentifyConverters";

type Props = {
  data: any,
  modalContainer?: any
};

const DeleteItemModal = ({ data, modalContainer }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [loading, setLoading] = useState(false);
  const [wholeBatch, setWholeBatch] = useState({});
  const [description, setDescription] = useState("");
  const [loadingModal, setLoadingModal] = useState(true);
  const [encrypted, setEncrypted] = useState(false);

  useEffect(() => {
    let descMessage = "";
    const hasBackup = data.find(item => HFN.config.isBackup(item));
    const isEncrypted = data.find(item => item.encrypted);
    setEncrypted(isEncrypted);

    if (data.length > 1) {
      if (isEncrypted) {
        descMessage = __(
          "crypto_delete_items_text",
          "Are you sure you want to delete these items from the Crypto folder? You won't be able to recover them, afterwards."
        );
      } else if (hasBackup) {
        descMessage = __(
          "delete_modal_backup_desc1",
          "One or more of the selected items are part of a backup. If you proceed, they will be deleted from <b>your devices</b> and moved to the pCloud Trash."
        );
      } else {
        descMessage = __(
          "delete_modal_desc1",
          "Are you sure you want to delete these items from your account? You can find it later in the pCloud Trash."
        );
      }
    } else {
      if (isEncrypted) {
        if (data[0].isfolder) {
          descMessage = __(
            "crypto_delete_folder_text",
            "Are you sure you want to delete this folder from the Crypto folder? You won't be able to recover it, afterwards."
          );
        } else {
          descMessage = __(
            "crypto_delete_file_text",
            "Are you sure you want to delete this file from the Crypto folder? You won't be able to recover it, afterwards."
          );
        }
      } else if (hasBackup) {
        if (data[0].isfolder) {
          descMessage = __(
            "delete_modal_backup_desc_folder",
            "This folder is part of one or more backups. If you proceed, this folder and its contents will be deleted from <b>your devices</b> and moved to the pCloud Trash."
          );
        } else {
          descMessage = __(
            "delete_modal_backup_desc_file",
            "This file is part of a backup. If you proceed, it will be deleted from <b>your devices</b> and moved to the pCloud Trash."
          );
        }
      } else {
        if (data[0].isfolder) {
          descMessage = __(
            "delete_modal_desc",
            "Are you sure you want to delete this folder from your account? You can find it later in the pCloud Trash."
          );
        } else {
          descMessage = __(
            "delete_modal_desc_file",
            "Are you sure you want to delete this file from your account? You can find it later in the pCloud Trash."
          );
        }
      }
    }
    setDescription(descMessage);
    setLoadingModal(false);
  }, [data]);

  useEffect(() => {
    const wholeBatch = new batchApiCall({
      errorCallback: function(ret, opts) {
        console.log("in error callback: ", ret, opts);

        if (ret.result === 2123) {
          HFN.message(
            __("error_2123", "There are active business shares for this folder."),
            "error"
          );
        } else {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
        }

        wholeBatch.abort();
        setLoading(false);
        setIsOpened(false);
      }
    });
    setWholeBatch(wholeBatch);
  }, []);

  const onCancelClick = () => {
    if (loading) {
      return;
    }
    setIsOpened(false);
  };

  const onDeleteClick = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setTimeout(() => {
      const delitem = function(item) {
        if (!item.isfolder)
          wholeBatch.addCall("deletefile", { fileid: item.fileid, name: item.name });
        else {
          wholeBatch.addCall("deletefolderrecursive", { folderid: item.folderid, name: item.name });
        }
      };
      for (let n = 0; n < data.length; ++n) delitem(data[n]);

      wholeBatch.execute(function(ret) {
        HFN.message(__("Items are deleted."));
        setLoading(false);
        setIsOpened(false);

        document.dispatchEvent(new CustomEvent("more-options-click", { detail: { action: "delete", data: ret[0].ret.metadata } }));
      });
    }, 500);
  };

  const renderContent = () => {
    const title = encrypted ? __("Important") : __("delete_modal_header", "	Move to the Trash");

    return (
      <React.Fragment>
        <Body>
          <Style.Header>{loading ? __("action_deleting", "Deleting") : title}</Style.Header>
          {!loading ? (
            <Text>
              <Componentify text={description} converters={[boldConverter]} />
            </Text>
          ) : (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </Body>
        <Style.Footer>
          <Btn
            style={{ marginRight: "5px" }}
            color="lightgray4"
            disabled={loading}
            onClick={onCancelClick}
          >
            {__("Cancel")}
          </Btn>
          <Btn
            style={{ marginLeft: "5px" }}
            color="red"
            disabled={loading}
            onClick={onDeleteClick}
          >
            {__("Delete")}
          </Btn>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal
      align="top"
      onClose={() => setIsOpened(false)}
      opened={isOpened}
      container={modalContainer}
    >
      <OvalModalWrap>
        <CloseModalIcon onClick={() => setIsOpened(false)} />
        <Content>{loadingModal ? <Loader /> : renderContent()}</Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeleteItemModal;

const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

const Text = styled.div`
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: center;
  color: #111111;
  /* margin-bottom: 35px; */
  max-width: 450px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* min-height: 120px; */
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 90px;
`;
