import React, { useState } from "react";

import { __ } from "../../../../../lib/translate";
import { Btn } from "../../../../ButtonDefault";
import InputText from "../../../../InputText";
import {
    FlexWrapper,
    LinkButton,
    PlaylistControlsWrapper,
    PlaylistInputWrapper,
    PlaylistLabelWrapper
} from "../../../userinterface/styles";
import usePlayerContext from "../../../hooks/usePlayerContext";
import { createPortal } from "react-dom";
import { getPlayerState } from "../../../helpers";

const SavePlaylistPanel = () => {
    const {
        onCreatePlaylist,
        onCloseAllOverlays,
        additionalClass,
        playerVariant
    } = usePlayerContext();

    const [name, setName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handlePlaylistSave = () => {
        if (name.trim() !== "") {
            setIsLoading(true);
            onCreatePlaylist(name.trim(), () => {
                setIsLoading(false);
                onCloseAllOverlays();
            });

            if (typeof gtag === "function") {
                gtag("event", "audio_player_click", {
                    action: "save as playlist",
                    state: getPlayerState(playerVariant)
                });
            }
        }
    };

    const container = document.getElementById("mainContainer");

    if (!container) {
        return null;
    }

    return (
        <>
            {createPortal(
                <PlaylistControlsWrapper className={additionalClass}>
                    <FlexWrapper>
                        <PlaylistLabelWrapper>
                            {__("Save as playlist")}
                        </PlaylistLabelWrapper>
                        <LinkButton onClick={onCloseAllOverlays}>
                            {__("Close")}
                        </LinkButton>
                    </FlexWrapper>
                    <FlexWrapper>
                        <PlaylistInputWrapper>
                            <InputText
                                name="name"
                                placeholder={__("Playlist name")}
                                value={name}
                                max={100}
                                onChange={(e) => {
                                    if (e.target.value.length <= 100) {
                                        setName(e.target.value);
                                    }
                                }}
                            />
                        </PlaylistInputWrapper>
                        <Btn
                            size="big"
                            style={{ width: 150 }}
                            onClick={handlePlaylistSave}
                            loading={isLoading}
                            disabled={name.trim().length === 0 || isLoading}
                        >
                            {__("Create")}
                        </Btn>
                    </FlexWrapper>
                </PlaylistControlsWrapper>,
                container
            )}
        </>
    );
};

export default SavePlaylistPanel;
