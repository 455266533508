import React from "react";
import { MuteButton, useMediaState } from '@vidstack/react';
import { VolumeIcon, VolumeLowIcon, MuteIcon } from "../../icons";

const MuteBtn = ({
  unmutedHidden = false,
  pressedLabel = "",
}) => {

  const isMuted = useMediaState('muted');

  return (
    unmutedHidden && !isMuted ? null :
    <div className="mute-button">
      <MuteButton className={`plyr__controls__item plyr__control${pressedLabel !== "" ? ` with-label` : ``}`} data-no-label data-plyr="mute">
        <MuteIcon className="mute-icon icon--pressed" />
        {/* <VolumeLowIcon className="volume-low-icon icon--not-pressed" /> */}
        <VolumeLowIcon className="volume-low-icon icon--not-pressed" />
        <VolumeIcon className="volume-high-icon icon--not-pressed" />
        {/* <span className="label--pressed plyr__tooltip">Unmute</span>
        <span className="label--not-pressed plyr__tooltip">Mute</span> */}
        {pressedLabel !== "" ? 
          <span className="label--pressed mute-btn-label">{pressedLabel}</span>
        : null}
      </MuteButton>
    </div>
  );
};

export default MuteBtn;