// @flow

import React, { PureComponent, useEffect } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import { spanClassTagConverter } from "../../lib/componentifyConverters";

import { Btn } from "../../components/ButtonDefault";
import { Container, Header, Message, Footer, Title } from "./SharedFamilyComponents";
import type { Member, Invite } from "./types";

type Props = {
  currentItem: Member | Invite,
  onRemoveMemberButton: () => void,
  onSuccess: () => void,
  onClose: () => void
};

const RemoveMemberModal = ({
  currentItem = null,
  onRemoveMemberButton = () => { },
  onSuccess = () => { },
  onClose = () => { }
}: Props) => {

  useEffect(() => {
    document.addEventListener("keyup", onEnter);

    return () => {
      document.removeEventListener("keyup", onEnter);
    }
  }, [])

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      onRemove();
    }
  }

  const onRemove = () => {
    const { userid } = currentItem;

    onRemoveMemberButton(userid);
    onSuccess();
  }

  const { email } = currentItem;
  const componentifyText = __("family_remove_message", "", {
    emailattr: `class='member-email'`,
    email: email
  });

  return (
    <Container>
      <Header>{__("family_remove")}</Header>
      <Message>
        <RemoveTitle>
          <Componentify
            text={componentifyText}
            converters={[spanClassTagConverter]}
          />
        </RemoveTitle>
      </Message>
      <Footer>
        <Btn
          color="lightgray4"
          style={{
            marginRight: "5px"
          }}
          onClick={onClose}
        >
          {__("No")}
        </Btn>
        <Btn
          color="red"
          style={{
            marginLeft: "5px"
          }}
          onClick={onRemove}
        >
          {__("Yes")}
        </Btn>
      </Footer>
    </Container>
  );
}

export default RemoveMemberModal;

const RemoveTitle = styled(Title)`
  margin: 0;
  color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  .member-email {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    margin: 0 5px;
  }
`;
