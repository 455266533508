import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import { Button } from "../../ButtonDefault";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { strongConverter, brTagConverter } from "../../../lib/componentifyConverters";
import { useDispatch, useSelector } from "react-redux";
import { editUser, loadAccountInfo } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";
import { getAccountInfoCall } from "../../../api/business";

const DeactivateMemberModal = ({
  memberId = 0,
  shouldDelete = false,
  name,
  email,
  resetModalAction = () => {},
  isOpen = false,
  handleClose = () => {}
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const businessMembers = useSelector(({ business }) => business.users);
  const accountInfo = useSelector(({ business }) => business.accountInfo);
  const currenMember = businessMembers.find((member) => member.id === parseInt(memberId)) || {};
  const dispatch = useDispatch();

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        removeUser();
      }
    },
    [removeUser]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const removeUser = useCallback(() => {
    if (!showWarning && shouldDelete) {
      setShowWarning(true);
      return;
    }
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const params = {
      userid: memberId
    };

    if (shouldDelete) {
      params.active = 0;
    } else {
      params.frozen = 1;
    }

    apiMethod(
      "account_usermodify",
      params,
      () => {
        if (shouldDelete) {
          getAccountInfoCall((accountInfoResponse) => {
            delete accountInfoResponse.result;
            accountInfoResponse.can_invite_account = accountInfo.can_invite_account;
            dispatch(loadAccountInfo(accountInfoResponse));
            dispatch(editUser({ ...currenMember, active: params.active, frozen: params.frozen }));

            const owner = accountInfoResponse?.account?.owner;
            if (owner) {
              dispatch(editUser({
                id: owner.id,
                quota: owner.quota,
                usedquota: owner.usedquota,
              }));
            }
            HFN.message(__("User Deleted."));
          });
        } else {
          dispatch(editUser({ ...currenMember, active: params.active, frozen: params.frozen }));
          HFN.message(__("User Deactivated."));
        }
        setIsLoading(false);
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  });

  const renderWarningMessage = () => {
    return (
      <WarningText>
        <Componentify text={__("Delete_business_user_warning")} converters={[strongConverter, brTagConverter]} />
      </WarningText>
    );
  };

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      opened={isOpen}
      title={shouldDelete ? __("modal_delete_title", "Delete user") : __("modal_deactivate_title", "Deactivate user")}
      subTitle={
        !!name && !!email ? (
          <Componentify
            text={
              shouldDelete
                ? __("confirm_message_delete_with_data", "Are you sure you want to delete %name%<br/>(%email%)", {
                    name: name,
                    email: email
                  })
                : __(
                    "confirm_message_deactivate_with_data",
                    "Are you sure you want to deactivate %name%<br/>(%email%)",
                    {
                      name: name,
                      email: email
                    }
                  )
            }
            converters={[brTagConverter]}
          />
        ) : null
      }
      description={
        !showWarning || !shouldDelete
          ? shouldDelete
            ? __("modal_delete_explain")
            : __("modal_deactivate_explain")
          : null
      }
      buttonsComponent={
        <Button loading={isLoading} styled="ModalDangerButton" onClick={removeUser}>
          {shouldDelete ? __("Delete") : __("Deactivate")}
        </Button>
      }
    >
      {showWarning && shouldDelete ? renderWarningMessage() : null}
    </ModalTemplate>
  );
};
export default DeactivateMemberModal;

const WarningText = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px rgba(247, 60, 60, 0.05);
  background-color: rgba(247, 60, 60, 0.07);
  font-size: 13px;
  font-weight: normal;
  color: #f73c3c;
`;
