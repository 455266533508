import React, { useEffect, useRef, useState } from "react";
import { getHashComment, getHashItemInfo } from "../../../lib/utils";
import { COMMENT, SHOW_COMMENTS, DEFAULT_SELECTED_ITEMS_INFO_TAB, DEFAULT_IEMS_INFO_PANEL_SETTING, PAGES_WITH_ITEMS_INFO_PANEL } from "../../../config/constants";
import { useSelector, useDispatch } from "react-redux";
import { setItemsInfoPanelData } from "../../../lib/state/reducers/contentSlice";
import { sendGAEvent } from "../../../config/options";
import { OptionIcon, MenuButtonAboveContent } from "../UI/Style";
import { getItemsInfoPanelSettingName } from "../../../components/ItemsInfoPanel/helpers";


const useItemsInfo = ({
  contentData,
  currentFolderData,
  selectedFiles, 
  selectedFolders, 
  contentDataInitDone,
  onSingleItemSelect,
  isMobileView,
  isBusiness,
  ignoreHideContextMenuOnResize,
  currentPage
}) => {
  const dispatch = useDispatch();
  const itemsInfoData = useSelector((({ content }) => content.itemsInfoPanelData));
  const loggedUserId= useSelector(({pCloudUser}) => pCloudUser?.userinfo?.userid || 0);

  const itemKeyToLandOnShownItemInfoPanel = useRef(isBusiness ? (getHashComment() || getHashItemInfo()) : getHashItemInfo());
  const selectedItemsInfoTab = useRef(isBusiness && getHashComment() ? "comments" : (getHashItemInfo() ? "info" : DEFAULT_SELECTED_ITEMS_INFO_TAB));
  const autoFocusItemsInfoAddComment = useRef(false);
  const forceSelectedTabChange = useRef(false);
  const disableHideContextMenuTimeout = useRef(null);
  const skipItemsInfoPanelSetting = useRef(false);
  // Used to prevent open if the item is selected as a secondary result of clicked action button.
  const skipOpenItemsInfoPanelRef = useRef(false);

  const [onAddOrViewCommentsClick, setOnAddOrViewCommentsClick] = useState();
  const [itemsInfoPanelSetting, setItemsInfoPanelSetting] = useState(localStorage.getItem(getItemsInfoPanelSettingName(loggedUserId)) || DEFAULT_IEMS_INFO_PANEL_SETTING);

  const OVERWRITE_OPTIONS_ONCLICK = {
    // onAddCommentClick
    [COMMENT]: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      const { id } = data;

      sendGAEvent("add comment", location);

      setOnAddOrViewCommentsClick({itemId: id, action: COMMENT});
    },
    // onViewCommentsClick (icon -> FolderList or context menu -> option "View comments" for mobile devices)
    [SHOW_COMMENTS]: (list, type, location, event) => {
      // Prevent default selection of clicked list row.
      const data = list.length >= 1 ? list[0] : list;
      const { id } = data;

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      sendGAEvent("view comments", location);

      setOnAddOrViewCommentsClick({itemId: id, action: SHOW_COMMENTS});
    }
  };

  useEffect(() => {
    if (!onAddOrViewCommentsClick) {
      return;
    }

    const { itemId, action } = onAddOrViewCommentsClick;
    if (!contentData.items[itemId]) {
      return;
    }

    selectedItemsInfoTab.current = "comments";
    autoFocusItemsInfoAddComment.current = action === COMMENT;
    forceSelectedTabChange.current = true;

    triggerOpenItemsInfo(contentData.items[itemId]);
  }, [onAddOrViewCommentsClick]);

  useEffect(() => {
    if (!contentDataInitDone) {
      return;
    }

    // Handle with &comments=<id> param in opened URL.
    if (itemKeyToLandOnShownItemInfoPanel.current && (contentData.items[itemKeyToLandOnShownItemInfoPanel.current] || (currentFolderData && currentFolderData.id === itemKeyToLandOnShownItemInfoPanel.current))) {
      triggerOpenItemsInfo(contentData.items[itemKeyToLandOnShownItemInfoPanel.current] || currentFolderData);
      itemKeyToLandOnShownItemInfoPanel.current = null;
      return;
    }

    if (isMobileView) {
      return;
    }

    if (selectedFiles.size > 0 || selectedFolders.size > 0) {
      if (!skipOpenItemsInfoPanelRef.current || itemsInfoData.show) {
        openItemsInfo(selectedFiles.size + selectedFolders.size === 1 ? (selectedFiles.size > 0 ? selectedFiles : selectedFolders).values().next().value : null);
      }
    } else if (itemsInfoData.show && !(itemsInfoData.item && !contentData.items[itemsInfoData.item.id])) {
      // itemsInfoData.item && !contentData.items[itemsInfoData.item.id] -> This is true if we've opened a Folder from Items panel.
      closeItemsInfo();
    } else if (itemsInfoData.show && itemsInfoData.item && !contentData.items[itemsInfoData.item.id]) {
      if (currentFolderData && currentFolderData.id && currentFolderData.id === itemsInfoData.item.id) {
        // We've opened a Folder from Items panel. -> Update selectedFiles Folders
        updateItemsInfoSelectedItems(new Map(), new Map());
      } else {
        // Close previously opened items info.
        closeItemsInfo();
      }
    }

    // Next time we want to open the default info tab.
    selectedItemsInfoTab.current = DEFAULT_SELECTED_ITEMS_INFO_TAB;
    autoFocusItemsInfoAddComment.current = false;
    // If we deselect all -> force going to default tab next time.
    forceSelectedTabChange.current = selectedFiles.size == 0 && selectedFolders.size == 0;
    skipItemsInfoPanelSetting.current = false;
    skipOpenItemsInfoPanelRef.current = false;

  }, [selectedFiles, selectedFolders, contentDataInitDone]);

  const triggerOpenItemsInfo = (item) => {
    skipItemsInfoPanelSetting.current = true;
    // !isMobileView -> onSingleItemSelect triggers useEffect hook which will openItemsInfo.  
    // isMobileView -> deselect others and select only one, because if we resize from mobileView to non mobile we should see only 1 selected.
    if (contentData.items[item.id]) {
      onSingleItemSelect(item);
    } 

    if (isMobileView || !contentData.items[item.id]) {
      openItemsInfo(item);
    }
  };

  const openItemsInfo = (newSelectedItem) => {
    // Deleted items have no items info / comments panel.
    if ((newSelectedItem && newSelectedItem.isdeleted) || !PAGES_WITH_ITEMS_INFO_PANEL.has(currentPage) || (itemsInfoPanelSetting !== "1" && !skipItemsInfoPanelSetting.current)) {
      return;
    }
    
    preventHideContextMenuDueToListResize();

    dispatch(setItemsInfoPanelData({
      show: true,
      // Check if we are on the same item, don't change the selected tab.
      tab: !forceSelectedTabChange.current && newSelectedItem && itemsInfoData.item && newSelectedItem.id === itemsInfoData.item.id ? itemsInfoData.tab : selectedItemsInfoTab.current,
      // Copy to trigger reload initial data.
      item: newSelectedItem ? {...newSelectedItem} : null,
      selectedFiles: selectedFiles,
      selectedFolders: selectedFolders,
      autoFocus: {value: autoFocusItemsInfoAddComment.current}
    }));
  };

  const preventHideContextMenuDueToListResize = () => {
    ignoreHideContextMenuOnResize.current = true;
    if (disableHideContextMenuTimeout.current) {
      clearTimeout(disableHideContextMenuTimeout.current);
    }

    // Await list resize animation to finish and added 100ms extra delay, before enabling hide context menu on resize.
    disableHideContextMenuTimeout.current = setTimeout(() => {
      ignoreHideContextMenuOnResize.current = false;
    }, 600);//#content-right-section-inner -> transition: width 0.5s ease-in-out;
  };

  const closeItemsInfo = () => {
    dispatch(setItemsInfoPanelData(
      {...itemsInfoData, show: false}
    ));
  };

  const updateItemsInfoSelectedItems = (sFiles, sFolders) => {
    dispatch(setItemsInfoPanelData(
      {
        ...itemsInfoData,
        selectedFiles: sFiles,
        selectedFolders: sFolders,
        skipCloseOnInitialLoad: false,
        skipZoomAnimation: true
      }
    ));
  };

  const toggleItemsInfoPanelSetting = () => {
    const newValue = itemsInfoPanelSetting === "1" ? "0" : "1";
    localStorage.setItem(getItemsInfoPanelSettingName(loggedUserId), newValue);
    setItemsInfoPanelSetting(newValue);
    
    if (newValue === "0" && itemsInfoData.show) {
      closeItemsInfo();
      
      // Update selected to nothing selected if we have opened items info panel.
      // if (itemsInfoData.item) {
      //   dispatch(setItemsInfoPanelData(
      //     {...itemsInfoData, selectedFiles: new Map(), selectedFolders: new Map()}
      //   ));
      // } else {
      //   // Shown on multiple selection -> close it.
      //   closeItemsInfo();
      // }
    }
  };

  const renderToggleRightSidePanelSetting = () => {
    if (selectedFiles.size + selectedFolders.size > 0) {
      return null;
    }

    return (
      <MenuButtonAboveContent 
        onClick={toggleItemsInfoPanelSetting}
        data-tooltip-content={__("toggle_details_panel", "Details panel")}
        data-tooltip-id="cv-tooltip"
      >
        <OptionIcon className={`toggle-button${itemsInfoPanelSetting === "1" ? ` active` : ``}`}>
          <i className="baseIcon darkColorIcon fa-regular fa-sidebar-flip" />
        </OptionIcon>
      </MenuButtonAboveContent>
    );
  };

  return {
    OVERWRITE_OPTIONS_ONCLICK,
    initialItemKey: itemKeyToLandOnShownItemInfoPanel.current,
    renderToggleRightSidePanelSetting,
    skipOpenItemsInfoPanelRef
  }
};

export default useItemsInfo;