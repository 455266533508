import React, { useState } from "react";
import StatsBarChart from "../../pages/LinkStats/StatsBarChart";
import styled from "styled-components";

const TrafficChart = (props) => {
  const [trafficPeriod, setTrafficPeriod] = useState(null);
  const [traffic, setTraffic] = useState([]);

  return <Wrapper>
    <StatsBarChart
      {...props}
      forceMobile={true}
      disableBarClick={true}
      showTrafficButton={false}
      showAlerts={false}
      showOverallData={false}
      traffic={traffic}
      trafficPeriod={trafficPeriod}
      setTraffic={setTraffic}
      setTrafficPeriod={setTrafficPeriod}
    />
  </Wrapper>;
};

export default TrafficChart;

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  padding-bottom: var(--spacing-lg);
  margin-top: var(--spacing-md);
  box-sizing: border-box;
`;