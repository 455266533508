import React from "react";
import {
  InvitationStatsContainerSubTitle,
  InvitationStatsContainerTitle,
  InvitationStatsContainerWrapper
} from "./styles";
import { RowColumnDataItem, RowColumnDataWrapper } from "../../styles/sharedStyles";
import { __ } from "../../lib/translate";

const InvitationStatsContainer = ({ invites = {} }) => {
  const invitationStats = invites.invites;

  if (!invitationStats) {
    return null;
  }

  return (
    <InvitationStatsContainerWrapper>
      <div>
        <InvitationStatsContainerTitle>{__("invitation_stats", "Invitation stats")}</InvitationStatsContainerTitle>
        <InvitationStatsContainerSubTitle>{__("invitation_stats_disclaimer", "Statistics include only invited users who have accepted the invitation and verified their emails.")}</InvitationStatsContainerSubTitle>
      </div>
      <RowColumnDataWrapper>
        <RowColumnDataItem>
          <span>{invitationStats.total}</span>
          {__("you_have_invited", "You have invited")}
        </RowColumnDataItem>
        <RowColumnDataItem>
          <span>{invitationStats.thismonth}</span>
          {__("total_invites_this_month", "Total invites this month")}
        </RowColumnDataItem>
        <RowColumnDataItem>
          <span>{invitationStats.chance > 0 ? "1:" + invitationStats.chance : 0}</span>
          {__("your_chance_to_win", "Your chance to win")}
        </RowColumnDataItem>
      </RowColumnDataWrapper>
    </InvitationStatsContainerWrapper>
  );
};

export default InvitationStatsContainer;
