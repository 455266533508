import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Modal, { OvalModalCloseV2, OvalModalWrap } from "../Modal";
import { CheckboxButton } from "../../components/ContentView/UI/Style";
import { __ } from "../../lib/translate";
import { sendAnalyticsEvent } from "../../lib/sendAnalyticsEvent";
import { Btn } from "../ButtonDefault";
import { setcookie } from "../../lib/utils";

const UserFeedbackModal = ({ opened = true, onClose = () => {} }) => {
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);

  const FEEDBACK_OPTIONS = [
    {
      id: 1,
      label: __("user_feedback_modal_opt1_label", "I’m lost in the new navigation"),
      sub: __("user_feedback_modal_opt1_sub", "I miss the simplicity of the old layout—it was just easier to get around.")
    },
    {
      id: 2,
      label: __("user_feedback_modal_opt2_label", "The redesign feels a bit overwhelming"),
      sub: __("user_feedback_modal_opt2_sub", "Everything looks so different, and it’s taking me longer to adjust than I’d like.")
    },
    {
      id: 3,
      label: __("user_feedback_modal_opt3_label", "Where are my favorite features?"),
      sub: __("user_feedback_modal_opt3_sub", "I can’t find the tools I use all the time or they’ve changed in a way that’s not as helpful.")
    },
    {
      id: 4,
      label: __("user_feedback_modal_opt4_label", "It’s just not running as smoothly"),
      sub: __("user_feedback_modal_opt4_sub", "The new version feels a bit sluggish or unresponsive, and it’s slowing me down.")
    },
    {
      id: 5,
      label: __("user_feedback_modal_opt5_label", "It’s hard for me to use now"),
      sub: __("user_feedback_modal_opt5_sub", "The new design isn’t as easy on my eyes or accessible—I’m struggling with things like text size or colors.")
    },
    {
      id: 6,
      label: __("user_feedback_modal_opt6_label", "I miss the old look"),
      sub: __("user_feedback_modal_opt6_sub", "I just liked the visual style of the old version better. It felt more familiar and comfortable.")
    }
  ]

  const toggleSelected = (id) => {
    const currentSelected = new Set(selectedOptions);

    if (currentSelected.has(id)) {
      currentSelected.delete(id);
    } else {
      currentSelected.add(id);
    }

    setSelectedOptions(currentSelected)
  };

  const handleClose = () => {
    if (loading) {
      return;
    }

    onClose();
  }

  const onSendFeedbackSuccess = () => {
    setcookie("newSiteURL", "");
    setcookie("oldDesign", 1, 365);
    window.location.href = HFN.constructURL("https://v2.pcloud.com");
  }

  const sendFeedback = () => {
    if (loading) {
      return;
    };

    if (selectedOptions.size) {
      setLoading(true)
      sendAnalyticsEvent({
        auth: token,
        category: "User feedback",
        label: "beta.pcloud.com",
        action: "view",
        os: 4
      }, {
        feedbackids: [...selectedOptions].join(",")
      }).then(() => {
        setLoading(false);
        onSendFeedbackSuccess()
      })
    } else {
      onSendFeedbackSuccess()
    }
  };

  return (
    <Modal align="top" opened={opened} onClose={handleClose}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose}/>
        <UserFeedbackContainer>
          <UserFeedbackTitle>{__("user_feedback_modal_title", "Thanks for exploring our new design!")}</UserFeedbackTitle>
          <UserFeedbackDescription>{__("user_feedback_modal_description", "We’d love to hear your thoughts on why you’re switching back to the classic style. Your feedback helps us keep improving!")}</UserFeedbackDescription>
          <UserFeedbackForm>
            <UserFeedbackOptions>
              {
                FEEDBACK_OPTIONS.map(({ id, label, sub }) => (
                  <FeedbackContainer key={id} onClick={() => toggleSelected(id)}>
                    <CheckboxButton className={selectedOptions.has(id) ? "checked" : ""}/>
                    <FeedbackTexts>
                      <Label>{label}</Label>
                      <Sub>{sub}</Sub>
                    </FeedbackTexts>
                  </FeedbackContainer>
                ))
              }
            </UserFeedbackOptions>
            <ButtonsWrapper>
              <SecondaryButton onClick={handleClose} disabled={loading}>{__("Cancel")}</SecondaryButton>
              {/* <PrimeButton onClick={sendFeedback} disabled={loading}>{__("", "Continue")}</PrimeButton> */}
              <Btn minWidth="108px" onClick={sendFeedback} loading={loading}>{__("Continue")}</Btn>
            </ButtonsWrapper>
          </UserFeedbackForm>
        </UserFeedbackContainer>
      </OvalModalWrap>
    </Modal>
  )
}

export default UserFeedbackModal;

const UserFeedbackContainer = styled.div`
  border-radius: var(--radius-sm, 8px);
  background: #FFF;
  width: 100%;
  max-width: 600px;
  padding: var(--spacing-xl, 32px) var(--spacing-lg, 24px);
  box-sizing: border-box;
`;

const UserFeedbackTitle = styled.div`
  margin-bottom: var(--spacing-lg, 24px);
  font-size: var(--font-size-18, 18px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--color-base-black, #000000FF);
  text-align: center;
`;

const UserFeedbackDescription = styled.div`
  margin-bottom: var(--spacing-xl, 32px);
  color: var(--color-base-black, #000000FF);;
  text-align: center;
  font-size: var(--font-size-16, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const UserFeedbackOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md, 16px);
  border-bottom: 1px solid var(--divider-secondary, #EFF0F1);
  padding: var(--spacing-sm, 8px) 0;
  margin-bottom: var(--spacing-lg, 24px);
`;

const UserFeedbackForm = styled.div`

`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-md, 16px);
  cursor: pointer;
`;

const FeedbackTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
`

const Label = styled.label`
  color: var(--color-base-black, #000);
  font-size: var(--font-size-14, 14px);
  padding: 2px 0 var(--spacing-xs, 4px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  cursor: pointer;
`;

const Sub = styled.div`
  color: var(--text-secondary, #8C959D);
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-md, 16px);
  margin-top: var(--spacing-xl, 32px);

  a {
    width: auto;
    margin: 0;
  }
`;

const Button = styled.button`
  display: inline-block;
  height: 40px;
  padding: var(--spacing-xs, 4px) var(--spacing-md 16px);
  border-radius: var(--radius-xs);
  box-sizing: border-box;
  min-width: 108px;
  width: auto;
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  cursor: pointer;
`;

const SecondaryButton = styled(Button)`
  color: var(--color-base-black, #000);
  background-color: var(--color-base-white, #fff);
`;

const PrimeButton = styled(Button)`
  color: var(--color-base-white, #fff);
  background-color: var(--color-primary-500, #17BED0);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
`;
