import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { __ } from "../../../../../../lib/translate";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { teamsCalc } from "../../../../../../containers/Business/utils";

const BusinessTeams = () => {
  const teams = useSelector(({ business }) => business.teams);
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    if (!teams || !teams.length) {
      return;
    }

    setCounter(teamsCalc(teams));
  }, [teams]);

  return (
    <Wrapper>
      {counter && (
        <>
          {"("}
          <span data-tooltip-id="b-teams-breadcrumb" data-tooltip-content={__("Active Teams")}>
            {counter.active}
          </span>
          {"/"}
          <span data-tooltip-id="b-teams-breadcrumb" data-tooltip-content={__("All Teams")}>
            {counter.all}
          </span>
          {")"}
        </>
      )}
      <ReactTooltip className="cv-tooltip" id="b-teams-breadcrumb" place="bottom" noArrow offset={12} />
    </Wrapper>
  );
};

export default BusinessTeams;

const Wrapper = styled.div`
  margin-left: 4px;
  display: inline-block;
  color: var(--text-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;
