// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";
import { formatSizeInGb, calculateUserQuota } from "../../lib/utils";
import { QUOTA } from "@pcloud/web-utilities/dist/config/constants";
import { TDiv } from "../intl";
import { Btn } from "../ButtonDefault";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

import type { BonusStep } from "./types";
import UploadButton from "../UploadManager/UploadButton";
import UploadContextProvider from "../UploadManager/context/UploadContextProvider";
import UploadManagerContainer from "../UploadManager/UploadManagerContainer";


type StepContentProps = {
  completed: boolean,
  step: BonusStep,
  onUploadFile: () => void,
  onVerifyMail: () => void,
  onNextStep?: () => void
};

const stepTitles = {
  "1": "step_title_verify",
  "2": "step_title_upload",
  "3": "step_title_drive",
  "4": "step_title_app",
  "5": "step_title_autoupload",
  "6": "step_action_invite_friends",
  //final: "step_title_final"
};

const CompletedButton = () => (
  <BonusStepsButton color="gray" disabled>
    {__("completed", "Completed")}
  </BonusStepsButton>
);

const StepOne = ({ onVerifyMail, completed, onNextStep, unlockedQuota, lockedQuota }) => (
  <StepWrap>
    <TextWrap>
      <StepTitle>{__(stepTitles[1])}
        <SubHeader completed={completed}>({completed ? __("1_gb_unlocked", "1 GB unlocked") : __("unlock_1_gb", "Unlock 1 GB")})</SubHeader>
      </StepTitle>
      <StepBody id="step_content_verify">
        Verify your account to unlock file sharing and make the most out of pCloud.
      </StepBody>
    </TextWrap>
    <FooterWrapper>
      <BtnsWrapper>
        {completed ? (
          <CompletedButton />
        ) : (
          <BonusStepsButton onClick={onVerifyMail}>
            {__("step_action_verify", "Verify")}
          </BonusStepsButton>
        )}
        {onNextStep && (
          <NextStep onClick={onNextStep}>{__("Next step")}<NestStepArrow /></NextStep>
        )}
      </BtnsWrapper>
      <SpaceWrapper>
        <SpaceText>{__("Space")}</SpaceText>
        <SpaceBox>
          <UnlockedWrapper>{__("Unlocked")}:&nbsp;<Space>{unlockedQuota}</Space></UnlockedWrapper>
          <SepLine></SepLine>
          <LockedWrapper>{__("Locked")}:&nbsp;<Space completed>{lockedQuota}</Space></LockedWrapper>
        </SpaceBox>
      </SpaceWrapper>
    </FooterWrapper>
  </StepWrap>
);

const StepTwo = ({ onUploadFile, completed, onNextStep, unlockedQuota, lockedQuota }) => {
  const [uploadData, setUploadData] = useState()
  return (
    <StepWrap>
      <TextWrap>
        <StepTitle>{__(stepTitles[2])}
          <SubHeader completed={completed}>({completed ? __("1_gb_unlocked", "1 GB unlocked") : __("unlock_1_gb", "Unlock 1 GB")})</SubHeader>
        </StepTitle>
        <StepBody id="step_content_upload">
          Add your very first photo, video or document to pCloud and it will be available on all your devices.
        </StepBody>
      </TextWrap>
      <FooterWrapper>
        <BtnsWrapper>
          <UploadButton onUpload={(data) => { HFN.uploadControl.onUpload(data) }} isOnboarding disabled={completed} />
          {onNextStep && (
            <NextStep onClick={onNextStep}>
              {__("Next step")}
              <NestStepArrow />
            </NextStep>
          )}
        </BtnsWrapper>
        <SpaceWrapper>
          <SpaceText>{__("Space")}</SpaceText>
          <SpaceBox>
            <UnlockedWrapper>{__("Unlocked")}:&nbsp;<Space>{unlockedQuota}</Space></UnlockedWrapper>
            <SepLine></SepLine>
            <LockedWrapper>{__("Locked")}:&nbsp;<Space completed>{lockedQuota}</Space></LockedWrapper>
          </SpaceBox>
        </SpaceWrapper>
      </FooterWrapper>
    </StepWrap >
  )
};

const StepThree = ({ completed, onNextStep, unlockedQuota, lockedQuota }) => (
  <StepWrap>
    <TextWrap>
      <StepTitle>{__(stepTitles[3])}
        <SubHeader completed={completed}>({completed ? __("1_gb_unlocked", "1 GB unlocked") : __("unlock_1_gb", "Unlock 1 GB")})</SubHeader>
      </StepTitle>
      <StepBody id="step_content_drive">
        Access and work on files from your computer without taking HDD space.
      </StepBody>
    </TextWrap>
    <FooterWrapper>
      <BtnsWrapper>
        {completed ? (
          <CompletedButton />
        ) : (
          <BonusStepsButton
            href={URLSITE + "download-free-online-cloud-file-storage.html?show=drive"}
            target="_blank"
          >
            {__("step_action_drive", "Download")}
          </BonusStepsButton>
        )}
        {onNextStep && (
          <NextStep onClick={onNextStep}>
            {__("Next step")}
            <NestStepArrow />
          </NextStep>
        )}
      </BtnsWrapper>
      <SpaceWrapper>
        <SpaceText>{__("Space")}</SpaceText>
        <SpaceBox>
          <UnlockedWrapper>{__("Unlocked")}:&nbsp;<Space>{unlockedQuota}</Space></UnlockedWrapper>
          <SepLine></SepLine>
          <LockedWrapper>{__("Locked")}:&nbsp;<Space completed>{lockedQuota}</Space></LockedWrapper>
        </SpaceBox>
      </SpaceWrapper>
    </FooterWrapper>
  </StepWrap>
);

const StepFour = ({ completed, onNextStep, unlockedQuota, lockedQuota }) => (
  <StepWrap>
    <TextWrap>
      <StepTitle>{__(stepTitles[4])}
        <SubHeader completed={completed}>({completed ? __("1_gb_unlocked", "1 GB unlocked") : __("unlock_1_gb", "Unlock 1 GB")})</SubHeader>
      </StepTitle>
      <StepBody id="step_content_app">
        Have all your files in your pocket and share them with anyone, anywhere you go.
      </StepBody>
    </TextWrap>
    <FooterWrapper>
      <BtnsWrapper>
        {completed ? (
          <CompletedButton />
        ) : (
          <BonusStepsButton
            href={URLSITE + "download-free-online-cloud-file-storage.html?show=app"}
            target="_blank"
          >
            {__("step_action_drive", "Download")}
          </BonusStepsButton>
        )}
        {onNextStep && (
          <NextStep onClick={onNextStep}>
            {__("Next step")}
            <NestStepArrow />
          </NextStep>
        )}
      </BtnsWrapper>
      <SpaceWrapper>
        <SpaceText>{__("Space")}</SpaceText>
        <SpaceBox>
          <UnlockedWrapper>{__("Unlocked")}:&nbsp;<Space>{unlockedQuota}</Space></UnlockedWrapper>
          <SepLine></SepLine>
          <LockedWrapper>{__("Locked")}:&nbsp;<Space completed>{lockedQuota}</Space></LockedWrapper>
        </SpaceBox>
      </SpaceWrapper>
    </FooterWrapper>
  </StepWrap>
);

const StepFive = ({ completed, onNextStep, unlockedQuota, lockedQuota }) => (
  <StepWrap>
    <TextWrap>
      <StepTitle>{__(stepTitles[5])}
        <SubHeader completed={completed}>({completed ? __("1_gb_unlocked", "1 GB unlocked") : __("unlock_1_gb", "Unlock 1 GB")})</SubHeader>
      </StepTitle>
      <StepBody id="step_content_autoupload">
        Back up all photos and videos from your phone, and free up space for more great memories.
        Open the pCloud Mobile app, go to Settings and activate Automatic Upload
      </StepBody>
    </TextWrap>
    <FooterWrapper>
      <BtnsWrapper>
        {completed ? (
          <CompletedButton />
        ) : (
          <BonusStepsButton
            href="https://blog.pcloud.com/automatic-upload-explained"
            target="_blank"
          >
            {__("step_action_autoupload", "Learn More")}
          </BonusStepsButton>
        )}
        {onNextStep && (
          <NextStep onClick={onNextStep}>
            {__("Next step")}

            <NestStepArrow />
          </NextStep>
        )}
      </BtnsWrapper>
      <SpaceWrapper>
        <SpaceText>{__("Space")}</SpaceText>
        <SpaceBox>
          <UnlockedWrapper>{__("Unlocked")}:&nbsp;<Space>{unlockedQuota}</Space></UnlockedWrapper>
          <SepLine></SepLine>
          <LockedWrapper>{__("Locked")}:&nbsp;<Space completed>{lockedQuota}</Space></LockedWrapper>
        </SpaceBox>
      </SpaceWrapper>
    </FooterWrapper>
  </StepWrap>
);

const StepSix = ({ completed, onNextStep, unlockedQuota, lockedQuota }) => (
  <StepWrap>
    <TextWrap>
      <StepTitle>{__(stepTitles[6])}</StepTitle>
      <StepBody id="invite_friends_get_1_gb">
        Invite friends and family to join pCloud. Each verified invitation brings you 1 GB.
      </StepBody>
    </TextWrap>
    <FooterWrapper>
      <BtnsWrapper>
        {completed ? (
          <CompletedButton />
        ) : (
          <BonusStepsButton
            href=" https://my.pcloud.com/#page=invitefriends"
          >
            {__("step_action_invite", "Invite")}
          </BonusStepsButton>
        )}
        {onNextStep && (
          <NextStep onClick={onNextStep}>
            {__("Next step")}
            <NestStepArrow />
          </NextStep>
        )}
      </BtnsWrapper>
      <SpaceWrapper>
        <SpaceText>{__("Space")}</SpaceText>
        <SpaceBox>
          <UnlockedWrapper>{__("Unlocked")}:&nbsp;<Space>{unlockedQuota}</Space></UnlockedWrapper>
          <SepLine></SepLine>
          <LockedWrapper>{__("Locked")}:&nbsp;<Space completed>{lockedQuota}</Space></LockedWrapper>
        </SpaceBox>
      </SpaceWrapper>
    </FooterWrapper>
  </StepWrap>
);

/*const StepFinal = ({
  premium,
  claimingreward,
  onRewardClaim
}: {
  premium: boolean,
  claimingreward: boolean,
  onRewardClaim: () => void
}) => (
  <StepWrap>
    <TextWrap>
      <StepTitle>{__(stepTitles["final"])}</StepTitle>
      <StepBody>
        {premium
          ? __(
              "step_content_final_premium",
              "You are now an advanced pCloud user! Enjoy you pCloud!"
            )
          : __(
              "step_content_final_free",
              "You are now an advanced pCloud user! Claim your 4GB free storage prize and enjoy your pCloud!"
            )}
      </StepBody>
    </TextWrap>
    <BonusStepsButton
      onClick={onRewardClaim}
      disabled={claimingreward}
      loading={claimingreward}
    >
      {premium
        ? __("step_action_final_premium", "Close")
        : __("step_action_final_free", "Claim")}
    </BonusStepsButton>
  </StepWrap>
);*/

export { /*StepFinal,*/ stepTitles };

const steps = {
  "1": props => <StepOne {...props} />,
  "2": props => <StepTwo {...props} />,
  "3": props => <StepThree {...props} />,
  "4": props => <StepFour {...props} />,
  "5": props => <StepFive {...props} />,
  "6": props => <StepSix {...props} />
};

const StepContent = ({ step, ...rest }: StepContentProps) => {
  const usedquota = useSelector(({ pCloudUser }) => pCloudUser.userinfo.usedquota);
  const freequota = useSelector(({ pCloudUser }) => pCloudUser.userinfo.freequota);
  const quota = useSelector(({ pCloudUser }) => pCloudUser.userinfo.quota);

  const userQuota = calculateUserQuota(usedquota, freequota, quota);
  const lockedQuota = userQuota[QUOTA["LOCKED"]] && userQuota[QUOTA["LOCKED"]].size;
  const unlockedQuota = formatSizeInGb(quota);

  return steps[step]({ lockedQuota, unlockedQuota, ...rest })
};

export default StepContent;

const BonusStepsButton = ({ children, ...rest }) => (
  <Btn
    style={{ minWidth: "130px", width: "130px", margin: "0" }}
    size="medium"
    color="darkGreen"
    {...rest}
  >
    {children}
  </Btn>
);

const StepWrap = styled.div`
  padding: 20px;
  text-align: left;
`;

const TextWrap = styled.div`
  margin-bottom: 20px;
`;

const StepTitle = styled.div`
  font-size: 17px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
  display: inline-block;
`;

const SubHeader = styled.div`
  display: inline-block;
  /*color: #8e8e8e;*/
  color: ${props => (props.completed ? "#8e8e8e" : "#61c100")};
  padding-left: 5px;
  font-weight: normal;
`;

const NextStep = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin: 0 20px;
  text-decoration: none;
  font-style: none;
  color: #61c100;
  cursor: pointer;
`;

const NestStepArrow = styled.span`
  background-repeat: no-repeat;
  width: 17px;
  height: 17px;
  background-position: center;
  cursor: pointer;
  position: absolute;
  background-size: 5px 8px;
  background-image: url(${require("../../../root/img/nextstep/next-green.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/nextstep/next-green@2x.png")});
  }
`;

const StepBody = styled(TDiv)`
  font-size: 15px;
  color: #000;
`;

const BtnsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SpaceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SpaceText = styled.div`
  font-size: 13px;
  text-align: center;
  color: #999999;
  margin-right: 10px;
`;

const SpaceBox = styled.div`
  height: 34px;
  font-size: 13px;
  border-radius: 6px;
  box-shadow: inset 0.7px 0.7px 2px 0 rgba(0, 0, 0, 0.01);
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  text-align: center;
  box-sizing: border-box;
`;

const SepLine = styled.div`
  width: 1px;
  height: 16px;
  background-color: #e8e8e8;
  margin: 0 10px;
`;

const UnlockedWrapper = styled.div`
  display: flex;
`;

const LockedWrapper = styled.div`
  display: flex;
`;

const Space = styled.div`
  color: ${props => (props.completed ? "#999" : "#60c000")};
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
