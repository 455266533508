import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../styles/sharedStyles";
import { hiDPI } from "polished";
import { TDiv } from "../../components/intl";
import { motion } from "framer-motion";

export const Wrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto var(--spacing-3xl);
  gap: var(--spacing-xl);
  box-sizing: border-box;
  padding-top: 50px;
  padding-right: var(--spacing-md);

  @media (max-width: 1023px) {
    padding: var(--spacing-md) var(--spacing-md) 100px;
  }
`;

export const Buttons = styled(FlexRow)`
  display: none;
`;

export const SettingsWrapper = styled(FlexRow)`
  width: 100%;
  display: flex;
  padding: var(--spacing-md) var(--spacing-md);
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--divider-primary);
`;

export const FolderLink = styled(FlexRow)`
  gap: var(--spacing-sm);
  justify-content: flex-start;

  a {
    color: var(--text-primary);
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 1023px) {
    min-width: 0;
    width: 100%;
    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const LinkIcon = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--color-primary-700);

  & i {
    color: var(--color-base-white, #fff);
    font-size: 13px;
  }
`;

export const FeaturesWrapper = styled(FlexColumn)`
  margin: var(--spacing-lg) auto;
  gap: var(--spacing-xl);
  width: 100%;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const FeaturesTitle = styled.div`
  color: var(--color-base-black);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;

export const Boxes = styled(FlexRow)``;

export const Box = styled(FlexColumn)`
  padding: 0 var(--spacing-md);
  width: 33%;
  gap: 8px;
`;

export const BoxImage = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 216px 153px;
  width: 216px;
  height: 153px;
  margin-bottom: var(--spacing-sm);
`;

export const SiteImage = styled(BoxImage)`
  background-image: url("/ZCDNURL/img/publicfolder/site.png");

  ${hiDPI(1.5)} {
    background-image: url("/ZCDNURL/img/publicfolder/site@2x.png");
  }
`;

export const LinkImage = styled(BoxImage)`
  background-image: url("/ZCDNURL/img/publicfolder/link.png");

  ${hiDPI(1.5)} {
    background-image: url("/ZCDNURL/img/publicfolder/link@2x.png");
  }
`;

export const ImagesImage = styled(BoxImage)`
  background-image: url("/ZCDNURL/img/publicfolder/images.png");

  ${hiDPI(1.5)} {
    background-image: url("/ZCDNURL/img/publicfolder/images@2x.png");
  }
`;

export const BoxTitle = styled(TDiv)`
  color: var(--color-base-black, #000);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

export const BoxDescription = styled(TDiv)`
  color: var(--text-secondary);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
`;

export const AlertWrapper = styled.div`
  width: 100%;
`;

export const OptionsWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  width: 36px !important;
  height: 36px !important;

  @media (max-width: 1023px) {
    width: 40px !important;
    height: 40px !important;
  }
`;
