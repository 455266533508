import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { useSelector } from "react-redux";
import InputText from "../../InputText";
import { detectIsMobile } from "../../../lib";

const localsMapping = {
  en: () => import("date-fns/locale/en-GB").then((loc) => loc.enGB),
  bg: () => import("date-fns/locale/bg").then((loc) => loc.bg),
  es: () => import("date-fns/locale/es").then((loc) => loc.es),
  ru: () => import("date-fns/locale/ru").then((loc) => loc.ru),
  pt: () => import("date-fns/locale/pt").then((loc) => loc.pt),
  fr: () => import("date-fns/locale/fr").then((loc) => loc.fr),
  it: () => import("date-fns/locale/it").then((loc) => loc.it),
  de: () => import("date-fns/locale/de").then((loc) => loc.de),
  zh: () => import("date-fns/locale/zh-CN").then((loc) => loc.zhCN),
  tr: () => import("date-fns/locale/tr").then((loc) => loc.tr),
  nl: () => import("date-fns/locale/nl").then((loc) => loc.nl),
  ja: () => import("date-fns/locale/ja").then((loc) => loc.ja)
};

const DateRangePicker = (props) => {
  const isMobileDevice = detectIsMobile();
  const [locale, setLocale] = useState();
  const userLang = useSelector(({ pCloudUser }) => pCloudUser.userinfo.language);

  useEffect(() => {
    try {
      localsMapping[userLang]().then((loc) => {
        setLocale(loc);
      });
    } catch (e) {
      console.error(e.message);
    }
  }, [userLang]);

  return (
    <DateStyled>
      <DatePicker
        {...props}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        withPortal={isMobileDevice}
        selectsRange={true}
        isClearable={true}
        dateFormat="MM/dd/yyyy"
        locale={locale ? locale : null}
        portalId="root-datepicker"
        customInput={<InputText className="input-styled" />}
      />
    </DateStyled>
  );
};

export default DateRangePicker;

const DateStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    position: relative;

    &:after {
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      font-size: 16px;
      content: "\\f073";
      color: #7a8d8f;
      position: absolute;
      right: 16px;
      top: 11px;
      pointer-events: none;
    }
  }

  .react-datepicker__close-icon {
    display: none;
  }
`;
