import React from "react";
import {
  FilemanagerLoaderList,
  LoaderAnimation,
  LoadingHeaderRow,
  LoadingHeaderRowMenu,
  LoadingListHeaderRow,
  LoadingListHeaderRowCell,
  LoadingListRow,
  LoadingListRowCell,
  LoadingListRowIcon
} from "./styles";
import hashManager from "../../../../lib/hashManager";
import BusinessLogsHeader from "./Customizations/BusinessLogsHeader";
import { TEMPLATES } from "../../ContentData";
import calculateSize from "../../../../lib/calculateTextWidth";
import { ACTIONS_COLUMN, FA_ICON, INITIALS, TYPE, TYPE_ICON } from "../../../../config/constants";

const SkeletonList = ({ viewType, contentType }) => {
  const isMobileDevice = HFN.config.isMobile();
  const countRows = 8;
  const columns = TEMPLATES[contentType].COLUMNS;

  const renderHeaderRow = () => {
    const page = hashManager.getState("page");
    if (page === "b_logs") {
      return null;
    }

    return (
      <>
        <LoadingHeaderRow className="loading-gray-boxes">
          <LoadingHeaderRowMenu />
          <LoadingHeaderRowMenu />
        </LoadingHeaderRow>
      </>
    );
  };

  const getRowCellData = (column, columnName, index) => {
    const customClass = ["loading-gray-boxes"];
    if (!column.showOnMobile) {
      customClass.push("hide-on-mobile");
    }
    customClass.push(columnName + "-col");

    if (column.innerColumns) {
      const innerColumns = column.innerColumns;

      return Object.keys(innerColumns).map((key, cIndex) => {
        if ([ACTIONS_COLUMN, TYPE].includes(key)) {
          return null;
        }
        if ([TYPE_ICON, FA_ICON, INITIALS].includes(key)) {
          return <LoadingListRowIcon key={cIndex} />;
        }

        const hasSecondLine =
          innerColumns[key].secondLine &&
          ((isMobileDevice && innerColumns[key].secondLine.secShowOnMobileOnly) ||
            !innerColumns[key].secondLine.secShowOnMobileOnly);

        if (hasSecondLine) {
          customClass.push("has-second-line");
        }

        return (
          <LoadingListRowCell key={cIndex} className={customClass.join(" ")} $stretchWidth={column.stretchWidth}>
            <div />
            {hasSecondLine && <div className="sub" />}
          </LoadingListRowCell>
        );
      });
    }

    return (
      <LoadingListRowCell key={index} className={customClass.join(" ")} $stretchWidth={column.stretchWidth}>
        <div />
      </LoadingListRowCell>
    );
  };

  return (
    <FilemanagerLoaderList>
      <LoaderAnimation />
      {renderHeaderRow()}
      <LoadingListHeaderRow>
        {Object.keys(columns).map((column, index) => {
          if (!columns[column].columnTitle) {
            return;
          }
          const customClass = ["loading-gray-boxes"];
          const tmpSize = calculateSize(columns[column].columnTitle[0], {
            font: "'Roboto',sans-serif",
            fontSize: "14px",
            fontWeight: "400"
          });
          if (!columns[column].showOnMobile) {
            customClass.push("hide-on-mobile");
          }
          customClass.push(column + "-col");

          return (
            <LoadingListHeaderRowCell
              key={index}
              className={customClass.join(" ")}
              $stretchWidth={columns[column].stretchWidth}
              $width={tmpSize.hasOwnProperty("width") ? tmpSize.width : 0}
            >
              <div />
            </LoadingListHeaderRowCell>
          );
        })}
      </LoadingListHeaderRow>
      {Array(countRows)
        .fill()
        .map((i, index) => {
          let additionalRowClass = ["loading-gray-boxes"];
          if (countRows - index < 5) {
            additionalRowClass.push("loading-item-last-" + (countRows - index));
          }
          if (viewType === "largeListView") {
            additionalRowClass.push("large");
          }
          return (
            <LoadingListRow key={index} className={additionalRowClass.join(" ")}>
              {Object.keys(columns).map((column, index) => {
                if (column === TYPE_ICON || column === FA_ICON) {
                  return <LoadingListRowIcon key={index} />;
                }

                if (!columns[column].columnTitle) {
                  return;
                }

                return getRowCellData(columns[column], column, index);
              })}
            </LoadingListRow>
          );
        })}
    </FilemanagerLoaderList>
  );
};

export default SkeletonList;
