// @flow

import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import ManageMemberQuotaSlider from "../../../containers/Business/MemberQuotaManagement/ManageMemberQuotaSlider";
import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { validateEmail } from "../../../lib/utils";
import { getSharedContacts } from "../../../lib/shareFolder-utils";
import {
  BUSINESS_MIN_STORAGE_MEMBER,
  BUSINESS_MIN_USER_QUOTA,
  BUSINESS_QUOTA_STEP
} from "@pcloud/web-utilities/dist/config/constants";
import { editUser, loadAccountInfo, loadInvitedUsers } from "../../../lib/state/reducers/businessSlice";
import { getUserStatus } from "../../../containers/Business/utils";
import ModalTemplate from "../ModalTemplate";
import { getAccountInfoCall } from "../../../api/business";
import { InputMessage } from "../../../styles/sharedStyles";
import MultiSelectSuggester from "../../AutoComplete/MultiSelectSuggester";

const InviteUsersToAccountModal = ({ resetModalAction = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [storagePerUser, setStoragePerUser] = useState(BUSINESS_MIN_STORAGE_MEMBER);
  const [maxStorageValue, setMaxStorageValue] = useState(0);
  const bUserContacts = useSelector(({ business }) => business.users);
  const bUserInvitedContacts = useSelector(({ business }) => business.invited_users);
  const accountInfo = useSelector(({ business }) => business.accountInfo);
  const { account: { owner = {} } = {} } = accountInfo;
  const { quota, usedquota } = owner;
  const freeOwnerQuota = quota - usedquota;
  const dispatch = useDispatch();
  const [textareaValue, setTextareaValue] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const groupedUsers = useMemo(() => {
    const active = [], deleted = [];
    bUserContacts.forEach((user) => {
      if (user.status !== 'deleted') {
        active.push(user);
      } else {
        deleted.push(user);
      }
    });
    return {
      active,
      deleted,
    };
  }, [bUserContacts]);

  const inputTexarea = useRef(null);
  const suggesterRef = useRef({});

  useEffect(() => {
    const minQuota = freeOwnerQuota - BUSINESS_QUOTA_STEP;
    const availableQuota = Math.floor(freeOwnerQuota / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;

    if (minQuota > BUSINESS_MIN_USER_QUOTA) {
      setMaxStorageValue(availableQuota - BUSINESS_MIN_USER_QUOTA);
    } else {
      setMaxStorageValue(BUSINESS_MIN_USER_QUOTA);
    }
  }, [freeOwnerQuota, usedquota]);

  const onTextareaChange = () => {
    setTextareaValue(inputTexarea.current.value);
  };

  const sendInvitations = (e) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    let emails = [];
    let hasError = false;

    if (suggesterRef.current.inputValue && validateEmail(suggesterRef.current.inputValue)) {
      emails.push(suggesterRef.current.inputValue)
    }

    if (selectedEmails.length) {
      for (let index in selectedEmails) {
        if (
          validateEmail(selectedEmails[index].label) &&
          deletedBusinessExistEmail(selectedEmails[index].label) &&
          businessExistEmail(selectedEmails[index].label)
        ) {
          emails.push(selectedEmails[index].label);
        } else {
          hasError = true;
        }
      }
    }

    if (!emails.length || hasError) {
      HFN.message(__("Enter valid e-mail."), "error");
      setIsLoading(false);
      return;
    }

    let xhr = [];
    let successResult = [];
    let succInvitations = [];
    let errorResult = {};
    const params = {
      quota: storagePerUser,
    };
    params.mails = emails.join(",");
    params.message = textareaValue;

    xhr.push(
      apiMethod(
        "account_invitemail",
        params,
        ({ mails }) => {
          successResult = [...mails];
        },
        {
          errorCallback: ({ result, error }) => {
            if (!(result === 2019) && !(result === 2024)) {
              errorResult = { result, error };
              hasError = true;
            }
            setIsLoading(false);
          },
          showErrorMessage: false
        }
      )
    );

    $.when.apply($, xhr).then(() => {
      successResult.map(({ result, mail, message }) => {
        if (result === 0 || result === 2019 || result === 2024) {
          succInvitations.push(mail);
        } else if (result === 2018) {
          hasError = true;
        } else {
          hasError = true;
        }
      });

      if (hasError && errorResult.result) {
        if (errorResult.result === 1064) {
          hasError = true;
        } else {
          hasError = true;
        }
      }

      if (succInvitations.length) {
        fetchData();
        HFN.message(__("Invitation(s) sent."));
      }

      if (!hasError) {
        handleClose();
      }
      if (suggesterRef.current && suggesterRef.current.resetSelections) {
        suggesterRef.current.resetSelections();
      }
    });
  };

  const fetchData = () => {
    let accountInfoResponse = [];
    let inviteListResponse = [];
    const xhrs = [];
    xhrs.push(
      getAccountInfoCall((ret) => {
        accountInfoResponse = ret;
      })
    );
    xhrs.push(apiMethod("account_invitelist", {}, function(ret) {
      inviteListResponse = ret;
    }, { forceFresh: true }));

    Promise.all(xhrs).then(() => {
      delete accountInfoResponse.result;
      accountInfoResponse.can_invite_account = accountInfo.can_invite_account;

      const tmpUsers = [];
      for (let n = 0; n < inviteListResponse.invites.length; ++n) {
        let tmpUser = {
          name: "",
          firstname: "",
          lastname: "",
          active: -1,
          invited: true,
          isBusinessUser: true,
          id: Math.random(),
          quota: inviteListResponse.invites[n].quota || "-",
          lastactivity: inviteListResponse.invites[n].sent,
          email: inviteListResponse.invites[n].email,
          teams: inviteListResponse.invites[n].teams,
          teamsids: inviteListResponse.invites[n].teams.map((item) => item.team.id),
          code: inviteListResponse.invites[n].code
        };
        tmpUser.status = getUserStatus(tmpUser);
        tmpUsers.push(tmpUser);
      }
      dispatch(loadInvitedUsers(tmpUsers));
      dispatch(loadAccountInfo(accountInfoResponse));

      const owner = accountInfoResponse?.account?.owner;
      if (owner) {
        dispatch(editUser({
          id: owner.id,
          quota: owner.quota,
          usedquota: owner.usedquota,
        }));
      }
    });
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const businessExistEmail = (email) => {
    let emailExists = groupedUsers.active.some(contact => contact.email === email);

    if (emailExists) {
      return false;
    }

    emailExists = bUserInvitedContacts.some(contact => contact.email === email);

    if (emailExists) {
      return false;
    }

    return true;
  }

  const deletedBusinessExistEmail = (email) => {
    return !groupedUsers.deleted.some(contact => contact.email === email);
  }

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const handleOnChange = (event, data) => {
    setSelectedEmails(data);
  }

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      opened={isOpen}
      title={__("business_invite_user_header", "Add users")}
      buttonsComponent={
        <Button styled="ModalPrimaryButton" loading={isLoading} onClick={sendInvitations}>
          {__("Add")}
        </Button>
      }
    >
      <InviteWrapper>
        <Style.Label>{__("Account user", "Account user")}</Style.Label>
        <InputEmailWrapper>
          <MultiSelectSuggester
            componentRef={suggesterRef}
            defaultIcon="fa-regular fa-envelope"
            list={[]}
            placeholder={__("Email")}
            onValueChange={handleOnChange}
            borderRadius={8}
            validationRules={[
              {
                check: validateEmail,
                message: __("This is not a valid contact or an email.")
              },
              {
                check: businessExistEmail,
                message: __("error_2307", "Invite request already exists")
              },
              {
                check: deletedBusinessExistEmail,
                message: __("This user has been deleted from the business account")
              }
            ]}
          />
        </InputEmailWrapper>
        <Style.Label>{__("manage_business_storage_quota", "Storage quota")}</Style.Label>
        <ManageMemberQuotaSlider
          updatedQuota={storagePerUser}
          minValue={0}
          users={selectedEmails.length ? selectedEmails.length : 1}
          startValue={BUSINESS_MIN_USER_QUOTA}
          minQuota={BUSINESS_MIN_USER_QUOTA}
          minStartValue={BUSINESS_MIN_USER_QUOTA}
          maxValue={maxStorageValue}
          getStorageValue={setStoragePerUser}
        />
        <StorageDiscaimer>
          {__(
            "business_storage_discaimer",
            "Select the storage quota every user will receive, when they join the Business account. You can change the storage by 100 GB (min)."
          )}
        </StorageDiscaimer>
        <Style.Label>{__("Message")}</Style.Label>
        <InputMessage
          className="textarea"
          ref={inputTexarea}
          name="message"
          value={textareaValue}
          placeholder={__("business_invite_user_message", "Add a message (optional)")}
          autoComplete="off"
          onChange={onTextareaChange}
        />
      </InviteWrapper>
    </ModalTemplate>
  );
};

export default InviteUsersToAccountModal;

const InviteWrapper = styled.div`
  
`;

const InputEmailWrapper = styled.div`
  margin-bottom: 17px;
`;

const StorageDiscaimer = styled.div`
  font-size: 13px;
  opacity: 0.5;
  margin-bottom: 15px;
`;
