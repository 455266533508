//@flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { hiDPI } from "polished";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import store from "../../lib/state/store";
import { modifyContentItem } from "../../lib/state/reducers/contentSlice";

import {
  SettingsWrapper,
  SettingsHeader,
  FileRequestSettingsTitle,
  FileRequestSettingsDescription,
  SettingOptionsContainer,
  InputWrapper,
  ToggleLabel,
  Error,
  FadeAnimationContainer
} from "./styledComponents";
import TopBar from "./TopBar";
import InputCheckbox from "../../components/InputCheckbox";
import { Btn } from "../../components/ButtonDefault";
import { __ } from "../../lib/translate";
import { roundHours } from "../../lib/utils";
import changeuploadlink from "../../api/changeuploadlink";
import { loadFileRequestData } from "../../lib/state/reducers/fileRequestsSlice";

type Props = {
  allSettingsBack: any,
  bodyHeight: string,
  parentRef: any
};

const FileRequestExpirationDate = ({ allSettingsBack, bodyHeight, parentRef }: Props) => {
  const timeNow = new Date();
  const yesterday = Datetime.moment().subtract(1, "day");

  const linkData = useSelector(({ fileRequests }) => fileRequests.linkData);
  const { expires: expirationDate, uploadlinkid: linkId, comment, maxspace } = linkData;
  const dispatch = useDispatch();

  const [toggleState, setToggleState] = useState(!!expirationDate);
  const [loading, setLoading] = useState(false);
  const [changesInited, setChangesInited] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dateValue, setDateValue] = useState(
    (expirationDate && new Date(expirationDate)) || roundHours(timeNow)
  );

  const timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;
  let timezoneOffsetText = "UTC";

  const buttonTexts = {
    save: __("Save"),
    change: __("Change")
  };

  const buttonText = !!expirationDate && toggleState ? buttonTexts.change : buttonTexts.save;

  if (timezoneOffset) {
    timezoneOffsetText += timezoneOffset > 0 ? "+" + timezoneOffset : timezoneOffset;
  }

  const validateDate = current => {
    return current.isAfter(yesterday);
  };

  const handleToggleChange = () => {
    if (loading) {
      return;
    }

    const newToggleState = !toggleState;

    setToggleState(newToggleState);
    setErrorMessage("");

    if (!!expirationDate != newToggleState) {
      setChangesInited(true);
    } else {
      setChangesInited(false);
    }

    setDateValue(expirationDate ? new Date(expirationDate) : roundHours(timeNow));
  };

  const handleDateChange = value => {
    setDateValue(value);
    setChangesInited(true);
  };

  const handleSubmit = () => {
    if (!changesInited) {
      return;
    }

    errorMessage && setErrorMessage("");
    setLoading(true);
    changePublink();
  };

  const changePublink = () => {
    const params = {
      uploadlinkid: linkId,
      comment: comment,
      maxspace: maxspace
    };

    if (dateValue && toggleState) {
      params.expire = new Date(dateValue).toISOString();
    } else if (!toggleState) {
      params.deleteexpire = true;
    }

    if (loading) {
      return;
    }

    changeuploadlink(params, {
      onSuccess: (ret: any) => {
        var item = {
          id: ret.code,
          code: ret.code,
          name: ret.metadata.name,
          comment: ret.comment,
          link: ret.link,
          files: ret.files,
          space: ret.space,
          mail: ret.mail,
          modified: ret.created,
          uploadlinkid: ret.uploadlinkid,
          metadata: ret.metadata,
          icon: ret.metadata.icon,
          isfilerequest: true
        };
        if (ret.maxspace) {
          item.maxspace = ret.maxspace;
        }
        if (ret.expires) {
          item.expires = ret.expires;
        }

        store.dispatch(modifyContentItem(item));

        dispatch(loadFileRequestData(ret));
        setLoading(false);
        setChangesInited(false);
        HFN.message(__("shared_links_settings_updated"), "success");
      },
      onError: () => {
        setLoading(false);
      }
    });
  };

  const renderHeader = () => {
    return (
      <SettingsHeader>
        <FileRequestSettingsTitle>{__("link_settings_exp_date")}</FileRequestSettingsTitle>
        <FileRequestSettingsDescription>
          {__("link_settings_exp_date_description")}
        </FileRequestSettingsDescription>
      </SettingsHeader>
    );
  };

  const renderBody = () => {
    return (
      <SettingOptionsContainer>
        <form>
          <InputWrapper>
            <InputCheckbox
              checked={toggleState}
              onChange={handleToggleChange}
              name="expdate-toggle"
              color="cyan"
              size="small"
            />
            <ToggleLabel>{__("link_settings_exp_date")}</ToggleLabel>
          </InputWrapper>
          <DateInputsWrapper>
            <DatePickerWrapper disabled={!toggleState}>
              <Datetime
                value={dateValue}
                minDate={timeNow}
                onChange={handleDateChange}
                className="date-picker"
                viewMode="days"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                inputProps={{ disabled: !toggleState }}
                isValidDate={validateDate}
                closeOnSelect
              />
              <CalendarIcon />
            </DatePickerWrapper>
            <TimePickerWrapper disabled={!toggleState}>
              <Datetime
                value={dateValue}
                onChange={handleDateChange}
                viewMode="time"
                timeFormat="HH:mm"
                dateFormat={false}
                className="time-picker"
                inputProps={{ disabled: !toggleState }}
                closeOnSelect
              />
              <TimeZoneText>{timezoneOffsetText}</TimeZoneText>
            </TimePickerWrapper>
          </DateInputsWrapper>
          <FadeAnimationContainer>
            <SwitchTransition>
              <CSSTransition
                key={errorMessage}
                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                classNames="fade"
              >
                {errorMessage ? <Error>{errorMessage}</Error> : <Error />}
              </CSSTransition>
            </SwitchTransition>
          </FadeAnimationContainer>
          <ButtonWrapper>
            <Btn
              loading={loading}
              disabled={!changesInited}
              borderRadius="3"
              color="cyan"
              onClick={handleSubmit}
            >
              <FadeAnimationContainer>
                <SwitchTransition>
                  <CSSTransition
                    key={buttonText}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                    classNames="fade"
                  >
                    <span>{buttonText}</span>
                  </CSSTransition>
                </SwitchTransition>
              </FadeAnimationContainer>
            </Btn>
          </ButtonWrapper>
        </form>
      </SettingOptionsContainer>
    );
  };

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef}>
      <div>
        <TopBar allSettingsBack={allSettingsBack} />
        {renderHeader()}
        {renderBody()}
      </div>
    </SettingsWrapper>
  );
};

export default FileRequestExpirationDate;

const DateInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  td.rdtDay {
    font-size: 14px;
    border-radius: 6px;
    transition: background 200ms ease-in-out;

    &.rdtActive {
      background-color: #17bed0 !important;
    }
  }

  .date-picker,
  .time-picker {
    border-radius: 3.1px;
    box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #c1c1c1;
    background-color: #ffffff;
    padding: 7px 12px;
    box-sizing: border-box;
    height: 36px;
    width: 160px;
    outline: none;

    @media (max-width: 600px) {
      width: 100%;
    }

    .rdtPicker {
      left: 0px;
      top: 35px;
      border-radius: 3.1px;
    }
  }

  .time-picker {
    .rdtPicker {
      width: 120px;

      .rdtCounter .rdtBtn {
        font-size: 13px;
        border-radius: 6px;
        transition: background 200ms ease-in-out;
      }
    }
  }
`;

const DatePickerWrapper = styled.div`
  position: relative;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: opacity 250ms ease-in-out;

  input {
    border: none;
    width: 100%;
    outline: none;
    background: transparent;
  }
`;

const TimePickerWrapper = styled(DatePickerWrapper)``;

const CalendarIcon = styled.div`
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  width: 14px;
  height: 14px;
  top: 11px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/b/callendar-inside.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/b/callendar-inside.png")});
  }
`;

const TimeZoneText = styled.div`
  position: absolute;
  right: 12px;
  top: 11px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 1.23;
  text-align: left;
  color: #999999;
`;

const ButtonWrapper = styled.div`
  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;
