// @flow
import { BOTTOM_ITEMS, CUSTOMER_JOURNEY_STEPS, DEFAULT_MENU_ITEMS, EXPLORE_MENU_ITEMS, PROFILE_MENU_ITEMS } from "./constants";
import pCloudIcon from '@pcloud/web-utilities/dist/resources/images/svg/menu/pCloud.svg';
import upgradeIcon from '@pcloud/web-utilities/dist/resources/images/svg/menu/upgrade.svg';
import PassLogoIcon from '@pcloud/web-utilities/dist/resources/images/svg/menu/passLogo.svg';
import TransferLogoIcon from '@pcloud/web-utilities/dist/resources/images/svg/menu/transferLogo.svg';
import DocsLogoIcon from '@pcloud/web-utilities/dist/resources/images/svg/menu/docsLogo.svg';
import pCloudSaveLogoIcon from '@pcloud/web-utilities/dist/resources/images/svg/menu/pcloudSaveLogo.svg';
import EncryptionCircle from "../../../root/img/promo-cards/encryption-circle.svg";
import BackupCircle from "../../../root/img/promo-cards/backup-circle.svg";
import PublicFolderCircle from "../../../root/img/promo-cards/public-folder-circle.svg";
import FileRequestsCircle from "../../../root/img/promo-cards/file-requests-circle.svg";
import RewindCircle from "../../../root/img/promo-cards/rewind-circle.svg";
import InviteFriendsCircle from "../../../root/img/promo-cards/invite-friends-circle.svg";
import EncryptionCircleSmall from "../../../root/img/promo-cards/encryption-circle-small.svg";
import BackupCircleSmall from "../../../root/img/promo-cards/backup-circle-small.svg";
import PublicFolderCircleSmall from "../../../root/img/promo-cards/public-folder-circle-small.svg";
import FileRequestsCircleSmall from "../../../root/img/promo-cards/file-requests-circle-small.svg";
import RewindCircleSmall from "../../../root/img/promo-cards/rewind-circle-small.svg";
import InviteFriendsCircleSmall from "../../../root/img/promo-cards/invite-friends-circle-small.svg";
import { PAGES } from "../../config/constants";

export const defaultMenuOptions = {
  [DEFAULT_MENU_ITEMS.MY_PCLOUD]: {
    name: "My pCloud",
    page: PAGES.FILE_MANAGER,
    svgIcon: pCloudIcon,
  },
  [DEFAULT_MENU_ITEMS.CRYPTO_FOLDER]: {
    name: "Crypto Folder",
    page: PAGES.FILE_MANAGER,
    icon: 'baseIcon fa-light fa-shield-keyhole',
    iconActive: 'baseIcon fa-solid fa-shield-keyhole',
  },
  [DEFAULT_MENU_ITEMS.CRYPTO_SHARES]: {
    name: "Crypto Shares",
    page: "cryptoshares",
    icon: 'baseIcon fa-light fa-shield-halved',
    iconActive: 'baseIcon fa-solid fa-shield-halved',
  },
  [DEFAULT_MENU_ITEMS.BACKUPS]: {
    name: "Backups",
    page: PAGES.BACKUPS,
    icon: 'baseIcon fa-light fa-cloud-arrow-up',
    iconActive: 'baseIcon fa-solid fa-cloud-arrow-up',
  },
  [DEFAULT_MENU_ITEMS.PHOTO_SEARCH]: {
    name: "Photo search",
    page: PAGES.PHOTO_SEARCH,
    icon: 'baseIcon fa-light fa-image',
    iconActive: 'baseIcon fa-solid fa-image',
  },
  [DEFAULT_MENU_ITEMS.AUDIO]: {
    name: "Audio",
    page: PAGES.AUDIO,
    icon: 'baseIcon fa-light fa-headphones',
    iconActive: 'baseIcon fa-solid fa-headphones',
  },
  [DEFAULT_MENU_ITEMS.SHARED]: {
    name: "left_menu_shared",
    page: PAGES.SHARES,
    icon: 'baseIcon fa-light fa-folder-user',
    iconActive: 'baseIcon fa-solid fa-folder-user',
  },
  [DEFAULT_MENU_ITEMS.FILE_REQUEST]: {
    name: "Upload Links",
    page: PAGES.FILE_REQUESTS,
    icon: 'baseIcon fa-light fa-file-plus',
    iconActive: 'baseIcon fa-solid fa-file-plus',
  },
  [DEFAULT_MENU_ITEMS.BOOKMARKS]: {
    name: "left_menu_pinned_links",
    page: PAGES.BOOKMARKS,
    icon: 'baseIcon fa-light fa-star',
    iconActive: 'baseIcon fa-solid fa-star',
  },
  [DEFAULT_MENU_ITEMS.REWIND]: {
    name: "rewind",
    page: PAGES.REWIND,
    icon: 'baseIcon fa-light fa-clock-rotate-left',
    iconActive: 'baseIcon fa-solid fa-clock-rotate-left',
  },
  [DEFAULT_MENU_ITEMS.TRASH]: {
    name: "Trash",
    page: PAGES.TRASH,
    icon: 'baseIcon fa-light fa-trash-alt',
    iconActive: 'baseIcon fa-solid fa-trash-alt',
  },
};

export const profileMenuOptions = {
  [PROFILE_MENU_ITEMS.B_ACCOUNT]: {
    name: "Account",
    page: PAGES.B_ACCOUNT,
    icon: 'baseIcon fa-light fa-briefcase',
    iconActive: 'baseIcon fa-solid fa-briefcase',
  },
  [PROFILE_MENU_ITEMS.B_USERS]: {
    name: "Users",
    page: PAGES.B_USERS,
    icon: 'baseIcon fa-light fa-user-group',
    iconActive: 'baseIcon fa-solid fa-user-group',
    subPages: [PAGES.B_USER]
  },
  [PROFILE_MENU_ITEMS.B_TEAMS]: {
    name: "Teams",
    page: PAGES.B_TEAMS,
    icon: 'baseIcon fa-light fa-users',
    iconActive: 'baseIcon fa-solid fa-users',
    subPages: [PAGES.B_TEAM]
  },
  [PROFILE_MENU_ITEMS.B_LOGS]: {
    name: "Logs",
    page: PAGES.B_LOGS,
    icon: 'baseIcon fa-light fa-clipboard-list-check',
    iconActive: 'baseIcon fa-solid fa-clipboard-list-check',
  },
  [PROFILE_MENU_ITEMS.B_BILLING]: {
    name: "Billing",
    page: PAGES.B_BILLING,
    icon: 'baseIcon fa-light fa-circle-dollar',
    iconActive: 'baseIcon fa-solid fa-circle-dollar',
  },
  [PROFILE_MENU_ITEMS.FAMILY]: {
    name: "family_left_menu_headline",
    page: PAGES.FAMILY,
    icon: 'baseIcon fa-light fa-house',
    iconActive: 'baseIcon fa-solid fa-house',
  },
  [PROFILE_MENU_ITEMS.MANAGE_SUBSCRIPTION]: {
    name: "manage_subscription",
    page: PAGES.MANAGE,
    icon: 'baseIcon fa-light fa-circle-dollar',
    iconRight: 'smallIcon lightColorIcon fa-light fa-arrow-up-right-from-square'
  },
  [PROFILE_MENU_ITEMS.INVITE_FRIENDS]: {
    name: "step_action_invite_friends",
    page: PAGES.INVITE_FRIENDS,
    icon: 'baseIcon fa-light fa-user-plus',
    iconActive: 'baseIcon fa-solid fa-user-plus',
  },
  [PROFILE_MENU_ITEMS.SETTINGS]: {
    name: "Settings",
    page: PAGES.SETTINGS,
    icon: 'baseIcon fa-light fa-cog',
    iconActive: 'baseIcon fa-solid fa-cog',
  },
  [PROFILE_MENU_ITEMS.SPEEDTEST]: {
    name: "speed_test_setting",
    page: "speedtest",
    icon: 'baseIcon fa-light fa-gauge-simple-high',
    iconActive: 'baseIcon fa-solid fa-gauge-simple-high',
  },
  [PROFILE_MENU_ITEMS.LANGUAGE]: {
    name: "Language",
    page: "",
    icon: 'baseIcon fa-light fa-globe',
    iconActive: 'baseIcon fa-solid fa-globe',
    iconRight: 'smallIcon lightColorIcon fa-light fa-chevron-right'
  },
  [PROFILE_MENU_ITEMS.HELP]: {
    name: "Help Center",
    page: "",
    disableTooltip: true,
    icon: 'baseIcon fa-light fa-circle-question',
    iconRight: 'smallIcon lightColorIcon fa-light fa-arrow-up-right-from-square'
  },
  [PROFILE_MENU_ITEMS.LOGOUT]: {
    name: "Logout",
    page: "",
    icon: 'baseIcon fa-light fa-arrow-right-from-bracket',
  },
};

export const bottomOptions = {
  [BOTTOM_ITEMS.DEVICE]: {
    name: "desktop_backup_download_button",
    page: "",
    icon: 'baseIcon fa-solid fa-display',
  },
  [BOTTOM_ITEMS.MORE_APPS]: {
    name: "Apps",
    page: "",
    icon: 'baseIcon fa-solid fa-grid-round',
  },
  [BOTTOM_ITEMS.PRICING]: {
    name: "upgrade",
    page: "",
    svgIcon: upgradeIcon,
  },
};

export const exploreOptions = {
  [EXPLORE_MENU_ITEMS.CRYPTO_FOLDER]: {
    name: "Encryption",
    page: { page: PAGES.FILE_MANAGER, crypto: 1 },
    icon: 'fa-solid fa-shield-keyhole',
    iconCircle: EncryptionCircle,
    iconCircleSmall: EncryptionCircleSmall,
    description: "explore_cards_description_encryption",
  },
  [EXPLORE_MENU_ITEMS.BACKUPS]: {
    name: "footer_backup",
    page: PAGES.BACKUPS,
    icon: 'fa-solid fa-rotate-left',
    iconCircle: BackupCircle,
    iconCircleSmall: BackupCircleSmall,
    description: "explore_cards_description_backup",
  },
  [EXPLORE_MENU_ITEMS.PUBLIC_FOLDER]: {
    name: "public_folder",
    page: PAGES.PUBLIC,
    icon: 'fa-solid fa-folder-open',
    iconCircle: PublicFolderCircle,
    iconCircleSmall: PublicFolderCircleSmall,
    description: "explore_cards_description_public_folder",
  },
  [EXPLORE_MENU_ITEMS.FILE_REQUEST]: {
    name: "Upload Links",
    page: PAGES.FILE_REQUESTS,
    icon: 'fa-solid fa-file-plus',
    iconCircle: FileRequestsCircle,
    iconCircleSmall: FileRequestsCircleSmall,
    description: "explore_cards_description_file_request",
  },
  [EXPLORE_MENU_ITEMS.INVITE_FRIENDS]: {
    name: "explore_cards_title_invite_friends",
    page: PAGES.INVITE_FRIENDS,
    icon: 'fa-solid fa-user-plus',
    iconCircle: InviteFriendsCircle,
    iconCircleSmall: InviteFriendsCircleSmall,
    description: "explore_cards_description_invite_friends",
  },
  [EXPLORE_MENU_ITEMS.REWIND]: {
    name: "rewind",
    page: PAGES.REWIND,
    icon: 'fa-solid fa-clock-rotate-left',
    iconCircle: RewindCircle,
    iconCircleSmall: RewindCircleSmall,
    description: "explore_cards_description_rewind",
  },
};

export const customerJourneySteps = {
  [CUSTOMER_JOURNEY_STEPS.VERIFY_MAIL]: "step_action_verify_email",
  [CUSTOMER_JOURNEY_STEPS.UPLOAD_FILE]: "step_action_upload_file",
  [CUSTOMER_JOURNEY_STEPS.DOWNLOAD_DRIVE]: "step_title_drive",
  [CUSTOMER_JOURNEY_STEPS.DOWNLOAD_APP]: "step_action_pcloud_app",
  [CUSTOMER_JOURNEY_STEPS.AUTO_UPLOAD]: "step_action_autoupload_on",
  [CUSTOMER_JOURNEY_STEPS.SENT_INVITATION]: "step_action_invite_friends",
}

// TODO change links with ones from web utils
export const moreAppsOptions = [
  { href: "https://pass.pcloud.com", name: "pCloud Pass", icon: PassLogoIcon },
  { href: "https://transfer.pcloud.com", name: "pCloud Transfer", icon: TransferLogoIcon },
  { href: "https://docs.pcloud.com/", name: "pCloud for Devs", icon: DocsLogoIcon },
  { href: "https://www.pcloud.com/download-free-online-cloud-file-storage.html?show=psave", name: "pCloud Save", icon: pCloudSaveLogoIcon }
];