// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Btn } from "../ButtonDefault";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { formatSize } from "../../lib/utils";
import { errorKeys } from "../../lib/errors";

const LeaveAccountModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const quota = useSelector(({ pCloudUser }) => pCloudUser.userinfo.quota);
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);

  useEffect(() => {
    if (isOpen) {
      console.log("ADD event on keyup LeaveAccountModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup LeaveAccountModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]); //[isOpen]

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onLeaveAccountClick();
      }
    },
    [onLeaveAccountClick]
  );

  const onLeaveAccountClick = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "fm_exitfromaccount",
      { auth: token },
      () => {
        location.reload();
        handleClose();
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
          setIsLoading(false);
        }
      }
    );
  });

  return (
    <Modal align="top" onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("family_leave_account_modal_title", "Leave Family Plan")}</Style.Header>
          <Style.Message>
            {__("family_leave_account_modal_message", false, { memberquota: formatSize(quota, 0) })}
          </Style.Message>
          <Style.Message>{__("family_leave_account_modal_message1")}</Style.Message>
          <Style.Message>{__("family_leave_account_modal_message2")}</Style.Message>
          <Style.Footer>
            <Btn
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("No")}
            </Btn>
            <Btn
              loading={isLoading}
              disabled={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onLeaveAccountClick}
            >
              {__("Yes")}
            </Btn>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default LeaveAccountModal;
