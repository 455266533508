import React from "react";
import { Column } from "../../UI/Style";
import styled from "styled-components";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";

const BusinessQuotaCell = ({ stretchWidth, item, classNames, contentKey, id }) => {
  classNames.push("initials");

  if (item.invited && item.storage) {
    return (
      <CustomColumn $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
        <Span>{!Number.isInteger(item.storage) ? '-' : renderQuota(item.storage, true)}</Span>
      </CustomColumn>
    );
  }

  return (
    <CustomColumn $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
      <Span>{renderQuota(item.usedquota, true)}</Span>
      {"/"}
      <Span>{renderQuota(item.quota, true)}</Span>
    </CustomColumn>
  );
};

export default BusinessQuotaCell;

const CustomColumn = styled(Column)`
  gap: 4px;
`;

const EditIcon = styled.i`
  display: flex;
  width: 20px;
  height: 20px;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Span = styled.span`
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const SpanClickable = styled.span`
  display: flex;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;
