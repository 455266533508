import styled from "styled-components";
import { OvalModalCloseV2 } from "../Modal";

export const MODAL_WIDTH = 560;

export const MODAL_MOBILE_VIEW = (MODAL_WIDTH + (16 * 2) - 1) + "px"; // calc(var(--modal-width) + (var(--spacing-md) * 2));

export const ModalContainer = styled.div`
  --modal-width: ${MODAL_WIDTH}px;
  display: flex;
  width: var(--modal-width);
  max-width: calc(100vw - (var(--spacing-md) * 2));
  padding: var(--spacing-md) var(--spacing-lg) var(--spacing-lg);
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: var(--radius-rounded);
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.17);
  position: relative;
    &.with-gap {
        gap: var(--spacing-xl);
    }
`;

export const ModalContent = styled.div`
    align-self: start;
    width: 100%;
`;

export const ModalClose = styled(OvalModalCloseV2)`
  width: 20px;
  height: 20px;// TODO use --modal-close-btn-height
  background-repeat: no-repeat;
  background-position: center;
  top: var(--spacing-lg);
  right: var(--spacing-lg);
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: var(--spacing-md);

  @media (max-width: ${MODAL_MOBILE_VIEW}) {
    flex-direction: column;
  }
`;
export const ModalHeaderContainer = styled.div`
  display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
`;
export const ModalHeader = styled.div`
  --modal-close-btn-height: 20px;
  margin-top: calc(var(--modal-close-btn-height) + var(--spacing-sm));
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--font-size-18);
  font-weight: 700;

  .ellipsis {
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  }
`;
export const ModalSubHeader = styled.div`
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    width: 100%;
    word-wrap: break-word;
`;

export const ModalDescription = styled.div`
  color: var(--text-primary);
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 600px) {
    text-align: left;
  }
`;