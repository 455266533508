import React from "react";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/users-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/users-mobile.svg";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import {
  MOBILE_WIDTH_BREAKPOINT,
  MODAL_BUSINESS_TEAM_INVITE_USER
} from "../../../../../config/constants";
import { EmptyStateMessage, EmptyStateMessageTitle, EmptyStateWrapper, ImageContainer } from "../styles";
import { __ } from "../../../../../lib/translate";
import styled from "styled-components";
import Button from "../../../../ButtonDefault";
import hashManager from "../../../../../lib/hashManager";
import { useDispatch, useSelector } from "react-redux";
import { setModalAction } from "../../../../../lib/state/reducers/contentSlice";

const BusinessTeamUsers = ({ noItemsLabel }) => {
  const { bodyWidth, bodyHeight } = useWindowDimensions();
  const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
  const entityId = hashManager.getState("id");
  const teams = useSelector(({ business }) => business.teams);
  const team = teams?.find((item) => item?.id === Number(entityId));
  const teamCanInvite = team ? team.can_invite : false;
  const dispatch = useDispatch();

  const handleInviteUsers = () => {
    dispatch(setModalAction({
      modal: MODAL_BUSINESS_TEAM_INVITE_USER,
      props: {
        teamName: team.name,
        teamId: team.id
      }
    }));
  }

  return (
    <>
      <EmptyStateWrapper $isMobile={isMobile}>
        <EmptyStateMessage $isMobile={isMobile}>
          <EmptyStateMessageTitle>{__(...noItemsLabel)}</EmptyStateMessageTitle>
        </EmptyStateMessage>

        {teamCanInvite && (
          <ButtonWrapper $isMobile={isMobile || bodyHeight < 450}>
            <Button
              styled="BorderedButton"
              onClick={handleInviteUsers}
            >
              {__("Add users to team")}
            </Button>
          </ButtonWrapper>
        )}

        <ImageContainer $isMobile={isMobile}>{isMobile ? <EmptyMobileIcon /> : <EmptyDesktopIcon />}</ImageContainer>
      </EmptyStateWrapper>
    </>
  );
};

export default BusinessTeamUsers;

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ $isMobile }) => ($isMobile ? "var(--spacing-lg)" : "0")};
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  @media (max-height: 580px) {
    margin-bottom: 0;
  }
`;
