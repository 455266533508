// @flow

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import * as CommonStyle from "../../components/CommonStyledSettings";
import Button from "../ButtonDefault";
import CountryCodeToName from "../../lib/countryUtils";
import InvoiceModal from "../InvoiceModal";
import ProFormaInvoices from "../../containers/Business/BillingPage/ProFormaInvoices";

const InvoiceDetails = () => {
  const billingInfo = useSelector(({ pCloudUser }) => pCloudUser.userSettings.billingInfo);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);

  const hasBillingInfo = useMemo(() => Object.keys(billingInfo).length > 0, [billingInfo]);

  const businessBillingInfoKeysForOut = useMemo(() => isBusiness ? Object.keys(billingInfo).sort() : [], [billingInfo]);
  const keyNameToLabel = useMemo(() => {
    return {
      billingAddress: __("ph_billing_address", "Billing Address:"),
      vat: __("ph_vat", "VAT:"),
      company: __("Company", "Company:"),
      names: __("ph_names", "Names:"),
      country: __("ph_country", "Country:"),
      // type: __("type", "Type"),
    }
  }, []);

  const [opened, setOpened] = useState(false);

  const renderInvoiceModal = () => {
    let buttonName = "";

    if (isBusiness) {
      buttonName = hasBillingInfo ? __("business_invoice_update_details", "Update details") : __("business_invoice_add_details", "Add details");
    } else {
      buttonName = hasBillingInfo ? __("Update My Invoice Information", "Update") : __("insert_invoice_info", "Add");
    }

    return (
      <InvoiceModal
        isBusiness={isBusiness}
        cta={buttonName}
        billingInfo={billingInfo}
        manageExternal={true}
        openedExternal={opened}
        onClose={() => setOpened(false)}
      />
    );
  };


  const renderInvoiceDetails = () => {
    return (
      <CommonStyle.Row>
          <div>
            <CommonStyle.Title>{__("ph_invoice_info", "Invoice details")}</CommonStyle.Title>

            {hasBillingInfo ?
              <CommonStyle.Description>{`${billingInfo.company ? (billingInfo.company + ", ") : ""}${billingInfo.names ? (billingInfo.names + ", ") : ""}${billingInfo.country ? (CountryCodeToName[billingInfo.country] + ", ") : ""}${billingInfo.billingAddress || billingInfo.billingaddress || ""}`}</CommonStyle.Description>
              : null
            }

            {!hasBillingInfo ?
              <CommonStyle.Description>{__("ph_billing_note", "Please add your billing details, in order to get an invoice for your payment.")}</CommonStyle.Description>
              : null
            }
          </div>

          <Button onClick={() => setOpened(true)}>
            {hasBillingInfo ? __("Update My Invoice Information", "Update") : __("insert_invoice_info", "Add")}
          </Button>
        </CommonStyle.Row>
    )
  };

  const renderBusinessInvoiceDetailsTable = () => {
    if (businessBillingInfoKeysForOut.length === 0) {
      return null;
    }

    return businessBillingInfoKeysForOut.map((keyName, keyIndex) => (
      keyName !== "type" && typeof keyNameToLabel[keyName] !== "undefined" ?
        <CommonStyle.Row key={keyIndex}>
          <div>
            <CommonStyle.Title>
              {keyNameToLabel[keyName]}
            </CommonStyle.Title>
            <CommonStyle.Description>
              {keyName == "country" ? CountryCodeToName[billingInfo[keyName]] : billingInfo[keyName]}
            </CommonStyle.Description>
          </div>

          {keyIndex === 0 ?
            <Button onClick={() => setOpened(true)}>{__("business_invoice_update_details", "Update details")}</Button>
          : null}
        </CommonStyle.Row>
      : null
    ));
  };

  const renderBusinessInvoiceDetails = () => {
    return (
      <React.Fragment>
        {renderBusinessInvoiceDetailsTable()}

        <ProFormaInvoicesWrapper>
          <ProFormaInvoices />
        </ProFormaInvoicesWrapper>

        <InfoBoxWrapper>
          <InfoMessageWrapper>
            {businessBillingInfoKeysForOut.length === 0 ?
              <InfoMessageTitle>
                {__("ph_billing_note", "Please add your billing details, in order to get an invoice for your payment.")}
              </InfoMessageTitle>
            : null}

            <InfoMessageDescription>
              {businessBillingInfoKeysForOut.length === 0 ? __("BA_pcloud_invoice") : __("BA_pcloud_invoice_permanent")}
            </InfoMessageDescription>
          </InfoMessageWrapper>

          {businessBillingInfoKeysForOut.length === 0 ?
            <Button styled="UpdateInvoiceDetails" onClick={() => setOpened(true)}>
              {__("business_invoice_add_details", "Add details")}
            </Button>
          : null}
        </InfoBoxWrapper>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderInvoiceModal()}
      {isBusiness ? renderBusinessInvoiceDetails() : renderInvoiceDetails()}
    </React.Fragment>
  );
};

export default InvoiceDetails;

const ProFormaInvoicesWrapper = styled.div`
  .proforma-invoices-row {
    border-bottom: none;
  }
`

const InfoBoxWrapper = styled.div`
  display: flex;
  gap: var(--spacing-2xl);
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border: 1px solid var(--color-warning-200);
  background: var(--color-functional-warning100);
  margin: 0 var(--spacing-md) var(--spacing-lg) var(--spacing-md);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: var(--spacing-lg);
    align-items: flex-start;
  }
`;

const InfoMessageWrapper = styled.div`
  font-size: var(--font-size-16);
  font-style: normal;
  line-height: 22px;
  color: var(--text-secondary);
`;

const InfoMessageTitle = styled.div`
  font-weight: 700;
`;

const InfoMessageDescription = styled.div`
  font-weight: 400;
`;
