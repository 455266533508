import * as React from "react";
import {
  Box,
  BoxDescription,
  Boxes,
  BoxTitle,
  FeaturesTitle,
  FeaturesWrapper,
  ImagesImage,
  LinkImage,
  SiteImage
} from "./styles";

const PublicFolderFeatures = () =>
  <FeaturesWrapper>
    <FeaturesTitle>{__("Public Folder usage", "Public Folder usage")}</FeaturesTitle>
    <Boxes>
      <Box>
        <SiteImage />
        <BoxTitle id="dl_title_static">Static HTML website</BoxTitle>
        <BoxDescription id="dl_description_static">
          Simply put HTML-formatted text files and linked graphics in the Public Folder (or in a sub-folder). Click
          'Share' and 'Copy Link' on the main directory, where your site's resources are. Open the link and there you
          have it - your very own website, hosted in pCloud!
        </BoxDescription>
      </Box>
      <Box>
        <ImagesImage />
        <BoxTitle id="dl_title_embed">Embed Images</BoxTitle>
        <BoxDescription id="dl_description_embed">
          Тo embed some images in your existing website or a forum, email signature, etc., click 'Share' for the
          selected image and then 'Embed'. An embed code for the image will be copied to your clipboard.
        </BoxDescription>
      </Box>
      <Box>
        <LinkImage />
        <BoxTitle id="dl_title_links">Direct links to files</BoxTitle>
        <BoxDescription id="dl_description_links">
          You can get a direct link to any file in the Public Folder by selecting 'Share'>'Get Link'. Upon clicking a
          direct link to a file, your browser will download it or preview it, depending on the file type.
        </BoxDescription>
      </Box>
    </Boxes>
  </FeaturesWrapper>;

export default PublicFolderFeatures;

