// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputTextarea from "../../InputTextarea";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import ModalTemplate from "../ModalTemplate";

const RequestMoreSpaceModal = ({ isOpen, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(
    __(
      "b_more_space_default_message",
      "Hello, pCloud Team, I would like to increase my account's space."
    )
  );
  const userEmail = useSelector(({ pCloudUser }) => pCloudUser.userinfo.email);

  const onMessageChange = ({ target }) => {
    setMessage(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSendClick();
    }
  };

  const onSendClick = () => {
    const messageTrimed = message.trim();
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (messageTrimed.length < 1) {
      HFN.message(__("Message can't be empty"), "error");
      return;
    }

    apiMethod(
      "feedback",
      {
        mail: userEmail,
        reason: "Request for more space",
        message: messageTrimed,
        feedbacktype: "contactsales"
      },
      () => {
        handleClose();
        HFN.message(__("Your message was sent successfully!"));
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      opened={isOpen}
      title={__("Request More Space")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          loading={isLoading}
          onClick={onSendClick}
        >
          {__("Send")}
        </Button>
      }
    >
      <Style.Label>{__("Message")}</Style.Label>
      <InputTextarea
        style={{ width: "100%", height: "50px", padding: "10px", boxSizing: "border-box" }}
        name="message"
        onChange={onMessageChange}
        value={message}
        autoFocus
        onKeyDown={onEnter}
      />
    </ModalTemplate>
  );
};

export default RequestMoreSpaceModal;
