// @flow
import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { MIN_CRYPTO_PASS_STRENGTH } from "@pcloud/web-utilities/dist/config/constants";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

import { Btn } from "../../ButtonDefault";
import PasswordStrength from "../../PasswordStrength";
import InputPassword from "../../InputPassword";
import { Button, InputWrapper } from "../../../containers/CryptoPages/styles";
import PasswordInput from "../../../containers/CryptoPages/parts/PasswordInput";
import hashManager from '../../../lib/hashManager'
import { PAGES } from "../../../config/constants"
import ModalTemplate from "../../Modals/ModalTemplate";
import RegularInput from "../../../containers/CryptoPages/parts/RegularInput";
import usePasswordValidation from "../../../containers/CryptoPages/hooks/usePasswordValidation";

type Props = {
  onPassChanged: () => void,
  onSuccess: () => void,
};

const fieldsDefaultValue = { code: "", password: "", repeatPassword: "", hint: "" };

const SetNewPass = ({ onPassChanged = () => { }, onSuccess = () => { } }: Props) => {
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const [fields, setFields] = useState(fieldsDefaultValue);
  const [loading, setLoading] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const [requirementsAreMet, setRequirementsAreMet] = useState(false);

  const { errors, disabledSubmitBtn } = usePasswordValidation(
    fields.password,
    fields.repeatPassword,
    fields.hint,
    requirementsAreMet,
  );

  const page = hashManager.getState('page');
  const entityId = hashManager.getState('id');

  const onFieldChange = function (field, value) {
    const newFields = { ...fields };
    newFields[field] = value;
    setFields(newFields);
  };

  const onSetNewPassword = e => {
    e.preventDefault();

    if (disabledSubmitBtn) {
      return;
    }

    const { code, password, repeatPassword, hint } = fields;

    if (loading || !password.length) {
      return;
    }

    if (code.length != 6 || !parseInt(code)) {
      HFN.message(__("invalid_verification_code"), "error");
      return;
    }

    const strength = pCloudCrypto.passwordStrength(password);

    if (password.length < 8 || strength < MIN_CRYPTO_PASS_STRENGTH) {
      HFN.message(__("Password is too weak!"), "error");
      return;
    }
    if (password !== repeatPassword) {
      HFN.message(__("both passwords must be exact match."), "error");
      return;
    }

    const hintContainsPass = hint.trim().indexOf(password.trim()) != -1;
    if (hintContainsPass) {
      HFN.message(__("Your Hint must not contain the Passphrase."), "error");
      return;
    }

    setLoading(true);

    pCrypt.encryptPrivateKeyWithNewPass(password, 0, ({ privatekey, signature }) => {
      const params = { auth: token, code: code, hint: hint, privatekey: privatekey, signature: signature };
      let methodName = "crypto_changeuserprivate";
      if (page === PAGES.B_USER) {
        params.userid = entityId;
        methodName = "account_changeusercryptoprivate"
      } else if (page === PAGES.B_TEAM) {
        params.teamid = entityId;
        methodName = "account_changeteamcryptotempprivate"
      }

      apiMethod(
        methodName,
        params,
        res => {
          if (res.result == 0) {
            HFN.message(__("crypto_pass_changed"));
            onPassChanged();
          }
          setLoading(false);
          onSuccess();
        },
        {
          type: "post",
          errorCallback: error => {
            console.log(error);
            if (error.result == 2012) {
              HFN.message(__("invalid_verification_code"), "error");
            } else {
              HFN.message("Saving key failed", "error");
            }
            setLoading(false);
          }
        }
      );
    });
  };

  const resendCode = () => {
    if (!canResend) {
      return;
    }
    setCanResend(false);
    setTimeout(() => setCanResend(true), 15000);
    apiMethod(
      "crypto_sendchangeuserprivate",
      { auth: token },
      ({ result }) => {
        if (result == 0) {
          HFN.message("Code has been sent");
        }
      },
      { errorCallback: e => HFN.message(e, "error") }
    );
  };

  return (
    <ModalTemplate
      animate
      externalModal
      title={__("heading_change_crypto_pass")}
      description={__("crypto_change_pass_verification_code_info")}
    >
      <Form onSubmit={onSetNewPassword}>
        {page !== PAGES.SETTINGS &&
          <Warning>
            {__("change_pass_from_owner_to_user_desc")}
          </Warning>
        }
        <InputWrapper>
          <RegularInput
            value={fields.code}
            onChange={(e) => onFieldChange("code", e.target.value.replace(/\s/g, ""))}
            autoFocus={true}
            className={"cryptopass"}
            placeholder={__(
              "verification_code_label",
            )}
          />
        </InputWrapper>

        <InputWrapper>
          <PasswordInput
            value={fields.password}
            onChange={(e) => onFieldChange("password", e.target.value)}
            className={"cryptopass"}
            invalid={errors.password}
            placeholder={__("new_crypto_pass_label")}
            usePasswordChecker={true}
            setRequirementsAreMet={setRequirementsAreMet}
          />
          <PassStrengWrap>
            <PasswordStrength password={fields.password} />
          </PassStrengWrap>
        </InputWrapper>

        <InputWrapper>
          <PasswordInput
            value={fields.repeatPassword}
            onChange={(e) => onFieldChange("repeatPassword", e.target.value)}
            invalid={errors.repeatPassword}
            className={"cryptopass"}
            placeholder={__("Repeat Crypto Pass")}
          />
        </InputWrapper>

        <InputWrapper>
          <RegularInput
            value={fields.hint}
            onChange={(e) => onFieldChange("hint", e.target.value)}
            invalid={errors.hint}
            className={"cryptopass"}
            placeholder={`${__("Hint")} (${__("Optional")})`}
          />
        </InputWrapper>

        <ButtonWrap>
          <Button
            type={"submit"}
            disabled={disabledSubmitBtn || loading}
          >
            {__("button_change_crypto_pass")}
          </Button>
          <ButtonLink
            className={classnames("lnk", { disabled: !canResend })}
            role="button"
            tabIndex="0"
            onKeyDown={e => e.keyCode == 13 && resendCode()}
            onClick={resendCode}
            canresend={canResend}
          >
            {__("resend_code_action")}
          </ButtonLink>
        </ButtonWrap>
      </Form>
    </ModalTemplate>
  );
};

export default SetNewPass;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
  margin-top: var(--spacing-sm);
`;
const PassStrengWrap = styled.div`
  position: relative;
  top: 3px;
  left: 2px;
`;

const ModalContainer = styled.div`
  text-align: center;
  border-radius: var(--radius-lg);
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
  padding: var(--spacing-xl);
  background: var(--color-base-white);
  max-width: 480px;
  width: 100%;
  min-height: auto;
  color: var(--color-base-black);
  box-sizing: border-box;
`;

const ModalHeader = styled.div`
  font-size: var(--font-size-18);
  color: var(--color-base-black);
  line-height: 32px;
  font-weight: bold;
  margin-bottom: var(--spacing-xl);
`;

const ModalDescription = styled.div`
  font-size: 15px;
  color: var(--color-base-black)
  padding: 0px var(--spacing-md);
  text-align: center;
  margin-bottom: 16px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--spacing-lg);
`;

const ButtonLink = styled.div`
  font-size: var(--font-size-14);
  font-weight: normal;
  text-align: center;
  width: 100%;
  color: ${({ canresend }) => canresend ? "var(--color-primary-500)" : "var(--color-grey-500)"};
  cursor: pointer;
  flex-shrink: 0;
`;

const Warning = styled.div`
  border: 1px solid var(--color-functional-warning200);
  background: var(--color-functional-warning100);
  color: var(--text-secondary);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: var(--spacing-md);
`