import { animated } from "@react-spring/web";
import React from "react";
import { TARGET_TRAFFIC } from "../constants";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";

const CustomTickY = ({ value, animatedProps, chartTarget, formattedTickQuota }) => {
  const isTraffic = chartTarget === TARGET_TRAFFIC;

  return (
    <animated.g
      transform={animatedProps.transform}
      style={{
        outline: "0",
        opacity: animatedProps.opacity
      }}
    >
      <rect x={-15} y={0} width={30} height={20} fill="transparent" />
      <line x1="0" x2="-5" y1="0" y2="0" stroke="rgb(119, 119, 119)" strokeWidth={1} />
      <animated.text
        textAnchor="end"
        dominantBaseline="middle"
        transform={animatedProps.textTransform}
        style={{
          fill: "var(--text-primary)",
          fontSize: 11
        }}
      >
        {formattedTickQuota && formattedTickQuota.hasOwnProperty(value)
          ? formattedTickQuota[value]
          : (
            isTraffic
              ? renderQuota(value || 0)
              : value
          )}
      </animated.text>
    </animated.g>
  );
};

export default CustomTickY;
