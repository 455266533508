// @flow

import * as React from "react";

import { Button } from "../../components/ButtonDefault";
import PublicFolderFeatures from "./Features";
import PublicFolderTop from "./Top";
import UpgradeButton from "./UpgradeButton";
import { TSpan } from "../../components/intl";
import { SubTitle } from "../../styles/sharedStyles";

const PublicFolderCreate = ({
  onCreateFolder,
  creatingFolder,
  cancreate,
  ispremium
}: {
  onCreateFolder: () => void,
  creatingFolder: boolean,
  cancreate: boolean,
  ispremium: boolean
}) =>
  <>
    <PublicFolderTop />
    {!cancreate && <SubTitle>{__("premium_for_public", "Upgrade to Premium to enable it!")}</SubTitle>}
      {cancreate &&
        ispremium &&
        <Button
          styled="PrimaryButton"
          onClick={onCreateFolder}
          disabled={creatingFolder}
          loading={creatingFolder}
        >
          <TSpan id="enable">Enable</TSpan>
        </Button>}
    {!ispremium && <UpgradeButton />}
    <PublicFolderFeatures />
  </>;

export default PublicFolderCreate;
