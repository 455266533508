import React from "react";
import {
  CheckIcon,
  Icon,
  ListBox,
  ListBoxItem,
  ListBoxItemTextWrapper,
  ListBoxLabel,
  ListBoxSubLabel
} from "../styles";

const List = ({ groupedOptions = [], getOptionProps = () => {}, getListboxProps = () => {} }) => {
  return (
    <ListBox {...getListboxProps()}>
      {groupedOptions.map((option, index) => {
        const { key, ...optionProps } = getOptionProps({ option, index });

        return (
          <ListBoxItem key={key} {...optionProps}>
            {(!!option.iconContent || !!option.icon) && (
              <Icon>
                {!!option.iconContent && option.iconContent}
                {!!option.icon && <i className={option.icon} />}
              </Icon>
            )}
            <ListBoxItemTextWrapper>
              <ListBoxLabel>{option.label}</ListBoxLabel>
              {!!option.subLabel && <ListBoxSubLabel>{option.subLabel}</ListBoxSubLabel>}
            </ListBoxItemTextWrapper>
            <CheckIcon className="fa-regular fa-check" />
          </ListBoxItem>
        );
      })}
    </ListBox>
  );
};

export default List;
