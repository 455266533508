import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import UserInvitationContainer from "./UserInvitationContainer";
import { loadFbApi } from "../../lib";
import { useSelector } from "react-redux";
import apiMethod from "../../api/apiMethod";
import InvitationStatsContainer from "./InvitationStatsContainer";
import Alert from "../../components/Alert";
import TermsContainer from "./TermsContainer";
import InvitationWinnerContainer from "./InvitationWinnerContainer";

const InviteFriends = () => {
  const isVerified = useSelector(({ pCloudUser }) => pCloudUser.userinfo.emailverified);
  const isPremiumLifetime = useSelector(({ pCloudUser }) => pCloudUser.userinfo.premiumlifetime);
  const [invites, setInvites] = useState({});
  const [inviteLink, setInviteLink] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isLoadedFbApi, setIsLoadedFbApi] = useState(null);

  useEffect(() => {
    if (isPremiumLifetime) {
      return;
    }
    getInvitesInfo();
    getContactList();
    loadFbApi().then(function(result) {
      setIsLoadedFbApi(result);
    });
  }, []);

  useEffect(() => {
    if (isPremiumLifetime) {
      return;
    }

    if (isVerified && !inviteLink) {
      getInviteLink();
    }
  }, [isVerified]);

  const getContactList = () => {
    apiMethod(
      "contactlist",
      {},
      (ret) => {
        setContacts(ret.contacts);
      },
      {
        errorCallback: (ret) => {
          HFN.message(ret.error, "error");
        }
      }
    );
  };

  const getInvitesInfo = () => {
    apiMethod(
      "getinvitesinfo",
      {},
      (ret) => {
        setInvites(ret);
      },
      {
        errorCallback: (ret) => {
          HFN.message(ret.error, "error");
        }
      }
    );
  };

  const getInviteLink = () => {
    apiMethod(
      "invite",
      {},
      (ret) => {
        setInviteLink(ret);
      },
      {
        errorCallback: (ret) => {
          HFN.message(ret.error, "error");
        }
      }
    );
  };

  return (
    <Wrapper>
      {isPremiumLifetime && (
        <Alert theme="warning" centerText multiline>
          {__("Sorry but this competition is available only for users that do not have Lifetime Plans.")}
        </Alert>
      )}
      {!isPremiumLifetime && (
        <>
          <UserInvitationContainer
            inviteLink={inviteLink}
            isLoadedFbApi={isLoadedFbApi}
            invites={invites}
            contacts={contacts}
            isVerified={isVerified}
          />
          <InvitationWinnerContainer invites={invites} />
          <InvitationStatsContainer invites={invites} />
          <TermsContainer />
        </>
      )}
    </Wrapper>
  );
};

export default InviteFriends;
