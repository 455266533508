// @flow

import React, { useState, useEffect, useRef } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import * as Style from "../../Modals/styledComponents";
import { Btn } from "../../ButtonDefault";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { errorKeys } from "../../../lib/errors";

import store from "../../../lib/state/store";
import { modifyContentItem } from "../../../lib/state/reducers/contentSlice";

type RenamePlaylistModalProps = {
    playlistobj: Array<any>
};

const RenamePlaylistModal = ({
    playlistobj = {}
}: RenamePlaylistModalProps) => {
    const { id } = playlistobj;

    const [isOpen, setIsOpen] = useState(true);
    const [newName, setPlaylistName] = useState(
        HFN.metaName(playlistobj) || ""
    );
    const [isLoading, setIsLoading] = useState(false);

    const inputTextRef = useRef(null);

    useEffect(() => {
        var renameInput = inputTextRef.current;

        createSelection(renameInput, 0, renameInput.value.length);
        renameInput.focus();
    }, []); //[]

    const handleClose = () => {
        setIsOpen(false);
        setPlaylistName("");
    };

    const onNameChange = ({ target }) => {
        setPlaylistName(target.value);
    };

    const onEnter = (e: any) => {
        if (e.keyCode === 13) {
            onRenameButtonClick();
        }
    };

    const onRenameButtonClick = () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);

        if (newName.trim().length < 1) {
            HFN.message(__("New name is required."), "errror");
            setIsLoading(false);
            setPlaylistName("");
            return;
        }

        apiMethod(
            "collection_rename",
            { collectionid: id, name: newName },
            () => {
                HFN.message(__("Playlist Renamed."));
                
                store.dispatch(modifyContentItem({ ...playlistobj, name: newName }));

                handleClose();
            },
            {
                errorCallback: ({ result, error }) => {
                    if (errorKeys[result]) {
                        HFN.message(__(errorKeys[result]), "error");
                    } else {
                        HFN.message(
                            __("something_went_wrong_refresh_and_try_again"),
                            "error"
                        );
                        setIsLoading(false);
                        throw new Error(error);
                    }
                    setIsLoading(false);
                }
            }
        );
    };

    const renderRenameBody = () => {
        return (
            <React.Fragment>
                <Style.Label>{__("Name", "Name")}</Style.Label>
                <InputText
                    innerRef={inputTextRef}
                    name="newName"
                    placeholder={__("Name")}
                    onChange={onNameChange}
                    value={newName}
                    autoFocus
                    onKeyDown={onEnter}
                />
                <Style.Footer>
                    <Btn
                        color="lightgray4"
                        style={{
                            marginRight: "5px"
                        }}
                        onClick={handleClose}
                    >
                        {__("Cancel")}
                    </Btn>
                    <Btn
                        color="cyan"
                        style={{
                            marginLeft: "5px"
                        }}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onRenameButtonClick}
                    >
                        {__("Rename")}
                    </Btn>
                </Style.Footer>
            </React.Fragment>
        );
    };

    return (
        <Modal align="top" onClose={handleClose} opened={isOpen}>
            <OvalModalWrap>
                <CloseModalIcon onClick={handleClose} />
                <Style.Container>
                    <Style.Header>{__("Rename Playlist")}</Style.Header>
                    {renderRenameBody()}
                </Style.Container>
            </OvalModalWrap>
        </Modal>
    );
};

export default RenamePlaylistModal;
