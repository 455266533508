import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Item from "./Item";
import ReusableMenu from "../../../UI/ReusableMenu";
import BreadcrumbDropdown from "./BreadcrumbDropdown";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import { __ } from "../../../../../lib/translate";
import calculateSize from "../../../../../lib/calculateTextWidth";
import { AnimatePresence } from "framer-motion";
import hashManager from "../../../../../lib/hashManager";

const Breadcrumb = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    const breadcrumbData = useSelector(({ content }) => content.breadcrumb);
    const itemData = useSelector(({ content }) => content.itemData);
    const breadcrumbLength = Object.keys(breadcrumbData).length;
    const breadcrumbRef = useRef(null);
    const [showItems, setShowItems] = useState([]);
    const breadcrumbKeys = Object.keys(breadcrumbData);
    const isBackupPage =
        !!hashManager.getState("backupmobile") || !!hashManager.getState("backupdesktop");

    useEffect(() => {
        if (breadcrumbLength < 1 || !breadcrumbRef.current) {
            return;
        }
        const parent = breadcrumbRef.current.parentNode;

        handleDynamicWidth();
        const observer = new ResizeObserver(handleDynamicWidth);
        observer.observe(parent);

        return () => {
            observer.unobserve(parent);
        };
    }, [breadcrumbData, breadcrumbRef]);

    const handleDynamicWidth = () => {
        const breadcrumbShortData = getShortBreadcrumbData(
            breadcrumbData,
            hashManager.getState("page")
        );
        const parent = breadcrumbRef.current.parentNode;
        const containerWidth = parent.offsetWidth;
        const breadcrumbNames = [];
        const page = hashManager.getState("page");
        const time = hashManager.getState("time");

        // Calculate every breadcrumb item width
        let i;
        for (i in breadcrumbShortData) {
            if (i == 0 && page === "time" && time) {
                breadcrumbShortData[i].name +=
                    " at " +
                    HFN.toBeautyDate(time) +
                    " " +
                    HFN.toParseTime(time)
                        .split(":")
                        .slice(0, 2)
                        .join(":");
            }

            const tmpSize = calculateSize(breadcrumbShortData[i].name, {
                font: "'Roboto',sans-serif",
                fontSize: "20px",
                fontWeight: "600"
            });
            breadcrumbNames.push({
                key: breadcrumbShortData[i].key,
                name: breadcrumbShortData[i].name,
                width: tmpSize.hasOwnProperty("width") ? tmpSize.width : 0
            });
        }

        const divider = 40; // Modifier for divider width. Include icon and gap

        // Get home item
        const homeItem = breadcrumbNames.shift();
        let currentWidth = homeItem.width + divider * 2;
        const tmpShowItems = [homeItem.key]; // Variable for store allowed to show folder ids
        breadcrumbNames.reverse(); // Reverse needed because we want to show more last folders

        breadcrumbNames.forEach((item, index) => {
            // Direct include last folder
            if (index === 0) {
                currentWidth += item.width + divider;
                tmpShowItems.push(item.key);
                return;
            }

            currentWidth += item.width + divider;

            /*
             * availableModifierForEllipsis is a tolerance if item is longer than 150 width we can set ellipsis for it
             * breadcrumbNames[0].width / 3 is represent wast item with ellipsis part
             */
            const availableModifierForEllipsis =
                breadcrumbNames[0].width > 150
                    ? breadcrumbNames[0].width / 3
                    : 0;
            if (currentWidth < containerWidth + availableModifierForEllipsis) {
                tmpShowItems.push(item.key);
            }
        });

        setShowItems(tmpShowItems);
    };

    if (!breadcrumbLength && !itemData.folderid) {
        return <Item folder={{ ...itemData }} breadcrumbLength={1} index={0} />;
    }

    if (isMobile && breadcrumbLength > 1) {
        return (
            <>
                <ReusableMenu />
                <BreadcrumbDropdown
                    prevFolder={
                        breadcrumbLength > 1
                            ? breadcrumbData[
                                  breadcrumbKeys[breadcrumbLength - 2]
                              ]
                            : null
                    }
                    breadcrumbLength={breadcrumbLength}
                    breadcrumbData={breadcrumbData}
                />
            </>
        );
    }

    const breadcrumb = breadcrumbKeys.map((foldertmp, index) => {
        const folder = { ...breadcrumbData[foldertmp] };
        if (
            breadcrumbLength > 1 &&
            index !== 1 &&
            showItems.length &&
            !showItems.includes(folder.id) &&
            !(
                folder.folderid === 0 &&
                (folder.isbackup || folder.iscryptomember)
            )
        ) {
            return;
        }

        return (
            <Item
                showSkipDots={
                    breadcrumbLength > 1 &&
                    index === 1 &&
                    showItems.length &&
                    !showItems.includes(folder.id)
                }
                prevFolder={
                    breadcrumbLength > 1
                        ? breadcrumbData[breadcrumbKeys[breadcrumbLength - 2]]
                        : null
                }
                breadcrumbLength={breadcrumbLength}
                isBackupPage={isBackupPage}
                key={index}
                folder={folder}
                index={index}
            />
        );
    });

    return (
        <>
            <ReusableMenu />
            <BreadcrumbWrapper
                ref={breadcrumbRef}
                id="breadcrumb"
                style={{
                    opacity:
                        !breadcrumbLength ||
                        (breadcrumbLength > 1 && !showItems.length)
                            ? 0
                            : 1
                }}
            >
                {breadcrumbLength > 1 && (
                    <AnimatePresence initial={false}>
                        {breadcrumb}
                    </AnimatePresence>
                )}
                {breadcrumbLength <= 1 && breadcrumb}
            </BreadcrumbWrapper>
        </>
    );
};

export default Breadcrumb;

export const getShortBreadcrumbData = (breadcrumbData, page) => {
    const keys = Object.keys(breadcrumbData);
    return keys.map((foldertmp) => {
        const folder = breadcrumbData[foldertmp];
        let folderName = "";

        if (folder.name === "/") {
            if (page === "trash") {
                folderName = __("Trash", "Trash");
            } else {
                folderName = __("My pCloud ", "My pCloud");
            }
        } else if (
            folder.name === "Backups"
        ) {
            folderName = __(folder.name);
        } else {
            folderName = HFN.metaName(folder);
        }

        if (folder.nameSubfix) {
            folderName += folder.nameSubfix;
        }
        
        return {
            key: foldertmp,
            id: folder.folderid,
            name: folderName
        };
    });
};

const BreadcrumbWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    transition-delay: 0.3s;
    @media (max-width: 1023px) {
        padding-right: 40px;
        gap: 0;
    }
`;
