import React from "react";
import styled from "styled-components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled as MUIStyled } from '@mui/material/styles';
import { MOBILE_WIDTH_BREAKPOINT } from "../../config/constants";

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderBottom: "var(--border-size, 1px) solid var(--border-primary, #E4E5E7)",
  '& .MuiTabs-indicator': {
    backgroundColor: "var(--color-primary-500)",
  },
  '& .MuiTabScrollButton-root': {
    paddingBottom: "8px"
  },
  maxWidth: "1140px",
});

export const StyledTab = MUIStyled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "var(--color-base-black)",
    // fontSize: "13px",
    fontFamily: 'Roboto',
    padding: "0 16px 8px 16px",

    '&.Mui-selected': {
      color: "var(--color-base-black)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: "transparent",
    },
  }),
);

export const ActionWrapper = styled.div`
    text-align: right;
    padding: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) 0;

    @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
        text-align: left;
        padding: var(--spacing-xs) var(--spacing-md) var(--spacing-md) var(--spacing-md);
    }
`;

export const TabsAndContentWrapper = styled.div`
  display: flex;
  padding-top: ${({$withActionBar}) => ($withActionBar ? '0' : '20px')};
  box-sizing: border-box;
  height: 100%;
  @media(max-width: 768px) {
    padding-top: 0;
    padding-bottom: 160px;
  }
`;

export const TabsAndContentWrapperInner = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TabContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--spacing-lg) 0;
  max-width: 1140px;
  height: 100%;
  @media(max-width: 768px) {
    padding: 0;
  }
`;

export const OuterScrollWrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
`;