// @flow

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import styled from "styled-components";

import { __ } from "../../../lib/translate";
import { open3DsecureIframe, on3DsecureError } from "../../../lib/utils";
import apiMethod from "../../../api/apiMethod";
import { updatePaymentInfoCall } from "../../../lib/paymentsUtils";
import { parseSettingsInfo } from "../../../lib/state/parseUser";

import PaymentView from "../../PaymentForm/PaymentView";
import { setUserSettingsInfo } from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";
import ModalTemplate from "../../../components/Modals/ModalTemplate";

type Props = {
  price: number,
  members: number,
  period: number,
  providerId: number,
  currency: string,
  prorationtime: boolean,
  endTrial: boolean,
  onSuccess: () => void
};

const PaymentFormStep = ({
  price = 12,
  members = 0,
  period = 1,
  providerId = 0,
  currency = "EUR",
  prorationtime = 0,
  endTrial = false,
  onSuccess = () => {}
}: Props) => {
  const [cart, setCart] = useState(null);
  const [paymentProvider, setPaymentProvider] = useState("safecharge");
  const [country, setCountry] = useState("US");

  const dispatch = useDispatch();

  const analytics = {
    category: "business",
    label: "payment"
  };

  useEffect(() => {
    apiMethod(
      "getip",
      {},
      ({ country }) => {
        setCountry(country);
      },
      {
        errorCallback: ({ error }) => {
          throw new Error(error);
        }
      }
    );
  }, []);

  const onPaymentSuccess = () => {
    onSuccess();
    setTimeout(() => {
      updatePaymentInfoCall(ret => {
        dispatch(setUserSettingsInfo(parseSettingsInfo(ret)));
      });
    }, 1000);
  };

  return (
    <ModalTemplate 
      key="paymentStep"
      externalModal
      title={__("b_billing_complate_order", "Complete Your Order")}
      containerProps={{style: {gap: 0}}}
    >
      <PaymentViewWrapper>
        <PaymentView
          planId={[]}
          billingPeriod={period}
          providerId={providerId}
          color="green"
          currency={currency}
          country={country}
          showSuccessMessage={onPaymentSuccess}
          open3DsecureIframe={open3DsecureIframe}
          on3DsecureError={on3DsecureError}
          cart={cart}
          setCart={setCart}
          paymentProvider={paymentProvider}
          setPaymentProvider={setPaymentProvider}
          analytics={analytics}
          price={price}
          members={members}
          prorationtime={prorationtime}
          endTrial={endTrial}
          isBusiness
        />
      </PaymentViewWrapper>
    </ModalTemplate>
  );
};

export default PaymentFormStep;

const PaymentViewWrapper = styled.div`
  .payment-method-title {
    display: none;
  }

  .paymentview_wrapper {
    padding: 0;
    margin-top: var(--spacing-lg);
  }

  .payment-align-container {
    padding: 0 0 var(--spacing-lg) 0;
    box-shadow: none;
    border-radius: 0;
  }

  .payment-form-wrapper {
    max-width: unset;
  }
`;
