import React from "react";
import PropTypes from "prop-types";
import { __ } from "../lib/translate";

import FoldableText from "../components/FoldableText";
import DownloadLinkStatsHolder, { StatsDivider } from "../components/downloadlink/StatsHolder";
import DLInvites from "../containers/DLInvites";
import TrafficChart from "../components/downloadlink/TrafficChart";

const DLStatsSidebar = ({ ...props }) => (
  <DownloadLinkStatsHolder {...props}>
    <StatsDivider />
    <FoldableText title={__("dl_viewedby", "Viewed by")} initialOpen={true}>
      <DLInvites {...props} />
    </FoldableText>
    <StatsDivider />
    <FoldableText title={__("dl_usagestats", "Usage stats")} initialOpen={true}>
      <TrafficChart {...props} />
    </FoldableText>
  </DownloadLinkStatsHolder>
);

DLStatsSidebar.propTypes = {
  token: PropTypes.string.isRequired,
  linkid: PropTypes.number.isRequired,
  onButtonClicked: PropTypes.func.isRequired,
  onInviteUsers: PropTypes.func.isRequired
};

export default DLStatsSidebar;
