import React from "react";
import { Avatar, Column, ProfileInitials } from "../../UI/Style";
import { userInitials } from "../../../../lib/user-utils";
import { prepUrl } from "../../../../api/utils";

const InitialsCell = ({ stretchWidth, item, classNames, contentKey, id, isMobileDevice, onClick, getIsDotted }) => {
  const isDotted = getIsDotted && getIsDotted(item);
  return (
    <Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
      {item.avatar && item.avatar.path && !item.avatar.isdefault ? (
        <Avatar
          onClick={onClick ? (event) => onClick(event, item) : () => {}}
          className={isMobileDevice ? "mobile" : ""}
          $backgroundImage={prepUrl(item.avatar)}
        />
      ) : (
        <ProfileInitials
          onClick={onClick ? (event) => onClick(event, item) : () => {}}
          $status={item.status}
          $isDotted={isDotted}
          className={isMobileDevice ? "mobile" : ""}
        >
          {contentKey === "name" && item.firstname && item.firstname
            ? userInitials({ firstname: item.firstname, lastname: item.lastname })
            : userInitials({ email: item.email })}
        </ProfileInitials>
      )}
    </Column>
  );
};

export default InitialsCell;
