import React, { useMemo } from "react";
import useTabs from "../../../components/Tabs/hooks/useTabs";
import * as SETTINGS from "../businessBillingConstants";
import { StyledTabs, StyledTab, TabsAndContentWrapper, TabsAndContentWrapperInner, TabContent, OuterScrollWrapper } from "../../../components/Tabs/tabs.style";
import MyAccount from "./MyAccount";
import UserPermissions from "./UserPermissions";
import Customization from "./Customization";
import { useSelector } from "react-redux";
import { detectIsMobile } from "@pcloud/web-utilities/dist/lib/utils";

const BusinessAccountContainer = () => {
  const tabs = useMemo(() => {
    const SettingsTabsCopy = SETTINGS.BUSINESS_ACCOUNT_TABS;
    if (!detectIsMobile() && !(SettingsTabsCopy.find((tab) => tab.tabId === SETTINGS.ACCOUNT_USER_CUSTOMIZATION_SETTINGS))) {
      SettingsTabsCopy.push(SETTINGS.ACCOUNT_CUSTOMIZATION)
    }
    return SettingsTabsCopy;
  }, []);
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const canAccountManage = account.permissions.account_manage;

  const {
    activeTab,
    onTabClick
  } = useTabs({
    tabs,
    defaultActiveTabId: SETTINGS.ACCOUNT_DETAILS_SETTINGS,
    activeTabHashParamName: "tab"
  });

  const renderTabContent = () => {
    if (activeTab === SETTINGS.ACCOUNT_DETAILS_SETTINGS) {
      return <MyAccount />;
    } else if (activeTab === SETTINGS.ACCOUNT_USER_PERMISSIONS_SETTINGS) {
      return <UserPermissions />;
    } else if (activeTab === SETTINGS.ACCOUNT_USER_CUSTOMIZATION_SETTINGS && canAccountManage) {
      return <Customization />;
    }
  };

  return (
    <TabsAndContentWrapper>
      <TabsAndContentWrapperInner className="inner-animation animation-type-fade">
        <StyledTabs
          value={activeTab}
          onChange={onTabClick}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs.map(({ tabId, tabTitle }) => (
            <StyledTab
              key={tabId}
              label={__(tabTitle)}
              style={
                !canAccountManage && tabId === SETTINGS.ACCOUNT_USER_CUSTOMIZATION_SETTINGS ?
                  { display: 'none' } : null
              }
            />
          ))}
        </StyledTabs>
        <OuterScrollWrapper>
          <TabContent>
            {renderTabContent()}
          </TabContent>
        </OuterScrollWrapper>
      </TabsAndContentWrapperInner>
    </TabsAndContentWrapper>
  );
};

export default BusinessAccountContainer;