import { useState, useMemo } from "react";
import hashManager from "../../../lib/hashManager";

type Props = {
  tabs: {
    tabId: string,
    tabTitle: string,
    search: string
  },

};

export const useTabs = ({
  tabs,
  defaultActiveTabId,
  activeTabHashParamName = "",
}: Props) => {
  const initialActiveTab = useMemo(() => {
    if (activeTabHashParamName && activeTabHashParamName !== "") {
      const urlSettingsTab = hashManager.getState(activeTabHashParamName);
      if (urlSettingsTab) {
        const tabData = tabs.find(({ search }) => search === urlSettingsTab);
        
        if (tabData) {
          return tabData.tabId;
        }
      }

      const defaultTab = tabs.find(({ tabId }) => tabId === defaultActiveTabId)
      if (defaultTab && (!urlSettingsTab || urlSettingsTab !== defaultTab.search)) {
        hashManager.pushState({[activeTabHashParamName]: defaultTab.search});
      }
    }
    
    return defaultActiveTabId;
  }, []);

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const onTabClick = (event, clickedTab) => {
    const tabData = tabs.find(({ tabId }) => tabId === clickedTab);

    if (activeTabHashParamName !== "" && tabData && hashManager.getState(activeTabHashParamName) != tabData.search) {
      hashManager.pushState({[activeTabHashParamName]: tabData.search});
    }

    setActiveTab(clickedTab);
  }

  return {
    activeTab,
    onTabClick
  };
}

export default useTabs;