// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { editUser } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

type Props = {
  fName: string,
  lName: string,
  currPosition: string
};

const EditUserModal = ({ userId = "", fName = "", lName = "", currPosition = "", isOpen, handleClose }: Props) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(fName);
  const [lastName, setLastName] = useState(lName);
  const [position, setPosition] = useState(currPosition === "0" ? "" : currPosition);
  const [isLoading, setIsLoading] = useState(false);

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSave();
    }
  };

  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
  };

  const onLastNameChange = ({ target }) => {
    setLastName(target.value);
  };

  const onPositionChange = ({ target }) => {
    setPosition(target.value);
  };

  const onSave = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const firstname = firstName.trim();
    const lastname = lastName.trim();
    const params = { firstname, lastname, position: position || 0, userid: userId };

    apiMethod(
      "account_usermodify",
      params,
      () => {
        dispatch(editUser({ id: userId, firstname, lastname, position: position }));

        HFN.message("Info saved.");
        setIsLoading(false);
        handleClose();
      },
      {
        errorCallback: ({ result, error, field }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2103) {
            if (field === "firstname") {
              errorMessage = __("business_edit_user_fname_error");
            } else if (field === "lastname") {
              errorMessage = __("business_edit_user_lname_error");
            } else if (field === "position") {
              errorMessage = __("business_edit_user_position_error");
            }
          } else if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      opened={isOpen}
      title={__("Edit User")}
      cancelLabel={__("Close")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          loading={isLoading}
          onClick={onSave}
        >
          {__("Save")}
        </Button>
      }
    >
      <Style.Label>{__("First Name", "Firt Mame")}</Style.Label>
      <InputText
        name="fistName"
        placeholder={__("modal_add_first_name", "Add first name")}
        onChange={onFirstNameChange}
        value={firstName}
        autoFocus
        style={{ marginBottom: "20px" }}
        onKeyDown={onEnter}
      />
      <Style.Label>{__("Last Name", "Last name")}</Style.Label>
      <InputText
        name="lastName"
        placeholder={__("modal_add_last_name", "Add last name")}
        onChange={onLastNameChange}
        value={lastName}
        style={{ marginBottom: "20px" }}
        onKeyDown={onEnter}
      />
      <Style.Label>{__("Position", "Position")}</Style.Label>
      <InputText
        name="position"
        placeholder={__("modal_add_position", "Add a position")}
        onChange={onPositionChange}
        value={position}
        onKeyDown={onEnter}
      />
    </ModalTemplate>
  );
};

export default EditUserModal;
