// @flow

import React, { useState, useEffect, useCallback } from "react";
import Componentify from "react-componentify";

import Button from "../ButtonDefault";

import { brTagConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";
import { rcookie } from "../../lib/utils";
import apiMethod from "../../api/apiMethod";
import { errorKeys } from "../../lib/errors";
import ModalTemplate from "./ModalTemplate";

type Props = {
  isOpen: boolean,
  isPremium: boolean,
  useButtonLoader: boolean,
  onDeactivateConfirm: () => void,
  onClose: () => void
};

const DeactivatePublicFolderPage = ({
  isOpen,
  isPremium,
  useButtonLoader = false,
  onDeactivateConfirm,
  onClose
}: Props) => {
  const [isOpened, setIsOpened] = useState(isOpen);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOpened(isOpen);
  }, [isOpen]); //[isOpen]

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleOnDeactivateConfirm = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod(
      "deactivatepublicfolder",
      {
        auth: rcookie("pcauth")
      },
      () => {
        setLoading(false);
        handleClose();
        HFN.message(__("deactivate_public_folder_success", "Public Folder deactivated."));
      },
      {
        errorCallback: ({ result, error }) => {
          setLoading(false);
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        if (typeof onDeactivateConfirm === "function") {
          onDeactivateConfirm();
        } else {
          handleOnDeactivateConfirm();
        }
      }
    },
    [onDeactivateConfirm, handleOnDeactivateConfirm]
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpened, onEnter]); //[isOpened, onEnter]

  const messageModal = __(
    "public_folder_deactivation_message_modal",
    "Are you sure you want to deactivate your public folder?<br/>All your Direct links will be disabled."
  );
  return (
    <ModalTemplate
      animate
      onClose={onClose || handleClose}
      opened={isOpened}
      title={__("public_folder_deactivation_header")}
      description={<Componentify text={messageModal} converters={[brTagConverter]} />}
      buttonsComponent={
        <Button
          styled="ModalDangerButton"
          disabled={(useButtonLoader || false) && loading}
          loading={(useButtonLoader || false) && loading}
          onClick={onDeactivateConfirm || handleOnDeactivateConfirm}
        >
          {__("public_folder_deactivation_header")}
        </Button>
      }
    ></ModalTemplate>
  );
};

export default DeactivatePublicFolderPage;
