// @flow

import React, { useState, useEffect } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Btn } from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import { styledSpanTagConverter } from "../../lib/componentifyConverters";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      color: "#17bed0",
      paddingLeft: "5px",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  data: object,
  opts: object
};

const PreviewGenericModal = ({ data, opts }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [openFileButtonUrel, setOpenFileButtonUrel] = useState("");

  useEffect(() => {
    HFN.getFileLinkBack(
      data,
      url => {
        setOpenFileButtonUrel(url);
      },
      opts
    );
  }, [data, opts]); //[data]

  const handleClose = () => {
    setIsOpen(false);
  };

  const onOpenFileClick = () => {
    setIsOpen(false);
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Componentify
            text={`${__("Preview Document", "Preview Document")}<span>"${data.name}"</span>`}
            converters={[customConverter]}
          />
          <Style.Footer>
            <Btn
              href={openFileButtonUrel}
              color="cyan"
              style={{
                marginRight: "5px"
              }}
              onClick={onOpenFileClick}
            >
              {__("Open File", "Open File")}
            </Btn>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default PreviewGenericModal;
