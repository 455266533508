// @flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

import Button from "../../../components/ButtonDefault";
import { Loader } from "../../PaymentForm/PaymentLoader";
import { setProcessingProfile } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../../../components/Modals/ModalTemplate";

type Props = {
  onSuccess: () => void,
  onClose: () => void
};

const CancelSubscriptionStep = ({ onSuccess = () => {}, onClose = () => {} }: Props) => {
  const dispatch = useDispatch();

  const isTrial = useSelector(
    ({ pCloudUser }) => pCloudUser.userinfo.account && pCloudUser.userinfo.account.is_trial
  );
  const billingInfo = useSelector(({ business }) => business.billingInfo);
  const { subscriptions = [] } = billingInfo;
  const [loader, setLoader] = useState(false);

  const onSubmit = () => {
    const { billingprovider, id, pcloudmanaged, stripesubscriptionid } = subscriptions[0];
    const params = {
      billingprovider: billingprovider,
      subscriptionid: stripesubscriptionid || id,
      pcloudmanaged: pcloudmanaged ? 1 : 0
    };
    setLoader(true);
    apiMethod(
      "cancelpaymentsubscription",
      params,
      res => {
        console.log("RESSSS", res);
        setLoader(false);
        onSuccess();
        dispatch(setProcessingProfile());
      },
      {
        errorCallback: res => {
          HFN.message(
            __(
              "cancel_subsc_error_message",
              "There was a problem canceling your subscription. Please try again later."
            ),
            "error"
          );
          setLoader(false);
          onClose();
          console.log("ERRORR", res);
        }
      }
    );
  };

  if (loader) {
    return (
      <ModalTemplate
        externalModal
        key="cancelSubsc"
        containerProps={{style: { minHeight: "249px" }}}
      >
        <Loader color="green" />
      </ModalTemplate>
    );
  }

  return (
    <ModalTemplate 
      externalModal
      key="cancelSubsc"
      title={__("cancel_subsc_modal_header", "Are you sure you want to cancel?")}
      description={isTrial ? __("cancel_subs_modal_desc_trial") : __("cancel_subs_modal_desc")}
      cancelable={false}
      buttonsComponent={
        <>
          <Button
            styled="ModalCancelButton"
            onClick={onSubmit}
          >
            {__("cancel_subs_modal_bnt_cancel", "Cancel subscription")}
          </Button>
          <Button
            styled="ModalActionButton"
            onClick={onClose}
          >
            {__("cancel_subs_modal_bnt_keep", "Keep plan")}
          </Button>
        </>
      }
    />
  );
};

export default CancelSubscriptionStep;
