//@flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";
import { boldConverter, linkConverter, linkConverterV2 } from "../lib/componentifyConverters";

import { __ } from "../lib/translate";

import apiMethod from "../api/apiMethod";
import BulbIcon from "../../root/img/svg/bulb.svg";

import * as Style from "./SharedInvitationComponents";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";

const UnsubscribeContainer = ({ code = "" }: { code: string }) => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    apiMethod(
      "unsubscribe",
      { code: code },
      ret => {
        setLoading(false);
      },
      {
        errorCallback: ({ result, error }) => {
          setErrorMessage(error);
        },
      }
    );
  }, []);

  const onGoToNotificationSettings = () => {
    window.open("https://my.pcloud.com/#page=settings&settings=tab-notifications", "_self");
  };

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      {loading ? (
        errorMessage ? (
          <Style.WarningMessage style={{ marginTop: "15px" }}>
            <Style.WarnIcon />
            <Style.ComponentifyWrapper>
              <Componentify text={errorMessage} converters={[boldConverter]} />
            </Style.ComponentifyWrapper>
          </Style.WarningMessage>
        ) : (
          <Style.LoadingWrapper>
            <div />
          </Style.LoadingWrapper>
        )
      ) : (
        <>
          <Style.Title>{__("quick_unsubscribe_title", "You have been unsubscribed")}</Style.Title>
          <Style.Message>
            <BulbIcon />
            <Style.ComponentifyWrapper>
              <Componentify text={__(
                "quick_unsubscribe_message",
                "You will no longer receive communication about promotions and special offers. You can always subscribe again from the https://my.pcloud.com/#page=settings&settings=tab-notifications/[Notification settings]"
              )} converters={[boldConverter, linkConverter, linkConverterV2]} />
            </Style.ComponentifyWrapper>
          </Style.Message>
        </>
      )}
    </Style.Container>
  );
};

export default UnsubscribeContainer;