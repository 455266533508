import React from "react";
import { InvitationWinnerContainerWrapper, WinnerDetails, WinnerHeading, WinnerSubTitle, WinnerTitle } from "./styles";
import { toBeautyMonthYear } from "../../lib";
import PrizeIcon from "../../../root/img/svg/invite-friends-prize.svg";

const InvitationWinnerContainer = ({ invites }) => {
  const lastWinner = invites.winner;

  if (!lastWinner) {
    return null;
  }

  return (
    <InvitationWinnerContainerWrapper>
      <WinnerHeading>
        <WinnerTitle>
          {__("Winner")+ " " + toBeautyMonthYear(lastWinner.dt)}
        </WinnerTitle>
        <WinnerSubTitle>
          500 GB LIFETIME
        </WinnerSubTitle>
      </WinnerHeading>
      <WinnerDetails>
        <span>{lastWinner.email}</span>
        <span>{__("%count% invites sent", "%count% invites sent", {count: lastWinner.totalinvites})}</span>
      </WinnerDetails>
      <PrizeIcon />
    </InvitationWinnerContainerWrapper>
  );
};

export default InvitationWinnerContainer;
