// @flow

import React, { useState, useEffect, useCallback } from "react";

import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

type Props = {
  memberId: number,
  memberFirstName: boolean,
  memberLastName: number,
  memberEmail: string
};

const ActivateUserModal = ({
  memberId = 0,
  memberFirstName = "",
  memberLastName = "",
  memberEmail = "",
  resetModalAction = () => {},
  isOpen = false,
  handleClose = () => { },
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const businessMembers = useSelector(({ business }) => business.users);
  const currenMember = businessMembers.find(member => member.id === parseInt(memberId)) || {};
  const dispatch = useDispatch();

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      onActivateUserBtnClick();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const onActivateUserBtnClick = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_usermodify",
      { userid: memberId, active: 1, frozen: 0 },
      () => {
        dispatch(editUser({...currenMember, active: 1, frozen: 0}))
        setIsLoading(false);
        HFN.message(__("User Activated."));
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      opened={isOpen}
      title={__("Activate User")}
      buttonsComponent={
        <Button
          loading={isLoading}
          styled="ModalPrimaryButton"
          onClick={onActivateUserBtnClick}
        >
          {__("Activate")}
        </Button>
      }
    >
      <Style.User>
        <Style.UserLabel>{__("User")}</Style.UserLabel>
        <Style.UserData>
          <Style.Name>{`${memberFirstName} ${memberLastName}`}</Style.Name>
          <Style.Email>({memberEmail})</Style.Email>
        </Style.UserData>
      </Style.User>
    </ModalTemplate>
  );
};

export default ActivateUserModal;
