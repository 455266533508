import React from "react";
import {
  FilemanagerLoaderGrid, SectionLabel,
  LoaderAnimation, LoadingFolder,
  LoadingFoldersLabel,
  LoadingHeaderRow,
  LoadingHeaderRowMenu, LoadingItems, LoadingFilesLabel, LoadingFile, LoadingFileThumb, LoadingFileCaption
} from "./styles";

const SkeletonGrid = () => {
  const countFolders = 10;
  const countItems = 10;

  return (
    <FilemanagerLoaderGrid>
      <LoaderAnimation />
      <LoadingHeaderRow className="loading-gray-boxes">
        <LoadingHeaderRowMenu />
        <LoadingHeaderRowMenu />
      </LoadingHeaderRow>
      <LoadingFoldersLabel>
        <SectionLabel />
      </LoadingFoldersLabel>
      <LoadingItems>
        {Array(countFolders)
          .fill()
          .map((i, index) => {
            let additionalRowClass = "";
            if (countFolders - index < 5) {
              additionalRowClass = " loading-item-last-" + (countFolders - index);
            }
            return (
              <LoadingFolder key={index} className={additionalRowClass} />
          );
          })}
      </LoadingItems>
      <LoadingFilesLabel>
        <SectionLabel />
      </LoadingFilesLabel>
      <LoadingItems>
        {Array(countItems)
          .fill()
          .map((i, index) => {
            let additionalRowClass = "";
            if (countItems - index < 5) {
              additionalRowClass = " loading-item-last-" + (countItems - index);
            }
            return (
              <LoadingFile key={index} className={additionalRowClass}>
                <LoadingFileThumb />
                <LoadingFileCaption />
              </LoadingFile>
            );
          })}
      </LoadingItems>
    </FilemanagerLoaderGrid>
  );
}

export default SkeletonGrid;