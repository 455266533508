import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as CommonStyle from "../../../components/CommonStyledSettings"
import { renderQuota } from '@pcloud/web-utilities/dist/plans/utils.plans'
import EditCompanyNameModal from '../../../components/Modals/Business/EditCompanyNameModal'
import EditCompanyPhoneModal from '../../../components/Modals/Business/EditCompanyPhoneModal'
import RequestMoreSpaceModal from '../../../components/Modals/Business/RequestMoreSpaceModal'
import { BUSINESS_PLANS_ID_MAP, BUSINESS_PLAN_2_ID } from '@pcloud/web-utilities/dist/plans/constants.plans'
import hashManager from "../../../lib/hashManager";
import { __ } from "../../../lib/translate";
import { getBusinessPlanName } from "../../../lib/plansInfo";
import Loader from "../../../components/Loader";

const MyAccount = () => {
  const accountInfo = useSelector(({ business }) => business?.accountInfo);
  const businessPlanId = accountInfo?.account?.businessplanid;
  const userInfo = useSelector(({ pCloudUser }) => pCloudUser?.userinfo);
  const { account } = accountInfo
  const { owner } = account
  const hideRequestSpace = accountInfo.businessplanid === BUSINESS_PLANS_ID_MAP[BUSINESS_PLAN_2_ID] && (accountInfo.istrial || !userInfo?.account?.owner)
  const userAccount = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const canAccountManage = userAccount.permissions.account_manage;

  const [isEditNameOpen, setIsEditNameOpen] = useState(false)
  const [isEditPhoneOpen, setIsEditPhoneOpen] = useState(false)
  const [isRequestMoreStorageOpen, setIsRequestMoreStorageOpen] = useState(false);

  if (!owner) {
    return <Loader />;
  }

  const navigateToUserBusinessUserInfo = () => {
    hashManager.pushState({ page: "b_user", 'tab-user': "userinfo", id: owner.id }, 2);
  }

  return (
    <>
      <EditCompanyNameModal isOpen={isEditNameOpen} handleClose={() => setIsEditNameOpen(false)} />
      <EditCompanyPhoneModal isOpen={isEditPhoneOpen} handleClose={() => setIsEditPhoneOpen(false)} />
      <RequestMoreSpaceModal isOpen={isRequestMoreStorageOpen} handleClose={() => setIsRequestMoreStorageOpen(false)} />
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__("plan_name", "Plan")}</CommonStyle.Title>
          <CommonStyle.Description>{!!businessPlanId &&  getBusinessPlanName(businessPlanId)}</CommonStyle.Description>
        </div>
      </CommonStyle.Row>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('BA_total_storage')}</CommonStyle.Title>
          <CommonStyle.Description>{renderQuota(account?.totalquota)}</CommonStyle.Description>
        </div>
        {!hideRequestSpace &&
          <CommonStyle.Button onClick={() => setIsRequestMoreStorageOpen(true)}>{__('Request More Space')}</CommonStyle.Button>
        }
      </CommonStyle.Row>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('Company name')}</CommonStyle.Title>
          <CommonStyle.Description>{account?.company}</CommonStyle.Description>
        </div>
        {canAccountManage && (
          <CommonStyle.Button onClick={() => setIsEditNameOpen(true)}>
            {__('BA_edit_company_name', 'Edit company name')}
          </CommonStyle.Button>
        )}
      </CommonStyle.Row>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('Owner')}</CommonStyle.Title>
          <CommonStyle.Description>{`${owner?.firstname} ${owner?.lastname} (${owner?.email})`}</CommonStyle.Description>
        </div>
        <CommonStyle.Button onClick={navigateToUserBusinessUserInfo}>
          {__("BA_view_owner_btn", 'View owner info')}
        </CommonStyle.Button>
      </CommonStyle.Row>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title>{__('Phone')}</CommonStyle.Title>
          <CommonStyle.Description>{account?.phone}</CommonStyle.Description>
        </div>
        {canAccountManage && (
          <CommonStyle.Button onClick={() => setIsEditPhoneOpen(true)}>
            {__("BA_edit_company_phone", 'Edit company phone')}
          </CommonStyle.Button>
        )}
      </CommonStyle.Row>
    </>
  )
}

export default MyAccount