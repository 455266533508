// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Button from "../../ButtonDefault";
import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { updateUserBranding } from '@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice';
import { useDispatch } from "react-redux";
import ModalTemplate from "../ModalTemplate";

type Props = {
  onSuccess: () => void,
  isOpen: boolean,
  handleClose: () => void,
};

const DeleteLogoModal = ({ isOpen, handleClose, onSuccess = () => { } }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        deleteLogo();
      }
    },
    [deleteLogo]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  const deleteLogo = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_branding_set",
      { deletelogo: 1 },
      () => {
        HFN.message(__("Logo deleted."));
        onSuccess();
        dispatch(updateUserBranding({ bgcolor: false, logo: false }));
        handleClose();
        setIsLoading(false);
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);

          let errorMessage = __("something_went_wrong_refresh_and_try_again");
          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      opened={isOpen}
      title={__("delete_branding_logo")}
      description={__("delete_logo_confirmation")}
      buttonsComponent={
        <Button
          styled="ModalDangerButton"
          loading={isLoading}
          onClick={deleteLogo}
        >
          {__("Delete", "Delete")}
        </Button>
      }
    >
    </ModalTemplate>
  );
};

export default DeleteLogoModal;