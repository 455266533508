import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";

export const calculateTrafficData = (traffic) => {
  let min = 0;
  let periodMin = 0;
  let max = 0;
  let periodMax = 0;
  let sum = 0;
  let sumInPeriod = 0;
  let cnt = 0;
  let avg = 0;
  const periodRange = HFN.calcTrafficPeriod();

  traffic.forEach(function(day, index) {
    if (max < parseInt(day.traffic)) max = parseInt(day.traffic);

    if (min == undefined || min > parseInt(day.traffic)) min = parseInt(day.traffic);

    if (inPeriod(day.dt, periodRange.begin)) {
      sumInPeriod += parseInt(day.traffic);
    }
    sum += parseInt(day.traffic);
    cnt += 1;

    if (periodMax < parseInt(day.traffic)) periodMax = parseInt(day.traffic);

    if (periodMin > parseInt(day.traffic)) periodMin = parseInt(day.traffic);

    if (cnt) {
      avg = parseFloat((sum / cnt).toFixed(1));
    }
  });

  return {
    sumInPeriodByte: sumInPeriod,
    sumInPeriod: renderQuota(sumInPeriod),
    sumByte: sum,
    sum: renderQuota(sum),
    avg: renderQuota(avg),
    periodMax: renderQuota(periodMax),
    periodMin: renderQuota(periodMin)
  };
};

function inPeriod(day, periodBegin) {
  const dayTime = new Date(day).getTime();
  return dayTime >= new Date(periodBegin).getTime();
}

export const getTickValues = (formatedData) => {
  let maxValue = Math.max(...formatedData.map((item) => item.value));

  if (!maxValue || maxValue <= 5) return [0, 1, 2, 3, 4, 5];
  if (maxValue <= 10) return [0, 2, 4, 6, 8, 10];

  let numberOfSteps = 5;
  const stepSize = Math.ceil(maxValue / (numberOfSteps - 1));
  const roundedStepSize = Math.ceil(stepSize / 5) * 5;

  return Array.from({ length: numberOfSteps }, (_, i) => roundedStepSize * i);
};

export const getQuotaTickValues = (formatedData) => {
  let maxValue = Math.max(...formatedData.map((item) => item.value));
  if (!maxValue) {
    maxValue = 1024 * 100;
  }
  const units = [
    { label: "B", value: 1 },
    { label: "KB", value: 1024 },
    { label: "MB", value: 1024 * 1024 },
    { label: "GB", value: 1024 * 1024 * 1024 },
    { label: "TB", value: 1024 * 1024 * 1024 * 1024 }
  ];

  function roundUpToNearest(value, digits) {
    const factor = Math.pow(10, digits);
    return Math.ceil(value / factor) * factor;
  }

  let selectedUnit = units.find((unit) => maxValue < unit.value * 1000) || units[units.length - 1];

  const valueInUnit = maxValue / selectedUnit.value;

  let roundedMax;
  if (valueInUnit < 10) {
    roundedMax = roundUpToNearest(valueInUnit, 0);
  } else if (valueInUnit < 100) {
    roundedMax = roundUpToNearest(valueInUnit, 1);
  } else {
    roundedMax = roundUpToNearest(valueInUnit, 2);
  }

  const step = roundedMax / 4;
  const returnData = {};
  for (let i = 0; i < 5; i++) {
    const value = Math.round(step * i * selectedUnit.value);
    returnData[value] = renderQuota(value);
  }
  return returnData;
};

export const generateMarkers = (tickValues) => {
  return tickValues.map((value) => ({
    axis: "y",
    value: value,
    lineStyle: { stroke: "#ddd", strokeWidth: 1 }
  }));
};