// @flow

import React, { useState, useEffect, useCallback } from "react";

import { Button } from "../../ButtonDefault";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { useDispatch, useSelector } from "react-redux";
import { editTeam, loadUsers } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

type Props = {
  teamName: string,
  teamId: number,
  active: boolean
};

const ChangeTeamStatusModal = ({ teamName = "", teamId = 0, active = true, resetModalAction = () => {} }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const businessTeams = useSelector(({ business }) => business.teams);
  const currenTeam = businessTeams.find((team) => team.id === teamId) || {};
  const dispatch = useDispatch();

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      changeStatus();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const changeStatus = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let message = __("Team Activated.");
    const params = {
      teamid: teamId,
      active: 1
    };

    if (active) {
      params.active = 0;
      message = __("Team Deactivated.");
    }

    apiMethod(
      "account_teammodify",
      params,
      () => {
        dispatch(editTeam({ ...currenTeam, active: params.active }));
        fetchData();
        HFN.message(message);
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  const fetchData = () => {
    apiMethod(
      "account_users",
      {
        userpermissions: 1,
        withavatars: 1,
        tracksettings: 1,
        avatarsize: "128x128",
        usersettings: 1,
        showteams: 1,
        showeveryone: 1
      },
      (ret) => {
        dispatch(loadUsers(ret.users));
      },
      { forceFresh: true }
    );
  };

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      opened={isOpen}
      title={
        active
          ? __("modal_change_team_status_header", "Deactivate Team")
          : __("modal_change_team_status_header_activate", "Activate Team")
      }
      subTitle={
        active
          ? __("modal_change_team_status_confirm_message", "Are you sure you want to deactivate %name%", {
              name: teamName
            })
          : __("modal_change_team_status_confirm_message_activate", "Are you sure you want to activate %name%", {
              name: teamName
            })
      }
      description={
        active
          ? __(
              "modal_change_team_status_confirm_explain",
              "Deactivating this team will remove access to all shared folders currently accessible within the team. Team members will no longer be able to view or edit files in these shared folders."
            )
          : __(
              "modal_change_team_status_confirm_explain_activate",
              "Activating this team will allow its members to access shared folders within the team. Team members will be able to view, edit, and collaborate on files shared among the team."
            )
      }
      buttonsComponent={
        <Button styled={active ? "ModalDangerButton" : "ModalPrimaryButton"} onClick={changeStatus}>
          {active ? __("Deactivate") : __("Activate")}
        </Button>
      }
    />
  );
};

export default ChangeTeamStatusModal;
