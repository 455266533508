import React from "react";
import { Column, FaIcon } from "../../UI/Style";

const FaIconCell = ({ stretchWidth, item, classNames, id, onClick, icon = '', getIcon = false }) => {
  return (
    <Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
      <FaIcon
        onClick={onClick ? (event) => onClick(event, item) : () => {}}
      >
        <i className={getIcon ? getIcon(item) : icon} />
      </FaIcon>
    </Column>
  );
};

export default FaIconCell;
