// @flow

import React, { useState } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import apiMethod from "../../../api/apiMethod";
import { Btn } from "../../ButtonDefault";
import * as Style from "../styledComponents";

import { __ } from "../../../lib/translate";
import { errorKeys } from "../../../lib/errors";
import hashManager from "../../../lib/hashManager";

const TrashDeleteModal = () => {
  const [isOpened, setIsOpened] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shouldRenderAttention, setShouldRenderAttention] = useState(false);

  const onCloseClick = () => {
    if (loading) {
      return;
    }
    setIsOpened(false);
  };

  const onEmptyTrashClick = () => {
    setShouldRenderAttention(true);
  };

  const onDeleteForeverClick = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    apiMethod(
      "trash_clear",
      { folderid: 0 },
      ret => {
        HFN.message(__("Trash is emptied."));
        HFN.cache.expireMatch("listfolder-list-[^-]+-trash");
        hashManager.pushState({ page: "trash" }, 2);
        if (HFN.pages.current === "trash") {
          HFN.refreshTrashFolder(0);
        }
        setIsOpened(false);
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
          setIsOpened(false);
          throw new Error(error);
        }
      }
    );
  };

  const renderContentDelete = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>{__("Empty Trash")}</Header>
          <Style.Container style={{ paddingBottom: "10px" }}>
            <Style.Title dangerouslySetInnerHTML={{__html: __("trash_modal_empty_trash")}}></Style.Title>
          </Style.Container>
        </Body>
        <Style.Footer>
          <Btn
            style={{ marginRight: "5px" }}
            color="lightgray4"
            disabled={loading}
            onClick={onCloseClick}
          >
            {__("Cancel")}
          </Btn>
          <Btn
            style={{ marginLeft: "5px" }}
            color="red"
            disabled={loading}
            onClick={onEmptyTrashClick}
          >
            {__("Empty Trash")}
          </Btn>
        </Style.Footer>
      </React.Fragment>
    );
  };

  const renderContentAttention = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>{__("Attention", "Attention")}</Header>
          <Style.Title>{__("trash_modal_empty_trash_attention")}</Style.Title>
        </Body>
        <Style.Footer>
          <Btn
            style={{ marginRight: "5px" }}
            color="lightgray4"
            disabled={loading}
            onClick={onCloseClick}
          >
            {__("Cancel")}
          </Btn>
          <Btn
            style={{ marginLeft: "5px" }}
            color="red"
            disabled={loading}
            loading={loading}
            onClick={onDeleteForeverClick}
          >
            {__("Delete Forever")}
          </Btn>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" opened={isOpened} onClose={onCloseClick}>
      <OvalModalWrap>
        <CloseModalIcon onClick={onCloseClick} />
        <Content>
          {shouldRenderAttention ? renderContentAttention() : renderContentDelete()}
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default TrashDeleteModal;

const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;
  
  @media (max-width: 500px) {
    width: 95vw;
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;
