import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  InputSection,
  ShareButton,
  ShareButtonsWrapper,
  ShareSection,
  UserInvitationContainerWrapper,
  FormContent,
  InputNote, FormField, InputGroup
} from "./styles";
import { INVITE_USER_TEXTS } from "./constants";
import { copyToClipboard } from "@pcloud/web-utilities/dist/lib/utils";
import Button from "../../components/ButtonDefault";
import Componentify from "react-componentify";
import { boldConverter } from "../../lib/componentifyConverters";
import { URLMY } from "@pcloud/web-utilities/dist/config";
import { validateEmail } from "../../lib";
import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { ErrorBox,
  Heading,
  SubTitle,
  Title,
  TopIcon, } from "../../styles/sharedStyles";
import MultiSelectSuggester from "../../components/AutoComplete/MultiSelectSuggester";

const UserInvitationContainer = ({
  inviteLink = {},
  isLoadedFbApi = null,
  contacts = [],
  isVerified = false
}) => {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const suggesterRef = useRef({});

  const searchList = useMemo(() => {
    return  contacts.map((item) => {
      return {
        id: item.value,
        label: item.value,
        icon: "fa-regular fa-envelope",
        subLabel: __("Send Email")
      };
    });
  }, [contacts]);

  const shareLink = () => {
    copyToClipboard({
      textToCopy: inviteLink.url,
      onSuccess: () => {
        HFN.message(__("Link copied."));
      },
      onError: () => {
        HFN.message(
          __("browser_doesnt_support_operation", "Your browser doesn't support this operation. Please copy manually")
        );
      }
    });
  };

  const shareFacebook = () => {
    const FBDesc =
      "pCloud is the place where you can save photos, videos and documents. Access them anywhere you go, on any device.";
    const FBTitle = "Join pCloud today and get 1 GB bonus!";
    const FBLink = inviteLink.url;
    const FBPic = (URLMY + "img/referral-fb.png").replace(/^\/+/i, "https://");

    // Open FB share popup
    isLoadedFbApi &&
      FB.ui(
        {
          method: "share_open_graph",
          mobile_iframe: true,
          action_type: "og.shares",
          action_properties: JSON.stringify({
            object: {
              "og:type": "object",
              "og:url": FBLink,
              "og:title": FBTitle,
              "og:description": FBDesc,
              "og:image": FBPic,
              "og:image:width": 1200,
              "og:image:height": 628
            }
          })
        },
        function(response) {}
      );
  };

  const shareTwitter = () => {
    window.open(
      "https://twitter.com/intent/tweet?url=" +
        encodeURIComponent(inviteLink.url) +
        "&text=" +
        encodeURIComponent("Join pCloud today and get 1 GB bonus!"),
      "twitter-share",
      "width=550,height=450"
    );
    return false;
  };

  const sendInvitations = () => {
    const emails = [];
    let manuallyAdded = [];
    let hasError = false;

    if (suggesterRef.current.inputValue && validateEmail(suggesterRef.current.inputValue)) {
      emails.push(suggesterRef.current.inputValue)
    }

    if (selectedEmails.length) {
      for (let index in selectedEmails) {
        if (validateEmail(selectedEmails[index].label)) {
          emails.push(selectedEmails[index].label);

          if (!contacts.some(contact => contact.value === selectedEmails[index].label)) {
            manuallyAdded.push(selectedEmails[index].label)
          }
        } else {
          hasError = true;
        }
      }
    }

    if (!emails.length || hasError) {
      HFN.message(__("Please, enter a contact or a valid e-mail."), "error");
      return;
    }

    let xhr = [],
      success = false;
    for (let n = 0; n < emails.length; ++n) {
      (function(n) {
        xhr.push(
          apiMethod(
            "sendinvitelink",
            { mail: emails[n] },
            (ret) => {
              success = true;
            },
            {
              errorCallback: (ret) => {
                HFN.message(ret.error, "error");
              }
            }
          )
        );
      })(n);
    }

    $.when.apply($, xhr).then(function() {
      if (success) {
        HFN.message(__("Link sent!"));

        if (suggesterRef.current && suggesterRef.current.resetSelections) {
          suggesterRef.current.resetSelections();
        }

        if (manuallyAdded.length) {
          apiMethod("contactload", { source: 1, mails: manuallyAdded.join(",") }, () => {});
        }
      }
    });
  };

  const handleOnChange = (event, data) => {
    setSelectedEmails(data);
  }

  return (
    <UserInvitationContainerWrapper>
      <TopIcon>
        <i className="fa-regular fa-user-plus" />
      </TopIcon>
      <Heading>
        <Title>{__(...INVITE_USER_TEXTS.freeUser.title)}</Title>
        <SubTitle>{__(...INVITE_USER_TEXTS.freeUser.subTitle)}</SubTitle>
      </Heading>
      {isVerified ? (
        <FormContent>
          <InputSection>
            <FormField>
              <InputGroup>
                <MultiSelectSuggester
                  componentRef={suggesterRef}
                  defaultIcon="fa-regular fa-envelope"
                  list={searchList}
                  placeholder={__("add_emails", "Add email addresses")}
                  onValueChange={handleOnChange}
                  validationRules={[
                    {
                      check: validateEmail,
                      message: __("This is not a valid contact or an email.")
                    }
                  ]}
                />
                <InputNote>
                  <Componentify text={__("invite_friends_note")} converters={[boldConverter]} />
                </InputNote>
              </InputGroup>
              <Button styled="PrimaryButton" onClick={sendInvitations}>
                {__("Send")}
              </Button>
            </FormField>
          </InputSection>
          <ShareSection>
            {__("or_share_invitation_link", "Or share invitation link") + ": "}
            <ShareButtonsWrapper>
              <ShareButton onClick={shareLink}>
                <i className="fa-regular fa-link"></i>
              </ShareButton>
              <ShareButton onClick={shareFacebook}>
                <i className="fa-brands fa-facebook"></i>
              </ShareButton>
              <ShareButton onClick={shareTwitter}>
                <i className="fa-brands fa-x-twitter"></i>
              </ShareButton>
            </ShareButtonsWrapper>
          </ShareSection>
        </FormContent>
      ) : (
        <ErrorBox>{__("to_send_links_must_verify_your_email", "In order to send invite links to you friends, you must first verify your e-mail address.")}</ErrorBox>
      )}
    </UserInvitationContainerWrapper>
  );
};

export default UserInvitationContainer;
