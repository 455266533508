// @flow
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import { useSelector } from "react-redux";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import InputPassword from "../../InputPassword";
import PasswordInput from "../../../containers/CryptoPages/parts/PasswordInput";
import { InputWrapper } from "../../../containers/CryptoPages/styles";
import { Tooltip as ReactTooltip } from "react-tooltip";

type Props = {
  password: string,
  onPassChange: string => void
};

const PassWithHint = ({ password, onPassChange }: Props) => {
  const [showHint, toggleHint] = useState(false);
  const [userHint, setUserHint] = useState(false);

  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const userinfo = useSelector(({ pCloudUser }) => pCloudUser.userinfo);

  useEffect(() => {
    apiMethod("crypto_getuserhint", { auth: token }, ({ hint }) => {
      setUserHint(hint);
    });
  }, [token]);

  return (
    <InputWrapper>
      <PasswordInput
        value={password}
        onChange={(e) => onPassChange(e.target.value)}
        autoFocus={true}
        className={"cryptopass"}
        placeholder={__(
          "crypto_password_label",
          "Crypto Password"
        )}
      />
      {userHint && (
        <>
          <HintWrapper>
            <HintLabel data-tooltip-id="crypto-hint">
              {__("Hint")}
            </HintLabel>
          </HintWrapper>

          <StyledReactTooltip
            id="crypto-hint"
            className="cv-tooltip visible-on-mobile"
            noArrow
            offset={12}
            place="bottom-end"
          >
            {__("Hint")}: {userHint}
            {userinfo.business && !userinfo.account.owner && (
              <InfoWrap>
                <b>{__("contact_business_account_owner")}:</b> {__("hint_help_message_user")}
              </InfoWrap>
            )}
          </StyledReactTooltip>
        </>
      )}
    </InputWrapper>


  );
};

export default PassWithHint;

const HintLink = styled.span`
  text-decoration: underline;
  font-size: 12px;
  color: #000;
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
  line-height: initial;

  &:hover {
    text-decoration: none;
  }
`;

const Hint = styled.div`
  background-color: #fff8df;
  border: 1px solid #fcf0cd;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  padding: 17px 11px 14px 11px;
  position: relative;
  margin-top: 10px;
`;

const InfoWrap = styled.div`
  margin-top: 10px;
  color: var(--text-secondary);
`;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  padding: 5px;
  right: 4px;
  top: 4px;
  opacity: 0.5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 9px;
  width: 9px;
  height: 9px;
  background-image: url(${require("../../../../root/img/header/close-modal.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/header/close-modal@2x.png")});
  }
`;

const HintWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
const HintLabel = styled.div`
  display: inline-block;
  margin: var(--spacing-none) 16px;
  color: var(--text-primary);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--text-primary);
`;

const StyledReactTooltip = styled(ReactTooltip)`
  background-color: var(--surface-tooltip);
  text-align: left;
  color: var(--color-base-white);
`;
