// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import InputText from "../../components/InputText";
import { Btn } from "../../components/ButtonDefault";
import * as Form from "../../components/FormSharedComponents";

import { __ } from "../../lib/translate";
import { detectIsMobile, rcookie } from "../../lib/utils";
import apiMethod from "../../api/apiMethod";

type Props = {
  type: "modal" | "tooltip",
  code: string,
  locationId: number,
  bookmarkName: { current: string },
  bookmarkDesc: string,
  onSave: () => void,
  onRemove: () => void,
  getIsPinned: () => boolean,
  setIsPinned: () => void,
  setSavedName: () => void,
  setSavedDescription: () => void
};

const EditBookmark = ({
  type,
  code,
  locationId,
  bookmarkName,
  bookmarkDesc = "",
  onSave = () => {},
  onRemove = () => {},
  getIsPinned = () => false,
  setIsPinned = () => {},
  setSavedName = () => {},
  setSavedDescription = () => {}
}: Props) => {
  const [name, setName] = useState(bookmarkName.current);
  const [description, setDescription] = useState(bookmarkDesc.current);
  const [error, setError] = useState("");
  const [isLoading, setLoding] = useState(false);

  const getPinData = () => {
    apiMethod(
      "publink/checkpinned",
      {
        auth: rcookie("pcauth"),
        code: code,
        locationid: locationId
      },
      ({ ispinned, pin }) => {
        setIsPinned(ispinned);
        if (ispinned) {
          setSavedName(pin.name);
          setSavedDescription(pin.description);
          setName(pin.name);
          setDescription(pin.description);
        }
      },
      {
        errorCallback: ({ result, error, limit }) => {
          console.log("error", error);
        }
      }
    );
  };

  useEffect(() => {
    setName(bookmarkName.current);
  }, [bookmarkName]);

  useEffect(() => {
    setDescription(bookmarkDesc.current);
  }, [bookmarkDesc]);

  useEffect(() => {
    getPinData();
  }, []);

  const onEnter = (e: any) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      onDone();
    }
  };

  const onNameChange = (e: any) => {
    const value = e.target.value;
    setName(value);
  };

  const onDescChange = (e: any) => {
    const value = e.target.value;
    setDescription(value);
  };

  const onRemoveClick = () => {
    if (isLoading) {
      return;
    }

    setLoding(true);

    apiMethod(
      "publink/unpin",
      {
        code: code,
        locationid: locationId
      },
      () => {
        HFN.message(__("bookmark_removed", "Bookmark removed"));
        onRemove();
        setLoding(false);
        setIsPinned(false);
      },
      {
        errorCallback: err => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          setLoding(false);
          console.log("error", err);
        }
      }
    );
  };

  const onSaveClick = () => {
    if (name.length === 0 || isLoading) {
      setError(__("Name is required."));
      return;
    }

    setLoding(true);

    apiMethod(
      "publink/changepin",
      {
        code,
        locationid: locationId,
        name,
        description
      },
      () => {
        HFN.message(__("Changes saved."));
        onSave(name, description);
        setLoding(false);
      },
      {
        errorCallback: ({ error, limit }) => {
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (limit) {
            errorMessage =
              limit === 100
                ? __("bookmark_name_error", "100-character name limit exceeded.")
                : __("bookmark_desc_error", "255-character description limit exceeded.");
          }

          setError(errorMessage);
          setLoding(false);
          console.log("error", error);
        }
      }
    );
  };

  const onDone = () => {
    if (getIsPinned()) {
      onSaveClick();
    } else {
      if (name.length === 0 || isLoading) {
        setError(__("Name is required."));
        return;
      }

      setLoding(true);

      apiMethod(
        "publink/pin",
        {
          code,
          locationid: locationId,
          name,
          description
        },
        () => {
          HFN.message(__("bookmark_added", "Bookmark added. Access from my.pCloud.com > Bookmarks"), "success", false, false, false, "true", true);
          setIsPinned(true);
          setLoding(false);
          onSaveClick();
        },
        {
          errorCallback: ({ result, error, limit }) => {
            setLoding(false);
            let errorMessage = __("something_went_wrong_refresh_and_try_again");
            if (result === 2326) {
              // This link is already pinned.
              onSaveClick();
              return;
            }
            if (limit) {
              errorMessage =
                limit === 100
                  ? __("bookmark_name_error", "100-character name limit exceeded.")
                  : __("bookmark_desc_error", "255-character description limit exceeded.");
            }
            HFN.message(errorMessage, "error");
            console.log("error", error);
          }
        }
      );
    }
  };

  const renderTooltipButtons = () => {
    return (
      <ButtonWrapper type={type}>
        <Btn
          color="lightgray3"
          onClick={onRemoveClick}
          style={{
            borderRadius: "3.1px",
            marginTop: "27px",
            marginRight: "5px"
          }}
        >
          {__("Remove")}
        </Btn>
        <Btn
          onClick={onDone}
          style={{
            borderRadius: "3.1px",
            marginTop: "27px",
            marginLeft: "5px"
          }}
        >
          {__("Done")}
        </Btn>
      </ButtonWrapper>
    );
  };

  const renderModalButton = () => {
    return (
      <ButtonWrapper type={type}>
        <Btn
          onClick={onDone}
          style={{
            minWidth: "120px",
            borderRadius: "3.1px",
            marginTop: "27px"
          }}
        >
          {__("Save")}
        </Btn>
      </ButtonWrapper>
    );
  };
  let width = "auto";

  if (type === "tooltip") {
    width = detectIsMobile() ? "100%" : "340px";
  } else {
    width = "440px";
  }
  return (
    <Wrapper width={width}>
      <Title>{__("bookmarks_edit_title", "Edit Bookmark")}</Title>
      <Form.ErrorMessage>{error}</Form.ErrorMessage>
      <Label>{__("Name")}</Label>
      <InputText
        name="name"
        title={name}
        value={name}
        onChange={onNameChange}
        onKeyDown={onEnter}
        style={{ marginBottom: "17px" }}
      />
      <Label>{__("bookmarks_description_title", "Description")}</Label>
      <InputText
        name="desctription"
        title={description}
        value={description}
        onChange={onDescChange}
        onKeyDown={onEnter}
        style={{ marginBottom: "17px" }}
        placeholder={__("bookmarks_description_placeholder", "Add a description")}
      />
      {type === "modal" ? renderModalButton() : renderTooltipButtons()}
    </Wrapper>
  );
};

export default EditBookmark;

const Wrapper = styled.div`
  padding: 20px;
  width: ${props => props.width};
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

const Title = styled.div`
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
`;

const Label = styled.div`
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.type === "modal" ? "center" : "space-between")};
`;
