import React from "react";
import Button from "../ButtonDefault";
import { __ } from "../../lib/translate";

import Modal from "../Modal";
import {
  ModalContainer,
  ModalHeader,
  ModalClose,
  ModalButtonsWrapper,
  ModalSubHeader,
  ModalDescription,
  ModalContent,
  ModalHeaderContainer,
} from "./ModalsStyled";

const ModalTemplate = (props) => {
  const {
    title = null,
    subTitle = null,
    description = null,
    cancelable = true,
    cancelLabel = "",
    buttonsComponent = null,
    onClose = null,
    children,
    containerProps = null,
    externalModal = false,
  } = props;

  const renderModalContent = () => {
    return (
      <>
        <ModalContainer className="modal-container with-gap" {...containerProps}>
        {!!onClose && <ModalClose onClick={onClose} />}
          {!!title || !!subTitle ?
            <ModalHeaderContainer>
              {!!title && <ModalHeader>{title}</ModalHeader>}
              {!!subTitle && <ModalSubHeader>{subTitle}</ModalSubHeader>}
            </ModalHeaderContainer>
          : null}
          {!!description && <ModalDescription>{description}</ModalDescription>}
          {!!children && <ModalContent>{children}</ModalContent>}
          {(!!buttonsComponent || (cancelable && !!onClose)) ? 
            <ModalButtonsWrapper>
              {cancelable ? 
                <Button styled="ModalCancelButton" onClick={onClose}>
                  {cancelLabel !== "" ? cancelLabel : __("Cancel")}
                </Button> 
              : null}
              {!!buttonsComponent ? buttonsComponent : null}
            </ModalButtonsWrapper>
          : null}
        </ModalContainer>
      </>
    );
  };

  return (
    !!externalModal ? renderModalContent() : <Modal align="top" {...props}>{renderModalContent()}</Modal>
  );
};

export default ModalTemplate;
