//flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import Modal, { OvalModalWrap } from "../../components/Modal";
import { Btn } from "../../components/ButtonDefault";

type ActionButtonOption = {
  text: string,
  onClick: () => void
};

type Props = {
  opened: boolean,
  showTopImage: boolean,
  text: string,
  actionButtonOption1: ActionButtonOption,
  actionButtonOption2: ActionButtonOption
};

type State = {};

export default class ActionModal extends PureComponent<Props, State> {
  static defaultProps = {
    opened: false,
    showTopImage: true
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { opened, showTopImage, text, actionButtonOption1, actionButtonOption2 } = this.props;

    return (
      <Modal align="top" shouldCloseOnOverlayClick={false} onClose={() => {}} opened={opened}>
        <OvalModalWrap>
          <Content>
            {showTopImage && <Icon />}
            <Text>{text}</Text>
            <ButtonWrapper>
              <Btn
                color="lightgray4"
                style={{ marginRight: "10px" }}
                onClick={actionButtonOption1.onClick}
              >
                {actionButtonOption1.text}
              </Btn>
              <Btn color="cyan" onClick={actionButtonOption2.onClick}>
                {actionButtonOption2.text}
              </Btn>
            </ButtonWrapper>
          </Content>
        </OvalModalWrap>
      </Modal>
    );
  }
}

const Content = styled.div`
  /* padding: 18px; */
  padding: 30px;
  width: 600px;
  box-sizing: border-box;
  text-align: center;
  
  @media only screen and (max-width: 600px) {
    width: 95%;
  }
`;

const Icon = styled.div`
  margin: 18px auto;
  width: 48px;
  height: 48px;
  background-size: 48px 48px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${require("../../../root/img/branding-settings/att.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/att@2x.png")});
  }
`;

const Text = styled.div`
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  /* font-weight: bold; */
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: center;
  color: #111111;
  margin: 18px auto;
  max-width: 450px;
`;

const ButtonWrapper = styled.div`
  /*margin: 18px auto;
  text-align: center;
  a {
    margin: 0px 2px;
    display: inline-block;
    min-width: 100px;
  }*/
  display: flex;
  margin-top: 30px;
  padding: 0;
  width: 100%;

  &.single {
    display: block;
  }

  a {
    display: flex;
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: normal;
    justify-content: center;
  }
`;
