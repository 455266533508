import React, { useMemo } from "react";
import useAutocomplete from "@mui/material/useAutocomplete";
import {
  InputWrapper,
  Wrapper
} from "./styles";
import List from "./parts/List";

const InputSearchSelector = ({
  placeholder = "",
  componentRef = null,
  defaultSelected = [],
  onItemClick = () => {},
  list = []
}) => {
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    getClearProps
  } = useAutocomplete({
    id: "input-search-selector",
    multiple: true,
    options: list,
    defaultValue: defaultSelected,
    onChange: onItemClick,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionLabel: (option) => option.label,
    getOptionKey: (option) => option.id
  });

  if (componentRef) {
    const resetSelections = () => {
      const { onClick } = getClearProps();
      onClick();
    };
    const removeTag = (event, index) => {
      const { onDelete } = getTagProps({ index });
      onDelete(event);
    };

    componentRef.current.resetSelections = resetSelections;
    componentRef.current.removeTag = removeTag;
  }

  let dynamicPlaceholder = useMemo(() => {
    if (!value.length) {
      return placeholder;
    }

    return value.map(({ label }) => label).join(", ");
  }, [value]);

  return (
    <Wrapper>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          <input placeholder={dynamicPlaceholder} className={"input-styled"} {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 && (
        <List groupedOptions={groupedOptions} getOptionProps={getOptionProps} getListboxProps={getListboxProps} />
      )}
    </Wrapper>
  );
};

export default InputSearchSelector;
