import styled from "styled-components";

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorBox = styled(FlexRow)`
  border: 1px solid var(--color-functional-danger200);
  background: var(--color-functional-danger100);
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  color: var(--text-danger);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;

export const RowColumnDataWrapper = styled(FlexRow)`
  width: 100%;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const RowColumnDataItem = styled(FlexColumn)`
  justify-content: space-between;
  gap: var(--spacing-sm);
  width: 100%;
  border-right: 1px solid var(--border-primary);

  color: var(--text-secondary);
  text-align: center;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  
  &:last-child {
    border: none;
  }
  
  & span {
    color: var(--color-base-black);
    text-align: center;

    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px; 
  }

  @media (max-width: 500px) {
    width: auto;
    min-width: 180px;
    border-right: none;
    border-bottom: 1px solid var(--border-primary);

    &:last-child {
      border: none;
    }
    
    padding: var(--spacing-lg);
  }
`;

export const CreditsWrapper = styled(FlexRow)`
  margin-top: auto;
  padding-bottom: 16px;
  font-size: var(--small-font-size);
  gap: var(--spacing-xs);
  text-align: left;
  color: var(--text-primary);
  
  @media (max-width: 1023px) {
    padding-bottom: 100px;
  }
`;

export const TopIcon = styled(FlexRow)`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 16px;
  background: linear-gradient(180deg, #17BED0 0%, #1AA4B3 100%);
  
  & i {
    color: var(--color-base-white);
    font-size: 28px;
  }
  
  @media (max-width: 700px) {
    display: none;
  }
`;

export const Heading = styled(FlexColumn)`
  gap: var(--spacing-sm);
  
  color: #000;
  text-align: center;
  font-family: Roboto, sans-serif;
  
  @media (max-width: 700px) {
    text-align: left;
    align-items: start;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;

  @media (max-width: 700px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; 
`;

export const InputMessage = styled.textarea`
  width: 100%;
  height: 85px;
  font-size: 14px;
  padding: var(--spacing-sm) var(--spacing-md);
  box-sizing: border-box;
  outline: none;
  border-radius: 8px;
  border: 1px solid var(--border-primary, #E4E5E7);
  background: var(--surface-secondary, #FCFCFC);
  resize: none;

  &:focus {
    border: 1px solid var(--color-primary-500, #17BED0);
  }

  &::-webkit-input-placeholder {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  &::-moz-placeholder {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  &::placeholder {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;