import React, { useEffect, useMemo, useRef } from "react";
import { Column } from "../../UI/Style";
import { getEventId } from "../../../../containers/Business/utils";
import { __ } from "../../../../lib/translate";
import { useSelector } from "react-redux";
import hashManager from "../../../../lib/hashManager";
import styled from "styled-components";
import FileIcon from "../../../FileIcon";
import { getIconType } from "../../../../lib";

const LogDataCell = ({ handleRowHeightChange, stretchWidth, item, classNames, id }) => {
  const data = item.obj;
  const eventData = data.data;
  const eventName = getEventId(item);
  const teams = useSelector(({ business }) => business.teams);
  const users = useSelector(({ business }) => business.users);
  let render = '-';
  const displayData = {};
  const colRef = useRef(null);

  useEffect(() => {
    const paddingModifier = 16;
    const listRow = colRef.current.closest(".list-row");
    const rowHeight = colRef.current.closest(".list-row")?.offsetHeight || 0;
    const cellHeight = colRef.current.offsetHeight ? colRef.current.offsetHeight + paddingModifier : 0;
    if (listRow && rowHeight < cellHeight) {
      item.newHeight = cellHeight;
      handleRowHeightChange(cellHeight);
    }
  }, []);

  const buildTeamLine = (id, title = false) => {
    const team = teams.find((item) => item.id === id);
    return (
      <DivLine
      >
        {title && __('Team') + ": "}
        <span
          className="clickable"
          onClick={() => {
            hashManager.pushState({
              page: "b_team",
              id: team.id
            });
          }}>
          {team.name}
        </span>
      </DivLine>
    )
  }

  const buildUserLine = (id, title = false, email = false) => {
    const user = users.find((item) => item.id === id);
    return (
      <DivLine
      >
        {title && __('User') + ": "}
        <span
          className="clickable"
          onClick={() => {
            hashManager.pushState({
              page: "b_user",
              id: user.id
            });
          }}>
          {user.firstname + " " + user.lastname}
          {email && <>
            <br />
            {email}
          </>}
        </span>
      </DivLine>
    )
  }

  const buildDataLine = (data) => {
    return (
      <div>
        <DivLine>
          {__("Data") + ": "}
        </DivLine>
        {Object.keys(data).map((key, index) => {
          return (
            <DivLine key={index}>
              {key + ": "}
              <strong>{data[key]}</strong>
            </DivLine>
          );
        })}
      </div>
    );
  }

  const getDataContent = () => {
    if (data.metadata) {
      const iconType = useMemo(() => getIconType(data.metadata), [data.metadata]);
      let title = data.metadata.name;
      if (data.metadata.encrypted) {
        title = __(data.metadata.isfolder ? "Encrypted folder" : "Encrypted file");
      }
      return <>
        <FileIcon item={iconType} type={HFN.ICONS.SVG_LIST} metadata={data.metadata} />
        <span
        className="clickable"
        onClick={() => {
          hashManager.pushState({page: 'filemanager', folder: data.metadata.parentfolderid, file: data.metadata.id })
        }}
        >{title}</span>
      </>
    } else if (eventName === "modifyuserinfo") {
      const language = pCloudIntl.getLanguages().find((lang) => lang.code === data.userinfo.language);
      return (
        <div>
          <DivLine>{__("Quota") + ": " + HFN.formatSize(data.userinfo.quota)}</DivLine>
          <DivLine>{"Premium: " + HFN.formatSize(data.userinfo.quota)}</DivLine>
          <DivLine>{__("Language") + ": " + language?.title || data.userinfo.language}</DivLine>
        </div>
      );
    } else if (data.share || eventData.sharename) {
      if (["create_teamshare"].indexOf(data.event) != -1) {
        return buildTeamLine(eventData.teamid, true)
      }

      const shareData = data.share || data.data;
      let title = "foldername" in shareData ? shareData.foldername : shareData.sharename;

        let icon = null;
      if (shareData.folderid) {
        let iconType = HFN.ICO.FOLDER;
        if (shareData.encrypted && !shareData.sharename) {
          title = __("Encrypted folder");
          iconType = HFN.ICO.CRYPTO_FOLDER;
        }
        icon = <FileIcon item={iconType} type={HFN.ICONS.SVG_LIST} metadata={data.metadata} />;
      }

      return <>
        {icon}
        <span
          className={shareData.folderid ? "clickable" : null}
          onClick={() => {
            if (shareData.folderid) {
              hashManager.pushState({ page: "filemanager", folder: shareData.folderid }, 2)
            }
          }}
        >{title || "No Information"}</span>
      </>
    } else if (eventName === "unlink_device" || eventName === "link_device") {
      return eventData.device + ", (" + eventData.REMOTE_ADDR + ")";
    } else if (eventName === "create_team") {
      return buildTeamLine(eventData.id);
    } else if (eventName === "modify_user") {
      return (
        <DivLine
          className="clickable"
          onClick={() => {
            hashManager.pushState({
              page: "b_user",
              id: eventData.id
            });
          }}
        >
          {eventData.firstname + " " + eventData.lastname}
        </DivLine>
      );
    } else if (
      eventName === "modify_user_paswordreset" ||
      eventName === "view_asother" ||
      eventName === "login_asother"
    ) {
      return buildUserLine(data.touserid || eventData.id, false, true);
    } else if (
      [
        "modify_teamlink",
        "add_teamlink_add",
        "add_teamlink_invite",
        "join_user_team",
        "remove_user_team",
        "modify_user_team_head_add",
        "modify_user_team_head_remove",
        "modify_user_team_team_activate",
        "modify_user_team_team_deactivate",
        "invite_user_team"
      ].indexOf(eventName) !== -1
    ) {
      return (
        <div>
          {buildUserLine(eventData.userid, true)}
          {buildTeamLine(eventData.teamid, true)}
        </div>
      );
    } else if (eventName.indexOf("modify_team_status") === 0) {
      return buildTeamLine(eventData.teamid, true);
    } else if (eventName === "modify_team") {
      if (eventData.active != undefined) displayData[__("Active")] = eventData.active ? __("Yes") : __("No");
      if (eventData.name) displayData[__("Name")] = eventData.name;
      if (eventData.settings)
        for (let n in eventData.settings) displayData[__(n)] = PCB.sflagsVals[eventData.settings[n]];

      return buildDataLine(displayData);
    } else if (eventName === "send_invitation" || eventName === "cancel_invitation") {
      return __("Send to") + " " + eventData.mail;
    } else if (eventName === "modify_user_avatarchange") {
      return buildUserLine(eventData.id, true);
    } else if (eventName === "modify_team_settings") {
      for (let n in eventData)
        if (PCB.sflagsVals[eventData[n]]) {
          displayData[__(n)] = __(PCB.sflagsVals[eventData[n]]);
        } else if (typeof eventData[n] == "boolean") displayData[__(n)] = eventData[n] ? __("Yes") : __("No");

      return (
        <div>
          {buildTeamLine(eventData.teamid, true)}
          {buildDataLine(displayData)}
        </div>
      );
    } else if (eventName === "modify_user_settings") {
      for (let n in eventData)
        if (PCB.sflagsVals[eventData[n]]) {
          displayData[__(n)] = __(PCB.sflagsVals[eventData[n]]);
        } else if (typeof eventData[n] == "boolean") displayData[__(n)] = eventData[n] ? __("Yes") : __("No");

      return (
        <div>
          {buildUserLine(data.touserid, true)}
          {buildDataLine(displayData)}
        </div>
      );
    } else if (eventName === "modify_account_settings") {
      for (let n in eventData)
        if (PCB.sflagsVals[eventData[n]]) {
          displayData[__(n)] = __(PCB.sflagsVals[eventData[n]]);
        } else if (typeof eventData[n] == "boolean") displayData[__(n)] = eventData[n] ? __("Yes") : __("No");

      return buildDataLine(displayData);
    } else if (eventName === "join_user") {
      return buildUserLine(eventData.id, true);
    } else if (eventName === "modify_account_quota") {
      displayData[__("Active Members")] = eventData.members;
      displayData[__("Max Members")] = eventData.maxmembers;
      return buildDataLine(displayData);
    } else if (eventName === "modify_invoiceinfo") {
      displayData[__("Company name")] = eventData.company;
      displayData[__("VAT")] = eventData.vat;
      displayData[__("Billing Address")] = eventData.billing_address;
      return buildDataLine(displayData);
    } else if (eventName === "modify_billinginfo") {
      displayData[__("Period")] = __(PCB.payment.periods.names[eventData.billingperiod]);
      displayData[__("Provider")] = __(PCB.payment.providers.names[eventData.billingprovider]);
      displayData[__("Trial")] = eventData.istrial ? __("Yes") : __("No");
      displayData[__("Next Billing Date")] = HFN.prepDt(eventData.billeduntil);
      displayData[__("Max Members")] = eventData.maxmembers;
      return buildDataLine(displayData);
    } else if (eventName === "create_account") {
      displayData[__("Company Name")] = eventData.name;
      displayData[__("Phone")] = eventData.phone;
      displayData[__("Members")] = eventData.members;
      displayData[__("Max Members")] = eventData.maxmembers;
      return buildDataLine(displayData);
    } else if (eventName === "modify_user_status_activate" || eventName === "modify_user_status_deactivate") {
      return buildUserLine(data.touserid || data.fromuserid, true);
    } else if (eventName === "join_user_team") {
    } else if (["comment_post", "comment_delete"].indexOf(eventName) !== -1) {
    } else if (eventName === "modify_account") {
      displayData[__("Company Name")] = eventData.name;
      displayData[__("Phone")] = eventData.phone;
      return (
        <div>
          {buildUserLine(eventData.ownerid, true)}
          {buildDataLine(displayData)}
        </div>
      );
    } else if (eventName === "invite_user_team") {
    }
    
    return '-';
  }

  try {
    render = getDataContent();
  } catch (e) {
    console.error(e.message)
  }

  return (
    <Column ref={colRef} $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
      {render}
    </Column>
  );
};

export default LogDataCell;

const DivLine = styled.div`
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
`;
