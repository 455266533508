import React, { useRef, useState } from "react";
import { TermRow, TermRowWrapper, TermsBoxContainer, TermsButton, TermsContent, TermsTitle } from "./styles";
import { TERMS } from "./constants";
import { AnimatePresence } from "framer-motion";
import TransitionAnimation from "@pcloud/web-utilities/dist/resources/components/Animation/TransitionAnimation";

const TermsContainer = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const termsEl = useRef(null);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <TermsBoxContainer>
      <TermsTitle>{__("terms and conditions")}</TermsTitle>
      <TermsButton className={isExpanded ? "active" : null} onClick={handleClick}>
        <i className="fa-solid fa-chevron-down" />
      </TermsButton>
      <TransitionAnimation
        trigger={isExpanded}
        type="expand"
        height={termsEl.current && termsEl.current.clientHeight || 0}
        unmountOnExit={false}
      >
        <TermsContent>
          <TermRowWrapper
            ref={termsEl}
          >
            {Object.keys(TERMS).map((key, i) => {
              const term = TERMS[key];

              return (
                <TermRow key={key}>
                  {i + 1}. <span dangerouslySetInnerHTML={{ __html: __(key, term) }} />
                </TermRow>
              );
            })}
          </TermRowWrapper>
        </TermsContent>
      </TransitionAnimation>
    </TermsBoxContainer>
  );
};

export default TermsContainer;
