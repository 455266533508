import React from "react";
import styled from "styled-components";
import { Button } from "../../components/ButtonDefault";
import { TSpan } from "../../components/intl";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { FlexColumn } from "../../styles/sharedStyles";

const UpgradeWrap = styled(FlexColumn)`
  gap: 4px;
  width: 100%;
`;

const Money = styled(TSpan)`
  line-height: 18px;
  font-size: 12px;
`;

const UpgradeButton = () => {
  const userCurrency = HFN.config.user.currency;
  return (
    <UpgradeWrap>
      <Button
        styled="PrimaryButton"
        onClick={() => window.open(URLSITE + "cloud-storage-pricing-plans.html")}>
        {__("upgrade", "Upgrade")}
      </Button>
      <Money id="public_folder_premium_from" replace={{currency: userCurrency}}>(from 3.99 %currency%/mo)</Money>
    </UpgradeWrap>
  );
}

export default UpgradeButton;
