import React, { useEffect, useState } from "react";
import ContentView from "../../../components/ContentView/ContentView";
import { BROWSE_B_USERS_CONTENT } from "../../../config/constants";
import SkeletonTable from "../../../components/ContentView/Templates/SkeletonTable";
import { useDispatch, useSelector } from "react-redux";
import { loadContent, setContentItems } from "../../../lib/state/reducers/contentSlice";
import styled from "styled-components";
import { getUserStatus } from "../utils";
import apiMethod from "../../../api/apiMethod";

const BusinessUsers = () => {
  const [tableLoaded, setTableLoaded] = useState(false);
  const users = useSelector(({ business }) => business.users);
  const invitedUsers = useSelector(({ business }) => business.invited_users);
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const dispatch = useDispatch();
  let xhrs = [];

  useEffect(() => {
    xhrs = [];
    if (
      !users.length &&
      !invitedUsers.length
    ) {
      let userRet, invitedRet;
      xhrs.push(apiMethod(
        "account_users",
        { userpermissions: 1, withavatars: 1, tracksettings: 1, avatarsize: "128x128", usersettings: 1, showteams: 1, showeveryone: 1 },
        ret => {
          userRet = ret.users;
        },
        { forceFresh: true }
      ));
      xhrs.push(apiMethod(
        "account_invitelist",
        {},
        ret => {
          invitedRet = ret.invites;
        },
        { forceFresh: true }
      ));

      Promise.all(xhrs).then(() => {
        loadTable(userRet, invitedRet);
      });
    } else {
      loadTable(users, invitedUsers);
    }

    return () => {
      if (xhrs.length) {
        xhrs.forEach((x) => x && "abort" in x && x.abort());
      }
    }
  }, [users, invitedUsers]);

  const loadTable = (usersData, invitedUsersData) => {
    const rows = [];
    if (invitedUsersData) {
      let n = 0;
      for ( ; n < invitedUsersData.length; ++n) {
        const tmpInvitedUser = {
          name: '',
          firstname: '',
          lastname: '',
          active: -1,
          invited: true,
          isBusinessUser: true,
          canUserManage: account.permissions.user_manage,
          id: Math.random(),
          storage: invitedUsersData[n].quota || "-",
          lastactivity: invitedUsersData[n].sent,
          email: invitedUsersData[n].email,
          teams: invitedUsersData[n].teams || [],
          teamsids: invitedUsersData[n].teams?.map(item => item.team.id) || [],
          code: invitedUsersData[n].code
        };
        tmpInvitedUser.status = getUserStatus(tmpInvitedUser);
        rows.push(tmpInvitedUser)
      }
    }

    if (usersData) {
      let k = 0;
      for ( ; k < usersData.length; ++k) {
        const tmpUser = usersData[k];
        tmpUser.name = tmpUser.firstname + ' ' + tmpUser.lastname;
        tmpUser.isBusinessUser = true;
        tmpUser.teamsids = tmpUser.teams.map(item => item.team.id);
        tmpUser.status = getUserStatus(tmpUser);
        tmpUser.canViewLogs = account.permissions.log_view;
        tmpUser.canUserManage = account.permissions.user_manage;
        rows.push(tmpUser);
      }
    }
    dispatch(loadContent(true));
    dispatch(setContentItems({ contents: rows } ));
    setTableLoaded(true);
  }

  return (
    <Wrapper>
      {tableLoaded ? (
        <ContentView contentType={BROWSE_B_USERS_CONTENT} />
      ) : (
        <SkeletonTable contentType={BROWSE_B_USERS_CONTENT} />
      )}
    </Wrapper>
  );
};

export default BusinessUsers;

const Wrapper = styled.div`
  height: calc(100%);
`;
