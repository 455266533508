// @flow

import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { validateEmail } from "../../../lib/utils";
import { getSharedContacts } from "../../../lib/shareFolder-utils";
import { loadTeams } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";
import Componentify from "react-componentify";
import { strongConverter } from "../../../lib/componentifyConverters";
import MultiSelectSuggester from "../../AutoComplete/MultiSelectSuggester";
import { prepUrl } from "../../../api/utils";

const InviteUsersToTeamModal = ({
  teamName = "",
  teamId = 0,
  resetModalAction = () => {}
}: {
  teamName: string,
  teamId: number
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userContacts = useSelector(({ pCloudUser }) => pCloudUser.userContacts);
  const bUserContacts = useSelector(({ business }) => business.users);
  const dispatch = useDispatch();

  const [contacts, setContacts] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const suggesterRef = useRef({});

  const searchList = useMemo(() => {
    return  contacts.map((item) => {
      const userAvatar =
        item.userobj?.avatar && !item.userobj.avatar.isdefault ?
          prepUrl(item.userobj.avatar) : null;
      return {
        id: item.value,
        label: item.name,
        icon: !userAvatar ? "fa-regular fa-user" : null,
        iconContent: userAvatar ? <img src={userAvatar} width="32" height="32" /> : null,
        subLabel: item.userobj?.email,
        obj: item.userobj,
      };
    });
  }, [contacts]);


  useEffect(() => {
    const contacts = getSharedContacts([], bUserContacts, [], true);
    setContacts(contacts);
  }, [userContacts, bUserContacts]);

  const checkUserStatus = (inputVal, data) => {
    let result = false;

    contacts.forEach(conatct => {
      const { userobj } = conatct;
      if (userobj) {
        const emailToCheck = data?.obj ? data.obj.email : (data?.label || inputVal);
        if (userobj.email.toLowerCase() === emailToCheck.toLowerCase()) {
          result = !userobj.active || userobj.frozen;
        }
      }
    });
    return !result;
  };

  const checkUserExists = (inputVal, data) => {
    const emailToCheck = data?.obj ? data.obj.email : (data?.label || inputVal);
    return bUserContacts.some(contact => (
      contact.email.toLowerCase() === emailToCheck.toLowerCase()
    ));
  };

  const sendInvitations = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    let emails = [];
    let hasError = false;

    if (
      suggesterRef.current.inputValue &&
      validateEmail(suggesterRef.current.inputValue) &&
      checkUserExists(suggesterRef.current.inputValue) &&
      checkUserStatus(suggesterRef.current.inputValue)
    ) {
      emails.push(suggesterRef.current.inputValue.toLowerCase())
    }

    if (selectedEmails.length) {
      for (let index in selectedEmails) {
        if (
          checkUserExists(selectedEmails[index].label, selectedEmails[index]) &&
          checkUserStatus(selectedEmails[index].label, selectedEmails[index])
        ) {
          const email = selectedEmails[index].obj ? selectedEmails[index].obj.email : selectedEmails[index].label;
          emails.push(email);
        } else {
          hasError = true;
        }
      }
    }

    if (!emails.length || hasError) {
      HFN.message(__("Please, enter a contact or a valid e-mail."), "error");
      setIsLoading(false);
      return;
    }

    let xhr = [];
    emails.forEach((email) => {
      const user = bUserContacts.find(contact => contact.email.toLowerCase() === email.toLowerCase())
      const params = {
        email: email,
        userid: user.id,
        teamid: teamId
      };

      xhr.push(
        apiMethod(
          "account_teamlink",
          params,
          () => {}
        )
      );
    });

    $.when.apply($, xhr).then(() => {
      setIsLoading(false);
      HFN.message(__("Users added to team(s)."));
      fetchData();
      handleClose();
    });
  };

  const fetchData = () => {
    apiMethod(
      "account_teams",
      {
        showeveryone: 1,
        showlinks: 1,
        showusers: 1,
        withavatars: 1,
        teamsettings: 1
      },
      (ret) => {
        dispatch(loadTeams(ret.teams))
      },
      { forceFresh: true }
    );
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const handleOnChange = (event, data) => {
    setSelectedEmails(data);
  }

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      opened={isOpen}
      title={__("Add users to team")}
      description={__("add_users_description")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          loading={isLoading}
          onClick={sendInvitations}
        >
          {__("Add")}
        </Button>
      }
    >
      <InviteWrapper>
        <Label>
          <Componentify text={__(
            "business_invite_user_to_team_label",
            "Enter users you want to add to %name%",
            { name: `<strong>${teamName}</strong>` }
          )} converters={[strongConverter]} />
        </Label>
        <InputEmailWrapper>
          <MultiSelectSuggester
            componentRef={suggesterRef}
            defaultIcon="fa-regular fa-envelope"
            list={searchList}
            placeholder={__("Name or Email")}
            onValueChange={handleOnChange}
            borderRadius={8}
            searchInSubLabel={true}
            validationRules={[
              {
                check: checkUserStatus,
                message: __("The user is inactive and can't receive mails.")
              },
              {
                check: checkUserExists,
                message: __("error_email_doesnt_exist_business", "User with this email doesn\'t exist in your Business account.")
              }
            ]}
          />
        </InputEmailWrapper>
      </InviteWrapper>
    </ModalTemplate>
  );
};

export default InviteUsersToTeamModal;

const InviteWrapper = styled.div`

`;

const InputEmailWrapper = styled.div`
  
`;

const Label = styled(Style.Label)`
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
