import React, { useEffect, useState } from "react";
import ContentView from "../../../components/ContentView/ContentView";
import { BROWSE_B_LOGS_CONTENT } from "../../../config/constants";
import SkeletonTable from "../../../components/ContentView/Templates/SkeletonTable";
import { useDispatch, useSelector } from "react-redux";
import { setContentItems } from "../../../lib/state/reducers/contentSlice";
import styled from "styled-components";
import { getBusinessUserName, getLogsDefaultDates, getUserStatus } from "../utils";
import apiMethod from "../../../api/apiMethod";
import hashManager from "../../../lib/hashManager";
import BusinessLogsOptionsRow from "./BusinessLogsOptionsRow";
import { formatDate } from "date-fns";

const userCache = {};

const BusinessLogs = () => {
  const users = useSelector(({ business }) => business.users);
  const registered = useSelector(({ pCloudUser }) => pCloudUser.userinfo.registered);
  const [tableLoaded, setTableLoaded] = useState(false);
  const dispatch = useDispatch();
  let rows = [];
  let xhrs = [];

  useEffect(() => {
    if (!users.length) {
      return;
    }
    fetchData();
    window.addEventListener("hashchange", fetchData);

    return () => {
      xhrs.forEach((x) => x && "abort" in x && x.abort());
      window.removeEventListener("hashchange", fetchData);
    };
  }, [users]);

  const fetchData = () => {
    if (xhrs.length) {
      xhrs.forEach((x) => x && "abort" in x && x.abort());
      xhrs = [];
    }
    rows = [];
    setTableLoaded(false);
    const defaultDates = getLogsDefaultDates(registered);
    const from = hashManager.getState("from") ? hashManager.getState("from") : formatDate(defaultDates.from, "yyyy-MM-dd");
    const to = hashManager.getState("to") ? hashManager.getState("to") : formatDate(defaultDates.to, "yyyy-MM-dd");
    const userIds = hashManager.getState("userids") ? hashManager.getState("userids").split(",") : [];
    const teamIds = hashManager.getState("teamids") ? hashManager.getState("teamids").split(",") : [];

    const logParams = { from, to };
    const diffBase = { after: from, until: to, full: 1, nosys: 1 };
    const diffParams = [];
    if (userIds.length) {
      logParams.userids = userIds.join(',');
      userIds.forEach((id) => {
        diffParams.push({...diffBase, userid: id});
      })
    }
    if (teamIds.length) {
      logParams.teamids = teamIds.join(',');
    }

    xhrs.push(
      apiMethod(
        "account_log",
        logParams,
        (response) => {
          response.events.forEach((item) => {
            rows.push(buildRowData(item));
          });
        },
        {
          timeout: 600000,
        }
      )
    );
    if (diffParams.length) {
      diffParams.forEach((diffParamsRow) => {
        xhrs.push(
          apiMethod(
            "diff",
            diffParamsRow,
            (response) => {
              response.entries.forEach((item) => {
                if (item.event === "modifyaccountinfo") {
                  return;
                }
                item.fromuserid = parseInt(diffParamsRow.userid);
                item.id = "diff" + item.diffid;
                rows.push(buildRowData(item));
              });
            }
          )
        );
      });
    }

    $.when(...xhrs).then(() => {
      dispatch(setContentItems({ contents: rows }));
      setTableLoaded(true);
    });
  };

  const buildRowData = (item) => {
    let user = {};
    if (userCache[item.fromuserid]) {
      user = userCache[item.fromuserid];
    } else {
      user = users.find(({ id }) => item.fromuserid === id);
      userCache[item.fromuserid] = user;
    }
    return {
      id: item.id,
      event: item.event,
      created: item.time,
      obj: item,
      userId: user.id,
      userName: getBusinessUserName(user),
      userEmail: user.email
    };
  };

  return (
    <Wrapper>
      <BusinessLogsOptionsRow />
      {tableLoaded ? (
        <ContentView contentType={BROWSE_B_LOGS_CONTENT} />
      ) : (
        <SkeletonTable contentType={BROWSE_B_LOGS_CONTENT} />
      )}
    </Wrapper>
  );
};

export default BusinessLogs;

const Wrapper = styled.div`
  height: calc(100%);
`;
