//  @flow

import React from "react";

import { __ } from "../../../lib/translate";
import * as CommonStyle from "../../../components/CommonStyledSettings";
import Button from "../../../components/ButtonDefault";

const ProFormaInvoices = () => {
  const onEmailClick = () => {
    window.location = "mailto:sales@pcloud.com";
  };

  return (
    <CommonStyle.Row className="proforma-invoices-row">
      <div>
        <CommonStyle.Title>{__("Pro Forma Invoices:")}</CommonStyle.Title>
        <CommonStyle.Description>
          {__(
              "business_proforma_description",
              "Need a preliminary bill of sale? Request a proforma invoice."
            )}
        </CommonStyle.Description>
      </div>
      <Button onClick={onEmailClick}>sales@pcloud.com</Button>
    </CommonStyle.Row>
  );
};

export default ProFormaInvoices;
