import React from 'react'
import styled from 'styled-components'
import { buildUserinfo } from '../containers/Business/utils';
import { __ } from '@pcloud/web-utilities/dist/lib/langManager';
import { boldConverter } from '@pcloud/web-utilities/dist/lib/componentifyConverters';
import Componentify from "react-componentify";
import Alert, { TopAlertContent } from "./Alert";
import Button from "./ButtonDefault";

const ViewAsBar = ({ loginas }) => {
  let message = __('viewas_yellow_message', 'You are currently viewing the business account as %name% with read-only permissions.', { name: '<b>' + buildUserinfo(HFN.config.user.account) + '</b>' });
  let exitText = __('exit_view_as', 'Exit "View As"')
  if (loginas) {
    message = __('loginas_yellow_message', 'You are currently viewing the business account as %name% with full permissions.', { name: '<b>' + buildUserinfo(HFN.config.user.account) + '</b>' })
    exitText = __('exit_login_as', 'Exit "Login As"');
  }

  return <Alert
    theme={"warning"}
    multiline={true}
    topAlert={true}
    centerText={true}
    children={
      <TopAlertContent>
        <div>
          <Componentify
            text={message}
            converters={[boldConverter]}
          />
        </div>
        <Button
          onClick={() => window.close()}
          alertButton={true}
          styled="DarkBorderedButton"
        >
          {exitText}
        </Button>
      </TopAlertContent>
    }
  />
}

export default ViewAsBar