import React, { useEffect, useRef, useState } from 'react'
import * as CommonStyle from "../../../components/CommonStyledSettings"
import styled, { css } from "styled-components"
import Componentify from "react-componentify"
import { useDispatch, useSelector } from 'react-redux'
import hashManager from '../../../lib/hashManager'
import { companyShareSettingInheritVals, companyShareSettingVals, defaultAccessPermissions, defaultAccessPermissionsTitles, defaultAdminSettings, defaultShareSettingInheritVals, defaultShareSettingVals, permissionsKeys, permissionsValuesTextMap } from './constants'
import apiMethod from '../../../api/apiMethod'
import { Menu, MenuItem } from '@szhsin/react-menu'
import { boldConverter } from "@pcloud/web-utilities/dist/lib/componentifyConverters";
import { PAGES } from "../../../config/constants"
import { editTeam, editUser, loadAccountInfo, loadUsers } from '../../../lib/state/reducers/businessSlice'
import ModalCallCenter from "../../../components/ContentView/UI/ModalCallCenter";
import useEntitySettings from './hooks/useEntitySettings'
import { getUsers } from '../../../lib/state/businessState'
import Loader from "../../../components/Loader";

const UserPermissions = () => {
  const dispatch = useDispatch()
  const business = useSelector(({ business }) => business)
  const [page, setPage] = useState(hashManager.getState('page'));
  const [entityId, setEntityId] = useState(hashManager.getState('id'));
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const canUserManage = account.permissions.user_manage;

  useEffect(() => {
    const handleHashChange = () => {
      setPage(hashManager.getState('page'));
      setEntityId(hashManager.getState('id'));
    };

    // Listen to hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const { entitySettings, entity, businessAccountInfoSettings } = useEntitySettings({ business, page, entityId });

  const [isDefaultAccessPermissionsDropdownOpened, setIsDefaultAccessPermissionsDropdownOpened] = useState(defaultAccessPermissions.map(() => false));

  const onPermissionsCLick = (title, value) => {
    let dropdownValues = entitySettings
    dropdownValues[title] = value
    if (page === PAGES.B_ACCOUNT) {
      apiMethod("account_modify", { [title]: value }, (accountInfoResponse) => {
        dispatch(loadAccountInfo(accountInfoResponse));
        getUsers((ret) => {
          dispatch(loadUsers(ret.users));
          HFN.message(__("Settings Saved."));
        });
      })
    } else if (page === PAGES.B_USER) {
      let updatedUser = entity
      updatedUser.settings[title] = value
      dispatch(editUser(updatedUser))
      apiMethod("account_usermodify", { [title]: value, userid: entityId }, () => {
        HFN.message(__("Settings Saved."));
      })
    } else if (page === PAGES.B_TEAM) {
      let updatedTeam = entity
      updatedTeam.settings[title] = value
      dispatch(editTeam(updatedTeam))
      apiMethod("account_teammodify", { [title]: value, teamid: entityId }, () => {
        getUsers((ret) => {
          dispatch(loadUsers(ret.users));
          HFN.message(__("Settings Saved."));
        });
      })
    }

    setIsDefaultAccessPermissionsDropdownOpened([...defaultAccessPermissions.map(() => false)]);
  };

  if (!business || !businessAccountInfoSettings || !entitySettings) {
    return <Loader />;
  }

  let subtitleMessage = __("access_settings_info_text");
  switch (page) {
    case PAGES.B_USER:
      subtitleMessage = __("user_access_settings_info_text")
      break;
    case PAGES.B_TEAM:
      subtitleMessage = __("team_access_settings_info_text")
      break;
  }

  return (
    <Wrapper>
      <PermissionsHeaderRow>
        <div>
          <PermissionsTitle>
            {__('default_access_settings')}
          </PermissionsTitle>
          <PermissionsDescription>
            <Componentify text={subtitleMessage} converters={[boldConverter]} />
          </PermissionsDescription>
        </div>
      </PermissionsHeaderRow>
      {defaultAccessPermissions.map((field, index) => {
        let dropdownValues = defaultShareSettingVals;
        if (defaultAccessPermissionsTitles.SHARE_OUT_COMPANY === field.title) {
          if (page !== PAGES.B_ACCOUNT) {
            dropdownValues = companyShareSettingInheritVals
          } else {
            dropdownValues = companyShareSettingVals
          }
        } else {
          if (page !== PAGES.B_ACCOUNT) {
            dropdownValues = defaultShareSettingInheritVals
          }
        }
        const settingsValue = entitySettings[field.title]
        let permissionsText = __(permissionsValuesTextMap[settingsValue])
        if (settingsValue === 0) {
          permissionsText = permissionsText + " (" + __(permissionsValuesTextMap[businessAccountInfoSettings[field.title]]) + ")"
        }
        return (
          <CommonStyle.Row key={field.title}>
            <div>
              <CommonStyle.Title>{__(field.title)}</CommonStyle.Title>
              <CommonStyle.Description>{__(field.description)}</CommonStyle.Description>
            </div>
            <Menu
              align="end"
              transition
              menuButton={
                <CommonStyle.Button disabled={!canUserManage || entity?.owner}>
                  {permissionsText}
                  {!entity?.owner && <i className="fa-light fa-angle-down smallIcon" />}
                </CommonStyle.Button>
              }
            >
              {dropdownValues.map((val) => {
                let dropdownValueText = __(permissionsValuesTextMap[val])
                if (val === 0) {
                  dropdownValueText = dropdownValueText + " (" + __(permissionsValuesTextMap[businessAccountInfoSettings[field.title]]) + ")"
                }
                return (
                  <StyledMenuItem
                    key={val}
                    onClick={() => { onPermissionsCLick(field.title, val) }}
                  >
                    {dropdownValueText} {permissionsText === dropdownValueText ? (<i className="smallIcon linkColorIcon fa-regular fa-check" />) : null}
                  </StyledMenuItem>
                )
              })}
            </Menu>
          </CommonStyle.Row >
        )
      })}
      <PermissionsHeaderRow>
        <div>
          <PermissionsTitle>
            {__('default_admin_settings')}
          </PermissionsTitle>
          <PermissionsDescription>
            <Componentify text={__('admin_settings_info_text')} converters={[boldConverter]} />
          </PermissionsDescription>
        </div>
      </PermissionsHeaderRow>
      {
        defaultAdminSettings.map((field) => {
          let dropdownValues = defaultShareSettingVals;
          if (page === PAGES.B_USER) {
            dropdownValues = defaultShareSettingInheritVals
          }
          const settingsValue = entitySettings[field.title]
          let permissionsText = __(permissionsValuesTextMap[settingsValue])
          if (settingsValue === 0) {
            permissionsText = permissionsText + " (" + __(permissionsValuesTextMap[businessAccountInfoSettings[field.title]]) + ")"
          }
          return (
            <CommonStyle.Row key={field.title}>
              <div>
                <CommonStyle.Title>{__(field.title)}</CommonStyle.Title>
                <CommonStyle.Description>{__(field.description)}</CommonStyle.Description>
              </div>
              {page === PAGES.B_ACCOUNT ?
                <CommonStyle.Button disabled>{__("Disabled")}</CommonStyle.Button> :
                <Menu
                  align="end"
                  transition
                  menuButton={
                    <CommonStyle.Button disabled={entity?.owner || !canUserManage}>
                      {permissionsText}
                      {!entity?.owner && <i className="fa-light fa-angle-down smallIcon" />}
                    </CommonStyle.Button>
                  }
                >
                  {dropdownValues.map((val) => {
                    let dropdownValueText = __(permissionsValuesTextMap[val])
                    if (val === 0) {
                      dropdownValueText = dropdownValueText + " (" + __(permissionsValuesTextMap[businessAccountInfoSettings[field.title]]) + ")"
                    }
                    return (
                      <StyledMenuItem
                        key={val}
                        onClick={() => { onPermissionsCLick(field.title, val) }}
                      >
                        {dropdownValueText} {permissionsText === dropdownValueText ? (<i className="smallIcon linkColorIcon fa-regular fa-check" />) : null}
                      </StyledMenuItem>
                    )
                  })}
                </Menu>
              }
            </CommonStyle.Row>
          )
        })
      }
    </Wrapper>
  )
}

export default UserPermissions

const Wrapper = styled.div`
  padding-bottom: 56px;
  box-sizing: border-box;
  @media(max-width: 768px) {
    padding-bottom: 0;
  }
`

const PermissionsHeaderRow = styled(CommonStyle.Row)`
  padding: var(--spacing-lg) var(--spacing-md);
  min-height: 80px;
  border: none;
`

const PermissionsTitle = styled.h2`
  background: linear-gradient(90deg, #1A87A3 0%, #001C1F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-size: var(--font-size-16);
  font-weight: 600;
  font-family: Inter;
  margin: 0;
  width: fit-content;
`

const PermissionsDescription = styled.p`
  color: var(--text-secondary, #8C959D);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const DefaultAdminSettingsDescription = styled(CommonStyle.Description)`
  display: block;
  height: ${({ $height }) => $height};
  overflow: hidden;
  transition: height 0.5s ease;
  min-height: unset;

  ul {
    margin: 0;
  }
`

const ShowHideAction = styled.div`
  display: flex;
  gap: 8px;
  height: 28px;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: var(--text-primary);
`

const ArrowIcon = styled.i`
  transition: transform 0.5s ease;
  transform: ${({ $isActive }) => $isActive ? "rotate(180deg)" : "rotate(0deg)"};
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
`