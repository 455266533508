import React from "react";

import styled, { css } from "styled-components";
import { Range, getTrackBackground } from "react-range";

type Props = {
  minValue: number,
  maxValue: number,
  value: [number],
  step?: number,
  disabled?: boolean,
  showLabel?: boolean,
  formatLabel?: (value: number) => string,
  onRangeValueChange?: (value) => void,
};
const RangeSlider = ({
  minValue,
  maxValue,
  value,
  step = 100,
  disabled = false,
  showLabel = false,
  formatLabel = (value) => value,
  onRangeValueChange = () => { },
  showButtons = false,
}: Props) => {
  const increment = () => {
    const newValue = Math.min(value + step, maxValue);
    onRangeValueChange(newValue);
  };

  const decrement = () => {
    const newValue = Math.max(value - step, minValue);
    onRangeValueChange(newValue);
  };

  return (
    <Wrapper>
      {showButtons &&
        <IconWrapper onClick={decrement} disabled={disabled}>
          <i className="baseIcon fa-regular fa-minus" />
        </IconWrapper>
      }
      <Range
        min={minValue}
        max={maxValue}
        disabled={disabled}
        step={step}
        values={[value]}
        onChange={(values) => onRangeValueChange(values[0])}
        renderTrack={({ props, children }) => (
          <RangeTrackWrapper onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} disabled={disabled}>
            <RangeTrack
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values: [value],
                  colors: disabled ? ["#8C959D", "#cccccc"] : ["#17bed0", "#cccccc"],
                  min: minValue,
                  max: maxValue
                })
              }}
            >
              {children}
            </RangeTrack>
          </RangeTrackWrapper>
        )}
        renderThumb={({ props }) => (
          <RangeSliderThumbWrapper {...props} key={props.key}>
            <RangeSliderThumb disabled={disabled} />
            {showLabel ? <RangeSliderLabel>{formatLabel(value)}</RangeSliderLabel> : null}
          </RangeSliderThumbWrapper>)}
      />
      {showButtons &&
        <IconWrapper onClick={increment} disabled={disabled}>
          <i className="'baseIcon fa-regular fa-plus" />
        </IconWrapper>
      }
    </Wrapper>
  );
};

export default RangeSlider;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--spacing-md);
  align-items: center;
`;

const RangeTrackWrapper = styled.div`
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
  height: 1rem;
  display: flex;
  width: 100%;
  min-height: 24px;
`;

const RangeTrack = styled.div`
  height: 4px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
`;

const RangeSliderThumbWrapper = styled.div`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RangeSliderThumb = styled.div`
  display: ${props => (props.disabled ? "none" : "block")};
  background: ${props => (props.disabled ? "#cccccc" : "#17bed0")};
  border: 1px solid ${props => (props.disabled ? "#cccccc" : "#17bed0")};
  border-radius: 100%;
  height: 14px;
  width: 14px;
  outline: none;
  box-shadow: ${props => (props.disabled ? "none" : "0 2px 8px 0 rgba(0, 0, 0, 0.16)")};
  transition: transform 0.3s ease-out;
  box-sizing: border-box;

  &:active {
    transform: ${props => (props.disabled ? "none" : "scale(1.3)")};
  }
`;

const RangeSliderLabel = styled.div`
  position: absolute;
  top: -27px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  pointer-events: none;/* Prevents slider to be draggable */
`;

const IconWrapper = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  background: ${({ disabled }) => disabled ? "#cccccc" : "var(--surface-skeleton)"};
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}
`;
