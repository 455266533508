// @flow

import React, { useState, useEffect } from "react";

import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { useDispatch, useSelector } from "react-redux";
import { editTeam } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

const EditTeamNameModal = ({ teamName = "", teamId = 0, resetModalAction = () => {} }: { teamName: string, teamId: number }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(teamName);
  const [isLoading, setIsLoading] = useState(false);
  const businessTeams = useSelector(({ business }) => business.teams);
  const currenTeam = businessTeams.find(team => team.id === teamId) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSave();
    }
  };

  const onNameChange = ({ target }) => {
    setName(target.value);
  };

  const onSave = () => {
    if (isLoading) {
      return;
    }
    if (!name.trim().length) {
      HFN.message(__("business_edit_user_team_name_error"), "error");
      return;
    }

    setIsLoading(true);

    apiMethod(
      "account_teammodify",
      { name: name, teamid: teamId },
      () => {
        dispatch(editTeam({...currenTeam, name: name}))
        HFN.message("Team Changed.");
        handleClose();
      },
      {
        errorCallback: ({ error, result, field }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2103) {
            if (field === "name") {
              errorMessage = __("business_edit_user_team_name_error");
            }
          } else if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          } else if (result === 2082) {
            errorMessage = __("business_edit_user_team_name_exists_error", "Team with this name already exists.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      opened={isOpen}
      onAnimationCloseComplete={resetModalAction}
      title={__("modal_edit_team_header", "Edit Team")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          onClick={onSave}
        >
          {__("Save")}
        </Button>
      }
    >
      <Style.Label>{__("Name", "Name")}</Style.Label>
      <InputText
        name="teamName"
        placeholder={__("modal_edit_team_input_placeholder", "Edit name")}
        onChange={onNameChange}
        value={name}
        autoFocus
        onKeyDown={onEnter}
      />
    </ModalTemplate>
  );
};

export default EditTeamNameModal;
