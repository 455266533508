// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import ModalTemplate from "../ModalTemplate";

type Props = {
  memberId: number,
  memberFirstName: boolean,
  memberLastName: number,
  memberEmail: string,
  isOpen: boolean,
  handleClose: () => void,
};

const ResetMemberPassModal = ({
  memberId = 0,
  memberFirstName = "",
  memberLastName = "",
  memberEmail = "",
  isOpen,
  handleClose,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      resetPass();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);

    }
  }, [isOpen]);

  const resetPass = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_invalidate_password",
      { userid: memberId },
      () => {
        PCB.refreshAll();
        HFN.message(__("The password has been reset."));
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onClose={handleClose}
      opened={isOpen}
      title={__("Reset Password")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          loading={isLoading}
          onClick={resetPass}
        >
          {__("Reset")}
        </Button>
      }
    >
      <Style.Label>{__("modal_reset_member_pass_header", "Reset password for user")}</Style.Label>
      <BoxReset>
        <b>{`${memberFirstName} ${memberLastName}`}</b>
        {` (${memberEmail})`}
      </BoxReset>
    </ModalTemplate>
  );
};

export default ResetMemberPassModal;

const BoxReset = styled(Style.Box)`
  display: block;
  font-weight: normal;
  text-align: left;
`;
