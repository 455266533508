import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../styles/sharedStyles";

export const Wrapper = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  
  gap: 24px;
  & > div {
    width: 100%;
  }
`;
export const HeaderActions = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
`;
export const DatesWrapper = styled(FlexRow)`
  display: flex;
  gap: var(--spacing-sm);
  flex-shrink: 0;

  color: var(--text-primary);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;
export const DateButton = styled(FlexRow)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  
  i {
    font-size: 14px;
  }
`;
export const Header = styled(FlexRow)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  padding-right: var(--spacing-lg);
  box-sizing: border-box;
  
  .hover-menu-button {
    margin: 0;
    padding: 0;
    gap: var(--spacing-sm);
  }
  
  &.mobile {
    margin-top: 0;
    flex-direction: column;
    align-items: start;
    padding: ${({$forceMobile}) => $forceMobile ? '0 var(--spacing-md)' : 'var(--spacing-md) var(--spacing-md) 0'};
    box-sizing: border-box;
    gap: var(--spacing-sm);
  }
`;

export const TrafficButton = styled.div`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px; 
`;

export const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ChartContainer = styled(FlexRow)`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.mobile {
    height: 150px;
  }
`;

export const OverallWrapper = styled.div`
  margin: var(--spacing-2xl) 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: end;
`;

export const AlertWrapper = styled.div`
  padding-right: var(--spacing-lg);
  box-sizing: border-box;

  &.mobile {
    padding-left: var(--spacing-md);
  }
`;