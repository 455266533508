// @flow
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MinusIcon from "@pcloud/web-utilities/dist/resources/images/svg/minus-icon.svg";
import PlusIcon from "@pcloud/web-utilities/dist/resources/images/svg/plus-icon.svg";
import RangeSlider from "./RangeSlider";
import { formatSizeInGb } from "../lib/utils";
import { BUSINESS_QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  users: number,
  minValue: number,
  maxValue: number,
  step?: number,
  startValue: number,
  minStartValue: number,
  disabled?: boolean,
  getSliderValue: string => void
};

const AdvancedSlider = ({
  users,
  minValue,
  maxValue,
  step = 100,
  startValue,
  minStartValue,
  disabled = false,
  getSliderValue = () => {}
}: Props) => {
  const [currentValue, setCurrentValue] = useState(startValue);

  useEffect(() => {
    checkValue(currentValue);
  }, [users]);

  const checkValue = (value) => {
    if (value >= maxValue / users) {
      const updatedValue = maxValue / users;
      const roundedValue = Math.floor(updatedValue / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;

      if (roundedValue > BUSINESS_QUOTA_STEP) {
        setCurrentValue(roundedValue);
        getSliderValue(roundedValue);
      } else {
        setCurrentValue(BUSINESS_QUOTA_STEP);
        getSliderValue(BUSINESS_QUOTA_STEP);
      }
    } else if (value < minValue) {
      setCurrentValue(BUSINESS_QUOTA_STEP);
      getSliderValue(BUSINESS_QUOTA_STEP);
    } else {
      setCurrentValue(value);
      getSliderValue(value);
    }
  };

  const formatLabel = (value) => {
    return formatSizeInGb(value, 0);
  };

  const onMinusClick = () => {
    if (disabled) {
      return;
    }
    if (currentValue - step >= minStartValue) {
      checkValue(currentValue - step);
    }
  };

  const onPlusClick = () => {
    if (disabled) {
      return;
    }
    checkValue(currentValue + step);
  };

  const onRangeValueChange = (value) => {
    if (value >= minStartValue) {
      checkValue(value);
    }
  };

  return (
    <Container $disabled={disabled}>
      <ButtonWrapper>
        <Button $disabled={disabled} onClick={onMinusClick}>
          <i className="fa-regular fa-minus" />
        </Button>
        <SliderWrapper>
          <RangeSlider
            minValue={minValue}
            maxValue={maxValue}
            value={currentValue}
            step={step}
            disabled={disabled}
            showLabel={false}
            formatLabel={formatLabel}
            onRangeValueChange={onRangeValueChange}
          />
        </SliderWrapper>
        <Button $disabled={disabled} onClick={onPlusClick}>
          <i className="fa-regular fa-plus" />
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default AdvancedSlider;

const Container = styled.div`
  display: table-cell;
  vertical-align: bottom;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

const Button = styled.button`
    display: flex;
    border: none;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background: var(--color-grey-300);
    font-size: 14px;
    cursor: ${props => (props.$disabled ? "default" : "pointer")};
    & i {
        color: ${props => (props.$disabled ? "var(--text-icons-light, #C6CACE)" : "inherit")};
    }
`;

const SliderWrapper = styled.div`
  width: 100%;
`;
