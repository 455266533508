// @flow

import React, { useState, useEffect, useCallback } from "react";

import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { useDispatch, useSelector } from "react-redux";
import { editUser, loadAccountInfo, loadInvitedUsers } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";
import { getAccountInfoCall } from "../../../api/business";

const CancelInvitationModal = ({ email = "", resetModalAction = () => {} }: { email: string, resetModalAction: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const invitedUsersState = useSelector(({ business }) => business.invited_users);
  const accountInfo = useSelector(({ business }) => business.accountInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      cancelInvite();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);

    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const cancelInvite = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_invitecancel",
      { mail: email },
      () => {
        const tmpInvitedUsers = invitedUsersState.filter((item) => {
          return item.email !== email;
        });

        getAccountInfoCall((accountInfoResponse) => {
          delete accountInfoResponse.result;
          accountInfoResponse.can_invite_account = accountInfo.can_invite_account;
          dispatch(loadAccountInfo(accountInfoResponse));
          dispatch(loadInvitedUsers(tmpInvitedUsers));

          const owner = accountInfoResponse?.account?.owner;
          if (owner) {
            dispatch(editUser({
              id: owner.id,
              quota: owner.quota,
              usedquota: owner.usedquota,
            }));
          }
          handleClose();
          HFN.message(__("Invitation Cancelled")+'.');
        });
      },
      {
        forceFresh: true,
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      cancelLabel={__("Close")}
      opened={isOpen}
      title={__("family_invitation_cancelled_title")}
      buttonsComponent={
        <Button
          loading={isLoading}
          styled="ModalDangerButton"
          onClick={cancelInvite}
        >
          {__("Cancel Invite")}
        </Button>
      }
    >
      <Style.User>
        <Style.UserLabel>{__("form_email", "Email")}</Style.UserLabel>
        <Style.UserData>
          <Style.Email>{email}</Style.Email>
        </Style.UserData>
      </Style.User>
    </ModalTemplate>
  );
};

export default CancelInvitationModal;
