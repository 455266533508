import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import DateRangePicker from "../../../components/Forms/Date/DateRangePicker";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../config/constants";
import Button from "../../../components/ButtonDefault";
import InputSearchSelector from "../../../components/AutoComplete/InputSearchSelector";
import { useSelector } from "react-redux";
import { getBusinessUserName, getLogsDefaultDates } from "../utils";
import { userInitials } from "../../../lib/user-utils";
import hashManager from "../../../lib/hashManager";
import { formatDate } from "date-fns";
import useWindowDimensions from "../../../lib/hooks/useWindowDimensions";

const BusinessLogsOptionsRow = () => {
  const registered = useSelector(({ pCloudUser }) => pCloudUser.userinfo.registered);
  const defaultDates = getLogsDefaultDates(registered);
  const [dateRange, setDateRange] = useState([
    hashManager.getState("from") ? new Date(hashManager.getState("from")) : defaultDates.from,
    hashManager.getState("to") ? new Date(hashManager.getState("to")) : defaultDates.to
  ]);
  const [startDate, endDate] = dateRange;
  const { bodyWidth } = useWindowDimensions();
  const isMobileView = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

  const inputSelectorRef = useRef({});
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const teams = useSelector(({ business }) => business.teams);
  const users = useSelector(({ business }) => business.users);

  const contentRef = useRef(null);

  const buildUser = (user) => {
    return {
      id: parseInt(user.id),
      label: getBusinessUserName(user),
      type: "user",
      iconContent: userInitials({ firstname: user.firstname, lastname: user.lastname }),
      subLabel: user.email
    };
  };

  const buildTeam = (team) => {
    return {
      id: parseInt(team.id),
      label: team.name,
      type: "team",
      icon: "fa-solid fa-users",
      subLabel: __("%num% members", null, { num: team.members.length })
    };
  };

  const defaultSelected = useMemo(() => {
    const tmpDefaultSelected = [];
    if (hashManager.getState("userids")) {
      const userIds = hashManager.getState("userids").split(",");
      userIds.forEach((id) => {
        const user = users.find((item) => item.id === parseInt(id));
        if (user) {
          tmpDefaultSelected.push(buildUser(user));
        }
      });
    }
    if (hashManager.getState("teamids")) {
      const teamIds = hashManager.getState("teamids").split(",");
      teamIds.forEach((id) => {
        const team = teams.find((item) => item.id === parseInt(id));
        if (team) {
          tmpDefaultSelected.push(buildTeam(team));
        }
      });
    }

    if (tmpDefaultSelected.length) {
      setSelectedFilters(tmpDefaultSelected);
    }
    return tmpDefaultSelected;
  }, []);

  const searchList = useMemo(() => {
    const userSelection = users.map((user) => {
      return buildUser(user);
    });

    const teamSelection = teams.map((team) => {
      return buildTeam(team);
    });

    return [...userSelection, ...teamSelection];
  }, [teams, users]);

  const onFilterClick = () => {
    if (!hasChanges) {
      return null;
    }
    setHasChanges(false);
    const params = {
      page: "b_logs",
      from: formatDate(dateRange[0], "yyyy-MM-dd"),
      to: formatDate(dateRange[1], "yyyy-MM-dd")
    };

    if (selectedFilters.length) {
      const userIds = [];
      const teamIds = [];
      selectedFilters.forEach((filter) => {
        if (filter.type === "user") userIds.push(filter.id);
        if (filter.type === "team") teamIds.push(filter.id);
      });
      if (userIds.length) params.userids = userIds.join(",");
      if (teamIds.length) params.teamids = teamIds.join(",");
    }

    hashManager.pushState(params, 2);
  };

  const onResetSelection = () => {
    if (inputSelectorRef.current && inputSelectorRef.current.resetSelections) {
      inputSelectorRef.current.resetSelections();
    }
    setHasChanges(true);
  };

  const onRemoveSelection = (event, index) => {
    if (inputSelectorRef.current && inputSelectorRef.current.removeTag) {
      inputSelectorRef.current.removeTag(event, index);
    }
    setHasChanges(true);
  };

  const handleOnItemClick = (event, data) => {
    setSelectedFilters(data);
    setHasChanges(true);
  };

  const renderFilterResults = () => {
    if (!isMobileView) {
      return (
        <InputSelectionResults onClick={onResetSelection}>
          <InputSelectionResultsItem>
            <i className="fa-regular fa-close" />
            <span>
              {selectedFilters.length === 1
                ? selectedFilters[0].label
                : selectedFilters.length + " " + __("selected_multiple")}
            </span>
          </InputSelectionResultsItem>
        </InputSelectionResults>
      );
    }

    return (
      <InputSelectionResults>
        {selectedFilters.map((item, index) => {
          return (
            <InputSelectionResultsItem key={index} onClick={(event) => onRemoveSelection(event, index)}>
              <i className="fa-regular fa-close" />
              {item.label}
            </InputSelectionResultsItem>
          );
        })}
        {selectedFilters.length > 1 && (
          <InputSelectionResultsItem className="noBorder" onClick={onResetSelection}>
            <i className="fa-regular fa-close" />
            {__("clear_all")}
          </InputSelectionResultsItem>
        )}
      </InputSelectionResults>
    );
  };

  return (
    <Wrapper ref={contentRef} $hasSelectedItems={!!selectedFilters.length} className="optionsRow disableGridFocus">
      <DateWrapper>
        <DateRangePicker
          minDate={new Date(registered)}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
            setHasChanges(true);
          }}
        />
      </DateWrapper>
      <SearchInputWrapper>
        <InputSearchSelector
          componentRef={inputSelectorRef}
          placeholder={__("Search teams or users")}
          list={searchList}
          defaultSelected={defaultSelected}
          onItemClick={handleOnItemClick}
        />
      </SearchInputWrapper>
      {!!selectedFilters.length && renderFilterResults()}
      <ButtonWrapper>
        <Button disabled={!hasChanges} styled="PrimaryButton" onClick={onFilterClick}>
          {isMobileView ? __("search_through") : __("Filter Logs")}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default BusinessLogsOptionsRow;

const Wrapper = styled.div`
  display: flex;
  justify-content: start !important;
  gap: var(--spacing-md);
  padding-right: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);

  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    padding: 0 var(--spacing-md) 0 var(--spacing-md);

    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(${({ $hasSelectedItems }) => ($hasSelectedItems ? "3fr" : "2fr")}, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 16px;
  }
`;

const DateWrapper = styled.div`
  width: 300px;

  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    width: 100%;
    grid-area: 3 / 1 / 4 / 2;
  }
  @media (max-width: 360px) {
    .react-datepicker__input-container:after {
      display: none;
    }
  }
`;

const SearchInputWrapper = styled(DateWrapper)`
  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    grid-area: 2 / 1 / 3 / 3;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  
  & button {
    flex-shrink: 0;
  }
  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    grid-area: 3 / 2 / 4 / 3;
    & button {
      width: 100%;
    }
  }
`;

const InputSelectionResults = styled.div`
  max-width: 200px;
  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    max-width: none;
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    gap: 8px;
    white-space: nowrap;
    overflow: auto;
  }
`;

const InputSelectionResultsItem = styled.div`
  display: flex;
  height: 40px;
  box-sizing: border-box;
  padding: var(--spacing-xs) var(--spacing-sm);
  align-items: center;
  gap: 8px;
  border-radius: var(--spacing-lg);
  border: 1px solid var(--button-primary-background-default);
  cursor: pointer;

  color: var(--text-primary);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &.noBorder {
    border: none;
  }

  & span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
