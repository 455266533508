import React, { useEffect, useMemo } from "react";
import useTabs from "../../../components/Tabs/hooks/useTabs";
import * as SETTINGS from "../businessBillingConstants";
import { StyledTabs, StyledTab, TabsAndContentWrapper, TabsAndContentWrapperInner, TabContent, OuterScrollWrapper } from "../../../components/Tabs/tabs.style";
import Info from "./Info";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import UserPermissions from "../BusinessAccountSettings/UserPermissions";
import ChangeCryptoPassSetting from "../../../components/Settings/ChangeCryptoPassSetting";
import hashManager from "../../../lib/hashManager";
import { useDispatch, useSelector } from "react-redux";
import { loadBreadcrumb } from "../../../lib/state/reducers/contentSlice";
import { __ } from "../../../lib/translate";
import { buildBusinessUsername } from "../../../lib/shareFolder-utils";
import Teams from "./Teams";

const BusinessUserContainer = () => {
  const entityId = Number(hashManager.getState("id"));
  const { account, cryptosetup, cryptov2isactive, userid } = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
  const tabs = useMemo(() => {
    const SettingsTabsCopy = [...SETTINGS.BUSINESS_USER_TABS];
    if (cryptosetup && account?.owner && cryptov2isactive && userid !== entityId && !(SettingsTabsCopy.find((tab) => tab.tabId === SETTINGS.BUSINESS_USER_CRYPTO))) {
      SettingsTabsCopy.push(SETTINGS.USER_CRYPTO);
    }
    return SettingsTabsCopy;
  }, []);
  const dispatch = useDispatch();
  const users = useSelector(({ business }) => business.users);
  const user = users?.find((item) => item?.id === entityId);

  const {
    activeTab,
    onTabClick
  } = useTabs({
    tabs,
    defaultActiveTabId: SETTINGS.BUSINESS_USER_INFO,
    activeTabHashParamName: "tab-user"
  });

  useEffect(() => {
    if (user) {
      let nameSubfix = "";
      if (user.id && !HFN.config.isMobile()) {
        nameSubfix = " (" + user.email + ")";
      }
      dispatch(
        loadBreadcrumb([
          {
            id: "0",
            name: __("Users"),
            customRedirectToState: {
              page: "b_users"
            },
            isBusinessUser: true
          },
          {
            ...user,
            id: user.id.toString(),
            name: buildBusinessUsername(user),
            nameSubfix: nameSubfix,
            isBusinessUser: true,
            canUserManage: account.permissions.user_manage,
            canViewLogs: account.permissions.log_view,
          }
        ])
      );
    }
  }, [user]);

  const renderTabContent = () => {
    if (activeTab === SETTINGS.BUSINESS_USER_INFO) {
      return <Info />;
    } else if (activeTab === SETTINGS.BUSINESS_USER_PERMISSIONS) {
      return <UserPermissions />;
    } else if (activeTab === SETTINGS.BUSINESS_USER_TEAMS) {
      return <Teams user={user} />;
    } else if (activeTab === SETTINGS.BUSINESS_USER_CRYPTO) {
      return <ChangeCryptoPassSetting />;
    }
  };

  return (
    <TabsAndContentWrapper>
      <ReactTooltip className="cv-tooltip" id="edit-image-tooltip" place="top" noArrow offset={4} />
      <TabsAndContentWrapperInner className="inner-animation animation-type-fade">
        <StyledTabs
          value={activeTab}
          onChange={onTabClick}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs.map(({ tabId, tabTitle }) => (
            <StyledTab key={tabId} label={__(tabTitle)} />
          ))}
        </StyledTabs>
        <OuterScrollWrapper>
          <TabContent>
            {renderTabContent()}
          </TabContent>
        </OuterScrollWrapper>
      </TabsAndContentWrapperInner>
    </TabsAndContentWrapper>
  );
};

export default BusinessUserContainer;