// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import FlowManager from "../../containers/TwoFactorAuth/FlowManager";
import TermsStep from "../../containers/MoveDataRegion/TermsStep";
import PaymentStep from "../../containers/MoveDataRegion/PaymentStep";
import RequestRelocationStep from "../../containers/MoveDataRegion/RequestRelocationStep";
import RequestReceivedStep from "../../containers/MoveDataRegion/RequestReceivedStep";
import CancelVerificationStep from "../../containers/MoveDataRegion/CancelVerificationStep";
import CancelConfirmationStep from "../../containers/MoveDataRegion/CancelConfirmationStep";
import CancelSuccessStep from "../../containers/MoveDataRegion/CancelSuccessStep";

import * as Step from "../../containers/TwoFactorAuth/index";
import { boldConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";
import { getGlobalLocationId } from "../../lib/utils";
import apiMethod from "../../api/apiMethod";
import {
  LOCATION_REGIONS,
  US_LOCATIONID,
  EU_LOCATIONID,
  CHANGE_DATA_REGION_STATUS
} from "@pcloud/web-utilities/dist/config/constants";
import * as CommonStyle from "../../components/CommonStyledSettings";
import Button from "../ButtonDefault";
import { borderRadius } from "polished";

const modalFlows = {
  sentEmail: [Step.SetupPassStep, Step.SentEmailStep],
  freeTransfer: [TermsStep, RequestRelocationStep, RequestReceivedStep],
  paidTransfer: [TermsStep, RequestRelocationStep, PaymentStep, RequestReceivedStep],
  cancelTransfer: [CancelVerificationStep, CancelConfirmationStep, CancelSuccessStep]
};

const statusListKeys = ["change_region_pending", "change_region_progress", "change_region_done"];

const DataRegionSettings = () => {
  const hasPassword = useSelector(({ pCloudUser }) => pCloudUser.userinfo.haspassword);
  const isVerified = useSelector(({ pCloudUser }) => pCloudUser.userinfo.emailverified);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const isBusinessMember = isBusiness && useSelector(({ pCloudUser }) => !pCloudUser.userinfo.account.owner);
  const isBusinessTrial = isBusiness && !isBusinessMember && useSelector(({ pCloudUser }) => pCloudUser.userinfo.account.is_trial);
  const isFamily = useSelector(({ pCloudUser }) => pCloudUser.userinfo.family);
  const isFamilyMember = isFamily && useSelector(({ pCloudUser }) => !pCloudUser.userinfo.family.owner);
  const isVivacom = useSelector(({ pCloudUser }) => !!pCloudUser.userinfo.vivapcloud);
  const currency = useSelector(({ pCloudUser }) => pCloudUser.userinfo.currency);
  const hasPaidRelocation = useSelector(({ pCloudUser }) => pCloudUser.userinfo.haspaidrelocation);
  const relocationStatus = useSelector(({ pCloudUser }) => pCloudUser.relocationInfo.relocationStatus);
  const canRelocate = useSelector(({ pCloudUser }) => pCloudUser.relocationInfo.canRelocate);
  const hasFree = useSelector(({ pCloudUser }) => pCloudUser.relocationInfo.hasFree);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("us");

  const [currentFlow, setCurrentFlow] = useState([]);
  const dispatch = useDispatch();

  const [shouldCloseOnEscape, setshouldCloseOnEscape] = useState(true);

  const currentLocationId = getGlobalLocationId();
  const changeToLocationId = currentLocationId === EU_LOCATIONID ? US_LOCATIONID : EU_LOCATIONID;
  const currentLocationKey = LOCATION_REGIONS[currentLocationId].key;

  useEffect(() => {
    apiMethod(
      "getip",
      {},
      ({ country }) => {
        setCountry(country);
      },
      {
        errorCallback: ({ result, error }) => {
          throw new Error(error);
        }
      }
    );
  }, [])

  const onChangeClick = () => {
    if (!isVerified) {
      HFN.message(__("Please verify your email address to perform this action."), "error");
      return;
    } else if (!canRelocate) {
      HFN.message(
        __(
          "error_2333",
          "You have recently moved this account. It's not possible to move it again so soon."
        ),
        "error"
      );
      return;
    } else if (isFamilyMember) {
      HFN.message(__("family_member_relocation_message"), "error");
    } else if (isBusinessMember) {
      HFN.message(__("business_user_relocation_message"), "error");
    } else if (!hasPassword) {
      setCurrentFlow(modalFlows["sentEmail"]);
    } else if (!hasFree && !hasPaidRelocation) {
      setCurrentFlow(modalFlows["paidTransfer"]);
    } else {
      setCurrentFlow(modalFlows["freeTransfer"]);
    }
  };

  const onCancelClick = () => {
    setCurrentFlow(modalFlows["cancelTransfer"]);
  };

  const onCloseClick = () => {
    setCurrentFlow([]);
  };

  const renderButton = () => {
    if (isBusiness && isBusinessMember) {
      return null;
    } else if (relocationStatus === CHANGE_DATA_REGION_STATUS["IN_PROGRESS"]) {
      return null;
    } else if (relocationStatus === CHANGE_DATA_REGION_STATUS["IN_QUEUE"]) {
      return <Button onClick={onCancelClick}>{__("change_region_cancel_button")}</Button>;
    } else if (
      relocationStatus === CHANGE_DATA_REGION_STATUS["CANCELED"] ||
      relocationStatus === CHANGE_DATA_REGION_STATUS["DEFAULT"]
    ) {
      return <Button onClick={onChangeClick}>{__("region_setting_change_button")}</Button>;
    }
  };

  const renderRightCol = () => {
    if (isBusinessTrial || isVivacom) {
      return null;
    }

    return (
      <>
        {statusListKeys[relocationStatus] ? (
          <>
            <Status>
              {__("region_setting_status")}
              {": "}
              <b>{__(statusListKeys[relocationStatus])}</b>
            </Status>
            {relocationStatus !== CHANGE_DATA_REGION_STATUS["IN_PROGRESS"] ? <SeparationLine /> : null}
          </>
        ) : null}
        {renderButton()}
      </>
    );
  };

  return (
    <>
      <CommonStyle.Row>
        <div>
          <CommonStyle.Title className="row-title">{__("data_region", "Data Region")}</CommonStyle.Title>
          <CommonStyle.Description>
            <div>
              <Componentify
                text={__(
                  "region_setting_description",
                  "Your files and data are currently being stored in <b>%locationA%</b>.",
                  { locationA: __(currentLocationKey) }
                )}
                converters={[boldConverter]}
              />
            </div>
          </CommonStyle.Description>
        </div>
        {renderRightCol()}
      </CommonStyle.Row>
      <FlowManager
        flow={currentFlow}
        onClose={onCloseClick}
        hasFree={hasFree}
        hasPaidRelocation={hasPaidRelocation}
        currency={currency}
        currentLocationId={currentLocationId}
        changeToLocationId={changeToLocationId}
        shouldCloseOnEscape={shouldCloseOnEscape}
        loading={loading}
        setLoading={setLoading}
        country={country}
        style={{
          borderRadius: "var(--radius-lg)"
        }}
      />
    </>
  );
};

export default DataRegionSettings;

const Status = styled.div`
  display: inline-block;
  font-size: 15px;
  color: var(--color-base-black);
`;

const SeparationLine = styled.div`
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #e9e9e9;
  margin: 0px 10px;
`;
