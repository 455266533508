// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import * as Style from "./preferences.style";
import * as CommonStyle from "../../components/CommonStyledSettings";
import { apiMethod } from "../../api";
import InputCheckbox from "../InputCheckbox";

const NotificationSetting = () => {
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const [notificationSettingsList, setNotificationSettingsList] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = () => {
    apiMethod("listnotificationsettings", { auth: token }, ret =>
      setNotificationSettingsList(ret.types)
    );
  };

  const onSettingChange = flag => {
    const notificationSettingsListCopy = [...notificationSettingsList];
    const index = notificationSettingsListCopy.findIndex(type => type.flag === flag);
    const toggleFlag = notificationSettingsListCopy[index];
    
    if (toggleFlag.canedit) {
      toggleFlag.subscribed = !toggleFlag.subscribed
    }

    apiMethod(
      "changenotificationsettings", {
        setting: notificationSettingsListCopy.reduce((acc, { subscribed, flag }) => (acc += subscribed ? flag : 0), 0),
        auth: token
      }, () => {
        setNotificationSettingsList(notificationSettingsListCopy);
      },
      { errorCallback: ret => console.error(ret) }
    );

  };

  if (!notificationSettingsList) {
    return null;
  }

  return notificationSettingsList.map(({ name, subscribed, flag, canedit }) => (
    <CommonStyle.Row>
      <div>
        <CommonStyle.Title>{__("subscription_type_" + flag + "_title", name)}</CommonStyle.Title>
        <CommonStyle.Description>{__("subscription_type_" + flag + "_description", "description")}</CommonStyle.Description>
      </div>
      <InputCheckbox size="small" checked={subscribed} disabled={!canedit} onClick={() => onSettingChange(flag)}/>
    </CommonStyle.Row>
  ))
};

export default NotificationSetting;
