import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../styles/sharedStyles";



export const Wrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 740px;
  margin: 0 auto var(--spacing-3xl);
  gap: var(--spaces-6);
  box-sizing: border-box;
  padding-top: 50px;

  @media (max-width: 1023px) {
    padding: var(--spacing-md) var(--spacing-md);
  }
`;

export const UserInvitationContainerWrapper = styled(FlexColumn)`
  width: 100%;
  gap: var(--spacing-xl);
`;

export const FormContent = styled(FlexColumn)`
  gap: var(--spacing-lg);
  width: 100%;
`;

export const ShareSection = styled(FlexRow)`
  gap: var(--spacing-lg);

  color: var(--color-base-black);
  text-align: center;

  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ShareButtonsWrapper = styled(FlexRow)`
  gap: 12px;
`;

export const ShareButton = styled(FlexRow)`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 9999px;
  background: var(--color-grey-300);
  & i {
    font-size: 15px;
    color: var(--color-primary-900);
  }
`;

export const InputSection = styled(FlexColumn)`
  width: 100%;
  max-width: 606px;
`;

export const FormField = styled(FlexRow)`
  gap: 14px;
  width: 100%;
  align-items: start;
  
  @media (max-width: 700px) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

export const InputGroup = styled(FlexColumn)`
  width: 100%;
`;

export const InputNote = styled.div`
  color: var(--text-secondary);
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
`;

export const InvitationStatsContainerWrapper = styled(FlexColumn)`
  width: 100%;
  gap: 38px;
  padding: var(--spacing-md);
  font-family: Roboto, sans-serif;
`;
export const InvitationStatsContainerTitle = styled.div`
  color: var(--color-base-black);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;
export const InvitationStatsContainerSubTitle = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; 
`;

export const TermsBoxContainer = styled(FlexColumn)`
  width: 100%;
  gap: var(--spacing-sm);
`;

export const TermsTitle = styled.div`
  color: var(--color-base-black);
  text-align: center;

  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

export const TermsButton = styled(FlexRow)`
  cursor: pointer;
  width: 40px;
  height: 40px;
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
  & i {
    font-size: 16px;
  }
  
  &.active {
    transform: rotate(180deg);
  }
`;

export const TermsContent = styled.div`
  overflow: hidden;
  max-height: 0;
`;

export const TermRowWrapper = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const TermRow = styled.li`
  color: var(--color-base-black);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

	a {
		color: #17bed0;
		text-decoration: none;
	}
`;

export const InvitationWinnerContainerWrapper = styled(FlexRow)`
  width: 100%;
  border-radius: 12px;
  background: #BEEDF2;
  height: 140px;
  padding: 0 16px 0 40px;
  box-sizing: border-box;
  z-index: 1;
  & svg {
    z-index: 2;
  }
  
  @media (max-width: 700px) {
    position: relative;
    flex-direction: column;
    height: auto;
    padding: 0;
    & svg {
      position: absolute;
      bottom: 8px;
      right: 16px;
    }
  }
`;

export const WinnerHeading = styled(FlexColumn)`
  gap: var(--spacing-sm);
  text-transform: uppercase;
  align-items: start;
  flex-grow: 1;
  @media (max-width: 700px) {
    padding: 32px 24px 16px;
    width: 100%;
    align-items: start;
    box-sizing: border-box;
  }
`;
export const WinnerTitle = styled.div`
  color: var(--text-primary);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; 
`;
export const WinnerSubTitle = styled.div`
  color: var(--text-primary);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;
export const WinnerDetails = styled(FlexColumn)`
  gap: var(--spacing-sm);
  color: var(--text-primary);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; 
  height: 100%;
  max-width: 300px;
  flex-grow: 1;
  background: #f2fbfc;
  box-sizing: border-box;
  text-align: center;
  transform: skewX(-30deg);
  & span {
    transform: skewX(30deg);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    min-width: 0;
  }
  
  @media (max-width: 700px) {
    padding: 21px 24px 16px;
    width: 100%;
    max-width: none;
    align-items: start;
    text-align: left;
    transform: skewX(0);
    & span {
      transform: skewX(0);
      padding-right: 60px;
    }
  }
`;