import React, { useEffect } from 'react';
import styled from 'styled-components';
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import { getPlanName } from "@pcloud/web-utilities/dist/plans/plansInfo";
import { useSelector } from "react-redux";
import ProgressSmart from '../ProgressSmart';
import UserQuotaTooltip from '../UserQuotaTooltip';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { calculateProgress } from './helpers';
import { getBusinessPlanName } from "../../lib/plansInfo";

const UserPlanInfo = () => {
  const businessPlanId = useSelector(({ business }) => business?.accountInfo?.account?.businessplanid);
  const userInfo = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
  const leftMenuContent = useSelector(({ content }) => content.leftMenuContent) || undefined;
  const { isProfileOpened } = leftMenuContent;
  const separator = { width: "2px", backgroundColor: "#fff" };

  const {
    quota,
    usedquota,
    plan,
    freequota,
    premium,
    lastsubscription,
    business,
    premiumlifetime,
    planparams,
  } = userInfo;

  const userHasActiveSubscription = lastsubscription && (lastsubscription.status === "active" || lastsubscription.status === "past_due");
  const userCanAddStorage = !business && premiumlifetime && (plan == 1 || plan == 3 || (plan == 13 && planparams && planparams.storage < 2) || (plan == 17 && planparams && planparams.storage <= 34));
  const userCanUpgrade = !business && !premium && (!lastsubscription || lastsubscription.status !== "active");
  const freeQuotaData = HFN.getFreeQuotaData();
  const isFullStorageSpace = freeQuotaData.ispremium ? freeQuotaData.freequotagb <= 1 : freeQuotaData.percent <= 1;
  const storageAlmostFull = freeQuotaData.ispremium ? freeQuotaData.freequotagb <= 10 : freeQuotaData.percent <= 10;

  const renderUpgradeButton = () => {
    let text = "";
    let link = "";
    if (!userHasActiveSubscription && userCanAddStorage) {
      text = "add_storage";
      link = "cloud-storage-pricing-plans.html";
    } else if (userCanUpgrade) {
      text = "upgrade_to_premium";
      link = "cloud-storage-pricing-plans.html";
    }

    if (!text || !link) {
      return null;
    }

    return <UpgradeButton onClick={() => window.open(URLSITE + link, '_blank')}>{__(text)}</UpgradeButton >
  }

  useEffect(() => {
    let timer;
    if (isProfileOpened) {
      timer = setTimeout(() => {
        HFN.checkModalQuotaWarning();
      }, 300);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [isProfileOpened]);

  return (
    <Container>
      <div data-tooltip-id="quota">
        <StorageUsedContainer>
          <Text>{__('used_quota').replace('%used%', renderQuota(usedquota)).replace('%total%', renderQuota(quota))}</Text>
        </StorageUsedContainer>
        <ProgressSmart
          fractions={calculateProgress(quota, usedquota, freequota, isFullStorageSpace, storageAlmostFull)}
          separator={separator}
          progressHeight="4px"
        />
        <StyledReactTooltip className="cv-tooltip" id="quota" noArrow place="right-end" offset={-8}>
          <UserQuotaTooltip quota={calculateProgress(quota, usedquota, freequota)} />
        </StyledReactTooltip>
        <Text>
          {__(`${(
            businessPlanId ? getBusinessPlanName(businessPlanId) : getPlanName(plan, userInfo)
          )}`)}
        </Text>
      </div>
      {renderUpgradeButton()}
    </Container>
  );
};

export default UserPlanInfo;

const Container = styled.div`
  padding: var(--spacing-md);
  background-color: var(--color-grey-100);
  width: 100%;
  box-sizing: border-box;
  height: auto;
  border-bottom: 1px solid var(--divider-primary);
`;

const StorageUsedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  color: var(--color-grey-600);
  font-size: 11px;
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: 12px;
  color: var(--text-secondary);
  line-height: 16px;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--color-grey-800);
  z-index: 1000;
`;

const UpgradeButton = styled.button`
  height: 40px;
  width: 208px;
  background-color: var(--button-primary-background-default);
  font-feature-settings: 'case' on;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  margin-top: var(--spacing-md);
  border: none;
  border-radius: 30px;
  color: var(--color-base-white);
  cursor: pointer;
`