import styled, { keyframes } from "styled-components";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../config/constants";

export const skeletonLoading = keyframes`
    100% {
        background-position-x: calc(100% + 200px);
    }
`;
export const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;
export const FilemanagerLoaderList = styled.div`
  margin-top: 0;
  animation: ${fadeIn} 0.1s normal forwards ease-in;
  background: #fff;
  padding: 14px 0 0 0;
  display: block;
  position: relative;

  & .select-col {
    width: 27px;
  }

  & .initials-col {
    flex-shrink: 0;
  }

  & .typeIcon-col {
    width: 24px;
    min-width: 24px;
  }

  & .logsEventSub-col {
    width: 100%;
  }

  & .business_quota-col {
    width: 180px;
    min-width: 180px;
  }

  & .teams-col,
  & .users-col {
    width: 260px;
    min-width: 260px;
  }

  & .modified-col,
  & .deleted-col,
  & .albums_count-col,
  & .songs_count-col,
  & .items-col,
  & .status-col,
  & .created-col {
    width: 100px;
    min-width: 100px;
  }

  & .description-col,
  & .comment-col,
  & .head-col {
    width: calc(35% - 100px);
    min-width: calc(35% - 100px);
  }

  & .size-col,
  & .traffic-col,
  & .downloads-col,
  & .quota-col {
    width: 110px;
    min-width: 110px;
    padding-right: var(--spacing-xs);
    justify-content: flex-end;
  }

  & .traffic-col,
  & .downloads-col {
    width: 120px;
    min-width: 120px;
  }

  & .permissions-col {
    width: 160px;
    min-width: 160px;
  }

  & .type-col {
    width: 180px;
    min-width: 180px;
  }

  & .ownername-col {
    width: 200px;
    min-width: 200px;
  }

  & .created-col-right,
  & .shares_count-col {
    width: 150px;
    min-width: 150px;
  }

  & .created-col-right-small {
    width: 90px;
    min-width: 90px;
  }

  & .created-col-with-time {
    width: 175px;
    min-width: 175px;
  }

  & .album-col,
  & .genre-col {
    max-width: 15%;
    min-width: 15%;
  }

  & .artist-col,
  & .log_data-col {
    max-width: 35%;
    min-width: 35%;
  }

  & .log_event-col {
    max-width: 20%;
    min-width: 20%;
  }

  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    & .hide-on-mobile {
      display: none;
    }
  }
`;

export const LoaderAnimation = styled.div`
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 70%
    )
    transparent;
  background-size: 200px 100%;
  background-position-x: -200px;
  background-repeat: no-repeat;
  animation: 1.5s ${skeletonLoading} ease-in-out infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;
export const LoadingHeaderRow = styled.div`
  margin: 0 16px;
  height: 24px;

  & .loading-gray-boxes div {
    background-color: var(--surface-skeleton);
    border-radius: var(--radius-minimum);
    height: 24px;
  }
`;
export const LoadingHeaderRowMenu = styled.div`
  float: right;
  margin: 0 8px;
  width: 40px;
`;
export const LoadingListHeaderRow = styled.div`
  margin: 12px 16px 0 0;
  padding-left: 24px;
  padding-bottom: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--surface-skeleton);
  display: flex;
  gap: 4px;
`;
export const LoadingListHeaderRowCell = styled.div`
  flex-grow: ${({ $stretchWidth }) => ($stretchWidth ? 1 : 0)};
  width: 100px;
  height: 14px;
  background: none;

  & div {
    width: ${({ $width }) => ($width ? $width + "px" : "64px")};
    max-width: 100%;
    height: 14px;
  }

  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    flex-grow: 0;
    width: 50% !important;
    max-width: 50% !important;
    & div {
      width: 50%;
    }
  }
`;
export const LoadingListRowIcon = styled.div`
  width: 24px;
`;
export const LoadingListRowCell = styled.div`
  flex-grow: ${({ $stretchWidth }) => ($stretchWidth ? 1 : 0)};
  background: none !important;

  & div {
    width: 160px;
    max-width: 80%;

    &.sub {
      width: 100px;
    }
  }

  &.has-second-line {
    & div {
      margin-top: 4px !important;
      height: 14px;
    }
  }

  @media (max-width: ${MOBILE_WIDTH_BREAKPOINT}px) {
    flex-grow: 0;
    width: 50% !important;
    max-width: 50% !important;
    & div {
      width: 60%;

      &.sub {
        width: 75%;
      }
    }
  }
`;
export const LoadingListRow = styled.div`
  margin-right: 16px;
  height: 23px;
  padding: 8px 2px 8px 23px;
  border-bottom: 1px solid var(--surface-skeleton);
  display: flex;
  gap: 4px;

  & .has-second-line div {
    transform: translateY(-8px);
  }

  &.large {
    height: 39px;

    & .has-second-line div {
      transform: translateY(0);
    }

    & ${LoadingListRowIcon} {
      width: 40px;
      height: 40px;
    }

    & ${LoadingListRowCell} div {
      margin-top: 7px;
    }
  }

  &.loading-item-last-4 {
    opacity: 0.8;
  }

  &.loading-item-last-3 {
    opacity: 0.6;
  }

  &.loading-item-last-2 {
    opacity: 0.4;
  }

  &.loading-item-last-1 {
    opacity: 0.2;
  }
`;
export const FilemanagerLoaderGrid = styled.div`
  background: #fff;
  padding: 1px 8px 0 8px;
  position: relative;
`;
export const SectionLabel = styled.div`
  width: 64px;
  height: 14px;
  background-color: #eff0f1;
  border-radius: var(--radius-minimum);
`;
export const LoadingFoldersLabel = styled.div`
  height: 14px;
  margin: 8px 0 22px;
`;
export const LoadingItems = styled.div`
  margin: 0 16px 0 0;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
`;
export const LoadingFolder = styled.div`
  height: 40px;
  background-color: #eff0f1;
  border-radius: var(--radius-minimum);
`;
export const LoadingFilesLabel = styled.div`
  height: 14px;
  margin: 36px 0 22px;
`;
export const LoadingFile = styled.div`
  height: 24px;
  border-radius: var(--radius-minimum);
  padding-top: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const LoadingFileThumb = styled.div`
  top: 0;
  right: 0;
  bottom: 30px;
  left: 0;
  position: absolute;
  background-color: #eff0f1;
  border-radius: var(--radius-minimum);
`;
export const LoadingFileCaption = styled.div`
  width: 90%;
  height: 24px;
  margin: 0 auto;
  background-color: #eff0f1;
  border-radius: var(--radius-minimum);
`;
