import React, { useState } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import { Btn } from "../ButtonDefault";
import { __ } from "../../lib/translate";
import { boldConverter } from "../../lib/componentifyConverters";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Text, Title } from "./sharedStyles";

const UploadCloseWithRetry = () => {
  const {
    isUploadCloseWithRetryOpen,
    handleUploadCloseWithRetryClose,
    handleWarningOnUploadModalYesClick,
    handleUploadCloseWithRetryYesClick
  } = useUploadContext();
  return (
    <Modal align="top" animate shouldCloseOnOverlayClick={false} onClose={handleUploadCloseWithRetryClose} opened={isUploadCloseWithRetryOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleUploadCloseWithRetryClose} />
        <Content>
          <React.Fragment>
            <Body>
              <Title>{__('upload_manager_close_title', 'Close Upload Manager')}</Title>
              <Text>
                <Componentify
                  text={__(
                    "upload_manager_close_with_retry_description",
                    "There are failed uploads. Closing the Upload manager will cancel them. How do you want to proceed?"
                  )}
                  converters={[boldConverter]}
                />
              </Text>
            </Body>
            <ButtonWrapper>
              <Btn inline color="lightgray4" onClick={handleWarningOnUploadModalYesClick}>
                {__('Close', 'Close')}
              </Btn>
              <Btn inline color="cyan" onClick={handleUploadCloseWithRetryYesClick}>
                {__('upload_manager_failed_items_action_success', 'Retry all')}
              </Btn>
            </ButtonWrapper>
          </React.Fragment>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default UploadCloseWithRetry;