import React, { useState, useEffect } from "react";
import { __ } from "../../lib/translate";
import { Button, InputWrapper, Content, Header, Wrapper } from "./styles";
import styled from "styled-components";
import PasswordInput from "./parts/PasswordInput";
import CryptoLoader from "./CryptoLoader";
import RegularInput from "./parts/RegularInput";
import hashManager from "../../lib/hashManager";
import usePasswordValidation from "./hooks/usePasswordValidation";

const CryptoSet = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [hintValue, setHintValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [repeatPasswordValue, setRepeatPasswordValue] = useState("");
    const [requirementsAreMet, setRequirementsAreMet] = useState(false);

    const { errors, disabledSubmitBtn } = usePasswordValidation(
        passwordValue,
        repeatPasswordValue,
        hintValue,
        requirementsAreMet,
    );

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (disabledSubmitBtn) {
            return;
        }

        setShowLoader(true);

        pCloudCrypto.setupCrypto(
            passwordValue,
            hintValue,
            function () {
                sessionStorage.setItem('cryptosetupcompleted', true)
                hashManager.pushState({ page: "filemanager", crypto: 1 }, 2);
            },
            function () {
                HFN.message("Invalid Crypto Pass.", "error");
                setShowLoader(false);
            }
        );
    };

    if (showLoader) {
        return (
            <CryptoLoader
                message={__("Generating your unique encryption key...")}
            />
        );
    }

    return (
        <WrapperCustom>
            <Form onSubmit={handleSubmit} autoComplete="off">
                <Content $maxWidth={430}>
                    <Main>
                        <Header>
                            <Title>
                                {__(
                                    "crypto_set_title",
                                    "Create your Crypto Pass"
                                )}
                            </Title>
                            <SubTitle>
                                {__(
                                    "crypto_set_message",
                                    "You will use your Crypto Pass to decrypt and access the files in the Crypto folder. We don’t know or store your Crypto Pass anywhere on our servers."
                                )}
                            </SubTitle>
                        </Header>
                        <SuggestContainer>
                            <SuggestContainerTitle>
                                {__(
                                    "crypto_set_suggestion_title",
                                    "To set a strong Crypto Pass use:"
                                )}
                            </SuggestContainerTitle>
                            <SuggestContainerDescription>
                                <li>
                                    {__(
                                        "crypto_set_suggestion_line1",
                                        "at least 8 characters"
                                    )}
                                </li>
                                <li>
                                    {__(
                                        "crypto_set_suggestion_line2",
                                        "digits, special symbols, uppercase letters"
                                    )}
                                </li>
                            </SuggestContainerDescription>
                        </SuggestContainer>
                        <InputWrapperCustom>
                            <PasswordInput
                                onKeyPress={handleKeyPress}
                                usePasswordChecker={true}
                                setRequirementsAreMet={setRequirementsAreMet}
                                invalid={errors.password}
                                value={passwordValue}
                                onChange={(e) => {
                                    setPasswordValue(e.target.value);
                                }}
                                placeholder={__("Create Crypto Pass") + " *"}
                            />
                            <PasswordInput
                                onKeyPress={handleKeyPress}
                                value={repeatPasswordValue}
                                invalid={errors.repeatPassword}
                                onChange={(e) => {
                                    setRepeatPasswordValue(e.target.value);
                                }}
                                placeholder={__("Repeat Crypto Pass") + " *"}
                            />
                            <RegularInput
                                onKeyPress={handleKeyPress}
                                value={hintValue}
                                invalid={errors.hint}
                                onChange={(e) => {
                                    setHintValue(e.target.value);
                                }}
                                placeholder={__("Hint")}
                            />
                        </InputWrapperCustom>
                    </Main>
                    <ButtowWrapper>
                        <Button
                            type={"submit"}
                            disabled={disabledSubmitBtn || showLoader}
                        >
                            {__("Create Crypto Folder")}
                        </Button>
                    </ButtowWrapper>
                </Content>
            </Form>
        </WrapperCustom>
    );
};

export default CryptoSet;

const WrapperCustom = styled(Wrapper)`
    margin-top: 40px;

    @media (max-width: 1024px) {
        margin-top: 0;
    }
`;

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const InputWrapperCustom = styled(InputWrapper)`
    max-width: 400px;
    gap: 16px;
`;

const ButtowWrapper = styled.div`
    width: 100%;
    max-width: 400px;
`;

const Main = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    max-width: 430px;
`;

const Title = styled.div`
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
`;

const SubTitle = styled.div`
    color: var(--text-primary);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
`;

const SuggestContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: var(--color-functional-positive100);
    padding: var(--spacing-md);
    box-sizing: border-box;
`;

const SuggestContainerTitle = styled.div`
    color: var(--text-primary);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
`;

const SuggestContainerDescription = styled.ul`
    color: var(--text-primary);
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 0;

    & li {
        margin-left: -30px;
    }
`;
