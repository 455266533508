// @flow

import React from "react";
import { useSelector } from "react-redux";

import { __ } from "../../lib/translate";
import * as CommonStyle from "../../components/CommonStyledSettings";
import Button from "../ButtonDefault";
import hashManager from "../../lib/hashManager";

const EncryptionSetupSetting = () => {
  const encryptionSetup = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptosetup);
  const encryptionExpire = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptoexpires);

  const onSetupEncryptionClick = () => {
    hashManager.pushState({ page: "crypto" }, 2);
  }

  if (encryptionSetup) {
    return null;
  }

  return (
    <CommonStyle.Row>
      <div>
        <CommonStyle.Title>{__("Encryption")}</CommonStyle.Title>
        <CommonStyle.Description>{__("The safest place, where your files are hidden from anyone else!")}</CommonStyle.Description>
      </div>
      <Button onClick={onSetupEncryptionClick}>{__("tfa_setup_set_up_btn", "Set up")}</Button>
    </CommonStyle.Row>
  )
};

export default EncryptionSetupSetting;
