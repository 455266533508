import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { __ } from "../../../../../../lib/translate";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { usersCalc } from "../../../../../../containers/Business/utils";

const BusinessUsers = () => {
  const users = useSelector(({ business }) => business.users);
  const memberinfo = useSelector(({ business }) => business.accountInfo.account.memberinfo);

  const [counter, setCounter] = useState(null);

  useEffect(() => {
    if (!users || !users.length || !memberinfo) {
      return;
    }

    const userCalc = usersCalc(users);
    if (userCalc.hasOwnProperty("active")) {
      setCounter({
        active: userCalc.active,
        total: memberinfo.maximum
      });
    }
  }, [users, memberinfo]);

  return (
    <Wrapper>
      {counter && (
        <>
          {"("}
          <span data-tooltip-id="b-users-breadcrumb" data-tooltip-content={__("Active Users")}>
            {counter.active}
          </span>
          {"/"}
          <span data-tooltip-id="b-users-breadcrumb" data-tooltip-content={__("Users Quota")}>
            {counter.total}
          </span>
          {")"}
        </>
      )}
      <ReactTooltip className="cv-tooltip" id="b-users-breadcrumb" place="bottom" noArrow offset={12} />
    </Wrapper>
  );
};

export default BusinessUsers;

const Wrapper = styled.div`
  margin-left: 4px;
  display: inline-block;
  color: var(--text-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;
