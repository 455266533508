// @flow
import React, { useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";

import { __ } from "../../../lib/translate";
import apiMethod from "../../../api/apiMethod";

import Alert, { AlertIcon } from "../../Alert";
import PassWithHint from "./PassWithHint";
import { Header, Container } from "../../Modals/styledComponents";
import { Btn } from "../../ButtonDefault";
import { Button } from "../../../containers/CryptoPages/styles";
import ModalTemplate from "../../Modals/ModalTemplate";

type Props = {
  onPassOk: () => void
};

const EnterPass = ({ onPassOk = () => { }, onSuccess = () => { } }: Props) => {
  const [password, onPassChange] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);

  const checkPassword = e => {
    e.preventDefault();
    if (loading || !password.length) {
      return;
    }
    setLoading(true);

    pCloudCrypto.unlockCrypto(password, ok => {
      if (ok) {
        apiMethod("crypto_sendchangeuserprivate", { auth: token }, res => {
          if (res.result == 0) {
            onPassOk();
            onSuccess();
          }
        });
      } else {
        HFN.message(__("wrong_password"), "error")
        setLoading(false);
      }
    });
  };

  return (
    <ModalTemplate
      animate
      externalModal
      title={__("heading_change_crypto_pass")}
      description={__("change_crypto_pass_desc_step_2")}
    >
      <Main onSubmit={checkPassword} autoComplete="off">
        <PassWithHint onPassChange={onPassChange} password={password} />
        <Button
          type={"submit"}
          disabled={password.length < 3 || loading}
        >
          {__("Continue")}
        </Button>
      </Main>
    </ModalTemplate>
  );
};

export default EnterPass;

const ModalContainer = styled.div`
  text-align: center;
  border-radius: var(--radius-lg);
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
  padding: var(--spacing-xl);
  background: var(--color-base-white);
  max-width: 480px;
  width: 100%;
  min-height: auto;
  color: var(--color-base-black);
  box-sizing: border-box;
`;

const ModalHeader = styled.div`
  font-size: var(--font-size-18);
  color: var(--color-base-black);
  line-height: 32px;
  font-weight: bold;
  margin-bottom: var(--spacing-xl);
`;

const ModalDescription = styled.div`
  font-size: var(--font-size-14);
  color: var(--text-primary);
  text-align: center;
  margin-bottom: var(--spacing-xl);
`;

const Main = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: var(--spacing-md);
`;