export const SUBSCRIPTION_DETAILS_SETTINGS = 0;
export const INVOICE_DETAILS_SETTINGS = 1;

export const ACCOUNT_DETAILS_SETTINGS = 0;
export const ACCOUNT_USER_PERMISSIONS_SETTINGS = 1;
export const ACCOUNT_USER_CUSTOMIZATION_SETTINGS = 2;

export const BUSINESS_USER_INFO = 0;
export const BUSINESS_USER_PERMISSIONS = 1;
export const BUSINESS_USER_TEAMS = 2;
export const BUSINESS_USER_CRYPTO = 3;

export const BUSINESS_TEAM_INFO = 0;
export const BUSINESS_TEAM_PERMISSIONS = 1;
export const BUSINESS_TEAM_CRYPTO = 2;

export const DEFAULT_TABS = [
  {
    tabId: SUBSCRIPTION_DETAILS_SETTINGS,
    tabTitle: "subscription_details",
    search: "subscription"
  },
  {
    tabId: INVOICE_DETAILS_SETTINGS,
    tabTitle: "invoice_details",
    search: "invoice"
  },
];

export const ACCOUNT_CUSTOMIZATION = {
  tabId: ACCOUNT_USER_CUSTOMIZATION_SETTINGS,
  tabTitle: "business_customization",
  search: "branding"
};

export const BUSINESS_ACCOUNT_TABS = [
  {
    tabId: ACCOUNT_DETAILS_SETTINGS,
    tabTitle: "Account",
    search: "myaccount"
  },
  {
    tabId: ACCOUNT_USER_PERMISSIONS_SETTINGS,
    tabTitle: "users_permissions",
    search: "usersettings"
  },
];

export const USER_CRYPTO = {
  tabId: BUSINESS_USER_CRYPTO,
  tabTitle: "crypto_access",
  search: "crypto"
}

export const TEAM_CRYPTO = {
  tabId: BUSINESS_TEAM_CRYPTO,
  tabTitle: "crypto_access",
  search: "crypto_access"
}

export const BUSINESS_USER_TABS = [
  {
    tabId: BUSINESS_USER_INFO,
    tabTitle: "User",
    search: "userinfo"
  },
  {
    tabId: BUSINESS_USER_PERMISSIONS,
    tabTitle: "Permissions",
    search: "userpermissions"
  },
  {
    tabId: BUSINESS_USER_TEAMS,
    tabTitle: "Teams",
    search: "teams"
  },
];

export const BUSINESS_TEAM_TABS = [
  {
    tabId: BUSINESS_TEAM_INFO,
    tabTitle: "Team",
    search: "teaminfo"
  },
  {
    tabId: BUSINESS_TEAM_PERMISSIONS,
    tabTitle: "Permissions",
    search: "teampermissions"
  },
];