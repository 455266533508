// flow
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { apiMethod } from "../../../api";
import { fileext } from "../../../lib/utils";
import { fetchMediaSuccess, deleteMediaItem } from "../../../lib/state/reducers/galleryPreviewSlice";
import { getProperThumbnailSize, getVideoUrls, getProperMaxVideoSize } from "../utils";
import { NUM_RETRIES_ON_ERROR } from "../constants";

const useGalleryData = ({
  rawContentData,
  initialMediaId,
  revisionId,
  opts = {}, 
  onClosePreview, 
  resetZoom, 
  fullscreenVideoFromSlideshow, 
  disableArrowKeys,
  slideshowActive, 
  slideshowPaused,
  vidstackMediaPlayer,
  autoPlayFailed,
  setAutoPlayFailed
}) => {
  const dispatch = useDispatch();
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);

  let contentData = useSelector(
    ({ content }) => ({ items: content.itemData.items, itemKeys: content.itemKeys }),
    (oldValue, newValue) => oldValue.items === newValue.items && oldValue.itemKeys === newValue.itemKeys
  ); // {id: {metadata}}

  if (!contentData.itemKeys.length) {
    contentData = rawContentData;
  }

  const [onMediaFetched, setOnMediaFetched] = useState(null);
  const [onMediaFetchedError, setOnMediaFetchedError] = useState(null);
  const [onShownItemAction, setOnShownItemAction] = useState(null);

  const initialItemKeys = useRef([...contentData.itemKeys]); // [0: 'f166656565', 1: "d02212121"] sorted item keys
  const initialContentData = useRef({ ...contentData.items });

  const cachedMediaData = useSelector(({ galleryPreview }) => galleryPreview.mediaItems);

  const [contentDataGalleryIds, setContentDataGalleryIds] = useState([]); // 0: { id: 'f166656565', missing: true || false }

  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(false);

  const [keyboardArrowClicked, setKeyboardArrowClicked] = useState(null);
  const [onScreenOrientationChange, setOnScreenOrientationChange] = useState(null);

  const [shownItemData, setShownItemData] = useState({ place: -1, metaData: null, autoPlay: false, seek: 0, autoPlayFailed: false });
  const [showErrorMediaPreview, setShowErrorMediaPreview] = useState({ show: false, options: {showDownloadButton: true} });

  const isDlink = HFN.config.isDlink();

  const getNext = n => (n === contentDataGalleryIds.length - 1 ? 0 : n + 1);

  const getPrevious = n => (n === 0 ? contentDataGalleryIds.length - 1 : n - 1);

  const fetchedMediaRetries = useRef({});

  // Used to be set on true on autoPlay fail. It changes fetch "getmediatranscodelink" to happen on showing new item,
  // instead of fetching after play button is clicked.
  const videoAutoPlayFailed = useRef(autoPlayFailed);

  const videoIsPlaying = () => {
    return (vidstackMediaPlayer && vidstackMediaPlayer.current && ((vidstackMediaPlayer.current?.el && vidstackMediaPlayer.current?.el.hasAttribute("data-starting")) || vidstackMediaPlayer.current?.state.seeking || vidstackMediaPlayer.current?.state.playing || vidstackMediaPlayer.current?.state.waiting));
  }

  const handleKeyUp = useCallback(event => {
    if (event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
      return false;
    }

    // Is video shown and playing?
    if (videoIsPlaying()) {
      // Safari required only, because we don't receive seeked event after video is seeked to the end.
      if (!vidstackMediaPlayer.current?.el || !vidstackMediaPlayer.current?.el.hasAttribute("data-ended")) {
        return false;
      }
    }

    const isAnotherModalOpen = document.documentElement.classList.contains("g-modal-open");
    const isClickInsideInputZoom = event.target.classList.contains("zoom-input");

    if (isAnotherModalOpen || isClickInsideInputZoom) {
      return false;
    }

    setKeyboardArrowClicked({ key: event.key });
  }, []);

  const onShownMediaError = ({tryToRefresh = true, autoPlay = true, mediaType = "image", lastPlayingTime = 0, disableHLSVideoRetries = false}) => {
    if (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType]?.status === "pending") {
      // Await previous retry to finish.
      return;
    }

    let numRetriesOnError = NUM_RETRIES_ON_ERROR[mediaType];
    
    if (mediaType === "video" && shownItemData.metaData !== null && fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType].disableHLSVideoRetries) {
      numRetriesOnError = NUM_RETRIES_ON_ERROR["transcodedVideo"];// 1
    }

    if (!tryToRefresh || shownItemData.metaData === null || (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType].counter >= numRetriesOnError)) {
      if (mediaType === "videoThumb") {
        return;
      }
      // Show error view!
      setLoading(false);
      setVideoLoading(false);
      // Clear the previously shown media if exists. 
      // Otherwise it'll be shown for a tiny moment while changing to another media on going next/prev.
      setMedia(null);
      setShowErrorMediaPreview({ show: true, options: {showDownloadButton: true} });

      return;
    }

    // Try to refresh it. Probably the links are expired.
    // Increase retry counter.
    if (!fetchedMediaRetries.current[shownItemData.metaData.id]) {
      fetchedMediaRetries.current[shownItemData.metaData.id] = {};
    }

    fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] = {
      counter: (fetchedMediaRetries.current[shownItemData.metaData.id][mediaType]?.counter || 0) + 1, 
      status: "pending",
      disableHLSVideoRetries: fetchedMediaRetries.current[shownItemData.metaData.id][mediaType]?.disableHLSVideoRetries || disableHLSVideoRetries
    };

    if (mediaType !== "video") {
      setLoading(true);
    }// else loading is shown on the player.

    // Handle the broken item: remove it from cache to refetch new URL-s from the API.
    dispatch(deleteMediaItem(shownItemData.metaData.id));

    // Refresh the item.
    showItem(shownItemData.place, autoPlay, lastPlayingTime, mediaType === "video");
  };

  const onAutoPlayFailed = ({refreshVideoData}) => {
    // Set fetch getmediatranscodelink on each item change.
    videoAutoPlayFailed.current = true;
    setAutoPlayFailed();

    if (!refreshVideoData) {
      return;
    }

    if (shownItemData.metaData === null) {
      return;
    }

    // Refresh the item with autoPlay = false which will require UI interaction with the player.
    showItem(shownItemData.place, false, 0, true, true);
  };

  const deleteById = (obj, id) => {
    for (const key in obj) {
      if (obj[key].id === id) {
        delete obj[key];
        break; // Exit the loop after deleting the property
      }
    }
  };

  const handleActionOnItemListener = e => {
    if (e && e.detail) {
      setOnShownItemAction(e.detail);
    }
  };

  useEffect(() => {
    if (!onShownItemAction) {
      return;
    }
      
    const { action, data } = onShownItemAction;
    const { id, name } = data;

    if (action === "rename") {
      initialContentData.current[id].name = name;
    } else if (action === "delete" || action === "move") {
      if (Object.keys(initialContentData.current[id]).length) {
        deleteById(initialContentData.current, id);
      }
      const index = initialItemKeys.current.findIndex(element => element === id);
      if (index > -1) {
        initialItemKeys.current.splice(index, 1);
      }

      const updatedArray = contentDataGalleryIds.filter(item => item.id !== id);
      setContentDataGalleryIds(updatedArray);
    }
  }, [onShownItemAction]);

  const setFetchMediaRetriesStatus = (id, status) => {
    if (shownItemData.metaData === null || shownItemData.metaData.id !== id) {
      return;
    }

    if (shownItemData.metaData.category === HFN.CATEGORY.IMAGE) {
      if (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id]["image"]) {
        fetchedMediaRetries.current[shownItemData.metaData.id]["image"].status = status;
      }
    } else if (shownItemData.metaData.category === HFN.CATEGORY.VIDEO) {
      if (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id]["video"]) {
        fetchedMediaRetries.current[shownItemData.metaData.id]["video"].status = status;
      }

      if (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id]["videoThumb"]) {
        fetchedMediaRetries.current[shownItemData.metaData.id]["videoThumb"].status = status;
      }
    }
  };

  const onShownMediaSuccess = (mediaType = "image") => {
    if (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType]?.status !== "pending") {
      delete fetchedMediaRetries.current[shownItemData.metaData.id][mediaType];
    }
  }

  const cleanupPreviousFetchMediaRetriesData = () => {
    if (shownItemData.metaData === null) {
      return;
    }

    if (fetchedMediaRetries.current[shownItemData.metaData.id]) {
      const fetchedMediaRetriesCopy = fetchedMediaRetries.current[shownItemData.metaData.id];
      fetchedMediaRetries.current = {};
      fetchedMediaRetries.current[shownItemData.metaData.id] = fetchedMediaRetriesCopy;
    } else {
      fetchedMediaRetries.current = {};
    }
  }

  const handleScreenOrientationChange = (event) => {
    // const type = event.target.type;
    // const angle = event.target.angle;
    setOnScreenOrientationChange(event);
  }

  useEffect(() => {
    if (!onScreenOrientationChange) {
      return;
    }

    if (typeof gtag === "function") {
      gtag("event", "media_preview_view", {
        action: "orientationchange",
        category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
      });
    }
  }, [onScreenOrientationChange]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("more-options-click", handleActionOnItemListener);
    window.addEventListener('popstate', onClosePreview);
    
    if (typeof screen !== "undefined" && typeof screen.orientation !== "undefined") {
      screen.orientation.addEventListener("change", handleScreenOrientationChange);
    }

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("more-options-click", handleActionOnItemListener);
      window.removeEventListener('popstate', onClosePreview);
      
      if (typeof screen !== "undefined" && typeof screen.orientation !== "undefined") {
        screen.orientation.removeEventListener("change", handleScreenOrientationChange);
      }
    };
  }, []);

  useEffect(() => {
    if (contentData.itemKeys.length === 0) {
      // Missing content data for preview.
      return;
    }

    if (initialItemKeys.current.length) {
      const galleryIds = initialItemKeys.current.reduce((acc, fileId) => {
        const item = contentData.items[fileId];

        if (item) {
          if (item.category && ((item.category === HFN.CATEGORY.IMAGE && item.thumb) || item.category === HFN.CATEGORY.VIDEO)) {
            acc.push({ id: fileId, missing: false });

            // Possible rename of the item from another tab/browser should be updataed.
            if (initialContentData?.current[fileId]) {
              initialContentData.current[fileId].name = item.name;
            }
          }
        } else {
          // deleted or moved item
          acc.push({ id: fileId, missing: true });
        }

        return acc;
      }, []);

      if (Object.keys(galleryIds).length === 0) {
        // Missing content data for preview.
        return;
      }

      setContentDataGalleryIds(galleryIds); // id
    }
  }, [contentData]);

  useEffect(() => {
    if (contentDataGalleryIds.length === 0) {
      return;
    }

    // Initially load clicked item.
    if (shownItemData.metaData === null) {
      if (initialMediaId) {
        const currentIndex = contentDataGalleryIds.findIndex(({ id }) => id === initialMediaId);

        if (currentIndex !== -1) {

          gtag("event", "media_preview_view", {
            action: "open",
            category: initialContentData?.current[initialMediaId] && initialContentData.current[initialMediaId].category === HFN.CATEGORY.VIDEO ? "video" : "image"
          });
          
          showItem(currentIndex, videoAutoPlayFailed.current ? false : true);
        }
      } else {
        // Missing required input param.
        onClosePreview();
      }
    } else if (!initialContentData?.current[shownItemData.metaData.id]) {
      // Deleted or moved item.
      // Show next item which now equals to the old index.
      if (shownItemData.place <= contentDataGalleryIds.length - 1) {
        // Show next item.
        showItem(shownItemData.place);
      } else {
        // Show first item.
        showItem(0);
      }
    }
  }, [contentDataGalleryIds]);

  useEffect(() => {
    if (shownItemData.metaData === null) {
      return;
    }

    if (showErrorMediaPreview.show) {
      setShowErrorMediaPreview({ show: false, options: {showDownloadButton: true} });
    }

    cleanupPreviousFetchMediaRetriesData();
    
    fetchMedia(shownItemData.metaData,
      fetchedMedia => {
        setOnMediaFetched(fetchedMedia);
        setFetchMediaRetriesStatus(fetchedMedia.id, "success");
      }, 
      (id, error) => {
        setOnMediaFetchedError({id, error});
        setFetchMediaRetriesStatus(id, "failed");
      },
      shownItemData.autoPlay
    );
  }, [shownItemData]);

  useEffect(() => {
    if (!onMediaFetched) {
      return;
    }

    const fetchedMedia = onMediaFetched;

    if (shownItemData.metaData.id === fetchedMedia.id) {
      
      if (fetchedMedia.autoPlayMuted) {
        // We wanted to apply this only once after autoPlay failed.
        delete fetchedMedia.autoPlayMuted;
      }
      
      setMedia(fetchedMedia);
      setLoading(false);

      preloadItemsBeforeAndAfter(shownItemData.place);

      if (shownItemData.metaData.category === HFN.CATEGORY.IMAGE) {
        // Reset previous zoom if !== 100%
        resetZoom();
      }
    }
  }, [onMediaFetched]);

  useEffect(() => {
    if (!onMediaFetchedError) {
      return;
    }

    if (shownItemData.metaData.id === onMediaFetchedError.id) {
      setLoading(false);
      setVideoLoading(false);
      // Clear the previously shown media if exists. 
      // Otherwise it'll be shown for a tiny moment while changing to another media on going next/prev.
      setMedia(null);

      let showDownloadButton = true;

      if (onMediaFetchedError.error && onMediaFetchedError.error.result) {
        switch (onMediaFetchedError.error.result) {
          case 2002: // "A component of parent directory does not exist."
          case 2003: // "Access denied. You do not have permissions to perform this operation."
          case 2009: // "File not found."
          case 2075: // "You are not a member of a business account."
          // TODO: we can implement "Try again" button.
          case 5002: // "Internal error, no servers available. Try again later."
          case 4008: // "This link is temporarily unavailable. Try again later."
          case 7002: // "This link is deleted by the owner."
          case 7003: // "This file is no longer available due to a copyright claim."
          case 7004: // "This link has expired."
          case 7005: // "This link has reached its traffic limit."
          case 7006: // "This link has reached maximum downloads."
          case 7010: // "Invalid link referer."
            showDownloadButton = false;
            break;
        }
      }

      setShowErrorMediaPreview({ show: true, options: {showDownloadButton} });
      
      // Preload before and after items.
      preloadItemsBeforeAndAfter(shownItemData.place);
    }
  }, [onMediaFetchedError]);

  useEffect(() => {
    if (keyboardArrowClicked === null || fullscreenVideoFromSlideshow || disableArrowKeys || contentDataGalleryIds.length <= 1) {
      return;
    }

    if (keyboardArrowClicked.key === "ArrowLeft" || keyboardArrowClicked.key === "ArrowRight") {
      if (typeof gtag === "function") {
        gtag("event", "media_preview_click", {
          action: keyboardArrowClicked.key === "ArrowLeft" ? "previous" : "next",
          category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
        });
      }

      if (keyboardArrowClicked.key === "ArrowLeft") {
        showItem(getPrevious(shownItemData.place));
      } else {
        showItem(getNext(shownItemData.place));
      }
    }
  }, [keyboardArrowClicked]);

  const showItem = (n, autoPlay = false, seek = 0, skipLoading = false, autoPlayFailed = false) => {
    if (!(n in contentDataGalleryIds)) {
      return;
    }

    // find metadata by id
    const meta = initialContentData?.current[contentDataGalleryIds[n].id];

    if (!meta) {
      return;
    }

    // Check if it's already fetched.
    if (!fetchMediaFromCache(meta.id) && !skipLoading) {
      setLoading(true);
    }

    if (!skipLoading) {
      // For autoPlay we should wait until videoStarted event is triggered before hiding the loading.
      setVideoLoading((autoPlay || (slideshowActive && !slideshowPaused)) && meta.category === HFN.CATEGORY.VIDEO);
    }

    // This will trigger the usEffect hook which will load the new item.
    setShownItemData({ place: n, metaData: meta, autoPlay, seek, autoPlayFailed });
  };

  const mediaExistsInCache = id => (cachedMediaData && cachedMediaData[id] ? cachedMediaData[id] : false);

  const fetchMediaFromCache = (id) => {
    const mediaDataCache = mediaExistsInCache(id);
    if (mediaDataCache && !(mediaDataCache.forceFetch && (mediaDataCache.forceFetch.image || mediaDataCache.forceFetch.video))) {
      return mediaDataCache;
    }

    return false;
  }

  const fetchImg = (meta, onSuccess = () => {}, onError = () => {}, {forceFresh = false, }) => {
    const scrWidth = window.screen.availWidth;
    const scrHeight = window.screen.availHeight;

    let availableHeight = scrHeight;
    let availableWidth = scrWidth;
    availableHeight = availableHeight && Math.min(availableHeight - (availableHeight % 4), 2048);
    availableWidth = availableWidth && Math.min(availableWidth - (availableWidth % 4), 2048);

    const size =
      meta.width && meta.height
        ? HFN.calcImageSize(meta.width, meta.height, scrWidth, scrHeight, 620, 360)
        : [availableWidth, availableHeight];

    const imgData = {
      id: meta.id,
      category: meta.category
      // url -> will be set later.
    };

    const getThumb = () => {
      const params = { fileid: meta.fileid, size: `${size[0]}x${size[1]}`, hashCache: meta.hash, crop: 0 };
      const method = opts.code ? "getpubthumblink" : "getthumblink";

      // Prevent showing notification messages from ERROR_MESSAGE_KEYS (web-utilities/src/api/errors.js),
      // because we have an error view.
      const apiMethodOpts = { showErrorMessage: false };

      if (forceFresh) {
        apiMethodOpts.forceFresh = true;
      } else {
        apiMethodOpts.forceCache = true;
      }

      if (opts.code) {
        params.code = opts.code;
        if (opts.linkpassword) {
          params.linkpassword = opts.linkpassword;
        }

        if (token) {
          params.auth = token;
        }
      }

      if (meta.revisionid) {
        params.revisionid = meta.revisionid;
      }

      if (fileext(meta.name) === "png") {
        params.type = "png";
      }

      apiMethod(
        method,
        params,
        ret => {
          const url = HFN.prepUrl(ret);
          const img = new Image();
          img.src = url;
          img.onload = () => {
            imgData.url = url;
            // Cache image data.
            dispatch(fetchMediaSuccess(imgData));
            onSuccess(imgData);
          };

          img.onerror = (err) => onError(imgData.id, err);
        },
        {
          ...apiMethodOpts,
          errorCallback(res) {
            console.log("ERROR 3001");
            // if (res.result === 3001) {
            //  // Set fake Image object without src and trigger the success callback to setup some actions like Download.
            // }

            onError(imgData.id, res);
          },
          onXhrError: (xhr, status, error) => {
            onError(imgData.id, { reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
          }
        }
      );
    };

    const candownload = typeof opts.candownload !== "undefined" ? opts.candownload : true;
    const getFileLink = () => {
      // Prevent showing notification messages from ERROR_MESSAGE_KEYS (web-utilities/src/api/errors.js),
      // because we have an error view.
      const apiMethodOpts = { showErrorMessage: false };

      if (forceFresh) {
        apiMethodOpts.forceFresh = true;
      }

      HFN.getFileLinkBack(
        meta,
        ret => {
          const img = new Image();
          img.src = ret;
          img.onload = () => {
            imgData.url = ret;
            // Cache image data.
            dispatch(fetchMediaSuccess(imgData));
            onSuccess(imgData);
          };

          img.onerror = (err) => onError(imgData.id, err);
        },
        {
          ...apiMethodOpts,
          code: opts.code || false,
          candownload,
          errorCallback(res) {
            onError(imgData.id, res);
          },
          onXhrError: (xhr, status, error) => {
            onError(imgData.id, { reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
          }
        }
      );
    };

    const cantShowInBrowser = ["tif", "nex"];
    const hasSize = size[0] && size[1];
    const dlinkImgNoMetaSize = isDlink && meta.thumb;

    if (
      (meta.thumb && hasSize) ||
      dlinkImgNoMetaSize ||
      cantShowInBrowser.indexOf(fileext(meta.name)) !== -1 ||
      (meta.thumb && meta.size > 1048576 * 2)
    ) {
      console.log("GET ------- THUMB");
      getThumb();
    } else {
      console.log("GET ------- FILE LINK");
      getFileLink();
    }
  };

  const fetchVideo = (meta, onSuccess = () => {}, onError = () => {}, {videoForceFresh = false, thumbForceFresh = false}, autoPlay = false, prefetch = false) => {
    // const scrWidth = window.screen.availWidth;
    // const scrHeight = window.screen.availHeight;
    // const videoSize =
    //   HFN.config.isMobile() || HFN.config.isPublinkMobile()
    //     ? HFN.calcVideoSize(Math.min(630, scrWidth), Math.min(360, scrHeight))
    //     : HFN.calcVideoSize(Math.max(630, scrWidth), Math.max(360, scrHeight));
    // const thumbParams = { fileid: meta.fileid, size: `${HFN.thToProperSize(videoSize[0])}x${HFN.thToProperSize(videoSize[1])}` };

    const maxVideoSize = getProperMaxVideoSize(meta.width || 0, meta.height || 0);

    const videoData = {
      id: meta.id,
      name: meta.name,
      category: meta.category,
      videoUrls: [],
      thumbUrl: "",
      thumbType: "image/jpeg",
      maxWidth: maxVideoSize.width,
      maxHeight: maxVideoSize.height,
      originalWidth: meta.width || 0,
      originalHeight: meta.height || 0,
      rotate: meta.rotate,
      HLSpath: ""
    };

    // Fetch videoUrl data.
    let fetchVideoDataPromise = Promise.resolve();
    
    if (!prefetch && (autoPlay || (slideshowActive && !slideshowPaused) || videoAutoPlayFailed.current || (fetchedMediaRetries.current[meta.id] && fetchedMediaRetries.current[meta.id]["video"] && fetchedMediaRetries.current[meta.id]["video"].counter > 0))) {
      // If we are on last allowed retry -> use the old way -> transcoded mp4 video variants.
      const disableHLS = fetchedMediaRetries.current[meta.id] && fetchedMediaRetries.current[meta.id]["video"] && (fetchedMediaRetries.current[meta.id]["video"].counter === NUM_RETRIES_ON_ERROR["video"] || fetchedMediaRetries.current[meta.id]["video"].disableHLSVideoRetries);
      
      // If videoAutoPlayFailed.current === true -> we should set transcodeAhead = false, because we'll prepare video URLs before play button is clicked.
      fetchVideoDataPromise = fetchVideoUrls(videoData, meta, videoForceFresh, onError, false, !videoAutoPlayFailed.current, disableHLS);
    }

    const fetchVideoThumbPromise = getFetchVideoThumbPromise(videoData, meta, thumbForceFresh);

    let calledOnSuccess = false;
    const outputVideoDataOnSuccess = (videoData) => {
      if (calledOnSuccess) {
        // Already called success.
        return;
      }

      calledOnSuccess = true;
      if (fetchedMediaRetries.current[meta.id] && fetchedMediaRetries.current[meta.id]["video"] && fetchedMediaRetries.current[meta.id]["video"].counter > 0) {
        onSuccess({ ...videoData, afterError: true });
      } else {
        onSuccess(videoData);
      }
    };

    if (!prefetch && (autoPlay || (slideshowActive && !slideshowPaused) || (fetchedMediaRetries.current[meta.id] && fetchedMediaRetries.current[meta.id]["video"]))) {
      // There is no need to wait for fetchVideoThumbPromise before outputing data, because on autoPlay we require only the video URL to be played. 
      fetchVideoDataPromise = fetchVideoDataPromise.then(() => {
        if (videoData.videoUrls.length > 0) {
          // Important -> don't put the data in cache, because we don't have the video thumb.
          // Output videoData
          outputVideoDataOnSuccess(videoData);
        }
      });
    }

    Promise.all([fetchVideoDataPromise, fetchVideoThumbPromise]).then((values) => {
      // const fetchVideoUrlsRes = values[0];
      // const fetchVideoThumbRes = values[1];

      // Cache video data.
      dispatch(fetchMediaSuccess(videoData));
  
      // Output videoData
      outputVideoDataOnSuccess(videoData);

    }).catch((err) => {
      console.log("Fetch video data error:", err);
    });
  };

  /**
   * Called after "Play" event was triggered.
   * Here we'll set the missing URL data which starts transcoding tasks.
   */
  const fetchShownVideoUrls = async () => {
    if (shownItemData.metaData === null || shownItemData.metaData.category !== HFN.CATEGORY.VIDEO) {
      return;
    }

    // Check if it's already fetched.
    const videoDataCache = mediaExistsInCache(shownItemData.metaData.id);
    
    // All required data is here.
    if (videoDataCache && videoDataCache.videoUrls.length > 0) {
      setOnMediaFetched({...videoDataCache, startPlayerLoading: true});
      return;
    }
    
    if (videoDataCache) {
      // Has thumb in cache, but no videoUrls.
      try {
        await fetchVideoUrls(videoDataCache, shownItemData.metaData, false, (id, error) => setOnMediaFetchedError({id, error}), true);
        // We are here after videoData.videoUrls is set.
        // On success
        setOnMediaFetched({...videoDataCache, startPlayerLoading: true});
      } catch (err) {
        // onError callback was invoked.
      }

      return;
    }

    if (!videoDataCache) {
      // refetch the whole media.
      // Refresh the item.
      fetchMedia(shownItemData.metaData,
        fetchedMedia => {
          fetchedMedia.startPlayerLoading = true;
          setOnMediaFetched(fetchedMedia);
        }, 
        (id, error) => {
          setOnMediaFetchedError({id, error});
        },
        true
      );
    }
  }

  const fetchVideoUrls = (videoData, meta, forceFresh, onError = () => {}, cache = false, transcodeAhead = true, disableHLS = false) => {
    const videoParams = { 
      fileid: meta.fileid, 
      transcodeahead: transcodeAhead,
      mediatype: "video",
      contenttype: "video/mp4"
    };

    let videoMethod = "getmediatranscodelink";// getvideolinks

    // Prevent showing notification messages from ERROR_MESSAGE_KEYS (web-utilities/src/api/errors.js),
    // because we have an error view.
    const videoApiMethodOpts = { showErrorMessage: false };

    if (forceFresh) {
      videoApiMethodOpts.forceFresh = true;
    } else {
      videoApiMethodOpts.forceCache = true;
    }

    if (opts.code && location.host != "pcloud") {
      videoMethod = "getmediatranscodepublink";// getpubvideolinks
      videoParams.code = opts.code;

      if (opts.linkpassword) {
        videoParams.linkpassword = opts.linkpassword;
      }
    }

    if (token) {
      videoParams.auth = token;
    }

    if (meta.revisionid) {
      videoParams.revisionid = meta.revisionid;
    }

    return new Promise((resolve, reject) => {
      apiMethod(
        videoMethod,
        videoParams,
        res => {
          // Init as non-HLS;
          videoData.HLSpath = "";
          // If we'are on download link -> disallow "Original" variant.
          let videoUrls = getVideoUrls(res.variants, !isDlink, disableHLS);
          
          if (videoUrls.length === 0) {
            onError(videoData.id, { reasoncode: "missing_video_urls" });
            reject({ videoData, reasoncode: "missing_video_urls" });
            return;
          }
  
          videoData.videoUrls = videoUrls;
          
          videoData.HLSpath = videoUrls.length === 1 && videoUrls[0].transcodetype === "hls" ? videoUrls[0].path : "";

          if (cache) {
            // Cache video data.
            dispatch(fetchMediaSuccess(videoData));
          }

          resolve(videoData.videoUrls);
  
          // Set subtitles if there are such.
          // const subRes = HFN.findSubForVideo(meta.parentfolderid, meta.name, opts.code || false);
          // if (subRes.length) {
          //   videoData.subUrl = `/bin/${opts.code ? "getpubtextfile" : "gettextfile"}?fileid=${subRes[0].fileid}${
          //     opts.code
          //       ? `&code=${opts.code}${opts.linkpassword ? `&linkpassword=${opts.linkpassword}` : ""}`
          //       : `&auth=${HFN.config.auth}`
          //   }`;
          // }

          return;
        },
        {
          ...videoApiMethodOpts,
          errorCallback(res) {
            onError(videoData.id, res);
            reject({videoData, res});
          },
          onXhrError: (xhr, status, error) => {
            onError(videoData.id, { reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
            reject({ videoData, reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
          }
        }
      );
    });
  }

  const getFetchVideoThumbPromise = (videoData, meta, forceFresh) => {
    const thumbParams = { fileid: meta.fileid };
    let thumbMethod = "getthumblink";

    // Prevent showing notification messages from ERROR_MESSAGE_KEYS (web-utilities/src/api/errors.js),
    // because we have an error view.
    const thumbApiMethodOpts = { showErrorMessage: false };

    if (forceFresh) {
      thumbApiMethodOpts.forceFresh = true;
    } else {
      thumbApiMethodOpts.forceCache = true;
    }

    if (opts.code && location.host != "pcloud") {
      thumbMethod = "getpubthumblink";
      thumbParams.code = opts.code;

      if (opts.linkpassword) {
        thumbParams.linkpassword = opts.linkpassword;
      }

      if (token) {
        thumbParams.auth = token;
      }
    }

    if (meta.revisionid) {
      thumbParams.revisionid = meta.revisionid;
    }

    // Set thumb size 1:1 with the video size.
    const thumbWidth = getProperThumbnailSize(Math.min(videoData.maxWidth, Math.max(window.screen.availWidth, window.screen.availHeight)));
    const thumbHeight = getProperThumbnailSize(Math.min(videoData.maxHeight, Math.max(window.screen.availWidth, window.screen.availHeight)));
    thumbParams.size = `${thumbWidth}x${thumbHeight}`;

    return new Promise((resolve) => {
      const onVideoThumbError = () => {
        // Even without thumb video should be shown to the user.
        videoData.thumbUrl = "";
        videoData.forceFetch = {image: true};
  
        // Failed thumb should not break showing the video.
        resolve(videoData);
      };
  
      // Fetch thumbUrl data.
      apiMethod(
        thumbMethod,
        thumbParams,
        res => {
  
          // if (!mediaExistsInCache(meta.id)) {
          // Break the thumb....
          // res.path = "/xyz";
          //}
  
          // Set videoUrls.
          videoData.thumbUrl = HFN.prepUrl(res);
          videoData.thumbSize = thumbParams.size;
  
          // Force browser to download and cache the thumb.
          const img = new Image();
          img.src = videoData.thumbUrl;
          img.onload = () => {
            videoData.thumbSize = img.width > 0 && img.height > 0 ? `${img.width}x${img.height}` : videoData.thumbSize;
            
            // Break the thumb....
            // videoData.thumbUrl = videoData.thumbUrl.substring(0, 30);
            resolve(videoData);
          };
  
          img.onerror = onVideoThumbError;
        },
        {
          ...thumbApiMethodOpts,
          errorCallback(res) {
            onVideoThumbError();
          },
          onXhrError: (xhr, status, error) => {
            onVideoThumbError();
          }
        }
      );
    });
  };

  const preloadItemsBeforeAndAfter = n => {
    if (!(n in contentDataGalleryIds)) {
      // N is not part of contentDataGalleryIds.
      return;
    }

    const idsToPreload = {};

    // Preload numItemsAfterToPreload after open img
    const numItemsAfterToPreload = 2;
    const startAfterIndex = n + 1;
    for (let i = startAfterIndex; i < startAfterIndex + numItemsAfterToPreload; i++) {
      if (i > contentDataGalleryIds.length - 1) {
        // After last -> get from beginning.
        const newIndex = i - (contentDataGalleryIds.length - 1) - 1;
        if (newIndex < n) {
          if (newIndex in contentDataGalleryIds) {
            idsToPreload[contentDataGalleryIds[newIndex].id] = newIndex;
          }
        } else {
          // We have reached the initial n item.
          break;
        }
      } else if (i in contentDataGalleryIds) {
        idsToPreload[contentDataGalleryIds[i].id] = i;
      }
    }

    // Preload numItemsAfterToPreload after open img
    const numItemsBeforeToPreload = 2;
    const startBeforeIndex = n - 1;
    for (let i = startBeforeIndex; i > startBeforeIndex - numItemsBeforeToPreload; i--) {
      if (i < 0) {
        // Before first -> get from last.
        const newIndex = contentDataGalleryIds.length + i;
        if (newIndex > n) {
          if (newIndex in contentDataGalleryIds) {
            idsToPreload[contentDataGalleryIds[newIndex].id] = newIndex;
          }
        } else {
          // We have reached the initial n item.
          break;
        }
      } else if (i in contentDataGalleryIds) {
        idsToPreload[contentDataGalleryIds[i].id] = i;
      }
    }

    for (const fileId in idsToPreload) {
      const meta = initialContentData?.current[fileId];
      if (meta) {
        fetchMedia(meta, () => {}, () => {}, false, true);
      }
    }
  };

  const fetchMedia = async (meta, onFetched = () => {}, onError = () => {}, autoPlay = false, prefetch = false) => {
    // Check if it's already fetched.
    const mediaDataCache = fetchMediaFromCache(meta.id);
    if (mediaDataCache) {
      // Has thumb in cache, but no videoUrls. && we are on auto play or coming after auto-play failed.
      if (!prefetch && mediaDataCache.category === HFN.CATEGORY.VIDEO && ((autoPlay || (slideshowActive && !slideshowPaused) || videoAutoPlayFailed.current) && mediaDataCache.videoUrls.length === 0)) {
        // Has thumb in cache, but no videoUrls.
        try {
          // If videoAutoPlayFailed.current === true -> we should set transcodeAhead = false, because we'll prepare video URLs before play button is clicked.
          await fetchVideoUrls(mediaDataCache, meta, false, onError, true, !videoAutoPlayFailed.current);
          // We are here after mediaDataCache.videoUrls is set.
          // On success
          onFetched({...mediaDataCache});
        } catch (err) {
          // onError callback was invoked.
        }
      } else {
        onFetched({...mediaDataCache});
      }
      return;
    }

    const forceFetch = {image: false, video: false};
    
    switch (meta.category) {
      case HFN.CATEGORY.IMAGE:
        forceFetch.image = forceFetch.image || (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id]["image"]?.counter > 0);
        fetchImg(meta, onFetched, onError, {forceFresh: forceFetch.image});
        break;
      case HFN.CATEGORY.VIDEO:
        forceFetch.video = forceFetch.video || (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id]["video"]?.counter > 0);
        forceFetch.image = forceFetch.image || (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id]["videoThumb"]?.counter > 0);
        fetchVideo(meta, onFetched, onError, {videoForceFresh: forceFetch.video, thumbForceFresh: forceFetch.image}, autoPlay, prefetch);
        break;
    }
  };

  return {
    media,
    loading,
    contentDataGalleryIds,
    shownItemData,
    showItem,
    getNext,
    getPrevious,
    videoLoading,
    setVideoLoading,
    onShownMediaError,
    onShownMediaSuccess,
    showErrorMediaPreview,
    fetchShownVideoUrls,
    onAutoPlayFailed,
    videoIsPlaying
  };
};

export default useGalleryData;
