import React, { useState, useRef } from "react";
import useAutocomplete from "@mui/material/useAutocomplete";
import { InputWrapperWithChip, Wrapper } from "./styles";
import Chip from "./parts/Chip";
import { Tooltip } from "react-tooltip";
import List from "./parts/List";

const MultiSelectSuggester = ({
  componentRef = {},
  validationRules = [],
  defaultSelected = [],
  list = [],
  placeholder = "",
  borderRadius = 22,
  defaultIcon = null,
  searchInSubLabel = false,
  onValueChange = () => {}
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const handleChange = (event, newValue) => {
    const updatedValues = newValue.map((option) => {
      if (typeof option === "string") {
        return {
          id: Date.now().toString(),
          label: option
        };
      }
      return option;
    });
    onValueChange(event, updatedValues);
    setSelectedValue(updatedValues);
  };

  const handleKeyDown = (event) => {
    if ((event.key === "Tab" || event.key === ",") && event.target.value.trim()) {
      event.preventDefault();
      const newOption = {
        id: Date.now().toString(),
        label: event.target.value.trim()
      };
      handleChange(event, [...selectedValue, newOption]);
      setInputValue("");
      componentRef.current.inputValue = "";
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      const listboxNode = document.getElementById('multi-select-suggester-listbox');
      if (listboxNode) {
        const options = listboxNode.querySelectorAll('div[role="option"]');
        const currentIndex = Array.from(options).findIndex(option => option.classList.contains('Mui-focused'));
        const nextIndex = event.key === 'ArrowDown' ?
          (currentIndex + 1) % options.length :
          (currentIndex - 1 + options.length) % options.length;
        options[currentIndex]?.classList.remove('Mui-focused');
        options[nextIndex]?.classList.add('Mui-focused');
      }
    }
  };

  const filterOptions = (options, { inputValue }) => {
    const lowerCaseInput = inputValue.toLowerCase();
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(lowerCaseInput) ||
        (
          searchInSubLabel &&
          option.subLabel.toLowerCase().includes(lowerCaseInput)
        )
    );
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getClearProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl
  } = useAutocomplete({
    id: "multi-select-suggester",
    onChange: handleChange,
    filterOptions: filterOptions,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
      componentRef.current.inputValue = newInputValue;
    },
    multiple: true,
    freeSolo: true,
    inputValue: inputValue,
    value: selectedValue,
    defaultValue: defaultSelected,
    options: list,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionLabel: (option) => option.label
  });

  if (componentRef) {
    componentRef.current.resetSelections = () => {
      const { onClick } = getClearProps();
      onClick();
    };
    componentRef.current.removeTag = (event, index) => {
      const { onDelete } = getTagProps({ index });
      onDelete(event);
    };
  }

  return (
    <Wrapper>
      <div {...getRootProps()}>
        <InputWrapperWithChip $borderRadius={borderRadius} ref={setAnchorEl} className={focused ? "focused" : ""}>
          {selectedValue.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                {...tagProps}
                validationRules={validationRules}
                defaultIcon={defaultIcon}
                option={option}
              />
            );
          })}
          <input onKeyDown={handleKeyDown} autoFocus placeholder={placeholder} {...getInputProps()} />
        </InputWrapperWithChip>
      </div>
      {groupedOptions.length > 0 && (
        <List groupedOptions={groupedOptions} getOptionProps={getOptionProps} getListboxProps={getListboxProps} />
      )}
      <Tooltip id="multi-select-suggester-tooltip" className="cv-tooltip visible-on-mobile" noArrow={true} />
    </Wrapper>
  );
};

export default MultiSelectSuggester;
