export const permissionsKeys = {
  INHERIT: 0,
  DISABLED: 1,
  ONLY_WITHIN_TEAMS: 2,
  ENABLED: 3,
}

export const permissionsValuesTextMap = {
  [permissionsKeys.INHERIT]: 'setting_option_0',
  [permissionsKeys.DISABLED]: 'setting_option_1',
  [permissionsKeys.ONLY_WITHIN_TEAMS]: 'setting_option_2',
  [permissionsKeys.ENABLED]: 'setting_option_3',
};

export const defaultAccessPermissionsTitles = {
  SHARE_OUT_WORLD: 'share_out_world',
  SHARE_OUT_COMPANY: 'share_out_company',
  SHARE_IN_WORLD: 'share_in_world',
  TRASH_ACCESS: 'trash_access',
  VIEW_OTHER: 'view_other',
  LOG_VIEW: 'log_view',
}

export const defaultShareSettingVals = [permissionsKeys.DISABLED, permissionsKeys.ENABLED];

export const defaultShareSettingInheritVals = [permissionsKeys.INHERIT, permissionsKeys.DISABLED, permissionsKeys.ENABLED];

export const companyShareSettingVals = [permissionsKeys.DISABLED, permissionsKeys.ONLY_WITHIN_TEAMS, permissionsKeys.ENABLED];

export const companyShareSettingInheritVals = [permissionsKeys.INHERIT, permissionsKeys.DISABLED, permissionsKeys.ONLY_WITHIN_TEAMS, permissionsKeys.ENABLED];

export const defaultAccessPermissions = [
  {
    title: defaultAccessPermissionsTitles.SHARE_OUT_WORLD,
    description: 'share_out_world_explain',
  },
  {
    title: defaultAccessPermissionsTitles.SHARE_OUT_COMPANY,
    description: 'share_out_company_explain',
  },
  {
    title: defaultAccessPermissionsTitles.SHARE_IN_WORLD,
    description: 'share_in_world_explain',
  },
  {
    title: defaultAccessPermissionsTitles.TRASH_ACCESS,
    description: 'trash_access_explain',
  },
  {
    title: defaultAccessPermissionsTitles.VIEW_OTHER,
    description: 'view_other_explain',
  },
  {
    title: defaultAccessPermissionsTitles.LOG_VIEW,
    description: 'log_view_explain',
  },
];

export const defaultAdminSettings = [
  {
    title: "team_manage",
    description: "team_manage_explain",
  },
  {
    title: "user_manage",
    description: "user_manage_explain",
  },
  {
    title: "account_manage",
    description: "account_manage_explain",
  },
  {
    title: "billing_manage",
    description: "billing_manage_explain",
  },
]