// @flow

import React, { useState, useEffect } from "react";

import Modal from "../../Modal";
import CopyLinkModal from "../../../containers/FamilyPlan/CopyLinkModal";
import { ModalClose } from "../ModalsStyled";

const CopyInviteLinkModal = ({ link = "", resetModalAction = () => {} }: { link: string, resetModalAction: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen} onAnimationCloseComplete={resetModalAction}>
      <ModalClose onClick={handleClose} />
      <CopyLinkModal title={"shared_links_settings_copy_link"} currentItem={{ invitelink: link }} onClose={handleClose} />
    </Modal>
  );
};

export default CopyInviteLinkModal;
