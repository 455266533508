// @flow
import React, { useEffect, useState } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { hiDPI } from "polished";
import Componentify from "react-componentify";
import {
  boldConverter,
  linkConverter,
  linkConverterV2,
  linkConverterSelf,
  spanClassTagConverter
} from "../lib/componentifyConverters";
import { FlexRow } from "../styles/sharedStyles";

type Props = {
  children?: any,
  theme: "error" | "warning",
  centerText?: boolean,
  hasIcon?: boolean,
  hasCloseButton?: boolean,
  directionColumn?: boolean,
  componentifyText?: ?string,
  onClose?: () => void,
  onMountAlert?: () => void
};

const themes = {
  error: {
    color: "#000",
    border: "solid 1px #ff9999",
    bg: "#fff4f4",
    linkColor: "#17bed0"
  },
  red: {
    color: "#000",
    border: "solid 0px #fff",
    bg: "#ff8484",
    linkColor: "#fff"
  },
  warning: {
    color: "#8C959D",
    border: "solid 1px #FFF4D5",
    bg: "#FFFBF1",
    linkColor: "#17bed0"
  },
  info: {
    color: "#8C959D",
    border: "solid 1px #C9ECF1",
    bg: "#EDF9FA",
    linkColor: "#8C959D"
  }
};

const Alert = ({
  children,
  theme,
  centerText,
  componentifyText,
  hasIcon,
  hasCloseButton,
  directionColumn,
  onClose,
  onMountAlert = () => {},
  topAlert = false,
  ...rest
}: Props) => {
  const [hideComponent, setHideComponet] = useState(false);

  if (!themes[theme]) {
    throw Error("Unknown theme: ", theme);
  }

  useEffect(() => {
    onMountAlert();
  }, []);

  const onCloseClick = () => {
    setHideComponet(true);
    if (typeof onClose === "function"){
      onClose();
    }
  };

  const Icon = theme === "error" ? AlertIcon : AttIcon;
  return !hideComponent ? (
    <ThemeProvider theme={themes[theme]}>
      <Wrap centerText={centerText} {...rest} directionColumn={directionColumn} $topAlert={topAlert}>
        {hasIcon ? <Icon directionColumn={directionColumn} /> : <EmptyDiv />}
        {componentifyText ? (
          topAlert ? (
            <TopAlertContent>
              <Componentify
                text={componentifyText}
                converters={[boldConverter, linkConverterV2, linkConverterSelf, spanClassTagConverter]}
              />
            </TopAlertContent>
          ) : (
            <ComponentifyWrapper>
              <Componentify
                text={componentifyText}
                converters={[boldConverter, linkConverterV2, linkConverterSelf, spanClassTagConverter]}
              />
            </ComponentifyWrapper>
          )
        ) : null}
        {children}
        {hasCloseButton ? <CloseButton onClick={onCloseClick} /> : <EmptyDiv />}
      </Wrap>
    </ThemeProvider>
  ) : null;
};

export default Alert;

const multiline = props => {
  if (props.multiline) {
    return {
      padding: "16px 16px",
      "line-height": "16px"
    };
  } else {
    return {
      "line-height": "42px"
    };
  }
};

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => (props.directionColumn ? "column" : "row")};
  font-size: 16px;
  ${props => multiline(props)};
  text-align: ${props => (props.centerText ? "center" : "left")};
  color: ${props => props.theme.color};
  border: ${props => props.theme.border};
  background-color: ${props => props.theme.bg};
  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
  }

  ${({ $topAlert }) =>
      $topAlert &&
        css`
            box-sizing: border-box;
            height: 56px;
            @media (max-width: 800px) {
              height: auto;
            }
        `}
`;

const ComponentifyWrapper = styled.div`
  text-align: center;
  padding: 0 20px;
`;

export const AlertIcon = styled.div`
  display: inline-block;
  margin-bottom: ${props => (props.directionColumn ? "10px" : "0")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  min-width: 18px;
  min-height: 18px;
  background-image: url(${require("../../root/img/header/unverified.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/unverified@2x.png")});
  }
`;
export const AttIcon = styled.div`
  display: inline-block;
  margin-bottom: ${props => (props.directionColumn ? "10px" : "0")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 20px;
  min-width: 24px;
  min-height: 20px;
  background-image: url(${require("../../root/img/att.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/att@2x.png")});
  }
`;

export const CenterIcon = styled.div`
  div {
    position: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

export const CloseButton = styled.div`
  display: inline-block;
  min-width: 13px;
  min-height: 13px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-image: url(${require("../../root/img/share-icons/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/share-icons/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const AlertContent = styled(FlexRow)`
  gap: 40px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: var(--spacing-md);
    align-items: start;
    text-align: left;
    width: 100%;
  }
`;

export const TopAlertContent = styled(FlexRow)`
  gap: 40px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: var(--spacing-xs);
    align-items: start;
    text-align: left;
    width: 100%;
  }
`;

const EmptyDiv = styled.div`
  visibility: hidden;
`;
