// @flow

import React, { useState, useEffect } from "react";

import { Button } from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";
import InputStyledCheckbox from "../../InputStyledCheckbox";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import hashManager from "../../../lib/hashManager";
import { useDispatch } from "react-redux";
import { loadTeams } from "../../../lib/state/reducers/businessSlice";
import ModalTemplate from "../ModalTemplate";

const CreateNewTeamModal = ({ folderid = 0, resetModalAction = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOpenAfter, setShouldOpenAfter] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
    setTeamName("");
    resetModalAction();
  };

  const onTeamNameChange = ({ target }) => {
    setTeamName(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onCreateButtonClick();
    }
  };

  const fetchData = () => {
    apiMethod(
      "account_teams",
      {
        showeveryone: 1,
        showlinks: 1,
        showusers: 1,
        showeveryone: 1,
        withavatars: 1,
        teamsettings: 1
      },
      (ret) => {
        dispatch(loadTeams(ret.teams))
      },
      { forceFresh: true }
    );
  };

  const onCreateButtonClick = () => {
    if(isLoading) {
      return;
    }
    setIsLoading(true);

    if (teamName.trim().length < 1) {
      HFN.message(__("error_team_name_required", "Team name is required."), "error");
      setIsLoading(false);
      return;
    }

    apiMethod('account_teamcreate', { name: teamName }, function (ret) {
      fetchData();
      HFN.message(__('Team Created'));
      handleClose();

      if (shouldOpenAfter) {
        hashManager.pushState({ page: 'b_team', id: ret.team.id });
      }

      if (PCB.hltips) {
        PCB.hltips.callback(ret);
      }
    }, {
      errorCallback: ({ error, result, field }) => {
        setIsLoading(false);
        let errorMessage = __("something_went_wrong_refresh_and_try_again");

        if (result === 2103) {
          if (field === "name") {
            errorMessage = __("business_edit_user_team_name_error");
          }
        } else if (result === 2082) {
          errorMessage = __("business_edit_user_team_name_exists_error");
        } else if (result === 2076) {
          errorMessage = __("error_2076", "You do not have the permissions for this action.");
        }

        HFN.message(errorMessage, "error");
        throw new Error(error);
      }
    });
  };

  return (
    <ModalTemplate
      animate
      onAnimationCloseComplete={resetModalAction}
      onClose={handleClose}
      opened={isOpen}
      title={__("create_team", "Create Team")}
      buttonsComponent={
        <Button
          styled="ModalPrimaryButton"
          onClick={onCreateButtonClick}
        >
          {__("Create")}
        </Button>
      }
    >
      <Style.Label>{__("Name", "Name")}</Style.Label>
      <InputText
        name="teamName"
        onChange={onTeamNameChange}
        value={teamName}
        autoFocus
        onKeyDown={onEnter}
      />
      <Style.CheckboxWrapper>
        <Style.CheckboxLabel for="open">
          <InputStyledCheckbox
            id="open"
            name="openLocationAfter"
            size="small"
            checked={shouldOpenAfter}
            onChange={e => setShouldOpenAfter(e.target.checked)}
          />
          <Style.CheckboxLabelText>{__("Open after creation")}</Style.CheckboxLabelText>
        </Style.CheckboxLabel>
      </Style.CheckboxWrapper>
    </ModalTemplate>
  );
};

export default CreateNewTeamModal;
