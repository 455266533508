import React from "react";
import { getViewType } from "../../../../lib";
import SkeletonList from "./SkeletonList";
import SkeletonGrid from "./SkeletonGrid";

const SkeletonTable = ({ contentType }) => {
  const viewType = getViewType(contentType);
  const viewTypeLoader = viewType.toLowerCase().includes("list") ? "list" : "grid";

  return viewTypeLoader === "list" ? (
    <SkeletonList viewType={viewType} contentType={contentType} />
  ) : (
    <SkeletonGrid viewType={viewType} />
  );
};

export default SkeletonTable;
