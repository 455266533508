import * as React from "react";
import { __ } from "../../lib/translate";
import { Heading, SubTitle, Title, TopIcon } from "../../styles/sharedStyles";

const PublicFolderTop = () => (
  <>
    <TopIcon>
      <i className="fa-regular fa-folder-open" />
    </TopIcon>
    <Heading>
      <Title>{__("public_folder", "Public Folder")}</Title>
      <SubTitle>
        {__(
          "public_folder_explain",
          "Use your pCloud storage as a hosting service to create static HTML websites, embed images or simply create direct\n" +
            "      links to your files. All files you place in the Public Folder will be accessible as a index directory."
        )}
      </SubTitle>
    </Heading>
  </>
);

export default PublicFolderTop;