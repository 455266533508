import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

export const InputWrapper = styled.div`
  width: 100%;

  position: relative;

  input.input-styled {
    padding-left: 36px;
  }

  &:after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    font-size: 16px;
    content: "\\f002";
    color: var(--color-icon-menu);
    position: absolute;
    left: 12px;
    top: 12px;
    pointer-events: none;
  }
`;

export const InputWrapperWithChip = styled.div`
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  padding: var(--spacing-xs) var(--spacing-sm);
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  border: 1px solid var(--border-primary);
  background-color: var(--surface-secondary);
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.focused {
    border: 1px solid var(--color-primary-500);
  }

  & input {
    background-color: var(--surface-secondary);
    color: var(--text-primary);
    height: 30px;
    box-sizing: border-box;
    padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-sm);
    width: 0;
    flex-grow: 1;
    border: 0;
    outline: 0;
    min-width: 150px;

    &::-webkit-input-placeholder {
      color: var(--text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &::-moz-placeholder {
      color: var(--text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: var(--text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &::placeholder {
      color: var(--text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 24px;
  background-color: inherit;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding: 6px;
  border-radius: 60px;
  border: 1px solid var(--divider-secondary);
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) 0;

  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid var(--color-functional-danger200);
      background: var(--color-functional-danger100);

      & ${ChipLabel} {
        color: var(--text-danger);
      }

      & ${ChipCloseIcon} {
        color: var(--text-danger);
      }
    `}
`;

export const ChipLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: var(--text-secondary);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  width: 100%;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ChipCloseIcon = styled.i`
  cursor: pointer;
  color: var(--text-secondary);
  font-size: 14px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckIcon = styled.i`
  display: none;
  font-size: 14px;
  color: var(--text-link);
`;

export const ListBox = styled.div`
  position: absolute;
  transform: translateY(5px);
  overflow: auto;
  max-height: 250px;
  z-index: 10;

  list-style: none;
  display: flex;
  width: 100%;
  padding: 8px 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-none);
  border-radius: var(--radius-minimum);
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  background: var(--color-base-white);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
`;

export const ListBoxItem = styled.div`
  cursor: pointer;
  padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) var(--spacing-md);
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-sm);

  &:hover, &.Mui-focused {
    background-color: var(--surface-hover);
  }

  &[aria-selected="true"] ${CheckIcon} {
    display: block;
  }
`;

export const ListBoxItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;

  & div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ListBoxLabel = styled.div`
  width: 100%;
  color: var(--text-primary);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ListBoxSubLabel = styled.div`
  width: 100%;
  color: var(--text-secondary);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--color-neutral-500);
  color: var(--color-base-white);
  overflow: hidden;

  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ChipIcon = styled(Icon)`
  background: var(--color-primary-700);
  width: 24px;
  height: 24px;
  font-size: 12px;
`;
