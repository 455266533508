// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import { divClassTagConverter } from "../../lib/componentifyConverters";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import errors from "../../lib/errors";
import { TFA_TYPE } from "@pcloud/web-utilities/dist/config/constants";
import { setTwoFactorActiveType, setVerifiedPhoneNumber } from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";

import { Btn } from "../../components/ButtonDefault";
import { Footer } from "../../components/Modals/styledComponents";
import CountDown from "../../components/CountDown";
import {
  Container,
  Header,
  Message,
  InputCode,
  ErrorMessage,
  BackButton
} from "./SharedComponents";
import BackIconModal from "../../../root/img/2fa-login/back-icon-modal.svg";

const VERIFICATION_CODE_LENGTH = 6;

type Props = {
  token: string,
  password: string,
  msisdn: string,
  countryCode: string,
  setTwoFactorActiveType: boolean => void,
  setVerifiedPhoneNumber: () => void,
  onSuccess: () => void,
  onBack: () => void
};

type State = {
  verificationCode: string,
  showErrorMessage: boolean,
  errorMessage: string,
  isValidCode: boolean,
  isLoading: boolean,
  shouldResendCode: boolean
};

class VerifyPhoneNumberStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    password: "",
    msisdn: "",
    countryCode: "",
    setTwoFactorActiveType: () => {},
    setVerifiedPhoneNumber: () => {},
    onSuccess: () => {},
    onBack: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onSubmit = this.onSubmit.bind(this);
    (this: any).onCodeChange = this.onCodeChange.bind(this);
    (this: any).onEnter = this.onEnter.bind(this);
    (this: any).resendSMS = this.resendSMS.bind(this);

    this.state = {
      verificationCode: "",
      showErrorMessage: false,
      errorMessage: "",
      isValidCode: false,
      isLoading: false,
      shouldResendCode: true
    };
  }

  validateCode(code: string) {
    return code.length === VERIFICATION_CODE_LENGTH;
  }

  onCodeChange(e: any) {
    let code = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);

    this.setState({
      verificationCode: code,
      showErrorMessage: false,
      isValidCode: this.validateCode(code)
    });
  }

  onSubmit() {
    const { verificationCode, isLoading, showErrorMessage, isValidCode } = this.state;
    const { token, onSuccess, setTwoFactorActiveType, setVerifiedPhoneNumber } = this.props;

    if (isLoading) {
      return;
    }

    if (!verificationCode.length || !isValidCode) {
      return;
    }

    if (!isValidCode) {
      this.setState({
        errorMessage: __("Wrong code"),
        showErrorMessage: true
      });

      return;
    }

    this.setState({ isLoading: true });

    apiMethod(
      "tfa_verifyactivationcode",
      {
        auth: token,
        code: verificationCode
      },
      res => {
        this.setState({ isLoading: false });
        setTwoFactorActiveType(TFA_TYPE.MSISDN_TYPE);
        setVerifiedPhoneNumber();
        onSuccess();
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";

          if (errors[result]) {
            errorMessage = errors[result];
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }

          this.setState({
            errorMessage: errorMessage,
            showErrorMessage: true,
            isLoading: false
          });

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  }

  onEnter(e: any) {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  resendSMS() {
    const { token, countryCode, msisdn, password } = this.props;
    const { shouldResendCode } = this.state;

    if (!shouldResendCode) {
      return;
    }

    apiMethod(
      "tfa_sendactivationcode",
      {
        auth: token,
        password,
        msisdn,
        countrycode: countryCode
      },
      res => {
        this.setState({
          shouldResendCode: false
        });
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";

          if (errors[result]) {
            errorMessage = error;
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }

          this.setState({
            errorMessage,
            showErrorMessage: true,
            shouldResendCode: false
          });

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  }

  render() {
    const { onBack, msisdn } = this.props;
    const {
      verificationCode,
      showErrorMessage,
      errorMessage,
      isValidCode,
      isLoading,
      shouldResendCode
    } = this.state;
    const phoneNumber = msisdn ? `+${msisdn}` : "";

    return (
      <Container>
        <BackButton onClick={onBack}>
          <BackIconModal />
          <Title>{__("Back")}</Title>
        </BackButton>
        <Header>{__("tfa_setup_verification_code_header")}</Header>
        <p>
          <Componentify
            text={__("tfa_setup_verification_code_subheading", "", {
              phonenumberattr: `class='phone-number'`,
              phonenumber: phoneNumber
            })}
            converters={[divClassTagConverter]}
          />
        </p>
        <Message className="verification-code">
          <ErrorMessage show={showErrorMessage}>{errorMessage}</ErrorMessage>
          <InputCode
            className="verification-input"
            name="code"
            type="text"
            autoFocus
            placeholder={__("tfa_login_login_enter_code", "Enter code")}
            value={verificationCode}
            onChange={this.onCodeChange}
            onKeyUp={this.onEnter}
            shouldRenderRedBorder={showErrorMessage}
          />
        </Message>
        <Footer>
          <Btn
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            disabled={!shouldResendCode}
            onClick={this.resendSMS}
          >
            {__("tfa_setup_verification_code_resend_sms")}
          </Btn>
          <Btn
            color="cyan"
            style={{
              marginLeft: "5px"
            }}
            disabled={isLoading || !isValidCode}
            loading={isLoading}
            onClick={this.onSubmit}
          >
            {__("Next")}
          </Btn>
        </Footer>
        {!shouldResendCode ? (
          <Counter>
            <CountDown
              startFrom={60}
              onFinish={() => {
                this.setState({ shouldResendCode: true });
              }}
            />
          </Counter>
        ) : null}
      </Container>
    );
  }
}

export default connect(
  ({ pCloudUser }) => {
    const { token, userinfo: { password, countryCode, msisdn } = {} } = pCloudUser;

    return {
      token,
      password,
      countryCode,
      msisdn
    };
  },
  dispatch =>
    bindActionCreators(
      {
        setTwoFactorActiveType,
        setVerifiedPhoneNumber
      },
      dispatch
    )
)(VerifyPhoneNumberStep);

const Counter = styled.div`
  position: absolute;
  bottom: 42px;
  left: 40px;
  font-size: 12px;
  color: red;
`;

const Title = styled.span`
  position: relative;
  transform: translate(0, -25%);
  top: 0;
  left: 0;
  display: inline-block;
  line-height: 13px;
`;
