//@flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";
import { boldConverter, linkConverter, linkConverterSelf, linkConverterV2 } from "../lib/componentifyConverters";

import { __ } from "../lib/translate";
import { detectIsMobile } from "../lib/utils";

import apiMethod from "../api/apiMethod";
import apiConfig from "../api/config";

import EmailVerifyIcon from "../../root/img/svg/email-verify-icon.svg";
import EmailVerifyIconMobile from "../../root/img/svg/email-verify-icon-mobile.svg";
import BulbIcon from "../../root/img/svg/bulb.svg";

import { Btn } from "./ButtonDefault";
import * as Style from "../containers/SharedInvitationComponents";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import hashManager from "../lib/hashManager";

const VerifyEmailPage = ({ code = "" }: { code: string }) => {
  const [verificationInProgress, setVerificationInProgress] = useState(true);
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);

  const userLang = useSelector(({ pCloudUser }) => pCloudUser.userinfo.language);
  const isMobileDevice = detectIsMobile();

  const langToGettingStartedPdf = {
    en: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud.pdf",
    de: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_de.pdf",
    fr: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_fr.pdf",
    ja: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_ja.pdf",
    nl: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_NL.pdf"
  };
  const DEFAULT_GUIDE_LINK =
    "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud.pdf";

  const langToGettingStartedPdfBusiness = {
    en: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business.pdf",
    de: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business%20-%20DE.pdf",
    fr: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business%20-%20FR.pdf",
    ja: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business_ja.pdf",
    nl: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20for%20Bussines%20NL.pdf",
    es: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business%20ES.pdf"
  };
  const DEFAULT_BUSINESS_GUIDE_LINK =
    "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business.pdf";

  let gettingStartedLink = langToGettingStartedPdf[userLang] || DEFAULT_GUIDE_LINK;

  useEffect(() => {
    apiMethod(
      "verifyemail",
      { code: code },
      ret => {
        setVerifiedEmail(ret.email);
        setVerificationInProgress(false);

        if (ret.isbusiness) {
          setIsBusiness(true);
        }

        HFN.psettings.init();
        if (hashManager.getState('locationid')) {
          HFN.removeUrlParam('locationid');
        }
      },
      {
        errorCallback: ({ result, error }) => {
          if (result === 2013) {
            setVerificationInProgress(false);
            setIsAlreadyVerified(true);
          } else {
            setErrorMessage(error);
          }
        },
      }
    );
  }, []);

  const onGoToMyPcloudClick = () => {
    window.open("https://my.pcloud.com", "_self");
  };

  if (isBusiness) {
    gettingStartedLink = langToGettingStartedPdfBusiness[userLang] || DEFAULT_BUSINESS_GUIDE_LINK;
  }

  const renderAlreadyVerified = () => {
    return <>
      <Style.Title>{__("email_already_verified", "Email is already verified")}</Style.Title>
      <Style.ComponentifyWrapper>
        <Componentify text={__("email_already_verified_description", "Your email %email% has already been verified, you can now proceed to your account.", { email: verifiedEmail })}
          converters={[boldConverter, linkConverterSelf, linkConverterV2, linkConverter]} />
      </Style.ComponentifyWrapper>
      <Style.Link onClick={onGoToMyPcloudClick}>
        {__("verify_mail_my", "Go to my Account")}
      </Style.Link>
    </>
  }

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      {verificationInProgress ? (
        errorMessage ? (
          <Style.WarningMessage style={{ marginTop: "15px" }}>
            <Style.WarnIcon />
            <Style.ComponentifyWrapper>
              <Componentify text={errorMessage} converters={[boldConverter]} />
            </Style.ComponentifyWrapper>
          </Style.WarningMessage>
        ) : (
          <Style.Title style={{ fontWeight: "normal" }}>{__("Verifying in progress ...")}</Style.Title>
        )
      ) : isAlreadyVerified ?
        renderAlreadyVerified() : (
          <>
            <Style.Title>{__("verify_mail_title", "Account verified")}</Style.Title>
            <Style.FormLabel>
              <Componentify
                text={__("verify_mail_desc").replace("%verified_email%", verifiedEmail)}
                converters={[boldConverter]}
              />
            </Style.FormLabel>
            <Style.Message>
              <BulbIcon />
              <Style.ComponentifyWrapper>
                <Componentify text={__(
                  "verify_mail_getstarted",
                  "Click on the button below for some useful tips & tricks on how to use your pCloud account to the fullest."
                )} converters={[boldConverter]} />
              </Style.ComponentifyWrapper>
            </Style.Message>
            <StyledButton href={gettingStartedLink} target="_blank" inline>
              {__("verify_mail_getstarted_cta")}
            </StyledButton>
            <Style.Link onClick={onGoToMyPcloudClick}>
              {__("verify_mail_my", "Go to my Account")}
            </Style.Link>
          </>
        )}
    </Style.Container>
  );
};

export default VerifyEmailPage;

const StyledButton = styled(Btn)`
  width: 100%;
  font-size: 15px;
`