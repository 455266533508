import React, { useEffect, useState } from "react";
import StatsBarChart from "./StatsBarChart";
import { formatDt, toParseDt } from "../../lib";
import hashManager from "../../lib/hashManager";
import { useDispatch, useSelector } from "react-redux";
import {
  loadBreadcrumb,
  loadContent,
  setContentItems,
  setDefaultBranding
} from "../../lib/state/reducers/contentSlice";
import { __ } from "../../lib/translate";
import { Wrapper } from "./styles";
import ContentView from "../../components/ContentView/ContentView";
import { BROWSE_SHAREDLINKS_TRAFFIC } from "../../config/constants";
import SkeletonTable from "../../components/ContentView/Templates/SkeletonTable";
import apiMethod from "../../api/apiMethod";
import Credits from "../../components/ContentView/UI/Footer/Credits";
import { CreditsWrapper } from "../../styles/sharedStyles";

const LinkStats = () => {
  let itemKeys = useSelector(({ content }) => content.itemKeys);
  const [tableLoaded, setTableLoaded] = useState(false);
  const [trafficPeriod, setTrafficPeriod] = useState(null);
  const [traffic, setTraffic] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    apiMethod(
      "getdefaultpublinkbranding",
      {},
      function(data){
        delete data.result;
        dispatch(setDefaultBranding(data));
      }
    );
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", handleSelectedDateChange);
    handleSelectedDateChange();
    return () => {
      window.removeEventListener("hashchange", handleSelectedDateChange);
    };
  }, [trafficPeriod]);

  const handleSelectedDateChange = () => {
    const dtParam = hashManager.getState('dt');
    if (dtParam) {
      dispatch(
        loadBreadcrumb([
          {
            id: "0",
            name: __("Download link traffic statistics", "Shared link traffic statistics"),
            customRedirectToState: {
              page: "linkstats"
            },
          },
          {
            id: '1',
            name: formatDt(dtParam),
            disableOptions: true
          }
        ])
      );
    }

    fetchData();
  }

  const fetchData = () => {
    const dtParam = hashManager.getState('dt');
    if (!trafficPeriod && !dtParam) {
      return false;
    }
    const params = {
      bdate: dtParam || toParseDt(trafficPeriod.from),
      edate: dtParam || toParseDt(trafficPeriod.to),
      groupby: 'link',
      directlink: 1,
      iconformat: 'id'
    };

    apiMethod('getpublinkstats', params, function(ret) {
      const items = [];
      for (let item of ret.stats) {
        const link = item.link;
        const tmpItem = link;
        if (!link.metadata) {
          continue;
        }
        tmpItem.id = link.code;
        tmpItem.linkid = link.linkid;
        tmpItem.name = link.metadata.name;
        tmpItem.link = link.link;
        tmpItem.created = link.created;
        tmpItem.traffic = item.traffic;
        tmpItem.downloads = item.downloads;
        tmpItem.views = item.views;
        tmpItem.icon = link.metadata.icon;
        if (link.metadata.isfolder && link.metadata.folderid == 0) {
          tmpItem.icon = HFN.ICO.VIRTUALFOLDER;
        }
        tmpItem.hash = link.metadata.hash;
        tmpItem.thumb = link.metadata.thumb;
        tmpItem.fileid = link.metadata.fileid || '';
        tmpItem.issharedlink = true;
        tmpItem.istrafficpage = true;

        items.push(tmpItem);
      }
      dispatch(setContentItems({ contents: items }));
      setTableLoaded(true);
    })
  }

  // TODO initGetTraffic
  // TODO initGetTrafficBusiness
  return (
    <Wrapper>
      <StatsBarChart
        traffic={traffic}
        trafficPeriod={trafficPeriod}
        setTraffic={setTraffic}
        setTrafficPeriod={setTrafficPeriod}
      />
      <div>
        {tableLoaded ? (
          <ContentView contentType={BROWSE_SHAREDLINKS_TRAFFIC} />
        ) : (
          <SkeletonTable contentType={BROWSE_SHAREDLINKS_TRAFFIC} />
        )}
      </div>
      {itemKeys.length !== 0 && (
        <CreditsWrapper>
          <Credits />
        </CreditsWrapper>
      )}
    </Wrapper>
  );
};

export default LinkStats;