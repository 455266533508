import React, { useEffect, useState } from "react";
import { BROWSE_B_TEAM_USERS_CONTENT } from "../../../config/constants";
import ContentView from "../../../components/ContentView/ContentView";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setContentItems } from "../../../lib/state/reducers/contentSlice";
import SkeletonTable from "../../../components/ContentView/Templates/SkeletonTable";
import { xhrs as businessXHRS } from "../../../lib/state/businessState";

const Info = ({ team }) => {
  const [tableLoaded, setTableLoaded] = useState(false);
  const account = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all(businessXHRS).then(() => {
      const users = [];

      if (team?.members) {
        team.members.forEach((member) => {
          member.user.isBusinessTeamUser = true;
          member.user.head = !!member.link.head;
          member.user.userFirstname = member.user.firstname;
          member.user.userLastname = member.user.lastname;
          member.user.userEmail = member.user.email;
          member.user.userId = member.user.id;
          member.user.teamName = team.name;
          member.user.teamId = team.id;
          member.user.invited = member.link.invited;
          member.user.canViewLogs = account.permissions.log_view && !member.link.invited;
          member.user.canModify = team.id !== 0 && team.can_modify;
          member.user.canInvite = team.id !== 0 && team.can_invite;
          member.user.canUserManage = account.permissions.user_manage;
          users.push(member.user);
        });
      }

      dispatch(setContentItems({ contents: users } ));
      setTableLoaded(true);
    });
  }, [team, account]);

  return (
    <Wrapper>
      {tableLoaded ? (
        <ContentView contentType={BROWSE_B_TEAM_USERS_CONTENT} />
      ) : (
        <SkeletonTable contentType={BROWSE_B_TEAM_USERS_CONTENT} />
      )}
    </Wrapper>
  );
};

export default Info;

const Wrapper = styled.div`
    height: calc(100%);
    & #selection-container {
        box-sizing: border-box;
        padding: var(--spacing-sm) 0 0 var(--spacing-sm);
    }
`;
