// @flow

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { StyledTabs, StyledTab, TabsAndContentWrapper, TabsAndContentWrapperInner, TabContent} from "../../components/Tabs/tabs.style";
import useTabs from "../../components/Tabs/hooks/useTabs";
// import Tabs from "../../../web-shared/components/Tabs";
import Account from "./tabs/Account";
import Security from "./tabs/Security";
import Encryption from "./tabs/Encryption";
import * as SETTINGS from "./settingsConstants";
import { __ } from "../../lib/translate";
import Notifications from "./tabs/Notifications";
import PaymentHistory from "./tabs/PaymentHistory";
import LinkedAccounts from "./tabs/LinkedAccounts";

const SettingsContainer = (props) => {
  const businessAccount = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const tabs = useMemo(() => businessAccount ? [...SETTINGS.DEFAULT_TABS] : [...SETTINGS.DEFAULT_TABS, SETTINGS.PAYMENT_HISTORY_TAB], []);

  const {
    activeTab,
    onTabClick
  } = useTabs({
    tabs,
    defaultActiveTabId: SETTINGS.ACCOUNT_SETTINGS,
    activeTabHashParamName: "settings"
  });

  const renderTabContent = () => {
    if (activeTab === SETTINGS.ACCOUNT_SETTINGS) {
      return <Account {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.ACCOUNT_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.LINKED_ACCOUNTS_SETTINGS) {
      return <LinkedAccounts {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.LINKED_ACCOUNTS_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.SECURITY_SETTINGS) {
      return <Security {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.SECURITY_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.ENCRYPTION_SETTINGS) {
      return <Encryption {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.ENCRYPTION_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.NOTIFICATIONS_SETTINGS) {
      return <Notifications {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.NOTIFICATIONS_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.PAYMENT_HISTORY_SETTINGS) {
      return <PaymentHistory {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.PAYMENT_HISTORY_SETTINGS]} />;
    }
  };

  return (
    <TabsAndContentWrapper>
      <TabsAndContentWrapperInner className="inner-animation animation-type-fade">
        <StyledTabs
          value={activeTab}
          onChange={onTabClick}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        
        >
          {tabs.map(({ tabId, tabTitle }) => (
            <StyledTab label={__(tabTitle)} />
          ))}
        </StyledTabs>
        <TabContent>
          <div>{renderTabContent()}</div>
        </TabContent>
      </TabsAndContentWrapperInner>
    </TabsAndContentWrapper>
  );
};

export default SettingsContainer;