// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { Btn } from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";

const PreviewArchiveModal = ({ data = {} }: { data: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const downloadArchive = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    HFN.getFileLinkBack(
      data,
      url => {
        window.open(url);
        setIsLoading(false);
      },
      { async: false, forcedownload: 1 }
    );
    handleClose();
  };

  const extractArchive = () => {
    handleClose();
    HFN.initExtract(data);
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("choose_action", "Choose action")}</Style.Header>
          <Style.Label>{__("Archive Name")}</Style.Label>
          <BoxTeam>
            <Icon src={HFN.createIconSrc(HFN.ICO.ARCHIVE, HFN.ICONS.SVG_LIST)} alt="icon" />
            {data.name}
          </BoxTeam>
          <Style.Message></Style.Message>
          <Style.Footer>
            <Btn
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={downloadArchive}
            >
              {__("preview_archive_modal_download_file", "Doenload file")}
            </Btn>
            <Btn
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              onClick={extractArchive}
            >
              {__("Extract in pCloud")}
            </Btn>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default PreviewArchiveModal;

const BoxTeam = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
`;
