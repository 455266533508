// @flow

import store from "./store";
import { abortAll } from "../XhrComponentUtils";
import apiMethod from "../../api/apiMethod";
import { getAccountInfoCall, getBillingInfoCall } from "../../api/business";
import businessSubscriber from "../business/businessSubscriber";

import type { Userinfo, UserState } from "../../types/user";
import {
  loadAccountInfo,
  clearAccountInfo,
  loadBillingInfo,
  clearBillingInfo,
  loadUsers,
  loadTeams,
  clearUsers,
  clearTeams,
  loadInvitedUsers,
  clearInvitedUsers
} from "./reducers/businessSlice";

let currentToken = "";
let stopListen;
export let xhrs = [];
let bUsers;
let bInvitedUsers;
let bTeams;
let canInviteAccount = false;
let billingInfo = {};
let accountInfo = {};

export const getUsers = (onSuccess) => {
  return apiMethod(
    "account_users",
    { auth: currentToken, userpermissions: 1, withavatars: 1, tracksettings: 1, avatarsize: "128x128", usersettings: 1, showteams: 1, showeveryone: 1 },
    ret => {
      bUsers = ret.users;
      canInviteAccount = ret.can_invite_account;
      onSuccess ? onSuccess(ret) : undefined;
    },
    { forceFresh: true }
  );
};
const getInvitedUsers = () => {
  return apiMethod(
    "account_invitelist",
    { auth: currentToken },
    ret => {
      bInvitedUsers = ret.invites;
    },
    { forceFresh: true }
  );
};

const getTeams = () => {
  return apiMethod(
    "account_teams",
    { auth: currentToken, showeveryone: 1, showlinks: 1, showusers: 1, showeveryone: 1, withavatars: 1, teamsettings: 1 },
    ret => {
      bTeams = ret.teams;
    },
    { forceFresh: true }
  );
};

store.subscribe(() => {
  checkUser(currentUserState());
});

checkUser(currentUserState());

function checkUser(userState: UserState) {
  if (userState.token != currentToken) {
    if (currentToken) {
      console.log("stop listen");
      currentToken = "";
      userLogout();
      stopListen();
    }

    if (userState.logged && userState.userinfo.business) {
      console.log("go Listen");
      console.log("userState.logged", userState.logged);
      console.log("currentToken", currentToken);
      console.log("userState.token", userState.token);

      currentToken = userState.token;
      userLogin();
      listen();
    }
  }
}

function userLogin() {
  xhrs.push(getUsers());
  xhrs.push(getInvitedUsers());
  xhrs.push(getTeams());
  xhrs.push(getBillingInfoCall(ret => {
    billingInfo = ret;
  }));
  xhrs.push(getAccountInfoCall(ret => {
    accountInfo = ret;
  }));

  Promise.all(xhrs).then(() => {
    delete billingInfo.result;
    delete accountInfo.result;
    accountInfo.can_invite_account = canInviteAccount;
    console.log("++++++++++++++++++++");
    console.log("bUsers", bUsers);
    console.log("bInvitedUsers", bInvitedUsers);
    console.log("bTeams", bTeams);
    console.log("billingInfo", billingInfo);
    console.log("accountInfo", accountInfo);

    store.dispatch(loadUsers(bUsers));
    store.dispatch(loadInvitedUsers(bInvitedUsers));
    store.dispatch(loadTeams(bTeams));
    store.dispatch(loadBillingInfo(billingInfo));
    store.dispatch(loadAccountInfo(accountInfo));

    xhrs = [];
    bUsers = [];
    bInvitedUsers = [];
    bTeams = [];
    billingInfo = {};
    accountInfo = {};
  });
}

function userLogout() {
  abortAll(xhrs);
  store.dispatch(clearUsers());
  store.dispatch(clearInvitedUsers());
  store.dispatch(clearTeams());
  store.dispatch(clearBillingInfo());
  store.dispatch(clearAccountInfo());
}

function currentUserinfo(): Userinfo | false {
  const user = currentUserState();
  return user.logged ? user.userinfo : false;
}

function currentUserState(): UserState {
  return store.getState().pCloudUser;
}

function listen() {
  stopListen = businessSubscriber.listen(currentToken, event => {
    console.log("EVENTS>>>>>>>>>>>>>>>", event);
    if (event === "users") {
      xhrs.push(getUsers());
      xhrs.push(getInvitedUsers());
    } else if (event === "teams") {
      xhrs.push(getTeams());
    }

    if (xhrs.length !== 0) {
      Promise.all(xhrs).then(() => {
        if (bUsers.length !== 0) {
          store.dispatch(loadUsers(bUsers));
        }
        if (event === "users" && Array.isArray(bInvitedUsers)) {
          store.dispatch(loadInvitedUsers(bInvitedUsers));
        }
        if (bTeams.length !== 0) {
          store.dispatch(loadTeams(bTeams));
        }

        xhrs = [];
        bUsers = [];
        bInvitedUsers = [];
        bTeams = [];
      });
    }
  });
}
