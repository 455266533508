import styled from "styled-components";
import { MODAL_MOBILE_VIEW } from "../../../components/Modals/ModalsStyled";

export const Description = styled.div`
  margin-top: var(--spacing-md);
  font-size: var(--font-size-14);
  font-weight: normal;
  text-align: center;
  width: 100%;
`;

export const SuccDescription = styled(Description)`
  margin-bottom: var(--spacing-lg);
`;

export const DescriptionModal = styled(Description)`
  margin: var(--spacing-xl) 0;
`;

export const MinUsersNote = styled.div`
  visibility: ${props => props.show ? "visible" : "hidden"};
  color: var(--text-secondary);
  font-size: 12px;
  min-height: 12px;
`;

export const UsersInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xl);
  width: 100%;

  @media (max-width: ${MODAL_MOBILE_VIEW}) {
    gap: var(--spacing-md);
  }
`;

export const UserInputsAndRadioBtnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;

  @media (max-width: ${MODAL_MOBILE_VIEW}) {
    gap: var(--spacing-sm);
  }
`;

export const BtnAndDisclaimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  align-items: center;
  width: 100%;
`;

export const PriceText = styled.div`
  font-size: var(--font-size-16);
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: var(--text-primary);
  span {
    font-weight: 400;
  }
`;

export const Disclaimer = styled.div`
  color: var(--text-secondary);
  text-align: center;
  font-size: var(--font-size-13);
  font-weight: 400;

  a {
    text-decoration: none;
    color: var(--text-link);
    font-weight: 700;
  }
`;

export const SubscriptionTypeLabel = styled.div`
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-secondary);
`;

export const SuccesssIconWrapper = styled.div`
  margin-bottom: var(--spacing-md);
`;