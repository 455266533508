
// @flow

import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { DEFAULT_MENU_ITEMS, PROFILE_MENU_ITEMS, BOTTOM_ITEMS, STORAGE_FULL_RATIO, STORAGE_ALMOST_FULL_RATIO, CRYPTO_LOCKED, LOCAL_STORAGE_TOGGLED } from "./constants";
import { __ } from "../../lib/translate"
import { usePrevious } from "../../lib/hooks";
import { bottomOptions, defaultMenuOptions, profileMenuOptions, moreAppsOptions } from "./leftMenuOptions";
import { userInitials } from "../../lib/user-utils";
import { useDispatch } from "react-redux";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import UserPlanInfo from './UserPlanInfo';
import { getLanguages } from "../../lib/pCloudIntl";
import useOutsideClicker from "@pcloud/web-utilities/dist/hooks/useOutsideClicker";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ExploreCards from "../ExploreCards/ExploreCards";
import { PCLOUD_LINKS } from "@pcloud/web-utilities/dist/config/constants";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import CustomerJourney from "./CustomerJourney";
import Popover from "../Popover";
import { MOBILE_WIDTH_BREAKPOINT } from '../../config/constants';
import {
  appendLeftMenuOverlay,
  createTemporaryActiveLine,
  filteredMenuOptions,
  isCryptoPage,
  removeBackgroundOverlay,
  removeHtmlElementScroll,
  resetHtmlElementScroll,
  isIpad,
} from "./helpers";
import UserFeedbackModal from "../Modals/UserFeedbackModal";
import { ONBOARDING_ID_ACCOUNT_SETTINGS, ONBOARDING_ID_UNLOCK_SPACE } from "../HighlightOnboardingComponent/constants";
import AccountSettingsContent from "../HighlightOnboardingComponent/Contents/AccountSettingsContent";
import HighlightOnboardingComponent from "../HighlightOnboardingComponent";
import { rcookie } from "@pcloud/web-utilities/dist/lib/utils";
import { removeItem, addItem } from "../../lib/state/reducers/onboardingSlice";
import { setLeftMenuContent } from "../../lib/state/reducers/contentSlice";
import hashManager from "../../lib/hashManager";
import apiMethod from "../../api/apiMethod";
import { PAGES_EMAIL_REDIRECTS } from "../../containers/EmailRedirectPagesContainer/constants";
import { getSiteLinkFromPlatform, toggleClassForPage, truncateEmailUtility } from "../../lib/utils";
import SkeletonLoad from "./SkeletonLoad";

const LANGUAGES_NOT_FITTING_SCREEN_SIZE = 410;

const LeftMenuContainer = () => {
  const { bodyWidth } = useWindowDimensions();
  const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
  const moveLanguagesOnSmallScreens = bodyWidth < LANGUAGES_NOT_FITTING_SCREEN_SIZE;
  const dispatch = useDispatch();
  const userInfo = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
  const accountBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account) || {};
  const leftMenuContent = useSelector(({ content }) => content.leftMenuContent) || undefined;
  const pageInfo = useSelector(({ content }) => content.pageInfo) || undefined;
  const { explorecards, explorecardsleft, leftmenuitems, leftprofilemenuitems } = pageInfo;
  const {
    toggled,
    activeItem,
    activeItemIndex,
    isProfileOpened,
    previousMainActiveItem,
    previousMainActiveItemIndex,
    isMobileMenuOpened
  } = leftMenuContent;
  const {
    family,
    business: isBusiness,
    premiumlifetime: isPremiumLifetime,
    cryptov2isactive: hasCryptoShare,
    emailverified,
    lastsubscription,
    premium,
    plan,
    planparams,
  } = userInfo;
  const freeQuotaData = HFN.getFreeQuotaData();
  const isFullStorageSpace = freeQuotaData.ispremium ?
    freeQuotaData.freequotagb <= 1 : freeQuotaData.percent <= 1;
  const storageAlmostFull = freeQuotaData.ispremium ?
    freeQuotaData.freequotagb <= 10 : freeQuotaData.percent <= 10;
  const isFamilyOwner = family && family.owner;
  const { permissions = {} } = accountBusiness;
  const userHasActiveSubscription = lastsubscription && (lastsubscription.status === "active" || lastsubscription.status === "past_due");
  const userCanAddStorage = !isBusiness && isPremiumLifetime && (plan == 1 || plan == 3 || (plan == 13 && planparams && planparams.storage < 2) || (plan == 17 && planparams && planparams.storage < 8));
  const userCanUpgrade = !isBusiness && !premium && (!lastsubscription || lastsubscription.status !== "active");
  const hasUpgradeButton = (!userHasActiveSubscription && userCanAddStorage) || userCanUpgrade;
  const {
    billing_manage: hasBillingPermission = false,
    log_view: hasLogPermission = false,
    trash_access: hasTrashPermisson = false,
    crypto_access: hasCryptoPermission = false
  } = permissions;

  const brandingLogo = userInfo?.account?.branding?.logo && HFN.prepUrl(userInfo.account.branding.logo);

  const leftMenuRef = useRef(null);
  const languageTabRef = useRef(null);
  const languageContainerRef = useRef(null);
  const moreAppsContainerRef = useRef(null);
  const moreAppsToggleRef = useRef(null);
  const emailRef = useRef(null);
  const activeLineRef = useRef(null);
  const profilePannelRef = useRef(null);
  const mainMenuContainerRef = useRef(null);

  const [isLanguagesMenuOpened, setIsLanguagesMenuOpened] = useState(false);
  const [isMoreAppsMenuOpened, setIsMoreAppsMenuOpened] = useState(false);
  const [showUserFeedbackModal, setShowUserFeedbackModal] = useState(false);
  const [profileMenuTransitioned, setProfileMenuTransitioned] = useState(true);
  const [isMainContainerScrollActive, setIsMainContainerScrollActive] = useState(false);
  const [profileMenuTransitionToggled, setProfileMenuTransitionToggled] = useState(false);
  const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const onLanguageClick = useCallback((code) => {
    if (typeof gtag === "function") {
      gtag("event", "navigation_click", {
        action: "change language",
      })
    }
    languageTabRef.current.click();
    pCloudIntl.setUserLang(code);
    closeMobileMenu();
  }, []);

  const onAppClick = useCallback((href) => {
    setIsMoreAppsMenuOpened(false);
    moreAppsToggleRef.current.click();
    window.open(href, '_blank');
  }, []);

  const truncateEmail = useMemo(() => {
    return truncateEmailUtility(userInfo.email, false, 184, 8.3);
  }, [userInfo.email]);

  const closeMobileMenu = () => {
    if (!isMobile) return;

    if (leftMenuRef.current.classList.contains('open')) {
      dispatch(setLeftMenuContent({
        ...leftMenuContent,
        isMobileMenuOpened: false
      }));
      dispatch(removeItem(ONBOARDING_ID_UNLOCK_SPACE));
      dispatch(removeItem(ONBOARDING_ID_ACCOUNT_SETTINGS));
    }
  }

  const handleCopyEmail = (e) => {
    e.preventDefault();
    e.clipboardData.setData('text/plain', userInfo.email);
  }

  useOutsideClicker(leftMenuRef, (e) => {
    const overlayContainer = document.querySelector('[class^=Modal__Overlay]');
    if ((
      !overlayContainer ||
      !overlayContainer.contains(e.target)
    )) {
      closeMobileMenu();

      if (isMobile) {
        return;
      }

      let activePageOnInit = hashManager.getState('page'),
        pageFoundInProfileMenu = false,
        menuOptions = filteredMenuOptions(profileMenuOptions, leftprofilemenuitems);

      for (const key in menuOptions) {
        if (
          menuOptions[key].page === activePageOnInit ||
          (
            menuOptions[key].subPages &&
            menuOptions[key].subPages.includes(activePageOnInit)
          )
        ) {
          pageFoundInProfileMenu = true;
        }
      }

      if (isProfileOpened && !pageFoundInProfileMenu) {
        openProfile();
      }
    }
  }, undefined, [isProfileOpened]);

  useOutsideClicker(languageContainerRef, () => {
    setIsLanguagesMenuOpened(false);
  }, languageTabRef);

  useOutsideClicker(moreAppsContainerRef, () => {
    setIsMoreAppsMenuOpened(false);
  }, moreAppsToggleRef);

  const handleActiveItem = () => {
    let activePageOnInit = hashManager.getState('page');
    toggleClassForPage(PAGES_EMAIL_REDIRECTS, "noleft");
    let isCrypto = hashManager.getState('crypto') || (HFN.inCrypto() && activePageOnInit === "filemanager" && currentFolder !== 0);
    let activeKey = '', activeIndex = 0;
    let isBackPage = !!hashManager.getState('backupmobile') || !!hashManager.getState('backupdesktop');
    let menuOptions = filteredMenuOptions(defaultMenuOptions, leftmenuitems);

    if (!activePageOnInit) {
      activeKey = DEFAULT_MENU_ITEMS.MY_PCLOUD;
    } else {
      for (const key in menuOptions) {
        if (
          (menuOptions[key].page === activePageOnInit && !isCrypto && !isBackPage) ||
          (menuOptions[key].page === DEFAULT_MENU_ITEMS.BACKUPS && isBackPage) ||
          (
            !isBackPage &&
            DEFAULT_MENU_ITEMS.CRYPTO_FOLDER === key &&
            (
              isCryptoPage(activePageOnInit) ||
              (activePageOnInit === "cryptolocked" && !HFN.data.afterCryptoUnlock) ||
              (
                activePageOnInit === "cryptolocked" &&
                HFN.data.afterCryptoUnlock &&
                HFN.data.afterCryptoUnlock.page === "filemanager"
              )
            )
          ) ||
          (
            !isBackPage &&
            DEFAULT_MENU_ITEMS.CRYPTO_SHARES === key &&
            (
              (activePageOnInit === "shares" && isCrypto) ||
              (
                activePageOnInit === "cryptolocked" &&
                HFN.data.afterCryptoUnlock &&
                HFN.data.afterCryptoUnlock.page === "shares"
              )
            )
          )
        ) {
          if (key === DEFAULT_MENU_ITEMS.CRYPTO_FOLDER && HFN?.data?.afterCryptoUnlock?.page === "shares") {
            ++activeIndex;
            continue;
          }
          activeKey = key;
          break;
        }
        ++activeIndex;
      }
    }
    if (activeKey) {
      dispatch(setLeftMenuContent({ ...leftMenuContent, activeItem: activeKey, activeItemIndex: activeIndex, previousMainActiveItem: activeKey, previousMainActiveItemIndex: activeIndex, isProfileOpened: false, toggled: localStorage.getItem(LOCAL_STORAGE_TOGGLED) === "true" }));
      return;
    }

    // check profile menu pages
    activeKey = '', activeIndex = 0;
    menuOptions = filteredMenuOptions(profileMenuOptions, leftprofilemenuitems);
    for (const key in menuOptions) {
      if (
        menuOptions[key].page === activePageOnInit ||
        (
          menuOptions[key].subPages &&
          menuOptions[key].subPages.includes(activePageOnInit)
        )
      ) {
        activeKey = key;
        break;
      }
      ++activeIndex;
    }

    if (activeKey) {
      dispatch(setLeftMenuContent({ ...leftMenuContent, activeItem: activeKey, activeItemIndex: activeIndex, toggled: false, isProfileOpened: true }));
      return;
    }

    dispatch(setLeftMenuContent({ activeItem: "", toggled: localStorage.getItem(LOCAL_STORAGE_TOGGLED) === "true", activeItemIndex: -1, isProfileOpened: false, previousMainActiveItem: "", previousMainActiveItemIndex: -1 }));
  };

  useEffect(() => {
    if (!isMobile && !HFN.config.isDlink() && !PAGES_EMAIL_REDIRECTS.includes(hashManager.getState('page'))) {
      dispatch(addItem(ONBOARDING_ID_ACCOUNT_SETTINGS));
    }

    const handleTransitionEnd = () => {
      setProfileMenuTransitioned(true);
      setProfileMenuTransitionToggled(false);
    };

    const profilePannelElement = profilePannelRef.current;
    profilePannelElement.removeEventListener('transitionend', handleTransitionEnd);
    profilePannelElement.addEventListener('transitionend', handleTransitionEnd);

    // check if main container has scroll active
    const checkScroll = () => {
      if (mainMenuContainerRef.current) {
        const hasScroll = mainMenuContainerRef.current.scrollHeight > mainMenuContainerRef.current.clientHeight || profilePannelElement.scrollHeight > profilePannelElement.clientHeight;
        setIsMainContainerScrollActive(hasScroll);
      }
    };

    checkScroll();
    window.removeEventListener('resize', checkScroll);
    window.addEventListener('resize', checkScroll);
    return () => {
      profilePannelElement.removeEventListener('transitionend', handleTransitionEnd);
      window.removeEventListener('resize', checkScroll);
    };
  }, [toggled]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    if (isMobileMenuOpened) {
      appendLeftMenuOverlay();
    } else {
      removeBackgroundOverlay();
    }
  }, [isMobileMenuOpened]);

  useEffect(() => {
    if (!isMobile) {
      removeBackgroundOverlay();
    }
    window.addEventListener('hashchange', handleActiveItem);
    handleActiveItem();
    return () => {
      window.removeEventListener('hashchange', handleActiveItem);
    };
  }, [isMobile, pageInfo, explorecards]);

  useEffect(() => {
    if (isLanguagesMenuOpened) {
      resetHtmlElementScroll();
    } else {
      removeHtmlElementScroll();
    }
  }, [isLanguagesMenuOpened]);

  const openProfile = useCallback(() => {
    let leftMenuContentData = {
      isProfileOpened: !isProfileOpened,
    }
    setProfileMenuTransitioned(false);
    let crypto = {};
    let pageFoundInDefaultMenu = false, pageFoundInProfileMenu = false;

    if (isProfileOpened) {
      let activePageOnInit = hashManager.getState('page');
      let menuOptions = filteredMenuOptions(defaultMenuOptions, leftmenuitems);
      for (const key in menuOptions) {
        if (menuOptions[key].page === activePageOnInit || (key === DEFAULT_MENU_ITEMS.CRYPTO_FOLDER && isCryptoPage(activePageOnInit) || activePageOnInit === "cryptolocked")) {
          pageFoundInDefaultMenu = true;
        }
      }

      menuOptions = filteredMenuOptions(profileMenuOptions, leftprofilemenuitems);
      for (const key in menuOptions) {
        if (menuOptions[key].page === activePageOnInit) {
          pageFoundInProfileMenu = true;
        }
      }

      if (pageFoundInDefaultMenu || pageFoundInProfileMenu) {
        leftMenuContentData.activeItem = previousMainActiveItem || DEFAULT_MENU_ITEMS.MY_PCLOUD;
        leftMenuContentData.activeItemIndex = previousMainActiveItem ? previousMainActiveItemIndex : 0;
        if (previousMainActiveItem === DEFAULT_MENU_ITEMS.CRYPTO_FOLDER && !pCrypt.locked) {
          crypto = { crypto: 1 };
        }
      }

      // Don't navigate to a main if the page is not in the profile section
      if (pageFoundInProfileMenu) {
        setTimeout(() => {
          hashManager.pushState({ page: defaultMenuOptions[leftMenuContentData.activeItem].page, ...crypto }, 2);
        }, 500)
      }
    } else {
      leftMenuContentData.activeItem = "";
      leftMenuContentData.activeItemIndex = -1;
      const activeItemElement = activeLineRef?.current?.getBoundingClientRect();
      if (activeItemElement) {
        createTemporaryActiveLine(activeItemElement.top);
      }
    }

    if (toggled) {
      setProfileMenuTransitionToggled(true);
      dispatch(setLeftMenuContent({ ...leftMenuContent, ...leftMenuContentData, toggled: false }));
    } else {
      dispatch(setLeftMenuContent({ ...leftMenuContent, ...leftMenuContentData }));
      leftMenuContentData.toggled = localStorage.getItem(LOCAL_STORAGE_TOGGLED) === "true";
    }

    if (typeof gtag === "function") {
      gtag("event", "navigation_click", {
        action: "open profile menu",
      })
    }
  }, [leftMenuContent, pageInfo, profileMenuTransitioned]);

  const toggleHandler = useCallback((isButtonClick = true) => {
    if (typeof gtag === "function") {
      gtag("event", "navigation_click", {
        action: "toggle naigation menu",
        state: toggled ? "expanded" : "minimized"
      })
    }

    if (isButtonClick) {
      localStorage.setItem(LOCAL_STORAGE_TOGGLED, !toggled);
    }

    dispatch(setLeftMenuContent({ ...leftMenuContent, toggled: !toggled }));
  }, [leftMenuContent]);

  const tabClickAction = useCallback(([key, value], index) => {
    closeMobileMenu();
    let crypto = {};

    if (key === DEFAULT_MENU_ITEMS.MY_PCLOUD) {
      currentFolder = 0;
    }
    if (key === DEFAULT_MENU_ITEMS.CRYPTO_FOLDER && !pCrypt.locked) {
      crypto = { crypto: 1 };
    }

    if (key === DEFAULT_MENU_ITEMS.CRYPTO_FOLDER && pCrypt.locked) {
      HFN.data.afterCryptoUnlock = { page: value.page, crypto: 1 };
    }
    if (key === DEFAULT_MENU_ITEMS.CRYPTO_SHARES) {
      const tmpState = { page: "shares", tab: "myshares-tab", crypto: 1 };
      if (pCrypt.locked) {
        HFN.data.afterCryptoUnlock = tmpState;
      } else {
        hashManager.pushState(tmpState, 2);
        return;
      }
    }

    dispatch(setLeftMenuContent({
      ...leftMenuContent,
      activeItem: key,
      activeItemIndex: index,
      previousMainActiveItem: isProfileOpened ? previousMainActiveItem : key,
      previousMainActiveItemIndex: isProfileOpened ? previousMainActiveItemIndex : index
    }));

    hashManager.pushState({
      page: (
        [
          DEFAULT_MENU_ITEMS.CRYPTO_FOLDER,
          DEFAULT_MENU_ITEMS.CRYPTO_SHARES
        ].includes(key) && pCrypt.locked
      ) ? CRYPTO_LOCKED : value.page,
      ...crypto
    }, 2);
  }, [leftMenuContent, isLanguagesMenuOpened, userInfo, isMobile]);

  const onRevertDesignClick = () => {
    if (rcookie("oldDesign")) {
      window.location.href = HFN.constructURL("https://v2.pcloud.com");
    } else {
      setShowUserFeedbackModal(true)
    }
  }

  const renderMenuItems = useCallback((isProfile) => {
    let menuOptions = filteredMenuOptions(defaultMenuOptions, leftmenuitems);

    if (isProfile) {
      menuOptions = filteredMenuOptions(profileMenuOptions, leftprofilemenuitems);

      // handle different action cases
      if (menuOptions[PROFILE_MENU_ITEMS.MANAGE_SUBSCRIPTION]) {
        menuOptions[PROFILE_MENU_ITEMS.MANAGE_SUBSCRIPTION].customAction = () => {
          window.open(URLSITE + PCLOUD_LINKS.MANAGE_SUBSCRIPTION, '_blank');
          closeMobileMenu();
        }
      }
      if (menuOptions[PROFILE_MENU_ITEMS.HELP]) {
        menuOptions[PROFILE_MENU_ITEMS.HELP].customAction = () => {
          window.open(URLSITE + PCLOUD_LINKS.HELP_CENTER, '_blank');
          closeMobileMenu();
        }
      }

      if (menuOptions[PROFILE_MENU_ITEMS.LANGUAGE]) {
        menuOptions[PROFILE_MENU_ITEMS.LANGUAGE].customAction = () => {
          setIsLanguagesMenuOpened(prev => !prev);
        }
      }

      if (menuOptions[PROFILE_MENU_ITEMS.LOGOUT]) {
        menuOptions[PROFILE_MENU_ITEMS.LOGOUT].customAction = () => {
          setcookie("pcauth", "", -1);
          setcookie("locationid", "", -1)
          HFN.setDefaultLocations();

          apiMethod('logout', {}, function () {
            HFN.uploadControl.destroy();
            if (HFN.config.isDlink()) {
              location.reload();
            } else {
              const currentPage = HFN.pages.getCurrent();
              sessionStorage.removeItem('pmdata');
              setcookie('pmtinit', '', -1);
              HFN.promoModalTimeout && clearTimeout(HFN.promoModalTimeout);

              if (currentPage && currentPage.reqauth) {
                if (HFN.config.label.hasTradeSite) {
                  location.href = getSiteLinkFromPlatform();
                } else {
                  location.href = "/#page=login";
                }
              } else {
                HFN.logout();
              }
            }
          });
          closeMobileMenu();
          dispatch(setLeftMenuContent({ activeItem: "", toggled: false, activeItemIndex: -1, isProfileOpened: false, previousMainActiveItem: "", previousMainActiveItemIndex: -1 }));
        }
      }
    }

    if (!leftmenuitems.length) {
      return <SkeletonLoad />;
    }

    return Object.entries(menuOptions).map(([key, value], index) => {
      const isLangTab = key === PROFILE_MENU_ITEMS.LANGUAGE;
      const SvgIcon = value.svgIcon;
      const isActive = activeItem === key || (isLangTab && isLanguagesMenuOpened);

      const content = (
        <ItemWrapper
          key={key}
          $isActive={isActive}
          $toggled={(toggled || profileMenuTransitionToggled) && !isProfile && !isMobile}
          $isLangTab={isLangTab}
          $isProfile={isProfile}
          data-tooltip-id="left-menu-item-tooltip"
          data-tooltip-content={
            !isTouchDevice() ? (value.disableTooltip ? null : __(value.name)) : null
          }
          onClick={() => {
            if (value.customAction) {
              value.customAction();
            } else {
              tabClickAction([key, value], index);
            }
          }}
          ref={isLangTab ? languageTabRef : undefined}
          onPointerDown={(e) => {
            if (!isIpad()) {
              e.preventDefault();
            }
            e.currentTarget.focus();
            if (isTouchDevice() && !value.disableTooltip) {
              e.currentTarget.setAttribute(
                'data-tooltip-content',
                __(value.name)
              );
            }

            if (isIpad()) {
              if (value.customAction) {
                value.customAction();
              } else {
                tabClickAction([key, value], index);
              }
            }
          }}
          onPointerUp={(e) => {
            if (isTouchDevice()) {
              e.currentTarget.removeAttribute('data-tooltip-content');
            }
          }}
        >
          <IconWrapper>
            {value.icon ? (
              <FontAwesomeIconGradient
                className={isActive ? value.iconActive : value.icon}
                $isActive={isActive}
                $isMenuItem
              />
            ) : (
              <SvgIcon />
            )}
          </IconWrapper>
          {((!toggled && !profileMenuTransitionToggled) || isProfile || isMobile) && (
            <ItemName $isActive={isActive}>
              {__(value.name)}
              {!emailverified && key === PROFILE_MENU_ITEMS.SETTINGS && <VerifyEmailDot />}
            </ItemName>
          )}
          {value.iconRight && (
            <IconWrapper $isRight>
              <i className={value.iconRight} />
            </IconWrapper>
          )}
        </ItemWrapper>
      )

      if (isLangTab) {
        return <Popover
          key={key}
          trigger="click"
          render={
            <MenuContainer ref={languageContainerRef} $opened={isLanguagesMenuOpened}>
              {getLanguages().map(({ code, title }) => (
                <MenuItem key={code} onClick={() => onLanguageClick(code)}>
                  {__(title)}
                  {code === pCloudIntl.getLang() && <LanguageFontAwesomeIcon className="smallIcon fa-regular fa-check" />}
                </MenuItem>
              ))}
            </MenuContainer>
          }
          cssPosition="absolute"
          position={moveLanguagesOnSmallScreens ? "center" : "right"}
          align={moveLanguagesOnSmallScreens ? "right" : "left"}
          valign={isMobile && isBusiness ? 'center' : 'top'}
          flyin="left"
        >
          {content}
        </Popover >
      }

      return content
    })
  }, [leftMenuContent, isLanguagesMenuOpened, userInfo, isMobile, isBusiness, moveLanguagesOnSmallScreens, leftprofilemenuitems, leftmenuitems, profileMenuTransitionToggled]);

  const renderBottomActions = useCallback(() => {
    // Bottom actions
    bottomOptions[BOTTOM_ITEMS.DEVICE].action = () => {
      if (typeof gtag === "function") {
        gtag("event", "navigation_click", {
          action: "download app",
        })
      }
      if (isMobile) {
        window.open(URLSITE + PCLOUD_LINKS.CLOUD_STORAGE_MOBILE, '_blank');
      } else {
        window.open(URLSITE + PCLOUD_LINKS.CLOUD_STORAGE_DESKTOP, '_blank');
      }
    }
    bottomOptions[BOTTOM_ITEMS.MORE_APPS].action = () => {
      if (typeof gtag === "function") {
        gtag("event", "navigation_click", {
          action: "more apps",
        })
      }
      setIsMoreAppsMenuOpened(prev => !prev);
    }
    bottomOptions[BOTTOM_ITEMS.PRICING].action = () => {
      if (typeof gtag === "function") {
        gtag("event", "navigation_click", {
          action: "pricing",
        })
      }
      window.open(URLSITE + (family ? PCLOUD_LINKS.FAMILY_PRICING : PCLOUD_LINKS.CLOUD_STORAGE_PRICING), '_blank');
    }

    return Object.entries(bottomOptions).map(([key, value]) => {
      const SvgIcon = value.svgIcon;
      const customStyle = { cursor: 'pointer', outline: 'none' };

      const content = <IconWrapper key={key} $isBottom ref={key === BOTTOM_ITEMS.MORE_APPS ? moreAppsToggleRef : undefined}>{value.icon ?
        <FontAwesomeIconGradient data-tooltip-id="left-menu-bottom-item-tooltip" data-tooltip-content={key === BOTTOM_ITEMS.MORE_APPS && isMoreAppsMenuOpened ? "" : __(value.name)} style={customStyle} onClick={() => value.action()} className={value.icon} /> :
        <SvgIcon data-tooltip-id="left-menu-bottom-item-tooltip" data-tooltip-content={__(value.name)} style={customStyle} onClick={() => value.action()} />}
      </IconWrapper>

      if (key === BOTTOM_ITEMS.MORE_APPS) {
        return <Popover
          key={key}
          trigger="click"
          render={
            <MenuContainer ref={moreAppsContainerRef} $opened={isMoreAppsMenuOpened}>
              {moreAppsOptions.map(({ href, name, icon }) => {
                const SvgIcon = icon;
                return (
                  <MenuItem key={name} onClick={() => { onAppClick(href) }} $isMoreApps>
                    <SvgIcon /> {name}
                  </MenuItem>
                )
              })}
            </MenuContainer>
          }
          onOpen={() => setIsMoreAppsMenuOpened(false)}
          cssPosition="fixed"
          position="top"
          align="left"
          flyin="bottom"
        >
          {content}
        </Popover>
      }

      return content;
    })
  }, [isMoreAppsMenuOpened, family]);

  return (
    <Container
      id="left-menu-container"
      className={isMobileMenuOpened ? 'open' : ''}
      onCopy={handleCopyEmail}
      ref={leftMenuRef}
      $toggled={toggled && !isProfileOpened && !isMobile}
    >
      <HighlightOnboardingComponent
        id={ONBOARDING_ID_ACCOUNT_SETTINGS}
        content={[<AccountSettingsContent />]}
        temporaryHide={(isProfileOpened || toggled) && !isMobile}
        showOverlay={true}
        notShowAfterClose={true}
        place={"top-right"}
        delay={500}
        offset={-20}
      >
        <ProfileContainer
          $toggled={toggled && !isMobile}
          $isOpened={isProfileOpened}
          onPointerDown={(e) => {
            if (!isIpad()) {
              e.preventDefault();
            }
            if (isTouchDevice()) {
              const tooltipContent = isFullStorageSpace
                ? __("full_quota_modal_header")
                : !emailverified
                  ? __("email_not_verified", "Email not verified")
                  : storageAlmostFull
                    ? __("almost_full_quota_modal_header")
                    : null;

              const tooltipElement = e.currentTarget.querySelector("[data-tooltip-id='profile-warning-tooltip']");
              if (tooltipElement && tooltipContent) {
                tooltipElement.setAttribute("data-tooltip-content", tooltipContent);
              }
            }

            openProfile();
          }}
          onPointerUp={(e) => {
            if (isTouchDevice()) {
              const tooltipElement = e.currentTarget.querySelector("[data-tooltip-id='profile-warning-tooltip']");
              if (tooltipElement) {
                tooltipElement.removeAttribute("data-tooltip-content");
              }
            }
          }}
        >
          {/* langs */}
          <ProfileInitials
            data-tooltip-id="profile-warning-tooltip"
            data-tooltip-content={
              !isTouchDevice()
                ? (isFullStorageSpace
                  ? __("full_quota_modal_header")
                  : !emailverified
                    ? __("email_not_verified", "Email not verified")
                    : storageAlmostFull
                      ? __("almost_full_quota_modal_header")
                      : null)
                : null
            }
            $danger={!isProfileOpened && isFullStorageSpace}
          >
            {(!isProfileOpened && (!emailverified || (storageAlmostFull && !isFullStorageSpace))) && (
              <ProfileWarning $storageAlmostFull={storageAlmostFull && !emailverified} />
            )}
            {isProfileOpened
              ? <i className="mediumIcon fa-solid fa-arrow-left" />
              : isFullStorageSpace
                ? <i className="mediumIcon fa-solid fa-cloud-exclamation" />
                : userInfo.email
                  ? userInitials(userInfo)
                  : null}
          </ProfileInitials>
          {(!toggled || isMobile) && (
            <ProfileEmail
              ref={emailRef}
              data-tooltip-id="email-tooltip"
              data-tooltip-content={
                !isTouchDevice()
                  ? (truncateEmail === userInfo.email && (!toggled || isMobile))
                    ? ""
                    : userInfo.email
                  : null
              }
              onPointerDown={(e) => {
                if (!isIpad()) {
                  e.preventDefault();
                }
                if (isTouchDevice()) {
                  e.currentTarget.setAttribute(
                    "data-tooltip-content",
                    (truncateEmail === userInfo.email && (!toggled || isMobile)) ? "" : userInfo.email
                  );
                }
              }}
              onPointerUp={(e) => {
                if (isTouchDevice()) {
                  e.currentTarget.removeAttribute("data-tooltip-content");
                }
              }}
            >
              {truncateEmail}
            </ProfileEmail>
          )}
          {(isProfileOpened && !emailverified) && (
            <NotVerifiedText>{__("email_not_verified", "Email not verified")}</NotVerifiedText>
          )}
        </ProfileContainer>

      </HighlightOnboardingComponent>
      <ProfilePannelBackgroundLayer
        $isOpened={isProfileOpened}
        $toggled={toggled && !isMobile}
        $transitioned={profileMenuTransitioned}
        $isMainContainerScrollActive={isMainContainerScrollActive}
        $profileMenuTransitionToggled={profileMenuTransitionToggled}
      />
      <ProfilePannel
        ref={profilePannelRef}
        $isOpened={isProfileOpened}
        $transitioned={profileMenuTransitioned}
        $isMainContainerScrollActive={isMainContainerScrollActive}
        $profileMenuTransitionToggled={profileMenuTransitionToggled}
      >
        <UserPlanInfo />
        <RelativeContainer>
          {(activeItemIndex !== -1 && isProfileOpened) && <ActiveLine $activeItem={activeItemIndex} $isProfileOpened={isProfileOpened} $isProfile={true} $hasUpgradeButton={hasUpgradeButton}><ActiveBlock $toggled={toggled && !isMobile} /></ActiveLine>}
          {renderMenuItems(true)}
        </RelativeContainer>
        {leftprofilemenuitems.some(({ name }) => name === PROFILE_MENU_ITEMS.PREV_DESIGN) && (
          <>
            <UserFeedbackModal opened={showUserFeedbackModal} onClose={() => setShowUserFeedbackModal(false)} />
            <AlignWrapper>
              <RevertDesignWrapper onClick={onRevertDesignClick}>
                <IconWrapper><FontAwesomeIconGradient className="fa-face-thinking fa-light baseIcon" /></IconWrapper>
                <RevertDesignText>{__("beta_revert_button", "Change pCloud interface back to old style")}</RevertDesignText>
              </RevertDesignWrapper>
            </AlignWrapper>
          </>
        )}
        {(!isMobile && (profileMenuTransitioned || isMainContainerScrollActive)) &&
          <BottomTabsContainer>
            {renderBottomActions()}
          </BottomTabsContainer>
        }
      </ProfilePannel>
      <MainMenuContainer ref={mainMenuContainerRef} $isIpad={isIpad() && !isMobile}>
        <MainTabsPannel id="main-tabs-pannel">
          {(activeItemIndex !== -1 && !isProfileOpened) && <ActiveLine ref={activeLineRef} $activeItem={activeItemIndex} $isProfileOpened={isProfileOpened} $isProfile={false}><ActiveBlock $toggled={toggled && !isMobile} /></ActiveLine>}
          {renderMenuItems(false)}
        </MainTabsPannel>
        {(explorecardsleft && explorecards.length > 0) && <ExploreCards closeMobileMenu={closeMobileMenu} />}
        {brandingLogo ? (
          <BrandingLogo src={brandingLogo} alt="Branding Logo" $toggled={toggled} />
        ) : null}
        <MenuBottomContainer $hasBrandingLogo={brandingLogo} $isProfileOpened={isProfileOpened}>
          <CustomerJourney closeMobileMenu={closeMobileMenu} />
          {!isMobile &&
            <BottomTabsContainer $toggled={toggled}>
              {renderBottomActions()}
              {toggled && < StyledReactTooltip className="cv-tooltip" id="left-menu-item-tooltip" place="right" noArrow offset={12} />}
              <StyledReactTooltip className="cv-tooltip" id="left-menu-bottom-item-tooltip" place={toggled ? "right" : "top"} noArrow offset={toggled ? 36 : undefined} />
              <StyledReactTooltip className="cv-tooltip" id="email-tooltip" place="right" noArrow offset={12} />
              <StyledReactTooltip className="cv-tooltip" id="left-menu-toggle-btn" place="top" noArrow offset={12} />
              {!isProfileOpened && <StyledReactTooltip className="cv-tooltip" id="profile-warning-tooltip" place="bottom-end" noArrow offset={12} />}
            </BottomTabsContainer>}
        </MenuBottomContainer>
      </MainMenuContainer>
      {
        (!isMobile && !isProfileOpened && profileMenuTransitioned) && (
          <ToggleButton
            data-tooltip-id="left-menu-toggle-btn"
            data-tooltip-content={
              !isTouchDevice()
                ? (!toggled
                  ? __('tooltip_collapse_menu', 'Collapse menu')
                  : __('tooltip_expand_menu', 'Expand menu'))
                : null
            }
            onPointerDown={(e) => {
              if (!isIpad()) {
                e.preventDefault();
              }
              if (isTouchDevice()) {
                const tooltipContent = !toggled
                  ? __('tooltip_collapse_menu', 'Collapse menu')
                  : __('tooltip_expand_menu', 'Expand menu');
                e.currentTarget.setAttribute('data-tooltip-content', tooltipContent);
              }
            }}
            onPointerUp={(e) => {
              if (isTouchDevice()) {
                e.currentTarget.removeAttribute('data-tooltip-content');
              }
            }}
            onClick={toggleHandler}
            $toggled={toggled}
          >
            <FontAwesomeIconToggle className="baseIcon darkColorIcon fa-regular fa-chevron-left" $toggled={toggled} />
          </ToggleButton>
        )
      }
    </Container>
  )
};

export default LeftMenuContainer;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 0px;
  display: flex;
  width: 24px;
  height: 56px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  left: ${({ $toggled }) => $toggled ? 74 : 242}px;
  border-radius: var(--spacing-none) var(--spacing-xs) var(--spacing-xs) var(--spacing-none);
  background: var(--surface-primary);
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: left 0.5s ease, opacity 0.3s ease-in-out;
  box-shadow: inset rgba(0, 0, 0, 0.08) 6px 0px 10px -6px;
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
`;

const Container = styled.div`
  width: ${({ $toggled }) => $toggled ? 72 : 240}px;
  transition: width 0.5s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1000;
  background-color: var(--surface-primary);

  &:hover > ${ToggleButton} {
    opacity: 1;
  }

  @media only screen and (max-width: 1023px) {
    position: fixed;
    top: 0;
    left: -240px;
    height: 100%;
    transition: 0.5s;
    z-index: 1000;
    &.open {
      left: 0;
      & #beta-marker {
        opacity: 1;
      }
    }
  }
`;

const hideScrollbarStyles = css`
  @media only screen and (min-width: 1023px) {
    /* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar in Firefox */
    scrollbar-width: none;

    /* Hide scrollbar in Internet Explorer and Edge */
    -ms-overflow-style: none;
  }
`

const MainMenuContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;

  ${hideScrollbarStyles};
`;

const FontAwesomeIconToggle = styled.i`
  transition: transform 0.5s ease;
  ${({ $toggled }) => $toggled && css`
    transform: rotate(180deg);
  `};
`;

export const MenuContainer = styled.div`
  white-space: nowrap;
  width: 100%;
  min-width: 160px;
  border-radius: 5px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  border: 0.5px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 8px 0;
  background-color: var(--color-base-white);
`

export const MenuItem = styled.a`
  display: flex;
  padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-xs) var(--spacing-md);
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--color-base-black);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 28px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  justify-content: ${({ $isMoreApps }) => $isMoreApps ? 'flex-start' : 'space-between'};
  &:hover {
    background-color: var(--color-grey-300);
  }
`

export const LanguageFontAwesomeIcon = styled.i`
  color: var(--text-link);
`

const ProfileContainer = styled.div`
  display: flex;
  padding: 12px 8px 11px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--divider-primary);
  margin-bottom: 8px;
  align-items: center;
  cursor: pointer;
  justify-content: ${({ $toggled }) => $toggled ? 'center' : 'flex-start'};
  position: relative;
  background-color: ${({ $isOpened }) => $isOpened ? 'var(--color-grey-100)' : 'var(--color-grey-200)'};
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
`

const NotVerifiedText = styled.div`
  color: var(--color-functional-danger500);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  left: 48px;
  bottom: 4px;  
`

const ProfileInitials = styled.div`
  width: 32px;
  height: 32px;
  background: ${({ $danger }) => $danger ? "var(--color-functional-danger500)" : "var(--color-primary-700)"};
  margin-right: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--color-base-white);
  position: relative;
`

const ProfileWarning = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #F0F4F5;
  background-color: ${({ $storageAlmostFull }) => $storageAlmostFull ? "--color-functional-warning500" : "var(--color-functional-danger500)"};
  top: 1px;
  right: 1px;
  box-sizing: border-box;
`

const ProfileEmail = styled.p`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color:  var(--text-primary);
  white-space: nowrap;
  width: 184px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  justify-content: ${({ $toggled }) => $toggled ? 'center' : 'flex-start'};
  cursor: pointer;
  padding: 0px 8px 0px ${({ $toggled }) => $toggled ? 8 : 18}px;
  z-index: ${({ $isProfile }) => $isProfile ? 5 : 2};
  white-space: nowrap;
  &:hover {
    ${({ $isActive }) => !$isActive && css`
      background-color: var(--color-grey-300);
    `}
  }

  ${({ $isActive, $isLangTab }) => ($isActive && $isLangTab) && css`
    padding: 0px 8px 0px ${({ $toggled }) => $toggled ? 6 : 12}px;
    border-left: 6px solid var(--color-primary-500);
    background-color: var(--color-base-white);
  `}
  -webkit-tap-highlight-color: transparent;
`;

const ItemName = styled.div`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 15px;
  font-weight: ${({ $isActive }) => $isActive ? 500 : 400};
  line-height: 24px;
  margin-left: 8px;
  position: relative;
`;

const MenuBottomContainer = styled.div`
  ${({ $hasBrandingLogo }) => !$hasBrandingLogo && css`
    margin-top: auto;
  `}
`

const BottomTabsContainer = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
  border-top: 1px solid var(--divider-primary);
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ $toggled }) => $toggled ? 'column' : 'row'};
  gap: 24px;
  align-items: center;
`

const MainTabsPannel = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid var(--divider-primary);
  position: relative;
`

const ActiveLine = styled.div`
  height: 40px;
  width: 6px;
  background-color: var(--color-primary-500);
  position: absolute;
  left: 0;
  transition: top 0.2s ease-in-out;
  top: ${({ $activeItem, $isProfileOpened, $hasUpgradeButton }) => $activeItem * 40}px;
`;

const ActiveBlock = styled.div`
  height: 40px;
  width: ${({ $toggled }) => $toggled ? 66 : 234}px;
  background: var(--color-base-white);
  margin-left: 6px;
  transition: width 0.5s ease;
`

const ProfilePannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 113px);
  width: 100%;
  position: absolute;
  top: 56px;
  transition: left 0.5s ease, opacity 0.5s ease;
  width: 240px;
  overflow-y: auto;

  ${hideScrollbarStyles};

  @media only screen and (max-width: 1023px) {
    height: calc(100% - 56px);
  }

  ${({ $transitioned, $isMainContainerScrollActive }) => ($transitioned || $isMainContainerScrollActive) && css`
    height: calc(100% - 56px);
  `}
`

const ProfilePannel = styled(ProfilePannelContainer)`
  background-color: var(--surface-primary);
  z-index: 3;
  left: ${({ $isOpened }) => $isOpened ? "0px" : "-242px"};
`

const ProfilePannelBackgroundLayer = styled(ProfilePannelContainer)`
  left: 0;
  opacity: 0;
  background-color: var(--color-grey-400);
  ${({ $isOpened }) => $isOpened && css`
    opacity: 1;
    visibility: visible;
    z-index: 2;
  `}
  ${({ $toggled }) => $toggled && css`
    height: 0;
  `}
  ${({ $profileMenuTransitionToggled }) => $profileMenuTransitionToggled && css`
    display: none;
  `}
`

const FontAwesomeIconGradient = styled.i`
  background: linear-gradient(180deg, #156A73 0%, #001C1F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StyledReactTooltip = styled(ReactTooltip)`
  background-color: var(--color-grey-800);
  z-index: 1000;
  color: var(--color-base-white);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
`

const IconWrapper = styled.div`
  width: ${({ $isBottom }) => $isBottom ? 24 : 20}px;
  height: ${({ $isBottom }) => $isBottom ? 24 : 20}px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  ${({ $isRight }) => $isRight && css`
    margin-left: auto;
  `}
`

const VerifyEmailDot = styled.div`
  position: absolute;
  top: 0px;
  right: -10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-functional-danger500);
`

const RelativeContainer = styled.div`
  position: relative;
  flex-grow: 1;
`;

const AlignWrapper = styled.div`
  padding: var(--spacing-sm, 8px);
`;

const RevertDesignWrapper = styled.div`
  border-radius: var(--radius-minimum, 4px);
  border: 1px solid var(--border-primary);
  background: var(--surface-secondary);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: var(--spacing-sm, 8px);
  cursor: pointer;
`;

const RevertDesignText = styled.div`
  font-size: 15px;
  color: var(--text-primary);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BrandingLogo = styled.img`
  width: fit-content;
  height: fit-content;
  margin: auto auto 8px auto;

  ${({ $toggled }) => $toggled && css`
    width: 68px;
    height: auto;
  `}
`;
